import { ApiV10EnrollmentSendHelpEmailPostRequest, BooleanApiResponse, EnrollmentApi } from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "./useApi";

export const useSendHelpEmailRequest = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10EnrollmentSendHelpEmailPostRequest
> => {
  const applicationApi = useApi(EnrollmentApi);
  const mutation = useMutation(async (request: ApiV10EnrollmentSendHelpEmailPostRequest) => {
    const response = await applicationApi.apiV10EnrollmentSendHelpEmailPost(request);

    return response;
  });
  return mutation;
};
