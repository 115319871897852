import { EmailInput, PasswordInput, TextInput } from "@chq/components";
import { Grid } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { usePasswordRules } from "../data/usePasswordRules";

export enum Fields {
  email = "email",
  firstName = "first-name",
  lastName = "last-name",
  password = "password",
  passwordAgain = "password-again",
}

export type FormProps = {
  [Fields.email]: string;
  [Fields.firstName]: string;
  [Fields.lastName]?: string;
  [Fields.password]?: string;
  [Fields.passwordAgain]?: string;
};

type Props = {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  passwordAgain?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();
  const passwordRules = usePasswordRules();

  return yup.object({
    [Fields.email]: yup
      .string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`business-user-form.${Fields.email}.label`) })),
    [Fields.firstName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-user-form.${Fields.firstName}.label`) })),
    [Fields.lastName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-user-form.${Fields.lastName}.label`) })),
    [Fields.password]: passwordRules,
    [Fields.passwordAgain]: yup
      .string()
      .required()
      .oneOf([yup.ref(`${[Fields.password]}`), null], t("errors.noPasswordMatch")),
  });
};

export const useFormikConfig = ({
  email: initialEmail = "",
  firstName: initialFirstName = "",
  lastName: initialLastName = "",
  password: initialPassword = "",
  passwordAgain: initialPasswordAgain = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: initialEmail,
      [Fields.firstName]: initialFirstName,
      [Fields.lastName]: initialLastName,
      [Fields.password]: initialPassword,
      [Fields.passwordAgain]: initialPasswordAgain,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const RegistrationForm: React.FC = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextInput
            required
            fullWidth
            label={t("login-registration-form.first-name")}
            id={Fields.firstName}
            name={Fields.firstName}
            value={formik.values[Fields.firstName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
            helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            required
            fullWidth
            label={t("login-registration-form.last-name")}
            id={Fields.lastName}
            name={Fields.lastName}
            value={formik.values[Fields.lastName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
            helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailInput
            label={t("login-registration-form.email")}
            required
            fullWidth
            id={Fields.email}
            name={Fields.email}
            value={formik.values[Fields.email]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
            helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            label={t("login-registration-form.password")}
            rules={[
              {
                humanReadableFormat: t("common.password-rules.min-length"),
                regEx: /.{8,}/,
              },
              {
                humanReadableFormat: t("common.password-rules.uppercase"),
                regEx: /[A-Z]/,
              },
              { humanReadableFormat: t("common.password-rules.digit"), regEx: /[0-9]/ },
            ]}
            required
            fullWidth
            id={Fields.password}
            name={Fields.password}
            value={formik.values[Fields.password]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.password] && Boolean(formik.errors[Fields.password])}
            helperText={formik.touched[Fields.password] && formik.errors[Fields.password]}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            label={t("login-registration-form.re-enter-password")}
            required
            fullWidth
            id={Fields.passwordAgain}
            name={Fields.passwordAgain}
            value={formik.values[Fields.passwordAgain]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.passwordAgain] && Boolean(formik.errors[Fields.passwordAgain])}
            helperText={formik.touched[Fields.passwordAgain] && formik.errors[Fields.passwordAgain]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default RegistrationForm;
