/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UploadTemplatesLinks
 */
export interface UploadTemplatesLinks {
  /**
   *
   * @type {string}
   * @memberof UploadTemplatesLinks
   */
  vehicleListTemplateLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTemplatesLinks
   */
  driverListTemplateLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTemplatesLinks
   */
  workersCompTemplateLink?: string | null;
}

export function UploadTemplatesLinksFromJSON(json: any): UploadTemplatesLinks {
  return UploadTemplatesLinksFromJSONTyped(json, false);
}

export function UploadTemplatesLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadTemplatesLinks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vehicleListTemplateLink: !exists(json, "vehicleListTemplateLink") ? undefined : json["vehicleListTemplateLink"],
    driverListTemplateLink: !exists(json, "driverListTemplateLink") ? undefined : json["driverListTemplateLink"],
    workersCompTemplateLink: !exists(json, "workersCompTemplateLink") ? undefined : json["workersCompTemplateLink"],
  };
}

export function UploadTemplatesLinksToJSON(value?: UploadTemplatesLinks | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vehicleListTemplateLink: value.vehicleListTemplateLink,
    driverListTemplateLink: value.driverListTemplateLink,
    workersCompTemplateLink: value.workersCompTemplateLink,
  };
}
