import { YesNoValues } from "@chq/components";

export const yesOrNoConversion = (b: boolean | null | undefined) => {
  if (b === true) {
    return YesNoValues.yes;
  } else if (b === false) {
    return YesNoValues.no;
  } else {
    return undefined;
  }
};

export const trueOrFalseConversion = (str: string | null | undefined) => {
  if (str === YesNoValues.yes) {
    return true;
  } else if (str === YesNoValues.no) {
    return false;
  } else {
    return undefined;
  }
};
