import { ApplicationApiResponse, CreateApplicationRequest, EnrollmentApi } from "@chq/lastmiledelivery-api";
import { useContext } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { EmailContext } from "../authentication/EmailContext";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

type CreateApplication = CreateApplicationRequest & {
  applicationState: string;
};

export const useCreateApplication = (): UseMutationResult<
  ApplicationApiResponse,
  Error | unknown,
  CreateApplication
> => {
  const applicationApi = useApi(EnrollmentApi);
  const { updateApplication } = useCachedApplication();
  const { updateEmail } = useContext(EmailContext);

  const mutation = useMutation(async ({ applicationState, ...request }: CreateApplication) => {
    const createApplicationResult = await applicationApi.apiV10EnrollmentCreateApplicationPost({
      createApplicationRequest: request,
    });
    if (createApplicationResult.error) {
      throw new Error(createApplicationResult.error.message || "Failed to create applicaiton");
    }
    const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({
      application: { ...createApplicationResult.data, applicationState },
    });
    if (response.error) {
      throw new Error(response.error.message || "Failed to create applicaiton");
    }
    if (response.data) {
      updateEmail(request.contactEmail!);
      updateApplication(response.data);
    }

    return response;
  });
  return mutation;
};
