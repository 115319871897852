import {
  Accordion,
  DatePicker,
  NumberPicker,
  NumericInput,
  StyleLabel,
  TextInput,
  ToggleSwitch,
  TooltipHelp,
} from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classnames from "classnames";
import { startOfToday } from "date-fns";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { maxInt } from "../utils/number-validation";

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    marginTop: "1rem",
    "& .MuiTypography-body1": {
      margin: ".5rem 0",
    },
  },
  adornmentField: {
    "& .MuiInputLabel-formControl": {
      position: "relative",
    },
    "& .MuiInputBase-root": {
      marginTop: "-1rem",
    },
    "& div": {
      justifyContent: "flex-start",
      // This captures the tooltip button
      "& div": {
        height: "1rem",

        position: "relative",
      },
    },
  },
  fieldMargin: {
    marginTop: "1.75rem",
  },
  heading: {
    color: theme.palette.secondary.main,
    marginBottom: "1.5rem",
  },
  labelOneLine: {
    whiteSpace: "nowrap",
  },
  labelOneLineDesktop: {
    height: "5rem",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      height: "auto",
    },
  },
  toggleSwitch: {
    "& label": {
      marginLeft: ".125rem",
    },
  },
  toggleSwitchContain: {
    margin: "2rem 0",
  },
  label: {
    position: "relative",
    top: "1rem",
  },
}));

export enum Fields {
  date = "effective-date",
  vehicles = "vehicles",
  years = "years",
  providers = "providers",
  liability = "liability-cargo",
  workersComp = "workers-comp",
  miles = "miles",
}

type Props = {
  date?: Date;
  vehicles?: number;
  years?: number;
  providers?: string;
  liability?: boolean;
  workersComp?: boolean;
  miles?: number;
};

export type FormProps = {
  [Fields.date]: Date | undefined;
  [Fields.vehicles]: number;
  [Fields.years]: number;
  [Fields.providers]: string;
  [Fields.liability]: boolean;
  [Fields.workersComp]: boolean;
  [Fields.miles]: number | undefined;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = (additionalFields: ObjectShape = {}) => {
  const [t] = useTranslation();
  const today = startOfToday();
  return yup.object().shape(
    {
      [Fields.date]: yup
        .date()
        .nullable()
        .required(t("errors.required", { field: t("coverage-dates-form.effective-date.label") }))
        .min(today, t("coverage-dates-form.errors.min-effective-date")),
      [Fields.vehicles]: yup
        .number()
        .required(t("errors.required", { field: t("coverage-dates-form.vehicles.label") }))
        .min(1, t("errors.min", { field: t("coverage-dates-form.vehicles.help-error-text"), min: 1 }))
        .max(maxInt, t("errors.max", { field: t("coverage-dates-form.vehicles.help-error-text"), max: maxInt }))
        .typeError(t(`errors.required`, { field: t("coverage-dates-form.vehicles.help-error-text") })),
      [Fields.years]: yup
        .number()
        .required(t("errors.required", { field: t("coverage-dates-form.years.label") }))
        .min(0, t("errors.min", { field: t("coverage-dates-form.years.help-error-text"), min: 0 }))
        .max(maxInt, t("errors.max", { field: t("coverage-dates-form.years.help-error-text"), max: maxInt }))
        .typeError(t(`errors.required`, { field: t("coverage-dates-form.years.help-error-text") })),
      [Fields.providers]: yup
        .string()
        .required(t("errors.required", { field: t("coverage-dates-form.providers.label") })),
      [Fields.liability]: yup.boolean().when(Fields.workersComp, {
        is: true,
        then: yup.boolean(),
        otherwise: yup
          .boolean()
          .required(t("errors.required", { field: t("coverage-dates-form.liability.label") }))
          .oneOf([true]),
      }),
      [Fields.workersComp]: yup.boolean().when(Fields.liability, {
        is: true,
        then: yup.boolean(),
        otherwise: yup
          .boolean()
          .required(t("errors.required", { field: t("coverage-dates-form.workers-comp.label") }))
          .oneOf([true]),
      }),
      [Fields.miles]: yup
        .number()
        .min(1, t("errors.min", { field: t("coverage-dates-form.miles.label"), min: 1 }))
        .max(maxInt, t("errors.max", { field: t("coverage-dates-form.miles.help-error-text"), max: maxInt }))
        .required(t("errors.required", { field: t("coverage-dates-form.miles.help-error-text") }))
        .typeError(t(`errors.required`, { field: t("coverage-dates-form.miles.help-error-text") })),
      ...additionalFields,
    },
    [[Fields.liability, Fields.workersComp]],
  );
};

export const useFormikConfig = ({
  date: initialDate,
  vehicles: initialVehicles = 1,
  years: initialYears = 0,
  providers: initialProviders = "",
  liability: initialLiability = false,
  workersComp: initialWorkersComp = false,
  miles: initialMiles = undefined,
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.date]: initialDate,
      [Fields.vehicles]: initialVehicles,
      [Fields.years]: initialYears,
      [Fields.providers]: initialProviders,
      [Fields.liability]: initialLiability,
      [Fields.workersComp]: initialWorkersComp,
      [Fields.miles]: initialMiles,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const CoverageDatesForm: React.FC<Props> = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} className={classes.fieldMargin}>
          <DatePicker
            required
            label={t("coverage-dates-form.effective-date.label")}
            id={Fields.date}
            name={Fields.date}
            KeyboardButtonProps={{
              "aria-label": t("coverage-dates-form.effective-date.button"),
            }}
            disablePast
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(date: any) => {
              formik.setFieldValue(Fields.date, date);
            }}
            margin="none"
            onBlur={formik.handleBlur}
            value={formik.values[Fields.date] || null}
            error={formik.touched[Fields.date] && Boolean(formik.errors[Fields.date])}
            helperText={formik.touched[Fields.date] && formik.errors[Fields.date]}
          />
        </Grid>
        <Grid item container xs={12} direction="row" alignItems="flex-start">
          <Grid item container xs={12} sm={12} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={10} lg={11} className={classnames(classes.fieldMargin, classes.label)}>
              <StyleLabel variant="h4" labelText={t("coverage-dates-form.vehicles.label")} />
            </Grid>
            <Grid item xs={12}>
              <NumberPicker
                required
                id={Fields.vehicles}
                name={Fields.vehicles}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.vehicles]}
                error={formik.touched[Fields.vehicles] && Boolean(formik.errors[Fields.vehicles])}
                helperText={formik.touched[Fields.vehicles] && formik.errors[Fields.vehicles]}
                inputProps={{ "aria-label": `${t("coverage-dates-form.vehicles.label")}` }}
                min={1}
                data-testid={"vehicles"}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={10} lg={11} className={classnames(classes.fieldMargin, classes.label)}>
              <StyleLabel variant="h4" labelText={t("coverage-dates-form.years.label")} />
            </Grid>
            <Grid item xs={12}>
              <NumberPicker
                required
                id={Fields.years}
                name={Fields.years}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[Fields.years]}
                error={formik.touched[Fields.years] && Boolean(formik.errors[Fields.years])}
                helperText={formik.touched[Fields.years] && formik.errors[Fields.years]}
                min={1}
                inputProps={{ "aria-label": `${t("coverage-dates-form.years.label")}` }}
                data-testid={"years"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.fieldMargin}>
          <NumericInput
            required
            thousandSeparator
            label={t("coverage-dates-form.miles.label")}
            id={Fields.miles}
            name={Fields.miles}
            value={formik.values[Fields.miles]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.miles] && Boolean(formik.errors[Fields.miles])}
            helperText={formik.touched[Fields.miles] && formik.errors[Fields.miles]}
            className={classes.labelOneLineDesktop}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldMargin}>
          <TextInput
            required
            fullWidth
            label={t("coverage-dates-form.providers.label")}
            id={Fields.providers}
            name={Fields.providers}
            value={formik.values[Fields.providers]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.providers] && Boolean(formik.errors[Fields.providers])}
            helperText={formik.touched[Fields.providers] && formik.errors[Fields.providers]}
            labelAdornment={
              <TooltipHelp title={t("coverage-dates-form.providers.tooltip").toString()} placement="right" />
            }
            className={classnames(classes.labelOneLine, classes.adornmentField)}
          />
        </Grid>
        <Grid container className={classes.toggleSwitchContain}>
          <Typography className={classes.heading} variant="h2">
            {t("coverage-dates-form.coverage-needed.label")}
          </Typography>
          <Grid item xs={12} className={classes.toggleSwitch}>
            <ToggleSwitch
              onChange={formik.handleChange}
              id={Fields.liability}
              name={Fields.liability}
              label={t("coverage-dates-form.fleet-coverage.label")}
              value={formik.values[Fields.liability]}
            />
          </Grid>
          <Grid item className={classes.accordion} xs={12}>
            <Accordion
              titleText={t("coverage-dates-form.auto-liability.label")}
              showSuccessIcon={formik.values[Fields.liability] === true ? true : false}
            >
              <Grid container>
                <Typography variant="body1">{t("coverage-dates-form.auto-liability.main")}</Typography>
                <Typography variant="body1">
                  <strong>{t("coverage-dates-form.coverages-limits.label")}</strong> -{" "}
                  {t("coverage-dates-form.auto-liability.coverages-limits")}
                </Typography>
              </Grid>
            </Accordion>
          </Grid>
          <Grid item className={classes.accordion} xs={12}>
            <Accordion
              titleText={t("coverage-dates-form.general-liability.label")}
              showSuccessIcon={formik.values[Fields.liability] === true ? true : false}
            >
              <Grid container>
                <Typography variant="body1">{t("coverage-dates-form.general-liability.main-one")}</Typography>
                <Typography variant="body1">{t("coverage-dates-form.general-liability.main-two")}</Typography>
                <Typography variant="body1">{t("coverage-dates-form.general-liability.main-three")}</Typography>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>{t("coverage-dates-form.coverages-limits.label")}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      <Typography variant="body1">{t("coverage-dates-form.general-liability.list-one")}</Typography>
                    </li>
                    <li>
                      <Typography variant="body1">{t("coverage-dates-form.general-liability.list-two")}</Typography>
                    </li>
                    <li>
                      <Typography variant="body1">{t("coverage-dates-form.general-liability.list-three")}</Typography>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
          <Grid item className={classes.accordion} xs={12}>
            <Accordion
              titleText={t("coverage-dates-form.cargo-liability.label")}
              showSuccessIcon={formik.values[Fields.liability] === true ? true : false}
            >
              <Grid container>
                <Typography variant="body1">{t("coverage-dates-form.cargo-liability.main")}</Typography>
              </Grid>
            </Accordion>
          </Grid>
          <Grid item xs={12} className={`${classes.fieldMargin} ${classes.toggleSwitch}`}>
            <ToggleSwitch
              onChange={formik.handleChange}
              id={Fields.workersComp}
              name={Fields.workersComp}
              label={t("workers-compensation.label")}
              value={formik.values[Fields.workersComp]}
            />
            <Grid item className={classes.accordion} xs={12}>
              <Accordion
                titleText={t("workers-compensation.label")}
                showSuccessIcon={formik.values[Fields.workersComp] === true ? true : false}
              >
                <Grid container>
                  <Typography variant="body1">{t("workers-compensation.main")}</Typography>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>{t("coverage-dates-form.coverages-limits.label")}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ul>
                      <li>
                        <Typography variant="body1">{t("workers-compensation.list-one")}</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">{t("workers-compensation.list-two")}</Typography>
                      </li>
                      <li>
                        <Typography variant="body1">{t("workers-compensation.list-three")}</Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CoverageDatesForm;
