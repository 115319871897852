/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Application,
  ApplicationFromJSON,
  ApplicationToJSON,
  ApplicationApiResponse,
  ApplicationApiResponseFromJSON,
  ApplicationApiResponseToJSON,
  ByteArrayApiResponse,
  ByteArrayApiResponseFromJSON,
  ByteArrayApiResponseToJSON,
  FileContentResultApiResponse,
  FileContentResultApiResponseFromJSON,
  FileContentResultApiResponseToJSON,
} from "../models";

export interface ApiV10DocumentAddDriverListDocumentPostRequest {
  emailAddress?: string;
  file?: Blob;
}

export interface ApiV10DocumentAddEDeliveryConsentPostRequest {
  application?: Application;
}

export interface ApiV10DocumentAddLossRunsDocumentPostRequest {
  emailAddress?: string;
  file?: Blob;
}

export interface ApiV10DocumentAddReturnToWorkDocumentPostRequest {
  emailAddress?: string;
  file?: Blob;
}

export interface ApiV10DocumentAddVehicleListDocumentPostRequest {
  emailAddress?: string;
  file?: Blob;
}

export interface ApiV10DocumentByteUriGetRequest {
  uri: string;
}

export interface ApiV10DocumentDeleteDriverListDocumentDeleteRequest {
  emailAddress?: string;
}

export interface ApiV10DocumentDeleteEDeliveryConsentDeleteRequest {
  emailAddress?: string;
}

export interface ApiV10DocumentDeleteLossRunsDocumentDeleteRequest {
  emailAddress?: string;
}

export interface ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest {
  emailAddress?: string;
}

export interface ApiV10DocumentDeleteVehicleListDocumentDeleteRequest {
  emailAddress?: string;
}

export interface ApiV10DocumentUriGetRequest {
  uri: string;
}

/**
 *
 */
export class DocumentApi extends runtime.BaseAPI {
  /**
   */
  async apiV10DocumentAddDriverListDocumentPostRaw(
    requestParameters: ApiV10DocumentAddDriverListDocumentPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/v1.0/Document/AddDriverListDocument`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentAddDriverListDocumentPost(
    requestParameters: ApiV10DocumentAddDriverListDocumentPostRequest,
  ): Promise<void> {
    await this.apiV10DocumentAddDriverListDocumentPostRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentAddEDeliveryConsentPostRaw(
    requestParameters: ApiV10DocumentAddEDeliveryConsentPostRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/AddEDeliveryConsent`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ApplicationToJSON(requestParameters.application),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10DocumentAddEDeliveryConsentPost(
    requestParameters: ApiV10DocumentAddEDeliveryConsentPostRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10DocumentAddEDeliveryConsentPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10DocumentAddLossRunsDocumentPostRaw(
    requestParameters: ApiV10DocumentAddLossRunsDocumentPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/v1.0/Document/AddLossRunsDocument`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentAddLossRunsDocumentPost(
    requestParameters: ApiV10DocumentAddLossRunsDocumentPostRequest,
  ): Promise<void> {
    await this.apiV10DocumentAddLossRunsDocumentPostRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentAddReturnToWorkDocumentPostRaw(
    requestParameters: ApiV10DocumentAddReturnToWorkDocumentPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/v1.0/Document/AddReturnToWorkDocument`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentAddReturnToWorkDocumentPost(
    requestParameters: ApiV10DocumentAddReturnToWorkDocumentPostRequest,
  ): Promise<void> {
    await this.apiV10DocumentAddReturnToWorkDocumentPostRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentAddVehicleListDocumentPostRaw(
    requestParameters: ApiV10DocumentAddVehicleListDocumentPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("File", requestParameters.file as any);
    }

    const response = await this.request({
      path: `/api/v1.0/Document/AddVehicleListDocument`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentAddVehicleListDocumentPost(
    requestParameters: ApiV10DocumentAddVehicleListDocumentPostRequest,
  ): Promise<void> {
    await this.apiV10DocumentAddVehicleListDocumentPostRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentByteUriGetRaw(
    requestParameters: ApiV10DocumentByteUriGetRequest,
  ): Promise<runtime.ApiResponse<ByteArrayApiResponse>> {
    if (requestParameters.uri === null || requestParameters.uri === undefined) {
      throw new runtime.RequiredError(
        "uri",
        "Required parameter requestParameters.uri was null or undefined when calling apiV10DocumentByteUriGet.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/Byte/{uri}`.replace(`{${"uri"}}`, encodeURIComponent(String(requestParameters.uri))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ByteArrayApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10DocumentByteUriGet(requestParameters: ApiV10DocumentByteUriGetRequest): Promise<ByteArrayApiResponse> {
    const response = await this.apiV10DocumentByteUriGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10DocumentDeleteDriverListDocumentDeleteRaw(
    requestParameters: ApiV10DocumentDeleteDriverListDocumentDeleteRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/DeleteDriverListDocument`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentDeleteDriverListDocumentDelete(
    requestParameters: ApiV10DocumentDeleteDriverListDocumentDeleteRequest,
  ): Promise<void> {
    await this.apiV10DocumentDeleteDriverListDocumentDeleteRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentDeleteEDeliveryConsentDeleteRaw(
    requestParameters: ApiV10DocumentDeleteEDeliveryConsentDeleteRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/DeleteEDeliveryConsent`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentDeleteEDeliveryConsentDelete(
    requestParameters: ApiV10DocumentDeleteEDeliveryConsentDeleteRequest,
  ): Promise<void> {
    await this.apiV10DocumentDeleteEDeliveryConsentDeleteRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentDeleteLossRunsDocumentDeleteRaw(
    requestParameters: ApiV10DocumentDeleteLossRunsDocumentDeleteRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/DeleteLossRunsDocument`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentDeleteLossRunsDocumentDelete(
    requestParameters: ApiV10DocumentDeleteLossRunsDocumentDeleteRequest,
  ): Promise<void> {
    await this.apiV10DocumentDeleteLossRunsDocumentDeleteRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentDeleteReturnToWorkDocumentDeleteRaw(
    requestParameters: ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/DeleteReturnToWorkDocument`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentDeleteReturnToWorkDocumentDelete(
    requestParameters: ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest,
  ): Promise<void> {
    await this.apiV10DocumentDeleteReturnToWorkDocumentDeleteRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentDeleteVehicleListDocumentDeleteRaw(
    requestParameters: ApiV10DocumentDeleteVehicleListDocumentDeleteRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/DeleteVehicleListDocument`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10DocumentDeleteVehicleListDocumentDelete(
    requestParameters: ApiV10DocumentDeleteVehicleListDocumentDeleteRequest,
  ): Promise<void> {
    await this.apiV10DocumentDeleteVehicleListDocumentDeleteRaw(requestParameters);
  }

  /**
   */
  async apiV10DocumentUriGetRaw(
    requestParameters: ApiV10DocumentUriGetRequest,
  ): Promise<runtime.ApiResponse<FileContentResultApiResponse>> {
    if (requestParameters.uri === null || requestParameters.uri === undefined) {
      throw new runtime.RequiredError(
        "uri",
        "Required parameter requestParameters.uri was null or undefined when calling apiV10DocumentUriGet.",
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Document/{uri}`.replace(`{${"uri"}}`, encodeURIComponent(String(requestParameters.uri))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => FileContentResultApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10DocumentUriGet(requestParameters: ApiV10DocumentUriGetRequest): Promise<FileContentResultApiResponse> {
    const response = await this.apiV10DocumentUriGetRaw(requestParameters);
    return await response.value();
  }
}
