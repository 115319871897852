import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  providerName?: string;
  shipmentPercent?: string | number;
  yearsContracted?: string | number;
  onEdit: EditableDeletableCardProps["onEdit"];
};

const useStyles = makeStyles((theme: Theme) => ({
  cardStyles: {
    backgroundColor: theme.palette.grey[600],
    width: "100%",
  },
  titleClass: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
}));

const LogisticsProviderCard: React.FC<Props> = ({ providerName, shipmentPercent, yearsContracted, onEdit }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <EditableDeletableCard
      className={classes.cardStyles}
      variant="edit"
      title={providerName}
      titleVariant="h3"
      titleClass={classes.titleClass}
      onEdit={onEdit}
      IconButtonProps={{ "aria-label": t("operations.logistics-provider-card.edit-button") }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <CardField
            id="shipment-percent"
            label={t("operations.logistics-provider-card.shipment-percent-label")}
            values={[
              {
                value: t("operations.logistics-provider-card.shipment-percent", { shipmentPercent: shipmentPercent }),
                valueAlign: "center",
              },
            ]}
            labelGrid={7}
            valueGrid={5}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardField
            id="years-contracted"
            label={t("operations.logistics-provider-card.years-contracted-label")}
            values={[
              {
                value: yearsContracted,
                valueAlign: "center",
              },
            ]}
            labelGrid={7}
            valueGrid={5}
          />
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default LogisticsProviderCard;
