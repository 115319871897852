import { Application, EnrollmentApi } from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useSubmitApplication = (): UseMutationResult<void, Error, Application> => {
  const applicationApi = useApi(EnrollmentApi);
  const { updateApplication } = useCachedApplication();
  return useMutation(async (request: Application) => {
    await applicationApi
      .apiV10EnrollmentUpsertApplicationPut({ application: request })
      .then((response) => {
        updateApplication(response.data!);
        return response;
      })
      .then((response) => {
        return applicationApi.apiV10EnrollmentSendCompletedEmailPost({ emailAddress: response.data!.contactEmail! });
      });
  });
};
