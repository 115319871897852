import { AlertType, LoadingOverlay, PageAlert, PrevNextNavBar } from "@chq/components";
import { ExcludedIndividual, Partner } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classnames from "classnames";
import dateFnsFormat from "date-fns/format";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ReviewAboutYourCompanyCard from "../../../components/review-about-your-company-card";
import ReviewAgreementsForm, { Fields, FormProps, useFormikConfig } from "../../../components/review-agreements-form";
import ReviewBusinessDetailsCard from "../../../components/review-business-details-card";
import ReviewCoveragesCard from "../../../components/review-coverages-card";
import ReviewHiringPracticesCard from "../../../components/review-hiring-practices-card";
import ReviewServicesCard from "../../../components/review-services-card";
import ReviewUploadDocumentsCard from "../../../components/review-upload-documents-card";
import ReviewWhereYourCompanyOperatesCard from "../../../components/review-where-your-company-operates-card";
import ReviewWorkersCompCard from "../../../components/review-workers-comp-card";
import {
  useAddEDeliveryConsent,
  useDeleteEDeliveryConsentDocument,
  useDownloadEDeliveryConsent,
  useGetApplication,
  useSubmitApplication,
} from "../../../data/enrollment";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { routes } from "../../routes";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    flexBasis: "auto",
  },
  container: {
    paddingBottom: "4rem",
  },
  alert: {
    textAlign: "center",
  },
  titleContainer: {
    textAlign: "center",
    margin: "2rem 0",
  },
  agreementsForm: {
    paddingTop: "2.5rem",
    borderTop: `2px solid ${theme.palette.grey[400]}`,
  },
  workersComp: {
    padding: "1.5rem 0",
    borderTop: `2px solid ${theme.palette.grey[400]}`,
  },
  uploadDocs: {
    padding: "1.5rem 0",
    borderTop: `2px solid ${theme.palette.grey[400]}`,
  },
  services: {
    padding: "1.5rem 0",
  },
  workersCompOnly: {
    marginBottom: "1.5rem",
  },
}));

const ReviewApplicationPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const { data: application } = useGetApplication();

  const { mutate: submitApplication, isLoading, error } = useSubmitApplication();

  const { mutate: addEDeliveryConsent, isLoading: signingIsLoading } = useAddEDeliveryConsent();
  const { mutate: downloadDocument } = useDownloadEDeliveryConsent();
  const { mutate: deleteEDeliveryConsentDocument } = useDeleteEDeliveryConsentDocument();

  const signEDeliveryConsent = () => {
    addEDeliveryConsent({ application: application });
  };

  const downloadSignedDocument = async () => {
    const uri = application?.electronicConsentRequirementsDocument?.storageUri || "";
    await downloadDocument({ uri });
  };

  const isNonConsentState = () => {
    const nonConsentStates = ["CA", "SC"];
    return application?.physicalState ? nonConsentStates.includes(application?.physicalState) : false;
  };

  const formikProps = useFormikConfig({
    eConsent: application?.electronicConsentRequirements || false,
    legalAuthority: application?.agreeTerms || false,
    lossRuns: application?.agreeLossRun || false,
    routineInquiry: application?.agreeFairCreditReporting || false,
    name: application?.submissionName || undefined,
    date: application?.submissionDate || undefined,
    eConsentSignedDownload: downloadSignedDocument,
    signEDeliveryConsent: signEDeliveryConsent,
    voluntaryEConsentState: isNonConsentState(),
  });

  const yesOrNo = (b: boolean | null | undefined) => {
    if (b !== undefined && b !== null) {
      return b ? t("common.yes") : t("common.no");
    } else {
      return t("review-application-page.not-applicable");
    }
  };

  const convertNum = (num: number | null | undefined) => {
    if (num || num === 0) {
      return num.toString();
    } else {
      return t("review-application-page.not-applicable");
    }
  };

  const effectiveDate = application?.effectiveDate
    ? dateFnsFormat(application?.effectiveDate as Date, "MM/dd/yyyy")
    : undefined;

  const statesDelivered = (application?.operationStates || []).map((s) => s.state);
  const formattedStatesDelivered =
    statesDelivered.length === 0
      ? t("review-application-page.not-applicable")
      : statesDelivered.length > 3
      ? t("where-your-company-operates.remaining-states", {
          states: statesDelivered.filter((_, index) => index < 3).join(", "),
          number: statesDelivered.length - 3,
        })
      : statesDelivered.join(", ");

  const reviewCoveragesProps = {
    statesDelivered: formattedStatesDelivered,
    effectiveDate: effectiveDate || t("review-application-page.not-applicable")!,
    vehicles: convertNum(application?.numberOfVehicles),
    years: convertNum(application?.yearsInBusiness),
    miles: t("common.numberNoDecimal", { val: application?.fleetAnnualMiles }),
    fleetCoverage: application?.coverageFleet || false,
    workersComp: application?.coverageWorkersComp || false,
    logisticsProvider: application?.logisticsProvider || undefined,
  };

  const partners = application?.partners?.map((partner: Partner) => {
    return partner.name;
  });

  const formattedEinTaxid = application?.einNumber?.replace(/(\d{2})(\d{7})/, "$1-$2");

  const subsidiariesText =
    application?.isOrHaveSubsidiaries && application?.subsidiaryInfo
      ? application?.subsidiaryInfo
      : t("review-application-page.not-applicable");

  const reviewBusinessDetailsProps = {
    companyName: application?.businessName || t("review-application-page.not-applicable")!,
    companyType: application?.companyType || t("review-application-page.not-applicable")!,
    dotNumber: application?.dotNumber || t("review-application-page.not-applicable")!,
    mcNumber: application?.mcNumber || t("review-application-page.not-applicable")!,
    einTaxId: formattedEinTaxid || t("review-application-page.not-applicable")!,
    physicalAddress:
      `${application?.physicalAddress}, ${application?.physicalCity}, ${application?.physicalState} ${application?.physicalZip}` ||
      t("review-application-page.not-applicable")!,
    mailingAddress:
      `${application?.mailingAddress}, ${application?.mailingCity}, ${application?.mailingState} ${application?.mailingZip}` ||
      t("review-application-page.not-applicable")!,
    businessOwner: application?.ownerName || t("review-application-page.not-applicable")!,
    partners: partners?.toString() || t("review-application-page.not-applicable")!,
    subsidiariesParents: subsidiariesText,
  };

  const reviewWhereCompanyWorksProps = {
    terminals: application?.terminals,
    logisticsProviders: application?.logisticsProviders,
  };

  let filingAddress: string;
  if (application?.filingSameAsPhysicalAddress) {
    filingAddress = `${application?.physicalAddress} ${application?.physicalCity}, ${application?.physicalState} ${application?.physicalZip}`;
  } else if (
    application?.filingStreet &&
    application?.filingCity &&
    application?.filingState &&
    application?.filingZip
  ) {
    filingAddress = `${application?.filingStreet} ${application?.filingCity}, ${application?.filingState} ${application?.filingZip}`;
  } else {
    filingAddress = t("review-application-page.not-applicable")!;
  }

  const reviewAboutYourCompanyProps = {
    yearsExperience: convertNum(application?.yearsContinousExperience),
    nonLastMile: yesOrNo(application?.hasNonLMDOperations),
    insurance: yesOrNo(application?.isMaintainingForTwelveMonths),
    maintenanceProgram: yesOrNo(application?.hasWrittenVehicleMaintenance),
    hireLease: yesOrNo(application?.employeeLeasing),
    EmpNonEmpVehicles: yesOrNo(application?.hasEmployeesUseTheirAutos),
    describeCircumstances:
      application?.employeesUsingTheirAutosDescription || t("review-application-page.not-applicable")!,
    filingAddress: filingAddress || t("review-application-page.not-applicable")!,
  };

  const reviewHiringPracticesProps = {
    mvrPrior: yesOrNo(application?.newDriverMvr),
    annualMvrReview: yesOrNo(application?.reviewMvrAnnually),
    preHireDrug: yesOrNo(application?.preHireDrugScreening),
    randomDrug: yesOrNo(application?.randomDrugScreening),
    postAccidentDrug: yesOrNo(application?.postAccidentDrugScreening),
    screeningProcess: yesOrNo(application?.applicationScreeningProcess),
    leaseEmployees: yesOrNo(application?.employeeLeasing),
    drivers2YearsExperience: yesOrNo(application?.twoYearsDrivingExperienceRequired),
    indContractors: yesOrNo(application?.utilizeIndependentContractors),
    dayCasualLabor: yesOrNo(application?.hireDayCasualLabor),
  };

  const itemsAssembled: Array<string> = [];

  if (application?.doesAssembleElectronics) {
    itemsAssembled.push("Electronics");
  }
  if (application?.doesAssembleExerciseEquipment) {
    itemsAssembled.push(" Exercise Equipment");
  }
  if (application?.doesAssembleExibitsAndOrDisplays) {
    itemsAssembled.push(" Exhibits/Displays");
  }
  if (application?.doesAssembleFurniture) {
    itemsAssembled.push(" Furniture");
  }
  if (application?.doesAssembleOtherProducts) {
    itemsAssembled.push(` Other: ${application?.otherProductsAssembled}`);
  }

  const dailyDeliveries: Array<string> = [];

  if (application?.waterIsDailyDelivery) {
    dailyDeliveries.push("Water");
  }
  if (application?.gasIsDailyDelivery) {
    dailyDeliveries.push(" Gas");
  }

  const reviewServicesProps = {
    onSiteAssemblyProducts: yesOrNo(application?.doesPerformOnSiteAssemblyOfProducts),
    dailyDeliveries: convertNum(application?.percentOfProductAssemblyIsDailyDeliveries),
    itemsAssembled: itemsAssembled.toString(),
    onSiteAssemblyAppliances: yesOrNo(application?.doesPerformOnsiteAssemblyOfAppliances),
    deliveries: convertNum(application?.percentOfApplianceAssemblyIsDailyDeliveries),
    applianceAssemblyType: dailyDeliveries.toString() || t("review-application-page.not-applicable")!,
    installsRequireLicense: yesOrNo(application?.doesPerformInstallsRequiringContractor),
    licensedContractor: yesOrNo(application?.isLicensedContractor),
  };

  const reviewUploadDocumentsProps = {
    attachedVehicleList: application?.vehicleListDocument?.name || t("review-application-page.no-file-attached")!,
    attachedDriverList: application?.driverListDocument?.name || t("review-application-page.no-file-attached")!,
    attachedLossRuns: application?.lossRunsDocument?.name || t("review-application-page.no-file-attached")!,
    returnToWorkDocument: application?.returnToWorkDocument?.name || t("review-application-page.no-file-attached")!,
    loadDefaultForm: application?.coverageFleet || false,
    loadReturnToWork: application?.doesHaveReturnToWorkProgram || false,
    loadWorkersComp: application?.coverageWorkersComp || false,
  };

  const excludedIndividuals = application?.excludedIndividuals?.map((individual: ExcludedIndividual) => {
    return individual.name;
  });

  const reviewWorkersCompProps = {
    excludedIndividuals: excludedIndividuals?.join(", ") || t("review-application-page.not-applicable")!,
    driverProjectedYearPayroll: application?.driverPayrollProjectedYear,
    driverCurrentYearPayroll: application?.driverPayrollCurrentYear,
    driverPriorYearPayroll: application?.driverPayrollPriorYear,
    clericalProjectedYearPayroll: application?.clericalPayrollProjectedYear,
    clericalCurrentYearPayroll: application?.clericalPayrollCurrentYear,
    clericalPriorYearPayroll: application?.clericalPayrollPriorYear,
    otherProjectedYearPayroll: application?.otherPayrollProjectedYear,
    otherCurrentYearPayroll: application?.otherPayrollCurrentYear,
    otherPriorYearPayroll: application?.otherPayrollPriorYear,
    projectedYearPremium: application?.premiumsProjectedYear,
    currentYearPremium: application?.premiumsCurrentYear,
    priorYearPremium: application?.premiumsPriorYear,
    medicalGroupProvided: yesOrNo(application?.isGroupMedicalProvided),
    isExcludedSalaryIncludedInPayroll: yesOrNo(application?.isExcludedSalaryIncludedInPayroll),
    percentEmployeesEnrolled:
      application?.percentOfEmployeesEnrolledInMedicalPlan?.toString() || t("review-application-page.not-applicable")!,
    formalAccident: yesOrNo(application?.isFormalAccidentReportingInPlace),
    lightRestrictedWork: yesOrNo(application?.doesHaveReturnToWorkProgram),
    createOneVanliner: yesOrNo(application?.isWillingToCreateVanlinerAssistance),
    utilizeIndContractors: yesOrNo(application?.doesUtilizeIndependentContractors),
    maintainCertificatesOfInsuranceForCoverage: yesOrNo(application?.maintainCertificatesOfInsuranceForCoverage),
    willProvideCoverageUnderWorkersCompPolicy: yesOrNo(application?.willProvideCoverageUnderWorkersCompPolicy),
  };

  useScrollToTop();

  return (
    <>
      {isLoading && <LoadingOverlay title={t("review-application-page.sending-application")} />}
      <Grid container justify="center">
        <Grid item xs={10} lg={6}>
          <Grid className={classes.container}>
            {error && (
              <Grid item xs={12} className={classes.alert}>
                <PageAlert alertType={AlertType.error} message={t("common.server-error")} />
              </Grid>
            )}
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography variant="h1" component="h3" align="left">
                {t("review-application-page.review-application")}
              </Typography>
            </Grid>
            <Grid container direction="column" wrap="nowrap">
              <Grid container wrap="nowrap" spacing={2}>
                <Grid container item direction="column" wrap="nowrap" spacing={1}>
                  <Grid item xs={12} className={classes.card}>
                    <ReviewCoveragesCard {...reviewCoveragesProps} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classnames(classes.card, {
                      [classes.workersCompOnly]: application?.coverageWorkersComp && !application?.coverageFleet,
                    })}
                  >
                    <ReviewBusinessDetailsCard {...reviewBusinessDetailsProps} />
                  </Grid>
                  {!(application?.coverageWorkersComp && !application?.coverageFleet) && (
                    <Grid item xs={12} className={classes.card}>
                      <ReviewWhereYourCompanyOperatesCard {...reviewWhereCompanyWorksProps} />
                    </Grid>
                  )}
                </Grid>
                {!(application?.coverageWorkersComp && !application?.coverageFleet) && (
                  <Grid container item direction="column" wrap="nowrap" spacing={1}>
                    <Grid item xs={12} className={classes.card}>
                      <ReviewAboutYourCompanyCard {...reviewAboutYourCompanyProps} />
                    </Grid>
                    <Grid item xs={12} className={classes.card}>
                      <ReviewHiringPracticesCard {...reviewHiringPracticesProps} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {!(application?.coverageWorkersComp && !application?.coverageFleet) && (
                <Grid item xs={12}>
                  <div className={classes.services}>
                    <ReviewServicesCard {...reviewServicesProps} />
                  </div>
                </Grid>
              )}
              {application?.coverageWorkersComp && (
                <Grid item xs={12}>
                  <div className={classes.workersComp}>
                    <ReviewWorkersCompCard {...reviewWorkersCompProps} />
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={classes.uploadDocs}>
                  <ReviewUploadDocumentsCard {...reviewUploadDocumentsProps} />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Formik
                {...formikProps}
                onSubmit={(values: FormProps) => {
                  submitApplication(
                    {
                      ...application,
                      agreeLossRun: values[Fields.lossRuns],
                      submissionName: values[Fields.name],
                      submissionDate: values[Fields.date],
                      agreeFairCreditReporting: values[Fields.routineInquiry],
                      agreeTerms: values[Fields.legalAuthority],
                      stateRequirements: values[Fields.legalAuthority],
                      electronicConsentRequirements: values[Fields.eConsent],
                    },
                    {
                      onSuccess: () => {
                        history.push(routes.success.path);
                      },
                    },
                  );
                }}
              >
                {(formik) => (
                  <Grid container direction="column" className={classes.agreementsForm}>
                    <Grid item>
                      <ReviewAgreementsForm
                        signEDeliveryConsent={signEDeliveryConsent}
                        eConsentSignedDownload={downloadSignedDocument}
                        voluntaryEConsentState={isNonConsentState()}
                        signingIsLoading={signingIsLoading}
                        deleteDocument={() =>
                          deleteEDeliveryConsentDocument({ emailAddress: application!.contactEmail! })
                        }
                      />
                    </Grid>
                    <PrevNextNavBar
                      prevOnClick={() => {
                        application?.coverageFleet || application?.doesHaveReturnToWorkProgram
                          ? history.push(routes.documentsUpload.path)
                          : history.push(routes.workersCompPage.path);
                      }}
                      prevAriaLabel={t("review-application-page.previous-page")}
                      nextOnClick={() => formik.handleSubmit()}
                      nextDisabled={!formik.isValid}
                      nextButtonText={t("review-application-page.submit-application")}
                    />
                  </Grid>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewApplicationPage;
