import { makeStyles, Theme } from "@chq/styles";
import { Button, Fade, Grid, Link, Modal, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    color: theme.palette.error.main,
    marginBottom: 10,
  },
  button: {
    height: 56,
    width: "100%",
  },
  buttonContain: {
    paddingTop: 36,
    width: "100%",
  },
  modal: {
    background: theme.palette.common.white,
    borderRadius: 4,
    maxWidth: 576,
    maxHeight: 252,
    padding: "18px 15px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalContain: {
    position: "relative",
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

const ExistingInfoModal: React.FC<Props> = ({ open, onClose }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Modal onClose={onClose} open={open} className={classes.modalContain}>
      <Fade in={open}>
        <Grid container direction="column" justify="center" alignItems="center" wrap="nowrap" className={classes.modal}>
          <Grid item xs={12}>
            <WarningIcon fontSize="large" className={classes.alert} />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h2" className={classes.alert}>
              {t(`existing-info-modal.heading`)}
            </Typography>
            <Typography variant="body2" align="center">
              <Trans i18nKey={"existing-info-modal.call"}>
                <Typography variant="body1" component="span">
                  Call
                </Typography>
                <Link href={`tel:833-791-0858`} variant="body1">
                  833-791-0858
                </Link>
                <Typography variant="body1" component="span">
                  to continue your current quote.
                </Typography>
              </Trans>
            </Typography>
          </Grid>
          <Grid className={classes.buttonContain} item xs={12}>
            <Button className={classes.button} onClick={onClose} variant="contained">
              {t(`common.ok`)}
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ExistingInfoModal;
