import { AlertType, PageAlert, PrevNextNavBar } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import HiringForm, {
  Fields,
  Fields as HiringFieldFields,
  FormProps as HiringFormProps,
  useFormikConfig as useHiringFormFormikConfig,
} from "../../../components/hiring-form";
import { useGetApplication } from "../../../data/enrollment";
import { useChangeApplication } from "../../../data/enrollment/useChangeApplication";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { RouteNames } from "../../../utils/route-names";
import { trueOrFalseConversion, yesOrNoConversion } from "../../../utils/yes-no-conversion";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: "2rem",
  },
  container: {
    padding: "0 0 4rem",
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem 1rem 2.25rem",
    },
  },
  pageButtons: {
    paddingTop: "0rem",
  },
}));

const HiringPage = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const formikProps = useHiringFormFormikConfig();
  const { data: application } = useGetApplication();
  const { mutate: editApplication, ...editApplicationCheck } = useChangeApplication();

  useScrollToTop();

  return (
    <>
      {editApplicationCheck.isError && (
        <PageAlert alertType={AlertType.error} message={t("hiring-page.page-level-error")} />
      )}
      <PageWrapper>
        <Formik
          {...formikProps}
          initialValues={{
            [Fields.doesObtainMvrsOnNewDrivers]: yesOrNoConversion(application?.newDriverMvr),
            [Fields.doesReviewMvrsOnDriversAnnually]: yesOrNoConversion(application?.reviewMvrAnnually),
            [Fields.doesConductPreHireSubstanceTesting]: yesOrNoConversion(application?.preHireDrugScreening),
            [Fields.doesConductRandomSubstanceTesting]: yesOrNoConversion(application?.randomDrugScreening),
            [Fields.doesConductPostAccidentSubstanceTesting]: yesOrNoConversion(application?.postAccidentDrugScreening),
            [Fields.isThereAnApplicantScreeningProcess]: yesOrNoConversion(application?.applicationScreeningProcess),
            [Fields.doesLeaseEmployees]: yesOrNoConversion(application?.employeeLeasing),
            [Fields.doesRequireTwoYearsDrivingExperience]: yesOrNoConversion(
              application?.twoYearsDrivingExperienceRequired,
            ),
            [Fields.doesUtilizeIndependentContractors]: yesOrNoConversion(application?.utilizeIndependentContractors),
            [Fields.doesHireLabors]: yesOrNoConversion(application?.hireDayCasualLabor),
          }}
          onSubmit={(values: HiringFormProps) => {
            editApplication(
              {
                ...application,
                applicationState: RouteNames.install,
                newDriverMvr: trueOrFalseConversion(values[HiringFieldFields.doesObtainMvrsOnNewDrivers]),
                reviewMvrAnnually: trueOrFalseConversion(values[HiringFieldFields.doesReviewMvrsOnDriversAnnually]),
                preHireDrugScreening: trueOrFalseConversion(
                  values[HiringFieldFields.doesConductPreHireSubstanceTesting],
                ),
                randomDrugScreening: trueOrFalseConversion(values[HiringFieldFields.doesConductRandomSubstanceTesting]),
                postAccidentDrugScreening: trueOrFalseConversion(
                  values[HiringFieldFields.doesConductPostAccidentSubstanceTesting],
                ),
                applicationScreeningProcess: trueOrFalseConversion(
                  values[HiringFieldFields.isThereAnApplicantScreeningProcess],
                ),
                employeeLeasing: trueOrFalseConversion(values[HiringFieldFields.doesLeaseEmployees]),
                twoYearsDrivingExperienceRequired: trueOrFalseConversion(
                  values[HiringFieldFields.doesRequireTwoYearsDrivingExperience],
                ),
                utilizeIndependentContractors: trueOrFalseConversion(
                  values[HiringFieldFields.doesUtilizeIndependentContractors],
                ),
                hireDayCasualLabor: trueOrFalseConversion(values[HiringFieldFields.doesHireLabors]),
              },
              {
                onSuccess: () => {
                  history.push(routes.install.path);
                },
              },
            );
          }}
        >
          {(formik) => (
            <Grid container direction="column" className={classes.container}>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="h1" component="h3">
                  {t("hiring-form.title")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <HiringForm />
              </Grid>
              <PrevNextNavBar
                prevOnClick={() => history.push(routes.aboutYourCompany.path)}
                prevAriaLabel={t("hiring-form.previous-button-operations")}
                nextOnClick={() => formik.handleSubmit()}
                nextDisabled={!formik.isValid || editApplicationCheck.isLoading}
                isLoading={editApplicationCheck.isLoading}
                nextButtonText={t("hiring-form.next-button-install")}
                className={classes.pageButtons}
              />
            </Grid>
          )}
        </Formik>
      </PageWrapper>
    </>
  );
};

export default HiringPage;
