/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateApplicationRequest
 */
export interface CreateApplicationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateApplicationRequest
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationRequest
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationRequest
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateApplicationRequest
   */
  contactPhone?: string | null;
}

export function CreateApplicationRequestFromJSON(json: any): CreateApplicationRequest {
  return CreateApplicationRequestFromJSONTyped(json, false);
}

export function CreateApplicationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateApplicationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    contactFirstName: !exists(json, "contactFirstName") ? undefined : json["contactFirstName"],
    contactLastName: !exists(json, "contactLastName") ? undefined : json["contactLastName"],
    contactEmail: !exists(json, "contactEmail") ? undefined : json["contactEmail"],
    contactPhone: !exists(json, "contactPhone") ? undefined : json["contactPhone"],
  };
}

export function CreateApplicationRequestToJSON(value?: CreateApplicationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    contactFirstName: value.contactFirstName,
    contactLastName: value.contactLastName,
    contactEmail: value.contactEmail,
    contactPhone: value.contactPhone,
  };
}
