import { CurrencyInput, StyleLabel } from "@chq/components";
import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { maxInt } from "../utils/number-validation";

const useStyles = makeStyles((theme: Theme) => ({
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    fontSize: ".75rem",
    marginLeft: ".25rem",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "3rem",
  },
  columnHeader: {
    padding: ".25rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      fontSize: "0.875rem",
      padding: "0rem 0rem 0rem 0.25rem",
    },
  },
  inputSection: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-1rem",
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: "1rem",
  },

  labelPlacementStart: {
    justifyContent: "space-between",
  },
  hiddenQuestion: {
    paddingLeft: "0.813rem",
  },
  yesNoInputSection: {
    marginTop: "1.5rem",
    marginLeft: "0",
  },
  label: {
    fontSize: "1rem",
  },
  labelOneLineDesktop: {
    height: "4rem",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      height: "auto",
    },
  },
  payrollForm: {
    "& .MuiInputAdornment-positionStart": {
      marginRight: ".25rem",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: ".5rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12.875px 10px 12.875px 0",
    },
  },
  coverageQuestions: {
    height: "7rem",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      height: "auto",
    },
  },
}));

export enum Fields {
  driverPayrollProjectedYear = "driver-payroll-projected-year",
  driverPayrollCurrentYear = "driver-payroll-current-year",
  driverPayrollPriorYear = "driver-payroll-prior-year",
  clericalPayrollProjectedYear = "clerical-payroll-projected-year",
  clericalPayrollCurrentYear = "clerical-payroll-current-year",
  clericalPayrollPriorYear = "clerical-payroll-prior-year",
  otherPayrollProjectedYear = "other-payroll-projected-year",
  otherPayrollCurrentYear = "other-payroll-current-year",
  otherPayrollPriorYear = "other-payroll-prior-year",
  premiumsProjectedYear = "premiums-projected-year",
  premiumsCurrentYear = "premiums-current-year",
  premiumsPriorYear = "premiums-prior-year",
}

export type FormProps = {
  [Fields.driverPayrollProjectedYear]: string;
  [Fields.driverPayrollCurrentYear]: string;
  [Fields.driverPayrollPriorYear]: string;
  [Fields.clericalPayrollProjectedYear]: string;
  [Fields.clericalPayrollCurrentYear]: string;
  [Fields.clericalPayrollPriorYear]: string;
  [Fields.otherPayrollProjectedYear]: string;
  [Fields.otherPayrollCurrentYear]: string;
  [Fields.otherPayrollPriorYear]: string;
  [Fields.premiumsProjectedYear]: string;
  [Fields.premiumsCurrentYear]: string;
  [Fields.premiumsPriorYear]: string;
};

type Props = {
  driverPayrollProjectedYear?: string;
  driverPayrollCurrentYear?: string;
  driverPayrollPriorYear?: string;
  clericalPayrollProjectedYear?: string;
  clericalPayrollCurrentYear?: string;
  clericalPayrollPriorYear?: string;
  otherPayrollProjectedYear?: string;
  otherPayrollCurrentYear?: string;
  otherPayrollPriorYear?: string;
  premiumsProjectedYear?: string;
  premiumsCurrentYear?: string;
  premiumsPriorYear?: string;

  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const useValidationSchema = () => {
  const [t] = useTranslation();
  return yup.object({
    [Fields.driverPayrollProjectedYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.driver-payroll-projected-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.driver-payroll-projected-year-error"), max: maxInt }),
      ),
    [Fields.driverPayrollCurrentYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.driver-payroll-current-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.driver-payroll-current-year-error"), max: maxInt }),
      ),
    [Fields.driverPayrollPriorYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.driver-payroll-prior-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.driver-payroll-prior-year-error"), max: maxInt }),
      ),
    [Fields.clericalPayrollProjectedYear]: yup
      .number()
      .required(
        t("errors.required", { field: t("workers-compensation.payroll.clerical-payroll-projected-year-error") }),
      )
      .max(
        maxInt,
        t("errors.max", {
          field: t("workers-compensation.payroll.clerical-payroll-projected-year-error"),
          max: maxInt,
        }),
      ),
    [Fields.clericalPayrollCurrentYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.clerical-payroll-current-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.clerical-payroll-current-year-error"), max: maxInt }),
      ),
    [Fields.clericalPayrollPriorYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.clerical-payroll-prior-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.clerical-payroll-prior-year-error"), max: maxInt }),
      ),
    [Fields.otherPayrollProjectedYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.other-payroll-projected-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.other-payroll-projected-year-error"), max: maxInt }),
      ),
    [Fields.otherPayrollCurrentYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.other-payroll-current-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.other-payroll-current-year-error"), max: maxInt }),
      ),
    [Fields.otherPayrollPriorYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.other-payroll-prior-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.other-payroll-prior-year-error"), max: maxInt }),
      ),
    [Fields.premiumsProjectedYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.premiums-projected-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.premiums-projected-year-error"), max: maxInt }),
      ),
    [Fields.premiumsCurrentYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.premiums-current-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.premiums-current-year-error"), max: maxInt }),
      ),
    [Fields.premiumsPriorYear]: yup
      .number()
      .required(t("errors.required", { field: t("workers-compensation.payroll.premiums-prior-year-error") }))
      .max(
        maxInt,
        t("errors.max", { field: t("workers-compensation.payroll.premiums-prior-year-error"), max: maxInt }),
      ),
  });
};

export const useFormikConfig = ({
  driverPayrollProjectedYear: initialDriverPayrollProjectedYear = "",
  driverPayrollCurrentYear: initialDriverPayrollCurrentYear = "",
  driverPayrollPriorYear: initialDriverPayrollPriorYear = "",
  clericalPayrollProjectedYear: initialClericalPayrollProjectedYear = "",
  clericalPayrollCurrentYear: initialClericalPayrollCurrentYear = "",
  clericalPayrollPriorYear: initialClericalPayrollPriorYear = "",
  otherPayrollProjectedYear: initialOtherPayrollProjectedYear = "",
  otherPayrollCurrentYear: initialOtherPayrollCurrentYear = "",
  otherPayrollPriorYear: initialOtherPayrollPriorYear = "",
  premiumsProjectedYear: initialPremiumsProjectedYear = "",
  premiumsCurrentYear: initialPremiumsCurrentYear = "",
  premiumsPriorYear: initialPremiumsPriorYear = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.driverPayrollProjectedYear]: initialDriverPayrollProjectedYear,
      [Fields.driverPayrollCurrentYear]: initialDriverPayrollCurrentYear,
      [Fields.driverPayrollPriorYear]: initialDriverPayrollPriorYear,
      [Fields.clericalPayrollProjectedYear]: initialClericalPayrollProjectedYear,
      [Fields.clericalPayrollCurrentYear]: initialClericalPayrollCurrentYear,
      [Fields.clericalPayrollPriorYear]: initialClericalPayrollPriorYear,
      [Fields.otherPayrollProjectedYear]: initialOtherPayrollProjectedYear,
      [Fields.otherPayrollCurrentYear]: initialOtherPayrollCurrentYear,
      [Fields.otherPayrollPriorYear]: initialOtherPayrollPriorYear,
      [Fields.premiumsProjectedYear]: initialPremiumsProjectedYear,
      [Fields.premiumsCurrentYear]: initialPremiumsCurrentYear,
      [Fields.premiumsPriorYear]: initialPremiumsPriorYear,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const PayrollForm: React.FC<Props> = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const formik = useFormikContext<FormProps>();

  const renderColumnHeaders = () => {
    return (
      <Grid container spacing={1} wrap="nowrap" justify="flex-end">
        <Grid item xs={12} sm={9} container alignItems="flex-end">
          <Grid item xs={3}>
            <StyleLabel
              className={classes.columnHeader}
              required={false}
              variant={"h2"}
              labelText={
                <>
                  {t("workers-compensation.card.driver")}
                  <br />
                  {t("workers-compensation.card.payroll")}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyleLabel
              className={classes.columnHeader}
              required={false}
              variant={"h2"}
              labelText={
                <>
                  {t("workers-compensation.card.clerical")}
                  <br />
                  {t("workers-compensation.card.payroll")}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyleLabel
              className={classes.columnHeader}
              required={false}
              variant={"h2"}
              labelText={
                <>
                  {t("workers-compensation.card.other")}
                  <br />
                  {t("workers-compensation.card.payroll")}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <StyleLabel
              className={classes.columnHeader}
              required={false}
              variant={"h2"}
              labelText={t("workers-compensation.payroll.premiums-input-label")}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.payrollForm}>
      <Grid className={classes.title}>
        <StyleLabel variant={"h2"} labelText={t("workers-compensation.payroll.payroll-section-label")} />
      </Grid>
      {!isMobile && renderColumnHeaders()}
      {/* ************************** Payroll Projected Year ************************** */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={3}>
          <StyleLabel variant={"h2"} labelText={t("workers-compensation.payroll.projected-year-label")} />
        </Grid>
        {isMobile && renderColumnHeaders()}
        <Grid item xs={12} sm={9} className={classes.inputSection}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.driverPayrollProjectedYear}` }}
                id={Fields.driverPayrollProjectedYear}
                name={Fields.driverPayrollProjectedYear}
                value={formik.values[Fields.driverPayrollProjectedYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.driverPayrollProjectedYear] &&
                  Boolean(formik.errors[Fields.driverPayrollProjectedYear])
                }
                helperText={
                  formik.touched[Fields.driverPayrollProjectedYear] && formik.errors[Fields.driverPayrollProjectedYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.clericalPayrollProjectedYear}` }}
                id={Fields.clericalPayrollProjectedYear}
                name={Fields.clericalPayrollProjectedYear}
                value={formik.values[Fields.clericalPayrollProjectedYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.clericalPayrollProjectedYear] &&
                  Boolean(formik.errors[Fields.clericalPayrollProjectedYear])
                }
                helperText={
                  formik.touched[Fields.clericalPayrollProjectedYear] &&
                  formik.errors[Fields.clericalPayrollProjectedYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.otherPayrollProjectedYear}` }}
                id={Fields.otherPayrollProjectedYear}
                name={Fields.otherPayrollProjectedYear}
                value={formik.values[Fields.otherPayrollProjectedYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.otherPayrollProjectedYear] &&
                  Boolean(formik.errors[Fields.otherPayrollProjectedYear])
                }
                helperText={
                  formik.touched[Fields.otherPayrollProjectedYear] && formik.errors[Fields.otherPayrollProjectedYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.premiumsProjectedYear}` }}
                id={Fields.premiumsProjectedYear}
                name={Fields.premiumsProjectedYear}
                value={formik.values[Fields.premiumsProjectedYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.premiumsProjectedYear] && Boolean(formik.errors[Fields.premiumsProjectedYear])
                }
                helperText={formik.touched[Fields.premiumsProjectedYear] && formik.errors[Fields.premiumsProjectedYear]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* ************************** Payroll Current Year ************************** */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={3}>
          <StyleLabel variant={"h2"} labelText={t("workers-compensation.payroll.current-year-label")} />
        </Grid>
        {isMobile && renderColumnHeaders()}
        <Grid item xs={12} sm={9} className={classes.inputSection}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.driverPayrollCurrentYear}` }}
                id={Fields.driverPayrollCurrentYear}
                name={Fields.driverPayrollCurrentYear}
                value={formik.values[Fields.driverPayrollCurrentYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.driverPayrollCurrentYear] &&
                  Boolean(formik.errors[Fields.driverPayrollCurrentYear])
                }
                helperText={
                  formik.touched[Fields.driverPayrollCurrentYear] && formik.errors[Fields.driverPayrollCurrentYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.clericalPayrollCurrentYear}` }}
                id={Fields.clericalPayrollCurrentYear}
                name={Fields.clericalPayrollCurrentYear}
                value={formik.values[Fields.clericalPayrollCurrentYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.clericalPayrollCurrentYear] &&
                  Boolean(formik.errors[Fields.clericalPayrollCurrentYear])
                }
                helperText={
                  formik.touched[Fields.clericalPayrollCurrentYear] && formik.errors[Fields.clericalPayrollCurrentYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.otherPayrollCurrentYear}` }}
                id={Fields.otherPayrollCurrentYear}
                name={Fields.otherPayrollCurrentYear}
                value={formik.values[Fields.otherPayrollCurrentYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.otherPayrollCurrentYear] &&
                  Boolean(formik.errors[Fields.otherPayrollCurrentYear])
                }
                helperText={
                  formik.touched[Fields.otherPayrollCurrentYear] && formik.errors[Fields.otherPayrollCurrentYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.premiumsCurrentYear}` }}
                id={Fields.premiumsCurrentYear}
                name={Fields.premiumsCurrentYear}
                value={formik.values[Fields.premiumsCurrentYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.premiumsCurrentYear] && Boolean(formik.errors[Fields.premiumsCurrentYear])}
                helperText={formik.touched[Fields.premiumsCurrentYear] && formik.errors[Fields.premiumsCurrentYear]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* ************************** Payroll Prior Year ************************** */}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={3}>
          <StyleLabel variant={"h2"} labelText={t("workers-compensation.payroll.prior-year-label")} />
        </Grid>
        {isMobile && renderColumnHeaders()}
        <Grid item xs={12} sm={9} className={classes.inputSection}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.driverPayrollPriorYear}` }}
                id={Fields.driverPayrollPriorYear}
                name={Fields.driverPayrollPriorYear}
                value={formik.values[Fields.driverPayrollPriorYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.driverPayrollPriorYear] && Boolean(formik.errors[Fields.driverPayrollPriorYear])
                }
                helperText={
                  formik.touched[Fields.driverPayrollPriorYear] && formik.errors[Fields.driverPayrollPriorYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.clericalPayrollPriorYear}` }}
                id={Fields.clericalPayrollPriorYear}
                name={Fields.clericalPayrollPriorYear}
                value={formik.values[Fields.clericalPayrollPriorYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.clericalPayrollPriorYear] &&
                  Boolean(formik.errors[Fields.clericalPayrollPriorYear])
                }
                helperText={
                  formik.touched[Fields.clericalPayrollPriorYear] && formik.errors[Fields.clericalPayrollPriorYear]
                }
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.otherPayrollPriorYear}` }}
                id={Fields.otherPayrollPriorYear}
                name={Fields.otherPayrollPriorYear}
                value={formik.values[Fields.otherPayrollPriorYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.otherPayrollPriorYear] && Boolean(formik.errors[Fields.otherPayrollPriorYear])
                }
                helperText={formik.touched[Fields.otherPayrollPriorYear] && formik.errors[Fields.otherPayrollPriorYear]}
              />
            </Grid>
            <Grid item xs={3}>
              <CurrencyInput
                fullWidth
                inputProps={{ "aria-label": `${Fields.premiumsPriorYear}` }}
                id={Fields.premiumsPriorYear}
                name={Fields.premiumsPriorYear}
                value={formik.values[Fields.premiumsPriorYear]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.premiumsPriorYear] && Boolean(formik.errors[Fields.premiumsPriorYear])}
                helperText={formik.touched[Fields.premiumsPriorYear] && formik.errors[Fields.premiumsPriorYear]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PayrollForm;
