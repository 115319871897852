import { DocumentUpload } from "@chq/components";
import { Document } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "0.5rem",
  },
  documentsSection: {
    marginTop: "1rem",
    "& > .MuiGrid-container": {
      marginBottom: "1rem",
    },
  },
  fullWidth: {
    width: "100%",
  },
  formTitle: {
    marginBottom: "1rem",
  },
}));

export enum Fields {
  rtwList = "return-to-work",
}

type Props = {
  rtwList?: Document | undefined;
  addReturnToWork?: (file: File) => void;
  onDelete?: () => void;
};

export type FormProps = {
  [Fields.rtwList]: Document | undefined;
};

const useValidationSchema = () => {
  const [t] = useTranslation();

  yup.object().shape({
    [Fields.rtwList]: yup.object().shape({
      name: yup
        .string()
        .required(t(`errors.required`, { field: t(`upload-some-documents.rtw-form.${Fields.rtwList}`) })),
    }),
  });
};

export const useFormikConfig = ({ rtwList: initialRtwList = undefined, addReturnToWork }: Props = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.rtwList]: initialRtwList,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const RTWDocumentsForm: React.FC<Props> = ({ rtwList, addReturnToWork, onDelete }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();

  return (
    <form onSubmit={formik.handleSubmit} className={classes.fullWidth}>
      <Grid container item direction="column" className={classes.documentsSection}>
        <Grid item className={classes.formTitle}>
          <Typography variant="h2" component="h3" align="left">
            {t("upload-some-documents.rtw-form.title")}
          </Typography>
        </Grid>
        <Grid item>
          <DocumentUpload
            key={"rtwList"}
            title={t(`upload-some-documents.rtw-form.${Fields.rtwList}`)}
            subtitle={t("upload-some-documents.form.subtitle")}
            fileTypes="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" // all images, .pdf, .doc, .docx, .xls, .xlsx
            isDocumentEntered={Boolean(rtwList)}
            name={rtwList?.name}
            filePrompt={t("upload-some-documents.form.prompt")}
            onChange={(e) => {
              addReturnToWork && addReturnToWork(e.target.files!.item(0)!);
              e.target.value = "";
            }}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default RTWDocumentsForm;
