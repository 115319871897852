/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenerateTokenPasswordResetRequest
 */
export interface GenerateTokenPasswordResetRequest {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenPasswordResetRequest
   */
  email?: string | null;
}

export function GenerateTokenPasswordResetRequestFromJSON(json: any): GenerateTokenPasswordResetRequest {
  return GenerateTokenPasswordResetRequestFromJSONTyped(json, false);
}

export function GenerateTokenPasswordResetRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenPasswordResetRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
  };
}

export function GenerateTokenPasswordResetRequestToJSON(value?: GenerateTokenPasswordResetRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
  };
}
