import { EmailInput, NumericInput, Select, TextInput } from "@chq/components";
import { PhoneType } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { usePhoneTypes } from "../data/usePhoneTypes";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
  },
}));

export enum Fields {
  ownerName = "owner-name",
  email = "email",
  phone = "phone",
  phoneType = "phone-type",
}

export type FormProps = {
  [Fields.ownerName]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
  [Fields.phoneType]: PhoneType | "";
};

type Props = {
  ownerName?: string;
  email?: string;
  phone?: string;
  phoneType?: PhoneType | "";
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.ownerName]: yup.string().required(t(`errors.required`, { field: t(`business-owner-form.name.label`) })),
    [Fields.email]: yup
      .string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`business-owner-form.${Fields.email}.label`) })),
    [Fields.phone]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-owner-form.${Fields.phone}.label`) }))
      .test("length", t(`errors.phone-number`), (val) => val !== undefined && val.toString().length === 10),
    [Fields.phoneType]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-owner-form.${Fields.phoneType}.label`) })),
  });
};

export const useFormikConfig = ({
  ownerName: initialOwnerName = "",
  email: initialEmail = "",
  phone: initialPhone = "",
  phoneType: initialPhoneType = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.ownerName]: initialOwnerName,
      [Fields.email]: initialEmail,
      [Fields.phone]: initialPhone,
      [Fields.phoneType]: initialPhoneType,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const BusinessOwnerForm: React.FC = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  const phoneTypes = usePhoneTypes();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.title}>
            {t("business-owner-form.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            required
            fullWidth
            label={t("business-owner-form.name.label")}
            id={Fields.ownerName}
            name={Fields.ownerName}
            value={formik.values[Fields.ownerName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.ownerName] && Boolean(formik.errors[Fields.ownerName])}
            helperText={formik.touched[Fields.ownerName] && formik.errors[Fields.ownerName]}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailInput
            required
            fullWidth
            label={t("business-owner-form.email.label")}
            id={Fields.email}
            name={Fields.email}
            value={formik.values[Fields.email]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
            helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
          />
        </Grid>
        <Grid item xs={8}>
          <NumericInput
            required
            fullWidth
            format="(###) ###-####"
            isNumericString
            label={t("business-owner-form.phone.label")}
            id={Fields.phone}
            name={Fields.phone}
            value={formik.values[Fields.phone]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.phone] && Boolean(formik.errors[Fields.phone])}
            helperText={formik.touched[Fields.phone] && formik.errors[Fields.phone]}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            required
            fullWidth
            label={t("business-owner-form.phone-type.label")}
            items={phoneTypes.map((type: PhoneType) => ({
              name: t(`phone-types.${type}`),
              value: type,
            }))}
            id={Fields.phoneType}
            name={Fields.phoneType}
            value={formik.values[Fields.phoneType]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.phoneType] && Boolean(formik.errors[Fields.phoneType])}
            helperText={formik.touched[Fields.phoneType] && formik.errors[Fields.phoneType]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BusinessOwnerForm;
