import { withAuthenticationRequired } from "@chq/authentication";
import { Footer, Header } from "@chq/components";
import { Grid, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import React, { ComponentType, FC } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import { EmailProvider } from "../../data/authentication/EmailContext";
import { useSendHelpEmailRequest } from "../../data/useSendHelpEmailRequest";
import { useTermsAndConditionLinks } from "../../data/useTermsAndConditionsLinks";
import { routes } from "../routes";
import HeaderText from "../shared/header-text";
import { useFormikConfig } from "../shared/validation-schema/validationSchema";
import withApplicationData from "./components/withApplicationData";
import AboutYourCompanyPage from "./pages/about-your-company-page";
import BusinessInfoPage from "./pages/business-info-page";
import BusinessUserPage from "./pages/business-user-page";
import CoveragesDatesPage from "./pages/coverages-dates-page";
import DocumentUploadsPage from "./pages/documents-upload-page";
import HiringPage from "./pages/hiring-page";
import InformationFailurePage from "./pages/information-failure-page";
import InformationSuccessPage from "./pages/information-success-page";
import InstallationPage from "./pages/installation-page";
import OperationsPage from "./pages/operations-page";
import RegistrationPage from "./pages/registration-page";
import ReviewApplicationPage from "./pages/review-application-page";
import VerifyAccountPage from "./pages/verify-account-page";
import WorkersCompPage from "./pages/workers-comp-page";

const withAuth = <P extends Record<string, unknown>>(Component: ComponentType<P>): FC<P> =>
  withAuthenticationRequired(Component, {
    onRedirecting: () => <Redirect push to={routes.path} />,
  });

const useStyles = makeStyles(() => ({
  enrollmentContainer: {
    minHeight: "100vh",
  },
  headerContainer: {
    textAlign: "center",
  },
  header: {
    padding: "0rem 3rem 0rem 3rem",
  },
}));

export const enrollmentRouteMap = {
  [routes.path]: "last-mile.header-text",
};

enum Fields {
  name = "name",
  email = "email",
  phone = "phone",
  problem = "problem",
  description = "description",
}

type FormProps = {
  [Fields.name]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
  [Fields.problem]: string;
  [Fields.description]: string;
};

const LastMileRoutes: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const formikProps = useFormikConfig();
  const { mutate: sendEmail } = useSendHelpEmailRequest();
  const terms = useTermsAndConditionLinks();
  const termsLink = terms.data?.data?.termsOfUseLink;
  const privacyLink = terms.data?.data?.privacyPolicyLink;

  //Get Array of Problems for footer email
  const listOfProblems = t("footer.problem-options", {
    returnObjects: true,
  });
  const problemOptionsArray: string[] = Object.values(listOfProblems);

  return (
    <Grid container direction="column" justify="center" className={classes.enrollmentContainer}>
      <Grid item className={classes.headerContainer}>
        <Header
          aonTitleAccess={t("common.aon-logo")}
          aonAriaLabel={t("common.aon-logo")}
          chqTitleAccess={t("common.chq-logo")}
          chqAriaLabel={t("common.chq-logo")}
          headerBarTitleAccess={t("common.middle-bar")}
          headerBarAriaLabel={t("common.middle-bar")}
          lastMile
        >
          <HeaderText routeMap={enrollmentRouteMap} className={classes.header} />
        </Header>
      </Grid>
      <EmailProvider>
        <Switch>
          <Route exact path={routes.path} component={BusinessUserPage} />
          <Route exact path={routes.coverages.path} component={withAuth(withApplicationData(CoveragesDatesPage))} />
          <Route exact path={routes.businessInfo.path} component={withAuth(withApplicationData(BusinessInfoPage))} />
          <Route exact path={routes.operations.path} component={withAuth(withApplicationData(OperationsPage))} />
          <Route exact path={routes.verify.path} component={withAuth(withApplicationData(VerifyAccountPage))} />
          <Route exact path={routes.hiring.path} component={withAuth(withApplicationData(HiringPage))} />
          <Route exact path={routes.register.path} component={RegistrationPage} />
          <Route
            exact
            path={routes.documentsUpload.path}
            component={withAuth(withApplicationData(DocumentUploadsPage))}
          />
          <Route exact path={routes.install.path} component={withAuth(withApplicationData(InstallationPage))} />
          <Route exact path={routes.success.path} component={InformationSuccessPage} />
          <Route exact path={routes.issue.path} component={InformationFailurePage} />
          <Route exact path={routes.review.path} component={withAuth(withApplicationData(ReviewApplicationPage))} />
          <Route
            exact
            path={routes.aboutYourCompany.path}
            component={withAuth(withApplicationData(AboutYourCompanyPage))}
          />
          <Route exact path={routes.workersCompPage.path} component={withAuth(withApplicationData(WorkersCompPage))} />

          <Redirect to={routes.missingPage.path} />
        </Switch>
      </EmailProvider>

      <Grid item>
        <Formik
          {...formikProps}
          onSubmit={(values: FormProps) => {
            sendEmail({
              name: values[Fields.name],
              emailAddress: values[Fields.email],
              phoneNumber: values[Fields.phone],
            });
          }}
        >
          <Footer
            isLmdUi={true}
            accordianSummaryAriaLable={t("footer.phone-number-aria-label")}
            termsLink={termsLink}
            privacyLink={privacyLink}
            nameLabel={t("footer.name")}
            phoneLabel={t("footer.phone")}
            emailLabel={t("footer.email")}
            problemLabel={t("footer.problem")}
            problemList={problemOptionsArray}
            problemListCondition={problemOptionsArray[6]}
            descriptionLabel={t("footer.description")}
            needHelpText={t("footer.need-help")}
            reachOutText={t("footer.reach-out")}
            privacyText={t("footer.privacy")}
            termsText={t("footer.terms")}
            legalText={t("footer.legal")}
            phoneNumber={t("footer.phone-number")}
            contactUsAt={t("footer.contact-us-at")}
            emailAddress={t("footer.email-address")}
            or={t("footer.or")}
            phoneNumberAriaLabel={t("footer.phone-number-aria-label")}
            emailAddressAriaLabel={t("footer.email-address-aria-label")}
          />
        </Formik>
      </Grid>
    </Grid>
  );
};

export default LastMileRoutes;
