/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { State, StateFromJSON, StateFromJSONTyped, StateToJSON } from "./";

/**
 *
 * @export
 * @interface Terminal
 */
export interface Terminal {
  /**
   *
   * @type {number}
   * @memberof Terminal
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Terminal
   */
  terminalCity?: string | null;
  /**
   *
   * @type {State}
   * @memberof Terminal
   */
  terminalState?: State;
  /**
   *
   * @type {string}
   * @memberof Terminal
   */
  terminalZipCode?: string | null;
}

export function TerminalFromJSON(json: any): Terminal {
  return TerminalFromJSONTyped(json, false);
}

export function TerminalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Terminal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    terminalCity: !exists(json, "terminalCity") ? undefined : json["terminalCity"],
    terminalState: !exists(json, "terminalState") ? undefined : StateFromJSON(json["terminalState"]),
    terminalZipCode: !exists(json, "terminalZipCode") ? undefined : json["terminalZipCode"],
  };
}

export function TerminalToJSON(value?: Terminal | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    terminalCity: value.terminalCity,
    terminalState: StateToJSON(value.terminalState),
    terminalZipCode: value.terminalZipCode,
  };
}
