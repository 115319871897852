/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenerateTokenSignInRequest
 */
export interface GenerateTokenSignInRequest {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenSignInRequest
   */
  id?: string | null;
}

export function GenerateTokenSignInRequestFromJSON(json: any): GenerateTokenSignInRequest {
  return GenerateTokenSignInRequestFromJSONTyped(json, false);
}

export function GenerateTokenSignInRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenSignInRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
  };
}

export function GenerateTokenSignInRequestToJSON(value?: GenerateTokenSignInRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
  };
}
