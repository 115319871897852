import { StyleLabel, YesNoInput } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classnames from "classnames";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    fontWeight: "bold",
  },
  yesNoInput: {
    marginTop: "0.5rem",
    margin: "0.625rem",
    marginBottom: "1.875rem",
    "& .MuiFormGroup-root": {
      flexWrap: "nowrap",
    },
  },
  subYesNoInput: {
    "& .MuiFormGroup-root": {
      [theme.breakpoints.up("lg")]: {
        "& >label": {
          minWidth: "4rem",
        },
      },
    },
  },
  drugScreenSectionParentLabel: {
    marginTop: "1.875rem",
    marginBottom: "1.875rem",
  },
  drugScreenSectionLabel: {
    marginTop: "1.875rem",
    marginBottom: "1.875rem",
  },
  drugScreenSectionContainer: {
    padding: "0 0.8rem",
  },
  labelContainer: {
    display: "flex",
  },
  preHireDrugScreeningSection: {
    marginTop: "1.25rem",
  },
  required: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

export enum Fields {
  doesObtainMvrsOnNewDrivers = "does-obtain-mvrs-on-new-drivers",
  doesReviewMvrsOnDriversAnnually = "does-review-mvrs-on-drivers-annually",
  doesConductPreHireSubstanceTesting = "does-conduct-pre-substance-testing",
  doesConductRandomSubstanceTesting = "does-conduct-Random-substance-testing",
  doesConductPostAccidentSubstanceTesting = "does-conduct-post-accident-substance-testing",
  isThereAnApplicantScreeningProcess = "is-applicant=screening-process",
  doesLeaseEmployees = "do-lease-employees",
  doesRequireTwoYearsDrivingExperience = "does-require-two-years-driving-experience",
  doesUtilizeIndependentContractors = "does-utilize-independent-contractors",
  doesHireLabors = "does-hire-laborers",
}

export type FormProps = {
  [Fields.doesObtainMvrsOnNewDrivers]: string | undefined;
  [Fields.doesReviewMvrsOnDriversAnnually]: string | undefined;
  [Fields.doesConductPreHireSubstanceTesting]: string | undefined;
  [Fields.doesConductRandomSubstanceTesting]: string | undefined;
  [Fields.doesConductPostAccidentSubstanceTesting]: string | undefined;
  [Fields.isThereAnApplicantScreeningProcess]: string | undefined;
  [Fields.doesLeaseEmployees]: string | undefined;
  [Fields.doesRequireTwoYearsDrivingExperience]: string | undefined;
  [Fields.doesUtilizeIndependentContractors]: string | undefined;
  [Fields.doesHireLabors]: string | undefined;
};

type Props = {
  doesObtainMvrsOnNewDrivers?: string | undefined;
  doesReviewMvrsOnDriversAnnually?: string | undefined;
  doesConductPreHireSubstanceTesting?: string | undefined;
  doesConductRandomSubstanceTesting?: string | undefined;
  doesConductPostAccidentSubstanceTesting?: string | undefined;
  isThereAnApplicantScreeningProcess?: string | undefined;
  doesLeaseEmployees?: string | undefined;
  doesRequireTwoYearsDrivingExperience?: string | undefined;
  doesUtilizeIndependentContractors?: string | undefined;
  doesHireLabors?: string | undefined;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const useValidationSchema = () => {
  return yup.object({
    [Fields.doesObtainMvrsOnNewDrivers]: yup.string().required(),
    [Fields.doesReviewMvrsOnDriversAnnually]: yup.string().required(),
    [Fields.doesConductPreHireSubstanceTesting]: yup.string().required(),
    [Fields.doesConductRandomSubstanceTesting]: yup.string().required(),
    [Fields.doesConductPostAccidentSubstanceTesting]: yup.string().required(),
    [Fields.isThereAnApplicantScreeningProcess]: yup.string().required(),
    [Fields.doesRequireTwoYearsDrivingExperience]: yup.string().required(),
    [Fields.doesUtilizeIndependentContractors]: yup.string().required(),
    [Fields.doesLeaseEmployees]: yup.string().required(),
    [Fields.doesHireLabors]: yup.string().required(),
  });
};

export const useFormikConfig = ({
  doesObtainMvrsOnNewDrivers: initialObtainMvrsOnNewDriversValue = "",
  doesReviewMvrsOnDriversAnnually: initialReviewMvrsOnDriversAnnuallyValue = "",
  doesConductPreHireSubstanceTesting: initialDoesConductPreHireSubstanceTesting = "",
  doesConductRandomSubstanceTesting: initialDoesConductRandomSubstanceTestingValue = "",
  doesConductPostAccidentSubstanceTesting: initialDoesConductPostAccientSubstanceTestingValue = "",
  isThereAnApplicantScreeningProcess: initialIsThereAnApplicantScreeningProcessValue = "",
  doesRequireTwoYearsDrivingExperience: initialRequireTwoYearsDrivingExperienceValue = "",
  doesUtilizeIndependentContractors: initialDoesHireIndependentContractorsValue = "",
  doesHireLabors: initialDoesHireLabors = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.doesObtainMvrsOnNewDrivers]: initialObtainMvrsOnNewDriversValue,
      [Fields.doesReviewMvrsOnDriversAnnually]: initialReviewMvrsOnDriversAnnuallyValue,
      [Fields.doesConductPreHireSubstanceTesting]: initialDoesConductPreHireSubstanceTesting,
      [Fields.doesConductRandomSubstanceTesting]: initialDoesConductRandomSubstanceTestingValue,
      [Fields.doesConductPostAccidentSubstanceTesting]: initialDoesConductPostAccientSubstanceTestingValue,
      [Fields.isThereAnApplicantScreeningProcess]: initialIsThereAnApplicantScreeningProcessValue,
      [Fields.doesLeaseEmployees]: initialDoesHireLabors,
      [Fields.doesRequireTwoYearsDrivingExperience]: initialRequireTwoYearsDrivingExperienceValue,
      [Fields.doesUtilizeIndependentContractors]: initialDoesHireIndependentContractorsValue,
      [Fields.doesHireLabors]: initialDoesHireLabors,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const HiringForm: React.FC = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container item xs={12} className={classes.drugScreenSectionLabel}>
        <StyleLabel variant={"h4"} labelText={t("hiring-form.obtain-mvrs-yes-no-question-label")} />
        <Grid item xs={12}>
          <YesNoInput
            className={classes.yesNoInput}
            required
            aria-pressed
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesObtainMvrsOnNewDrivers}
            name={Fields.doesObtainMvrsOnNewDrivers}
            value={formik.values[Fields.doesObtainMvrsOnNewDrivers]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesObtainMvrsOnNewDrivers] &&
              Boolean(formik.errors[Fields.doesObtainMvrsOnNewDrivers])
            }
            helperText={
              formik.touched[Fields.doesObtainMvrsOnNewDrivers] && formik.errors[Fields.doesObtainMvrsOnNewDrivers]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <StyleLabel variant={"h4"} labelText={t("hiring-form.review-mvrs-yes-no-question-label")} />
          <YesNoInput
            className={classes.yesNoInput}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesReviewMvrsOnDriversAnnually}
            name={Fields.doesReviewMvrsOnDriversAnnually}
            value={formik.values[Fields.doesReviewMvrsOnDriversAnnually]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesReviewMvrsOnDriversAnnually] &&
              Boolean(formik.errors[Fields.doesReviewMvrsOnDriversAnnually])
            }
            helperText={
              formik.touched[Fields.doesReviewMvrsOnDriversAnnually] &&
              formik.errors[Fields.doesReviewMvrsOnDriversAnnually]
            }
          />
        </Grid>
        <Grid container item spacing={2} className={classes.labelContainer}>
          <StyleLabel variant={"h4"} labelText={t("hiring-form.drug-test-label.drug-test-questions-parent-label")} />
        </Grid>
        <Grid container item xs={12} spacing={2} direction="row" className={classes.preHireDrugScreeningSection}>
          <Grid item container xs={12} lg={4}>
            <StyleLabel variant={"h4"} labelText={t("hiring-form.drug-test-label.pre-hire-yes-no-question-label")} />
            <Grid item>
              <YesNoInput
                className={classnames(classes.yesNoInput, classes.subYesNoInput)}
                required
                yesText={t("common.yes")}
                noText={t("common.no")}
                id={Fields.doesConductPreHireSubstanceTesting}
                name={Fields.doesConductPreHireSubstanceTesting}
                value={formik.values[Fields.doesConductPreHireSubstanceTesting]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.doesConductPreHireSubstanceTesting] &&
                  Boolean(formik.errors[Fields.doesConductPreHireSubstanceTesting])
                }
                helperText={
                  formik.touched[Fields.doesConductPreHireSubstanceTesting] &&
                  formik.errors[Fields.doesConductPreHireSubstanceTesting]
                }
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.drugScreenSectionContainer} xs={12} lg={4}>
            <StyleLabel variant={"h4"} labelText={t("hiring-form.drug-test-label.random-yes-no-question-label")} />
            <Grid item>
              <YesNoInput
                className={classnames(classes.yesNoInput, classes.subYesNoInput)}
                required
                yesText={t("common.yes")}
                noText={t("common.no")}
                id={Fields.doesConductRandomSubstanceTesting}
                name={Fields.doesConductRandomSubstanceTesting}
                value={formik.values[Fields.doesConductRandomSubstanceTesting]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.doesConductRandomSubstanceTesting] &&
                  Boolean(formik.errors[Fields.doesConductRandomSubstanceTesting])
                }
                helperText={
                  formik.touched[Fields.doesConductRandomSubstanceTesting] &&
                  formik.errors[Fields.doesConductRandomSubstanceTesting]
                }
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} lg={4}>
            <StyleLabel
              variant={"h4"}
              labelText={t("hiring-form.drug-test-label.post-accident-yes-no-question-label")}
            />
            <Grid item>
              <YesNoInput
                className={classnames(classes.yesNoInput, classes.subYesNoInput)}
                required
                yesText={t("common.yes")}
                noText={t("common.no")}
                id={Fields.doesConductPostAccidentSubstanceTesting}
                name={Fields.doesConductPostAccidentSubstanceTesting}
                value={formik.values[Fields.doesConductPostAccidentSubstanceTesting]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.doesConductPostAccidentSubstanceTesting] &&
                  Boolean(formik.errors[Fields.doesConductPostAccidentSubstanceTesting])
                }
                helperText={
                  formik.touched[Fields.doesConductPostAccidentSubstanceTesting] &&
                  formik.errors[Fields.doesConductPostAccidentSubstanceTesting]
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <StyleLabel variant={"h4"} labelText={t("hiring-form.application-screening-proccess-yes-no-question-label")} />
        <Grid item>
          <YesNoInput
            className={classes.yesNoInput}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.isThereAnApplicantScreeningProcess}
            name={Fields.isThereAnApplicantScreeningProcess}
            value={formik.values[Fields.isThereAnApplicantScreeningProcess]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.isThereAnApplicantScreeningProcess] &&
              Boolean(formik.errors[Fields.isThereAnApplicantScreeningProcess])
            }
            helperText={
              formik.touched[Fields.isThereAnApplicantScreeningProcess] &&
              formik.errors[Fields.isThereAnApplicantScreeningProcess]
            }
          />
        </Grid>
        <StyleLabel variant={"h4"} labelText={t("hiring-form.lease-employees-yes-no-question-label")} />
        <Grid item xs={12}>
          <YesNoInput
            className={classes.yesNoInput}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesLeaseEmployees}
            name={Fields.doesLeaseEmployees}
            value={formik.values[Fields.doesLeaseEmployees]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.doesLeaseEmployees] && Boolean(formik.errors[Fields.doesLeaseEmployees])}
            helperText={formik.touched[Fields.doesLeaseEmployees] && formik.errors[Fields.doesLeaseEmployees]}
          />
        </Grid>
        <StyleLabel variant={"h4"} labelText={t("hiring-form.driver-experience-requirement-yes-no-question-label")} />
        <Grid item xs={12}>
          <YesNoInput
            className={classes.yesNoInput}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesRequireTwoYearsDrivingExperience}
            name={Fields.doesRequireTwoYearsDrivingExperience}
            value={formik.values[Fields.doesRequireTwoYearsDrivingExperience]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesRequireTwoYearsDrivingExperience] &&
              Boolean(formik.errors[Fields.doesRequireTwoYearsDrivingExperience])
            }
            helperText={
              formik.touched[Fields.doesRequireTwoYearsDrivingExperience] &&
              formik.errors[Fields.doesRequireTwoYearsDrivingExperience]
            }
          />
        </Grid>
        <StyleLabel variant={"h4"} labelText={t("hiring-form.independent-contractors-yes-no-question-label")} />
        <Grid item xs={12}>
          <YesNoInput
            className={classes.yesNoInput}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesUtilizeIndependentContractors}
            name={Fields.doesUtilizeIndependentContractors}
            value={formik.values[Fields.doesUtilizeIndependentContractors]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesUtilizeIndependentContractors] &&
              Boolean(formik.errors[Fields.doesUtilizeIndependentContractors])
            }
            helperText={
              formik.touched[Fields.doesUtilizeIndependentContractors] &&
              formik.errors[Fields.doesUtilizeIndependentContractors]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <StyleLabel variant={"h4"} labelText={t("hiring-form.laboror-hire-type-yes-no-question-label")} />
          <YesNoInput
            className={classes.yesNoInput}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesHireLabors}
            name={Fields.doesHireLabors}
            value={formik.values[Fields.doesHireLabors]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.doesHireLabors] && Boolean(formik.errors[Fields.doesHireLabors])}
            helperText={formik.touched[Fields.doesHireLabors] && formik.errors[Fields.doesHireLabors]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default HiringForm;
