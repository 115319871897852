import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

type Props = SvgIconProps & {
  imageAriaLabel?: string;
};

const AonLogo: React.FC<Props> = ({ imageAriaLabel, ...SvgIconProps }) => {
  return (
    <SvgIcon titleAccess={imageAriaLabel} {...SvgIconProps} width="79" height="36" viewBox="0 0 79 36">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12142 29.7726H0.166504L12.8679 0.358253H17.4904L30.1918 29.7726H24.1115L15.2 8.00135L10.4108 19.5081H16.7406L19.0313 24.8113H8.2872L6.12142 29.7726ZM54.307 17.1666C54.307 24.4524 48.4503 30.3582 41.226 30.3582C34.0018 30.3582 28.145 24.4524 28.145 17.1666C28.145 9.88092 34.0014 3.97506 41.2252 3.97506C48.4491 3.97506 54.307 9.88132 54.307 17.1666ZM41.2682 9.52314C37.0817 9.52314 33.688 12.9456 33.688 17.1674C33.688 21.3893 37.0817 24.8114 41.2682 24.8114C45.4547 24.8114 48.848 21.3889 48.848 17.1674C48.848 12.946 45.4543 9.52314 41.2682 9.52314ZM56.5127 29.7726H62.0391V14.1358L74.5599 29.7726H78.8153V4.4855H73.3374V19.5614L61.1588 4.4855H56.5127V29.7726Z"
        fill="#EB0017"
      />
    </SvgIcon>
  );
};

export default AonLogo;
