/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  UserCreateResponse,
  UserCreateResponseFromJSON,
  UserCreateResponseFromJSONTyped,
  UserCreateResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface UserCreateResponseApiResponse
 */
export interface UserCreateResponseApiResponse {
  /**
   *
   * @type {UserCreateResponse}
   * @memberof UserCreateResponseApiResponse
   */
  data?: UserCreateResponse;
  /**
   *
   * @type {ErrorResponse}
   * @memberof UserCreateResponseApiResponse
   */
  error?: ErrorResponse;
}

export function UserCreateResponseApiResponseFromJSON(json: any): UserCreateResponseApiResponse {
  return UserCreateResponseApiResponseFromJSONTyped(json, false);
}

export function UserCreateResponseApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserCreateResponseApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : UserCreateResponseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function UserCreateResponseApiResponseToJSON(value?: UserCreateResponseApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: UserCreateResponseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
