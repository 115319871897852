import { Grid } from "@material-ui/core";
import React from "react";
import VerifyAccount from "../../../components/verify-account";
import { useGetApplication } from "../../../data/enrollment";
import { useScrollToTop } from "../../../data/useScrollToTop";
import LoginVerifyLayout from "../components/login-verify-layout";

const InformationFailurePage: React.FC = () => {
  const { data: application } = useGetApplication();

  useScrollToTop();

  return (
    <LoginVerifyLayout>
      <Grid item>
        <VerifyAccount email={application?.contactEmail} />
      </Grid>
    </LoginVerifyLayout>
  );
};

export default InformationFailurePage;
