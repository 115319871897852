/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TokenSignInRequest
 */
export interface TokenSignInRequest {
  /**
   *
   * @type {string}
   * @memberof TokenSignInRequest
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof TokenSignInRequest
   */
  token?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TokenSignInRequest
   */
  rememberLogin?: boolean;
  /**
   *
   * @type {string}
   * @memberof TokenSignInRequest
   */
  returnUrl?: string | null;
}

export function TokenSignInRequestFromJSON(json: any): TokenSignInRequest {
  return TokenSignInRequestFromJSONTyped(json, false);
}

export function TokenSignInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenSignInRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    token: !exists(json, "token") ? undefined : json["token"],
    rememberLogin: !exists(json, "rememberLogin") ? undefined : json["rememberLogin"],
    returnUrl: !exists(json, "returnUrl") ? undefined : json["returnUrl"],
  };
}

export function TokenSignInRequestToJSON(value?: TokenSignInRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    token: value.token,
    rememberLogin: value.rememberLogin,
    returnUrl: value.returnUrl,
  };
}
