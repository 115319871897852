import {
  ApiV10DocumentDeleteDriverListDocumentDeleteRequest,
  ApiV10DocumentDeleteEDeliveryConsentDeleteRequest,
  ApiV10DocumentDeleteLossRunsDocumentDeleteRequest,
  ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest,
  ApiV10DocumentDeleteVehicleListDocumentDeleteRequest,
  DocumentApi,
  EnrollmentApi,
} from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useDeleteDriverList = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentDeleteDriverListDocumentDeleteRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10DocumentDeleteDriverListDocumentDeleteRequest>(
    async (request: ApiV10DocumentDeleteDriverListDocumentDeleteRequest) => {
      await documentApi.apiV10DocumentDeleteDriverListDocumentDelete(request);
      const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          driverListDocument: undefined,
        },
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useDeleteLossRunsList = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentDeleteLossRunsDocumentDeleteRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10DocumentDeleteLossRunsDocumentDeleteRequest>(
    async (request: ApiV10DocumentDeleteLossRunsDocumentDeleteRequest) => {
      await documentApi.apiV10DocumentDeleteLossRunsDocumentDelete(request);
      const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          lossRunsDocument: undefined,
        },
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useDeleteReturnToWorkList = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest>(
    async (request: ApiV10DocumentDeleteReturnToWorkDocumentDeleteRequest) => {
      await documentApi.apiV10DocumentDeleteReturnToWorkDocumentDelete(request);
      const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          returnToWorkDocument: undefined,
        },
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useDeleteVehicleList = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentDeleteVehicleListDocumentDeleteRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10DocumentDeleteVehicleListDocumentDeleteRequest>(
    async (request: ApiV10DocumentDeleteVehicleListDocumentDeleteRequest) => {
      await documentApi.apiV10DocumentDeleteVehicleListDocumentDelete(request);
      const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          vehicleListDocument: undefined,
        },
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useDeleteEDeliveryConsentDocument = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentDeleteEDeliveryConsentDeleteRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, ApiV10DocumentDeleteEDeliveryConsentDeleteRequest>(
    async (request: ApiV10DocumentDeleteEDeliveryConsentDeleteRequest) => {
      await documentApi.apiV10DocumentDeleteEDeliveryConsentDelete(request);
      const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          electronicConsentRequirementsDocument: undefined,
        },
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};
