import { CardField, EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  onSiteAssemblyProducts?: string;
  dailyDeliveries?: string;
  itemsAssembled?: string;
  onSiteAssemblyAppliances?: string;
  deliveries?: string;
  applianceAssemblyType?: string;
  installsRequireLicense?: string;
  licensedContractor?: string;
  onEdit?: EditableDeletableCardProps["onEdit"];
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 1000,
    width: "100%",
  },
  cardField: {
    margin: ".2rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
    },
  },
  coverageCopy: {
    fontWeight: 700,
  },
  content: {
    marginTop: "1rem",
  },
}));

const ReviewHiringPracticesCard: React.FC<Props> = ({
  onSiteAssemblyProducts,
  dailyDeliveries,
  itemsAssembled,
  onSiteAssemblyAppliances,
  deliveries,
  applianceAssemblyType,
  installsRequireLicense,
  licensedContractor,
  onEdit,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container item xs={12}>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("services.card.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("services.card.edit-button") }}
        onEdit={() => history.push(routes.install.path)}
      >
        <Grid className={classes.content} spacing={1} container direction="row" wrap="nowrap">
          <Grid container item direction="column" wrap="nowrap">
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="on-site-assembly"
                label={t("services.card.on-site-assembly")}
                values={[{ value: onSiteAssemblyProducts, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="percent-daily-deliveries"
                label={t("services.card.percent-daily-deliveries")}
                values={[{ value: dailyDeliveries, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="items-assembled"
                label={t("services.card.items-assembled")}
                values={[{ value: itemsAssembled, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="on-site-assembly-appliances"
                label={t("services.card.on-site-assembly-appliances")}
                values={[{ value: onSiteAssemblyAppliances, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="percentage-deliveries"
                label={t("services.card.percentage-deliveries")}
                values={[{ value: deliveries, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="appliance-assembly-type"
                label={t("services.card.appliance-assembly-type")}
                values={[{ value: applianceAssemblyType, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="installs-requiring-license"
                label={t("services.card.installs-requiring-license")}
                values={[{ value: installsRequireLicense, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="licensed-contractor"
                label={t("services.card.licensed-contractor")}
                values={[{ value: licensedContractor, valueAlign: "left" }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ReviewHiringPracticesCard;
