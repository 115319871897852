import {
  ApiV10EnrollmentSendResumeApplicationLinkPostRequest,
  BooleanApiResponse,
  EnrollmentApi,
} from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "./useApi";

export const useSendResumeApplicationEmail = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10EnrollmentSendResumeApplicationLinkPostRequest
> => {
  const applicationApi = useApi(EnrollmentApi);
  const mutation = useMutation(async (request: ApiV10EnrollmentSendResumeApplicationLinkPostRequest) => {
    const response = await applicationApi.apiV10EnrollmentSendResumeApplicationLinkPost(request);

    return response;
  });
  return mutation;
};
