import { CardField, EditableDeletableCard } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name?: any;
  title?: string | null;
  relationshipType?: string | null;
  ownershipPercentage?: number;
  salary?: number;
  onEdit?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    width: "100%",
  },
  container: {
    paddingTop: "1rem",
  },
}));

const ExcludedIndividualCard: React.FC<Props> = ({
  name,
  title,
  relationshipType,
  ownershipPercentage,
  salary,
  onEdit,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <Grid container className={classes.container} item xs={12} sm={12}>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        onEdit={onEdit}
        title={name}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("excluded-individual-card.edit-button") }}
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            <CardField id="title" values={[{ value: title }]} />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <CardField
              id="relationshipType"
              label={t("excluded-individual-card.relationship-type-label")}
              values={[
                {
                  value: t("excluded-individual-card.relationship-type", {
                    relationshipType: relationshipType,
                  }),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardField
              id="ownershipPercentage"
              label={t("excluded-individual-card.ownership-percentage-label")}
              values={[
                {
                  value: t("excluded-individual-card.ownership-percentage", {
                    ownershipPercentage: ownershipPercentage,
                  }),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardField
              id="salary"
              label={t("excluded-individual-card.salary-label")}
              values={[{ value: t("excluded-individual-card.salary", { money: salary }) }]}
            />
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ExcludedIndividualCard;
