import { NumericInput, Select, TextInput } from "@chq/components";
import { State } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
  },
  labelOneLine: {
    whiteSpace: "nowrap",
  },
}));

export enum Fields {
  street = "street",
  city = "city",
  state = "state",
  zip = "zip",
}

export type FormProps = {
  [Fields.street]: string;
  [Fields.city]: string;
  [Fields.state]: State | "";
  [Fields.zip]: string;
};

type Props = {
  title?: string;
  street?: string;
  city?: string;
  state?: State | "";
  zip?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.street]: yup
      .string()
      .required(t(`errors.required`, { field: t(`physical-address-form.${Fields.street}.label`) })),
    [Fields.city]: yup
      .string()
      .required(t(`errors.required`, { field: t(`physical-address-form.${Fields.city}.label`) })),
    [Fields.state]: yup
      .string()
      .required(t(`errors.required`, { field: t(`physical-address-form.${Fields.state}.label`) })),
    [Fields.zip]: yup
      .string()
      .required(
        t("errors.required", {
          field: t(`physical-address-form.${Fields.zip}.label`),
        }),
      )
      .test(
        "length",
        t("errors.exact-digits", {
          field: t(`physical-address-form.${Fields.zip}.label`),
          exact: 5,
        }),
        (val) => val !== undefined && val.toString().length === 5,
      ),
  });
};

export const useFormikConfig = ({
  street: initialStreet = "",
  city: initialCity = "",
  state: initialState = "",
  zip: initialZip = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      street: initialStreet,
      city: initialCity,
      state: initialState,
      zip: initialZip,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const PhysicalAddressForm: React.FC<Props> = ({ title }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const states = useStates();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {title && (
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextInput
            fullWidth
            required
            label={t("physical-address-form.street.label")}
            id={Fields.street}
            name={Fields.street}
            value={formik.values[Fields.street]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.street] && Boolean(formik.errors[Fields.street])}
            helperText={formik.touched[Fields.street] && formik.errors[Fields.street]}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            fullWidth
            required
            label={t("physical-address-form.city.label")}
            id={Fields.city}
            name={Fields.city}
            value={formik.values[Fields.city]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.city] && Boolean(formik.errors[Fields.city])}
            helperText={formik.touched[Fields.city] && formik.errors[Fields.city]}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            fullWidth
            required
            items={states
              .map((state) => ({
                name: state.abv,
                value: state.abv,
              }))
              .sort((a, b) => {
                return a.value > b.value ? 1 : -1;
              })}
            label={t("physical-address-form.state.label")}
            id={Fields.state}
            name={Fields.state}
            value={formik.values[Fields.state]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.state] && Boolean(formik.errors[Fields.state])}
            helperText={formik.touched[Fields.state] && formik.errors[Fields.state]}
          />
        </Grid>
        <Grid item xs={3}>
          <NumericInput
            fullWidth
            format="#####"
            isNumericString
            required
            label={t("physical-address-form.zip.label")}
            id={Fields.zip}
            name={Fields.zip}
            value={formik.values[Fields.zip]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.zip] && Boolean(formik.errors[Fields.zip])}
            helperText={formik.touched[Fields.zip] && formik.errors[Fields.zip]}
            className={classes.labelOneLine}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default PhysicalAddressForm;
