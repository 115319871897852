import { CardField, EditableDeletableCard } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  mvrPrior?: string;
  annualMvrReview?: string;
  preHireDrug?: string;
  randomDrug?: string;
  postAccidentDrug?: string;
  screeningProcess?: string;
  leaseEmployees?: string;
  drivers2YearsExperience?: string;
  indContractors?: string;
  dayCasualLabor?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 500,
    width: "100%",
  },
  cardField: {
    margin: ".2rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
    },
  },
  coverageCopy: {
    fontWeight: 700,
  },
  content: {
    marginTop: "1rem",
  },
}));

const ReviewHiringPracticesCard: React.FC<Props> = ({
  mvrPrior,
  annualMvrReview,
  preHireDrug,
  randomDrug,
  postAccidentDrug,
  screeningProcess,
  leaseEmployees,
  drivers2YearsExperience,
  indContractors,
  dayCasualLabor,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("review-hiring-practices.card.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("review-hiring-practices.card.edit-button") }}
        onEdit={() => history.push(routes.hiring.path)}
      >
        <Grid className={classes.content} spacing={1} container direction="row" wrap="nowrap">
          <Grid container item direction="column" wrap="nowrap">
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="mvr-prior"
                label={t("review-hiring-practices.card.mvr-prior")}
                values={[{ value: mvrPrior, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="annual-mvr-review"
                label={t("review-hiring-practices.card.annual-mvr-reviews")}
                values={[{ value: annualMvrReview, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="pre-hire-drug"
                label={t("review-hiring-practices.card.pre-hire-drug")}
                values={[{ value: preHireDrug, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="random-drug"
                label={t("review-hiring-practices.card.random-drug")}
                values={[{ value: randomDrug, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="post-accident-drug"
                label={t("review-hiring-practices.card.post-accident-drug")}
                values={[{ value: postAccidentDrug, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="screening-process"
                label={t("review-hiring-practices.card.applicant-screening")}
                values={[{ value: screeningProcess, valueAlign: "right" }]}
                labelGrid={10}
                valueGrid={2}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="lease-employees"
                label={t("review-hiring-practices.card.lease-employees")}
                values={[{ value: leaseEmployees, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="drivers-two-years-experience"
                label={t("review-hiring-practices.card.drivers-two-years")}
                values={[{ value: drivers2YearsExperience, valueAlign: "right" }]}
                labelGrid={9}
                valueGrid={3}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="independent-contractors"
                label={t("review-hiring-practices.card.ind-contractors")}
                values={[{ value: indContractors, valueAlign: "right" }]}
                labelGrid={9}
                valueGrid={3}
              />
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                className={classes.cardCopy}
                id="day-casual-labor"
                label={t("review-hiring-practices.card.day-casual-labor")}
                values={[{ value: dayCasualLabor, valueAlign: "right" }]}
                labelGrid={8}
                valueGrid={4}
              />
            </Grid>
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ReviewHiringPracticesCard;
