import { EditableDeletableCard, EditableDeletableCardProps, NumericInput, TextInput } from "@chq/components";
import { Button, Grid, IconButtonProps, makeStyles, Theme } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import classNames from "classnames";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  saveButtonContainer: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
  },
  saveButton: {
    fontSize: "",
  },
  saveButtonText: {
    paddingLeft: ".4rem",
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  saveButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.grey[400],
    },
  },
  saveButtonDisabled: {
    backgroundColor: theme.palette.primary.light,
  },
  saveIcon: {
    height: "1.125rem",
  },
  saveButtonLabel: {
    alignItems: "center",
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    fontSize: ".75rem",
    marginLeft: ".25rem",
  },
  addButton: {
    padding: "1.125rem 0rem",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "3rem",
  },
  bodyCopy: {
    fontSize: ".75rem",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  bodyHeading: {
    fontSize: ".875rem",
  },
  button: {
    padding: "1rem 0",
  },
  checkboxContain: {
    marginTop: "2.75rem",
  },
  formHeading: {
    height: "2rem",
    marginBottom: "1rem",
  },
  formInstance: {
    marginTop: "2rem",
  },
  cardStyles: {
    backgroundColor: theme.palette.grey[600],
    padding: "0",
    width: "100%",
    marginTop: "1rem",
  },
  editableDeletableCardStyles: {
    padding: "0",
    backgroundColor: theme.palette.common.white,
  },
  bold: {
    fontWeight: "bold",
  },
}));

export enum Fields {
  providerName = "providerName",
  shipmentPercent = "shipmentPercent",
  yearsContracted = "yearsContracted",
  delete = "delete-button",
}

type Props = {
  providerName?: string | null;
  shipmentPercent?: string | number;
  yearsContracted?: string | number;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  onDelete?: EditableDeletableCardProps["onDelete"];
  providerNumber?: number;
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
};

export type FormProps = {
  [Fields.providerName]: string | null;
  [Fields.shipmentPercent]: string | number;
  [Fields.yearsContracted]: string | number;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.providerName]: yup
      .string()
      .required(
        t("errors.required", { field: t(`operations.logistics-provider-form.${[Fields.providerName]}.label`) }),
      ),
    [Fields.shipmentPercent]: yup
      .number()
      .min(
        1,
        t("errors.min", {
          field: t(`operations.logistics-provider-form.${[Fields.shipmentPercent]}.label`),
          min: 1,
        }),
      )
      .max(
        100,
        t("errors.max", {
          field: t(`operations.logistics-provider-form.${[Fields.shipmentPercent]}.label`),
          max: 100,
        }),
      )
      .required(
        t("errors.required", { field: t(`operations.logistics-provider-form.${[Fields.shipmentPercent]}.label`) }),
      ),
    [Fields.yearsContracted]: yup
      .number()
      .required(
        t("errors.required", { field: t(`operations.logistics-provider-form.${[Fields.yearsContracted]}.label`) }),
      ),
  });
};

export const useFormikConfig = ({
  providerName: initialProviderName = "",
  shipmentPercent: initialShipmentPercent = "",
  yearsContracted: initialYearsContracted = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.providerName]: initialProviderName,
      [Fields.shipmentPercent]: initialShipmentPercent,
      [Fields.yearsContracted]: initialYearsContracted,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const LogisticsProviderForm: React.FC<Props> = ({
  providerName: initialProviderName = "",
  shipmentPercent: initialShipmentPercent = "",
  yearsContracted: initialYearsContracted = "",
  onSubmit,
  onDelete,
  providerNumber = 0,
  IconButtonProps,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const validationSchema = useValidationSchema();

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.providerName]: initialProviderName,
      [Fields.shipmentPercent]: initialShipmentPercent,
      [Fields.yearsContracted]: initialYearsContracted,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
  });

  const checkIfErrorIsShowing = () => {
    const errors = Object.keys(formik.errors);
    const isErrorShowing = Object.keys(formik.touched).map((touched) => {
      return errors.includes(touched);
    });
    return isErrorShowing.includes(true);
  };

  return (
    <EditableDeletableCard
      className={classes.cardStyles}
      containerClass={classes.editableDeletableCardStyles}
      border={false}
      title={t("operations.logistics-provider-form.logistics-provider", { number: providerNumber + 1 })}
      error={checkIfErrorIsShowing()}
      titleVariant="h3"
      titleComponent="h3"
      titleClass={classes.bold}
      variant="delete"
      onDelete={onDelete}
      IconButtonProps={{
        "aria-label": t("operations.logistics-provider-card.delete-icon"),
        ...IconButtonProps,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="row" key={`logistics-provider-${providerNumber}`} className={classes.formInstance}>
          <Grid item xs={12}>
            <TextInput
              required
              id={Fields.providerName}
              name={Fields.providerName}
              label={t(`operations.logistics-provider-form.${[Fields.providerName]}.label`)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.providerName] && Boolean(formik.errors[Fields.providerName])}
              helperText={formik.touched[Fields.providerName] && formik.errors[Fields.providerName]}
              value={formik.values[Fields.providerName]}
              fullWidth
            />
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={6}>
              <NumericInput
                format="###"
                id={Fields.shipmentPercent}
                name={Fields.shipmentPercent}
                label={t(`operations.logistics-provider-form.${[Fields.shipmentPercent]}.label`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.shipmentPercent] && Boolean(formik.errors[Fields.shipmentPercent])}
                helperText={formik.touched[Fields.shipmentPercent] && formik.errors[Fields.shipmentPercent]}
                value={formik.values[Fields.shipmentPercent]}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumericInput
                format="###"
                id={Fields.yearsContracted}
                name={Fields.yearsContracted}
                label={t(`operations.logistics-provider-form.${[Fields.yearsContracted]}.label`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.yearsContracted] && Boolean(formik.errors[Fields.yearsContracted])}
                helperText={formik.touched[Fields.yearsContracted] && formik.errors[Fields.yearsContracted]}
                value={formik.values[Fields.yearsContracted]}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.saveButtonContainer}>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            disabled={!formik.isValid}
            className={formik.isValid ? classNames(classes.saveButtonActive) : classNames(classes.saveButtonDisabled)}
          >
            <Grid container className={classes.saveButtonLabel}>
              <SaveIcon className={classes.saveIcon} />
              <div className={classes.saveButtonText}>
                {t("operations.logistics-provider-form.save-logistics-provider", { number: providerNumber + 1 })}
              </div>
            </Grid>
          </Button>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default LogisticsProviderForm;
