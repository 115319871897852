/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TermsAndConditionsLinks
 */
export interface TermsAndConditionsLinks {
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  termsOfUseLink?: string | null;
  /**
   *
   * @type {string}
   * @memberof TermsAndConditionsLinks
   */
  privacyPolicyLink?: string | null;
}

export function TermsAndConditionsLinksFromJSON(json: any): TermsAndConditionsLinks {
  return TermsAndConditionsLinksFromJSONTyped(json, false);
}

export function TermsAndConditionsLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsAndConditionsLinks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    termsOfUseLink: !exists(json, "termsOfUseLink") ? undefined : json["termsOfUseLink"],
    privacyPolicyLink: !exists(json, "privacyPolicyLink") ? undefined : json["privacyPolicyLink"],
  };
}

export function TermsAndConditionsLinksToJSON(value?: TermsAndConditionsLinks | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    termsOfUseLink: value.termsOfUseLink,
    privacyPolicyLink: value.privacyPolicyLink,
  };
}
