import { DeleteIcon, NumericInput, PlusCircleIcon as PlusCircle, TextInput } from "@chq/components";
import { Button, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldArray, FormikConfig, FormikErrors, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    paddingBottom: "0.5rem",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  labelOneLine: {
    whiteSpace: "nowrap",
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  addButton: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
  },
}));

export enum Fields {
  id = "id",
  name = "name",
  percentage = "percentage",
}

export type Partner = {
  [Fields.id]?: number;
  [Fields.name]: string;
  [Fields.percentage]?: string;
};

export type FormProps = {
  partners: Partner[];
};

type Props = FormikProps & {
  isDeleting: boolean;
  onDeletePartner: (id: number) => void;
};

type FormikProps = {
  partners?: Partner[];
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  return yup.object({
    partners: yup.array().of(
      yup.object({
        [Fields.id]: yup.string(),
        [Fields.name]: yup.string(),
        [Fields.percentage]: yup.string(),
      }),
    ),
  });
};

export const useFormikConfig = ({
  partners: initialPartners = [
    {
      [Fields.name]: "",
      [Fields.percentage]: "",
    },
  ],
}: FormikProps = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      partners: initialPartners.length
        ? initialPartners
        : [
            {
              [Fields.name]: "",
              [Fields.percentage]: "",
            },
          ],
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const PartnerForm: React.FC<Props> = ({ isDeleting, onDeletePartner }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();
  const hidden = true;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.title}>
          {t("partner-form.title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <FieldArray name={"partners"}>
            {({ push, remove }) => {
              return (
                <>
                  {!hidden && (
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<PlusCircle className={classes.addIcon} />}
                        onClick={() => {
                          push({
                            [Fields.name]: "",
                            [Fields.percentage]: "",
                          });
                        }}
                        className={classes.addButton}
                      >
                        {t("partner-form.add-button")}
                      </Button>
                    </Grid>
                  )}
                  {formik.values.partners.map((partner, index) => {
                    const partnerTouched = formik.touched.partners || [];
                    const partnerError = (formik.errors.partners as FormikErrors<Partner>[]) || [];
                    return (
                      <Grid item container spacing={1} key={`partner ${index}`} direction="row" alignItems="center">
                        <Grid item xs={7}>
                          <TextInput
                            fullWidth
                            label={t("partner-form.name.label")}
                            id={Fields.name}
                            name={`partners.${[index]}.${[Fields.name]}`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.partners[index][Fields.name]}
                            error={
                              partnerTouched[index] &&
                              partnerTouched[index][Fields.name] &&
                              Boolean(partnerError[index] && partnerError[index][Fields.name])
                            }
                            helperText={
                              partnerTouched[index] &&
                              partnerTouched[index][Fields.name] &&
                              partnerError[index] &&
                              partnerError[index][Fields.name]
                            }
                            className={classes.labelOneLine}
                          />
                        </Grid>
                        {!hidden && (
                          <Grid item xs={3}>
                            <NumericInput
                              disabled
                              fullWidth
                              isNumericString
                              label={t("partner-form.percentage.label")}
                              id={Fields.percentage}
                              name={`partners.${[index]}.${[Fields.percentage]}`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.partners[index][Fields.percentage]}
                              error={
                                partnerTouched[index] &&
                                partnerTouched[index][Fields.percentage] &&
                                Boolean(partnerError[index] && partnerError[index][Fields.percentage])
                              }
                              helperText={
                                partnerTouched[index] &&
                                partnerTouched[index][Fields.percentage] &&
                                partnerError[index] &&
                                partnerError[index][Fields.percentage]
                              }
                            />
                          </Grid>
                        )}
                        {index !== 0 && (
                          <Grid item xs={2}>
                            <IconButton
                              aria-label={t(`partner-form.delete-icon`)}
                              className={classes.iconButton}
                              disabled={isDeleting}
                              onClick={() => {
                                const existingPartnerId = partner[Fields.id];
                                if (existingPartnerId && onDeletePartner) {
                                  onDeletePartner(existingPartnerId);
                                } else {
                                  remove(index);
                                }
                              }}
                            >
                              <DeleteIcon color="disabled" className={classes.icon} />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </>
              );
            }}
          </FieldArray>
        </form>
      </Grid>
    </Grid>
  );
};

export default PartnerForm;
