import { CardField, EditableDeletableCard } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  companyName?: string;
  companyType?: string;
  dotNumber?: string;
  mcNumber?: string;
  einTaxId?: string;
  physicalAddress?: string;
  mailingAddress?: string;
  businessOwner?: string | JSX.Element;
  partners?: string | JSX.Element;
  subsidiariesParents?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 500,
    width: "100%",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  businessDetails: {
    marginBottom: ".5rem",
  },
  cardField: {
    margin: ".25rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
      lineHeight: "1.125rem",
    },
  },
  coverageCopy: {
    fontWeight: 700,
  },
  content: {
    marginTop: ".5rem",
    "& .MuiTypography-root": {
      lineHeight: "1.125rem",
    },
  },
  section: {
    padding: ".5rem 0",
  },
}));

const ReviewBusinessDetailsCard: React.FC<Props> = ({
  companyName,
  companyType,
  dotNumber,
  mcNumber,
  einTaxId,
  physicalAddress,
  mailingAddress,
  businessOwner,
  partners,
  subsidiariesParents,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container item xs={12}>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("review-business-details.card.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("review-business-details.card.edit-button") }}
        onEdit={() => history.push(routes.businessInfo.path)}
      >
        <Grid className={classes.content} spacing={1} container direction="row" wrap="nowrap">
          <Grid container item direction="column" wrap="nowrap">
            <Grid container direction="column" className={classes.businessDetails}>
              <Grid item>
                <Typography variant="h4">{companyName}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">{companyType}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classNames(classes.borderBottom, classes.section)}>
              <Grid item xs={12} sm={12} md={5} lg={6}>
                <CardField
                  className={classes.cardCopy}
                  id="dot-number"
                  label={t("review-business-details.card.dot-number")}
                  values={[{ value: dotNumber, valueAlign: "left" }]}
                  labelGrid={3}
                  valueGrid={9}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <CardField
                  className={classes.cardCopy}
                  id="mc-number"
                  label={t("review-business-details.card.mc-number")}
                  values={[{ value: mcNumber, valueAlign: "left" }]}
                  labelGrid={3}
                  valueGrid={9}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7}>
                <CardField
                  className={classes.cardCopy}
                  id="ein-tax-id"
                  label={t("review-business-details.card.ein-tax-id")}
                  values={[{ value: einTaxId, valueAlign: "left" }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
            </Grid>
            <Grid container direction="column" className={classNames(classes.borderBottom, classes.section)}>
              <CardField
                className={classes.cardCopy}
                id="physical-address"
                label={t("review-business-details.card.physical-address")}
                values={[{ value: physicalAddress }]}
                labelGrid={6}
                valueGrid={6}
              />
              <CardField
                className={classes.cardCopy}
                id="mailing-address"
                label={t("review-business-details.card.mailing-address")}
                values={[{ value: mailingAddress }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="column" className={classNames(classes.borderBottom, classes.section)}>
              <CardField
                className={classes.cardCopy}
                id="business-owner"
                label={t("review-business-details.card.business-owner")}
                values={[{ value: businessOwner }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            {partners && (
              <Grid container direction="column" className={classNames(classes.borderBottom, classes.section)}>
                <CardField
                  className={classes.cardCopy}
                  id="partners-officers"
                  label={t("review-business-details.card.partners-officers")}
                  values={[{ value: partners }]}
                  labelGrid={6}
                  valueGrid={6}
                />
              </Grid>
            )}
            <Grid container direction="column" className={classes.section}>
              <CardField
                className={classes.cardCopy}
                id="subsidiaries"
                label={t("review-business-details.card.subsidiaries-parents")}
                values={[{ value: subsidiariesParents }]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ReviewBusinessDetailsCard;
