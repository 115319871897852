import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useScrollToTop } from "../../../data/useScrollToTop";
import animationData from "../../../lotties/thumbs-up.json";
import CompletedContentLayout from "../components/completed-content-layout";

const useStyles = makeStyles((theme: Theme) => ({
  successIcon: {
    color: theme.palette.success.main,
    display: "block",
    fontSize: "6rem",
    margin: "0 auto",
  },
  divider: {
    borderBottom: "1px solid",
    color: theme.palette.grey[400],
    margin: "2.5rem 0",
  },
  createCopy: {
    marginBottom: "1.5rem",
    maxWidth: "18.75rem",
    margin: "0 auto",
    "& .MuiTypography-root": {
      lineHeight: "1.25rem",
      fontWeight: 700,
    },
  },
  mainBodyText: {
    fontSize: "1rem",
  },
  button: {
    maxWidth: "22.5rem",
  },
}));

const InformationFailurePage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useScrollToTop();

  return (
    <CompletedContentLayout
      title={t("success-page.title")}
      icon={<Lottie options={defaultOptions} height={125} width={125} />}
    >
      <Grid item>
        <Typography className={classes.mainBodyText} variant="body2" align="center">
          {t("success-page.main-one")}
        </Typography>
      </Grid>
      {/* Remove for now per Blaine on 10/21 */}
      {/* <Grid item>
        <div className={classes.divider}></div>
      </Grid>
      <Grid item className={classes.createCopy}>
        <Typography align="center">{t("success-page.main-two")}</Typography>
      </Grid>
      <Grid container justify="center">
        <NextButton className={classes.button} fullWidth variant="contained">
          {t("success-page.button")}
        </NextButton>
      </Grid> */}
    </CompletedContentLayout>
  );
};

export default InformationFailurePage;
