import { DocumentUpload } from "@chq/components";
import { Document } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useUploadTemplatesLinks } from "../data/useUploadTemplatesLinks";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "0.5rem",
  },
  documentsSection: {
    marginTop: "1rem",
    "& > .MuiGrid-container": {
      marginBottom: "1rem",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

export enum Fields {
  vehicleList = "vehicle-list",
  driverList = "driver-list",
  lossRuns = "loss-runs",
}

type Props = {
  vehicleList?: Document;
  driverList?: Document;
  lossRuns?: Document;
  addVehicleList?: (file: File) => void;
  addDriverList?: (file: File) => void;
  addLossRuns?: (file: File) => void;
  onDeleteVehicleList?: () => void;
  onDeleteDriverList?: () => void;
  onDeleteLossRunList?: () => void;
};

export type FormProps = {
  [Fields.vehicleList]?: Document;
  [Fields.driverList]?: Document;
  [Fields.lossRuns]?: Document;
};

const useValidationSchema = () => {
  const [t] = useTranslation();

  yup.object().shape({
    [Fields.vehicleList]: yup.object().shape({
      name: yup
        .string()
        .required(t(`errors.required`, { field: t(`upload-some-documents.form.${Fields.vehicleList}`) })),
    }),
    [Fields.driverList]: yup.object().shape({
      name: yup
        .string()
        .required(t(`errors.required`, { field: t(`upload-some-documents.form.${Fields.driverList}`) })),
    }),
    [Fields.lossRuns]: yup.object().shape({
      name: yup.string().required(t(`errors.required`, { field: t(`upload-some-documents.form.${Fields.lossRuns}`) })),
    }),
  });
};

export const useFormikConfig = ({
  vehicleList: initialVehicleList = undefined,
  driverList: initialDriverList = undefined,
  lossRuns: initialLossRuns = undefined,
  addVehicleList,
  addDriverList,
  addLossRuns,
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.vehicleList]: initialVehicleList,
      [Fields.driverList]: initialDriverList,
      [Fields.lossRuns]: initialLossRuns,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const DocumentsForm: React.FC<Props> = ({
  vehicleList,
  driverList,
  lossRuns,
  addVehicleList,
  addDriverList,
  addLossRuns,
  onDeleteVehicleList,
  onDeleteDriverList,
  onDeleteLossRunList,
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();

  const templates = useUploadTemplatesLinks();
  const vehicleListTemplateLink = templates.data?.data?.vehicleListTemplateLink;
  const driverListTemplateLink = templates.data?.data?.driverListTemplateLink;

  return (
    <form onSubmit={formik.handleSubmit} className={classes.fullWidth}>
      <Grid container item direction="column" className={classes.documentsSection}>
        <DocumentUpload
          key={"vehicleList"}
          title={t(`upload-some-documents.form.${Fields.vehicleList}`)}
          subtitle={t("upload-some-documents.form.subtitle")}
          fileTypes="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" // all images, .pdf, .doc, .docx, .xls, .xlsx
          isDocumentEntered={Boolean(vehicleList?.name)}
          name={vehicleList?.name}
          filePrompt={t("upload-some-documents.form.prompt")}
          downloadTemplate={vehicleListTemplateLink!}
          downloadTemplateText={t("upload-some-documents.form.template")}
          onChange={(e) => {
            addVehicleList && addVehicleList(e.target.files!.item(0)!);
            e.target.value = "";
          }}
          onDelete={onDeleteVehicleList}
        />
        <DocumentUpload
          key={"driverList"}
          title={t(`upload-some-documents.form.${Fields.driverList}`)}
          subtitle={t("upload-some-documents.form.subtitle")}
          fileTypes="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" // all images, .pdf, .doc, .docx, .xls, .xlsx
          isDocumentEntered={Boolean(driverList)}
          name={driverList?.name}
          filePrompt={t("upload-some-documents.form.prompt")}
          downloadTemplate={driverListTemplateLink!}
          downloadTemplateText={t("upload-some-documents.form.template")}
          onChange={(e) => {
            addDriverList && addDriverList(e.target.files!.item(0)!);
            e.target.value = "";
          }}
          onDelete={onDeleteDriverList}
        />
        <DocumentUpload
          key={"lossRuns"}
          title={t(`upload-some-documents.form.${Fields.lossRuns}`)}
          subtitle={t("upload-some-documents.form.subtitle")}
          fileTypes="image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" // all images, .pdf, .doc, .docx, .xls, .xlsx
          isDocumentEntered={Boolean(lossRuns)}
          name={lossRuns?.name}
          filePrompt={t("upload-some-documents.form.prompt")}
          onChange={(e) => {
            addLossRuns && addLossRuns(e.target.files!.item(0)!);
            e.target.value = "";
          }}
          onDelete={onDeleteLossRunList}
        />
      </Grid>
    </form>
  );
};

export default DocumentsForm;
