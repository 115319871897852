import { NumericInput, Select, TextInput } from "@chq/components";
import { CompanyType } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useEntityTypes } from "../data/useEntityTypes";

const useStyles = makeStyles((theme: Theme) => ({
  labelOneLineDesktop: {
    height: "4rem",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      height: "auto",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      whiteSpace: "normal",
    },
  },
}));

export enum Fields {
  businessName = "business-name",
  companyType = "company-type",
  dotNumber = "dot-number",
  mcNumber = "mc-number",
  ein = "ein",
}

export type FormProps = {
  [Fields.businessName]: string;
  [Fields.companyType]: CompanyType | "";
  [Fields.dotNumber]: string;
  [Fields.mcNumber]: string;
  [Fields.ein]: string;
};

type Props = {
  businessName?: string;
  companyType?: CompanyType | "";
  dotNumber?: string;
  mcNumber?: string;
  ein?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.businessName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-info-form.${Fields.businessName}.label`) })),
    [Fields.companyType]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-info-form.${Fields.companyType}.label`) })),
    [Fields.dotNumber]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-info-form.${Fields.dotNumber}.label`) }))
      .test(
        "length",
        t("errors.between-digits", {
          field: t(`business-info-form.${Fields.dotNumber}.label`),
          min: "5",
          max: "8",
        }),
        (val) => (val ? val.toString().length >= 5 && val.toString().length <= 8 : true),
      ),
    [Fields.mcNumber]: yup
      .string()
      .test(
        "length",
        t("errors.between-digits", {
          field: t(`business-info-form.${Fields.mcNumber}.label`),
          min: "6",
          max: "8",
        }),
        (val) => (val ? val.toString().length >= 6 && val.toString().length <= 8 : true),
      )
      .required(t("errors.required", { field: t(`business-info-form.${Fields.mcNumber}.label`) })),
    [Fields.ein]: yup
      .string()
      .required(t("errors.required", { field: t("business-info-form.ein.label") }))
      .test(
        "length",
        t("errors.exact-digits", {
          field: t("business-info-form.ein.label"),
          exact: 9,
        }),
        (val) => val !== undefined && val.toString().length === 9,
      ),
  });
};

export const useFormikConfig = ({
  businessName: initialBusinessName = "",
  companyType: initialCompanyType = "",
  dotNumber: initialDotNumber = "",
  mcNumber: initialMcNumber = "",
  ein: initialEin = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.businessName]: initialBusinessName,
      [Fields.companyType]: initialCompanyType,
      [Fields.dotNumber]: initialDotNumber,
      [Fields.mcNumber]: initialMcNumber,
      [Fields.ein]: initialEin,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const BusinessInfoForm: React.FC<Props> = () => {
  const [t] = useTranslation();
  const entityTypes = useEntityTypes();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInput
            label={t("business-info-form.business-name.label")}
            required
            fullWidth
            id={Fields.businessName}
            name={Fields.businessName}
            value={formik.values[Fields.businessName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.businessName] && Boolean(formik.errors[Fields.businessName])}
            helperText={formik.touched[Fields.businessName] && formik.errors[Fields.businessName]}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={t("business-info-form.company-type.label")}
            required
            fullWidth
            id={Fields.companyType}
            name={Fields.companyType}
            value={formik.values[Fields.companyType]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.companyType] && Boolean(formik.errors[Fields.companyType])}
            helperText={formik.touched[Fields.companyType] && formik.errors[Fields.companyType]}
            items={entityTypes.map((status: CompanyType) => ({
              name: t(`entity-types.${status}`),
              value: status,
            }))}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericInput
            className={classes.labelOneLineDesktop}
            required
            format="########"
            fullWidth
            isNumericString
            id={Fields.dotNumber}
            name={Fields.dotNumber}
            label={t("business-info-form.dot-number.label")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.dotNumber]}
            error={formik.touched[Fields.dotNumber] && Boolean(formik.errors[Fields.dotNumber])}
            helperText={formik.touched[Fields.dotNumber] && formik.errors[Fields.dotNumber]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericInput
            className={classes.labelOneLineDesktop}
            required
            format="#######"
            fullWidth
            label={t("business-info-form.mc-number.label")}
            id={Fields.mcNumber}
            name={Fields.mcNumber}
            value={formik.values[Fields.mcNumber]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.mcNumber] && Boolean(formik.errors[Fields.mcNumber])}
            helperText={formik.touched[Fields.mcNumber] && formik.errors[Fields.mcNumber]}
          />
        </Grid>
        <Grid item xs={4}>
          <NumericInput
            className={classes.labelOneLineDesktop}
            format="##-#######"
            isNumericString
            required
            fullWidth
            label={t("business-info-form.ein.label")}
            id={Fields.ein}
            name={Fields.ein}
            value={formik.values[Fields.ein]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.ein] && Boolean(formik.errors[Fields.ein])}
            helperText={formik.touched[Fields.ein] && formik.errors[Fields.ein]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BusinessInfoForm;
