/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PhoneType {
  Cell = "Cell",
  Office = "Office",
}

export function PhoneTypeFromJSON(json: any): PhoneType {
  return PhoneTypeFromJSONTyped(json, false);
}

export function PhoneTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneType {
  return json as PhoneType;
}

export function PhoneTypeToJSON(value?: PhoneType | null): any {
  return value as any;
}
