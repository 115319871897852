import { CollectionsApi } from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useDeletePartner = (): UseMutationResult<void, Error, number> => {
  const collectionsApi = useApi(CollectionsApi);
  const { application, updateApplication } = useCachedApplication();
  const mutation = useMutation<void, Error, number>(
    async (id: number) => {
      const response = await collectionsApi.apiV10CollectionsDeletePartnerDelete({
        id,
      });
      if (response.error || !response.data) {
        throw new Error(response.error?.message || "Delete Partner Failed");
      }
      return;
    },
    {
      onSuccess: (data, id: number) => {
        updateApplication({
          ...application,
          partners: (application?.partners || []).filter((p) => p.id !== id),
        });
      },
    },
  );
  return mutation;
};
