import { PrevNextNavBar } from "@chq/components";
import { State } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import AboutYourCompanyForm, {
  Fields as AboutCompanyFields,
  useFormikConfig as useAboutCompanyFormikConfig,
} from "../../../components/about-your-company-form";
import MailingAddressCheck, {
  Fields as AddressFields,
  useFormikConfig as useAddressFormikConfig,
} from "../../../components/mailing-address-check";
import { useChangeApplication, useGetApplication } from "../../../data/enrollment";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { RouteNames } from "../../../utils/route-names";
import { trueOrFalseConversion, yesOrNoConversion } from "../../../utils/yes-no-conversion";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: "2rem",
  },
  pageButtons: {
    marginTop: "1.5rem",
  },
  title: {
    paddingBottom: "2rem",
  },
}));

const AboutYourCompanyPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation();
  const { mutate: editApplication, isLoading: isEditingApplication } = useChangeApplication();

  const { data: application } = useGetApplication();
  const formikOperationsProps = useAboutCompanyFormikConfig();
  const formikAddressProps = useAddressFormikConfig({
    mailingSameAsPhysicalAddress: application?.mailingSameAsPhysicalAddress || undefined,
    mailingStreet: application?.filingStreet || undefined,
    mailingCity: application?.filingCity || undefined,
    mailingState: application?.filingState || undefined,
    mailingZip: application?.filingZip || undefined,
  });
  const validationSchema = formikOperationsProps.validationSchema.concat(formikAddressProps.validationSchema);

  useScrollToTop();

  return (
    <PageWrapper>
      <Formik
        initialValues={{
          [AboutCompanyFields.yearsExperience]: application?.yearsContinousExperience || 0,
          [AboutCompanyFields.hasOtherOperations]: yesOrNoConversion(application?.hasNonLMDOperations),
          [AboutCompanyFields.otherOperations]: application?.otherOperationsDescription || undefined,
          [AboutCompanyFields.isMaintainingForTwelveMonths]: yesOrNoConversion(
            application?.isMaintainingForTwelveMonths,
          ),
          [AboutCompanyFields.hasWrittenVehicleMaintenance]: yesOrNoConversion(
            application?.hasWrittenVehicleMaintenance,
          ),
          [AboutCompanyFields.hasOtherVehicles]: yesOrNoConversion(application?.hasOtherVehicles),
          [AboutCompanyFields.hasEmployeesUseTheirAutos]: yesOrNoConversion(application?.hasEmployeesUseTheirAutos),
          [AboutCompanyFields.reasonsEmployeesUseTheirAutos]:
            application?.employeesUsingTheirAutosDescription || undefined,
          [AboutCompanyFields.hasEmployeeInsuranceOfTheirAutos]: yesOrNoConversion(
            application?.hasEmployeeInsuranceOfTheirAutos,
          ),
          [AboutCompanyFields.numberOfEmployeesUsingTheirAutos]: application?.numberOfEmployeesUsingTheirAutos || 0,
          ...formikAddressProps.initialValues,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => {
          const employeesUsingAuto: number | null | undefined =
            trueOrFalseConversion(values[AboutCompanyFields.hasEmployeesUseTheirAutos]) === true
              ? values[AboutCompanyFields.numberOfEmployeesUsingTheirAutos]
              : null;
          editApplication(
            {
              ...application,
              applicationState: RouteNames.hiring,
              yearsContinousExperience: values[AboutCompanyFields.yearsExperience],
              hasNonLMDOperations: trueOrFalseConversion(values[AboutCompanyFields.hasOtherOperations]),
              otherOperationsDescription: values[AboutCompanyFields.otherOperations],
              isMaintainingForTwelveMonths: trueOrFalseConversion(
                values[AboutCompanyFields.isMaintainingForTwelveMonths],
              ),
              hasWrittenVehicleMaintenance: trueOrFalseConversion(
                values[AboutCompanyFields.hasWrittenVehicleMaintenance],
              ),
              hasOtherVehicles: trueOrFalseConversion(values[AboutCompanyFields.hasOtherVehicles]),
              hasEmployeesUseTheirAutos: trueOrFalseConversion(values[AboutCompanyFields.hasEmployeesUseTheirAutos]),
              employeesUsingTheirAutosDescription: values[AboutCompanyFields.reasonsEmployeesUseTheirAutos],
              hasEmployeeInsuranceOfTheirAutos: trueOrFalseConversion(
                values[AboutCompanyFields.hasEmployeeInsuranceOfTheirAutos],
              ),
              numberOfEmployeesUsingTheirAutos: employeesUsingAuto,
              filingSameAsPhysicalAddress: values[AddressFields.mailingSameAsPhysicalAddress],
              filingStreet: values[AddressFields.mailingSameAsPhysicalAddress]
                ? application?.physicalAddress
                : values[AddressFields.mailingStreet],
              filingState: values[AddressFields.mailingSameAsPhysicalAddress]
                ? application?.physicalState
                : (values[AddressFields.mailingState] as State),
              filingCity: values[AddressFields.mailingSameAsPhysicalAddress]
                ? application?.physicalCity
                : values[AddressFields.mailingCity],
              filingZip: values[AddressFields.mailingSameAsPhysicalAddress]
                ? application?.physicalZip
                : values[AddressFields.mailingZip],
            },
            {
              onSuccess: () => {
                history.push(routes.hiring.path);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Grid container spacing={1} className={classes.container}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h1" component="h3">
                {t("about-your-company-page.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AboutYourCompanyForm />
            </Grid>
            <Grid item xs={12}>
              <MailingAddressCheck title={t("about-your-company-page.mailing-title")} />
            </Grid>
            <PrevNextNavBar
              prevOnClick={() => {
                history.push(routes.operations.path);
              }}
              prevAriaLabel={t("about-your-company-page.previous-button")}
              nextOnClick={() => formik.handleSubmit()}
              nextDisabled={!formik.isValid || isEditingApplication}
              isLoading={isEditingApplication}
              nextButtonText={t("about-your-company-page.next-button")}
              className={classes.pageButtons}
            />
          </Grid>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default AboutYourCompanyPage;
