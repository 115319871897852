import { CompanyType } from "@chq/lastmiledelivery-api";

export const useEntityTypes = (): CompanyType[] => {
  return [
    CompanyType.SoleProprietorship,
    CompanyType.Partnership,
    CompanyType.LLC,
    CompanyType.Corp,
    CompanyType.Other,
  ];
};
