import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSendResumeApplicationEmail } from "../data/useSendResumeApplicationLink";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
  },
  titleContainer: {
    display: "flex",
  },
  mainEmail: {
    fontSize: "1.25rem",
  },
  resendEmail: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: ".75rem",
  },
  sentLink: {
    fontSize: "1rem",
    lineHeight: "2rem",
  },
  smallCopy: {
    fontSize: ".75rem",
  },
  heading: {
    marginBottom: "5rem",
  },
  noReceive: {
    margin: "6.25rem auto 5rem",
  },
}));

type Props = {
  code?: number;
  email?: string | null;
};

const VerifyAccountForm: React.FC<Props> = ({ email }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { mutate: sendResumeApplicationEmail } = useSendResumeApplicationEmail();
  const [isError, setIsError] = useState<boolean>();
  const [isSuccess, setIsSuccess] = useState<boolean>();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.heading}>
        <Typography align="center" variant="h1">
          {t("verify-account.verify-your-account")}
        </Typography>
      </Grid>
      <Grid container className={classes.titleContainer}>
        <Grid item xs={12}>
          <Typography align="center" className={classes.sentLink} variant="body2">
            {t("verify-account.we-sent-link")}
            <br />
            <span className={classes.mainEmail}>{email}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" direction="column" className={classes.noReceive}>
        <Grid item xs={12}>
          <Typography align="center" className={classes.smallCopy}>
            {t("verify-account.havent-received")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Link
            href="#"
            onClick={() => {
              sendResumeApplicationEmail(
                { emailAddress: email || undefined },
                {
                  onSuccess: () => {
                    setIsSuccess(false);
                  },
                  onError: () => {
                    setIsError(true);
                  },
                },
              );
            }}
          >
            <Typography align="center" className={classes.resendEmail}>
              {t("verify-account.resend-email")}
            </Typography>
          </Link>
        </Grid>
        {isError && (
          <Typography color="error" align="center">
            {t("verify-account.submission-error")}
          </Typography>
        )}
        {isSuccess && (
          <Typography color="error" align="center">
            {t("verify-account.email-sent")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default VerifyAccountForm;
