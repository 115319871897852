import { EmailInput, NumericInput, TextInput } from "@chq/components";
import { Grid } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export enum Fields {
  email = "email",
  firstName = "first-name",
  lastName = "last-name",
  phone = "phone",
}

export type FormProps = {
  [Fields.email]: string;
  [Fields.firstName]: string;
  [Fields.lastName]?: string;
  [Fields.phone]?: string;
};

type Props = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.email]: yup
      .string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`business-user-form.${Fields.email}.label`) })),
    [Fields.firstName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-user-form.${Fields.firstName}.label`) })),
    [Fields.lastName]: yup
      .string()
      .required(t(`errors.required`, { field: t(`business-user-form.${Fields.lastName}.label`) })),
    [Fields.phone]: yup
      .string()
      .required(t("errors.required", { field: t(`business-user-form.${Fields.phone}.label`) }))
      .test("length", t(`errors.phone-number`), (val) => val !== undefined && val.toString().length === 10),
  });
};

export const useFormikConfig = ({
  email: initialEmail = "",
  firstName: initialFirstName = "",
  lastName: initialLastName = "",
  phone: initialPhone = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.email]: initialEmail,
      [Fields.firstName]: initialFirstName,
      [Fields.lastName]: initialLastName,
      [Fields.phone]: initialPhone,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const BusinessUserForm: React.FC = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EmailInput
            label={t("business-user-form.email.label")}
            required
            fullWidth
            id={Fields.email}
            name={Fields.email}
            value={formik.values[Fields.email]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
            helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            required
            fullWidth
            label={t("business-user-form.first-name.label")}
            id={Fields.firstName}
            name={Fields.firstName}
            value={formik.values[Fields.firstName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.firstName] && Boolean(formik.errors[Fields.firstName])}
            helperText={formik.touched[Fields.firstName] && formik.errors[Fields.firstName]}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextInput
            required
            fullWidth
            label={t("business-user-form.last-name.label")}
            id={Fields.lastName}
            name={Fields.lastName}
            value={formik.values[Fields.lastName]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.lastName] && Boolean(formik.errors[Fields.lastName])}
            helperText={formik.touched[Fields.lastName] && formik.errors[Fields.lastName]}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            required
            fullWidth
            format="(###) ### – ####"
            label={t("business-user-form.phone.label")}
            id={Fields.phone}
            name={Fields.phone}
            value={formik.values[Fields.phone]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.phone] && Boolean(formik.errors[Fields.phone])}
            helperText={formik.touched[Fields.phone] && formik.errors[Fields.phone]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BusinessUserForm;
