export enum RouteNames {
  home = "/",
  coverages = "/coverages",
  businessInfo = "/business-info",
  operations = "/operations",
  hiring = "/hiring",
  install = "/install",
  workersComp = "/workers-comp",
  documentsUpload = "/documents-upload",
  verify = "/verify",
  issue = "/issue",
  success = "/success",
  registration = "/registration",
  review = "/review",
  aboutYourCompany = "/about-your-company",
}
