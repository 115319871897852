import { useAuth } from "@chq/authentication";
import {
  ApiV10DocumentAddDriverListDocumentPostRequest,
  ApiV10DocumentAddEDeliveryConsentPostRequest,
  ApiV10DocumentAddLossRunsDocumentPostRequest,
  ApiV10DocumentAddReturnToWorkDocumentPostRequest,
  ApiV10DocumentAddVehicleListDocumentPostRequest,
  ApiV10DocumentByteUriGetRequest,
  ApiV10DocumentUriGetRequest,
  DocumentApi,
  EnrollmentApi,
} from "@chq/lastmiledelivery-api";
import { saveAs } from "file-saver";
import { useContext } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { EmailContext } from "../authentication/EmailContext";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useAddDriverList = (): UseMutationResult<void, Error, ApiV10DocumentAddDriverListDocumentPostRequest> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const { user } = useAuth();
  const { email } = useContext(EmailContext);
  const mutation = useMutation<void, Error, ApiV10DocumentAddDriverListDocumentPostRequest>(
    async (request: ApiV10DocumentAddDriverListDocumentPostRequest) => {
      await documentApi.apiV10DocumentAddDriverListDocumentPost(request);
      await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          driverListDocument: undefined,
        },
      });
      const response = await applicationApi.apiV10EnrollmentGetApplicationGet({
        emailAddress: email.email ? email.email : user?.preferred_username,
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useAddLossRunsList = (): UseMutationResult<void, Error, ApiV10DocumentAddLossRunsDocumentPostRequest> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const { user } = useAuth();
  const { email } = useContext(EmailContext);
  const mutation = useMutation<void, Error, ApiV10DocumentAddLossRunsDocumentPostRequest>(
    async (request: ApiV10DocumentAddLossRunsDocumentPostRequest) => {
      await documentApi.apiV10DocumentAddLossRunsDocumentPost(request);
      await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          lossRunsDocument: undefined,
        },
      });
      const response = await applicationApi.apiV10EnrollmentGetApplicationGet({
        emailAddress: email.email ? email.email : user?.preferred_username,
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useAddReturnToWorkList = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentAddReturnToWorkDocumentPostRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const { user } = useAuth();
  const { email } = useContext(EmailContext);
  const mutation = useMutation<void, Error, ApiV10DocumentAddReturnToWorkDocumentPostRequest>(
    async (request: ApiV10DocumentAddReturnToWorkDocumentPostRequest) => {
      await documentApi.apiV10DocumentAddReturnToWorkDocumentPost(request);
      await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          returnToWorkDocument: undefined,
        },
      });
      const response = await applicationApi.apiV10EnrollmentGetApplicationGet({
        emailAddress: email.email ? email.email : user?.preferred_username,
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useAddVehicleList = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentAddVehicleListDocumentPostRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const { user } = useAuth();
  const { email } = useContext(EmailContext);
  const mutation = useMutation<void, Error, ApiV10DocumentAddVehicleListDocumentPostRequest>(
    async (request: ApiV10DocumentAddVehicleListDocumentPostRequest) => {
      await documentApi.apiV10DocumentAddVehicleListDocumentPost(request);
      await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          vehicleListDocument: undefined,
        },
      });
      const response = await applicationApi.apiV10EnrollmentGetApplicationGet({
        emailAddress: email.email ? email.email : user?.preferred_username,
      });
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useAddEDeliveryConsent = (): UseMutationResult<
  void,
  Error,
  ApiV10DocumentAddEDeliveryConsentPostRequest
> => {
  const documentApi = useApi(DocumentApi);
  const applicationApi = useApi(EnrollmentApi);
  const { application, updateApplication } = useCachedApplication();
  const { user } = useAuth();
  const { email } = useContext(EmailContext);
  const mutation = useMutation<void, Error, ApiV10DocumentAddEDeliveryConsentPostRequest>(
    async (request: ApiV10DocumentAddEDeliveryConsentPostRequest) => {
      await documentApi.apiV10DocumentAddEDeliveryConsentPost(request);
      await applicationApi.apiV10EnrollmentUpsertApplicationPut({
        application: {
          ...application,
          electronicConsentRequirementsDocument: undefined,
        },
      });
      const response = await applicationApi.apiV10EnrollmentGetApplicationGet({
        emailAddress: email.email ? email.email : user?.preferred_username,
      });
      if (response.error) {
        throw new Error(response.error.message || "Failed to create signed");
      }
      if (response.data) {
        updateApplication(response.data);
      }
      return;
    },
  );
  return mutation;
};

export const useGetDocument = (): UseMutationResult<Blob, Error, ApiV10DocumentUriGetRequest> => {
  const documentApi = useApi(DocumentApi);
  const mutation = useMutation<Blob, Error, ApiV10DocumentUriGetRequest>(
    async (request: ApiV10DocumentUriGetRequest) => {
      const response = await documentApi.apiV10DocumentUriGet(request);
      if (response.error) {
        throw new Error(response.error.message || "failed to get document");
      }
      return response.data!;
    },
  );
  return mutation;
};

export const useDownloadEDeliveryConsent = (): UseMutationResult<void, Error, ApiV10DocumentByteUriGetRequest> => {
  const documentApi = useApi(DocumentApi);
  const mutation = useMutation<void, Error, ApiV10DocumentByteUriGetRequest>(
    async (request: ApiV10DocumentByteUriGetRequest) => {
      const response = await documentApi.apiV10DocumentByteUriGet(request);

      if (response.error) {
        throw new Error(response.error.message || "Failed to create signed");
      }
      if (response.data) {
        // decode and parse Base64 string into a file blob
        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        saveAs(blob, "ElectronicDeliveryConsent.pdf");
      }
      return;
    },
  );
  return mutation;
};
