import { NextButton } from "@chq/components";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ExistingInfoModal from "../../../components/existing-info-modal";
import RegistrationForm, { Fields, FormProps, useFormikConfig } from "../../../components/registration-form";
import { useCheckEmail } from "../../../data/authentication";
import { useAddPassword, VerifiedStatus } from "../../../data/authentication/useAddPassword";
import { useChangeApplication, useCheckApplication, useGetApplication } from "../../../data/enrollment";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import LoginVerifyLayout from "../components/login-verify-layout";

const useStyles = makeStyles((theme: Theme) => ({
  pageButtons: {
    paddingTop: "1rem",
  },
  next: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "1rem",
    },
  },
  cancel: {
    minHeight: 56,
    width: "100%",
  },
  title: {
    marginBottom: "1.25rem",
  },
}));

const InformationFailurePage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const { mutate: checkApplication, ...applicationCheck } = useCheckApplication();
  const { data: application } = useGetApplication();
  const { mutate: editApplication } = useChangeApplication();
  const { mutate: addPassword } = useAddPassword();
  const formikProps = useFormikConfig({});
  const [openModal, setOpenModal] = useState<boolean>(Boolean(applicationCheck.data?.data?.doesApplicationExist));
  const { mutate: checkEmail } = useCheckEmail();

  useScrollToTop();

  return (
    <LoginVerifyLayout>
      <ExistingInfoModal open={openModal} onClose={() => setOpenModal(false)} />

      <Grid item>
        <Formik
          initialValues={{ ...formikProps.initialValues }}
          validateOnMount
          validationSchema={formikProps.validationSchema}
          enableReinitialize
          onSubmit={(values: FormProps) => {
            checkEmail(
              {
                email: values[Fields.email],
              },
              {
                onSuccess: (response) => {
                  addPassword(
                    { userId: response.data?.userId, password: values[Fields.password] },
                    {
                      onSuccess: (response) => {
                        if (response === VerifiedStatus.accountIsVerifiedPasswordCreated) {
                          editApplication(
                            { ...application, applicationState: RouteNames.success },
                            {
                              onSuccess: () => {
                                history.push(routes.success.path);
                              },
                            },
                          );
                        } else if (response === VerifiedStatus.verfiyEmailSentPasswordCreated) {
                          editApplication(
                            { ...application, applicationState: RouteNames.verify },
                            {
                              onSuccess: () => {
                                history.push(routes.verify.path);
                              },
                            },
                          );
                        }
                      },
                    },
                  );
                },
              },
            );
          }}
        >
          {(formik) => (
            <Grid container item direction="column">
              <Grid container item>
                <Typography variant="h1" align="left" className={classes.title}>
                  {t("registration-page.title")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RegistrationForm />
              </Grid>
              <Grid container item direction="row" justify="space-between" className={classes.pageButtons} xs={12}>
                <Grid item xs={10} className={classes.next}>
                  <NextButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid}
                  >
                    {t("registration-page.continue")}
                  </NextButton>
                </Grid>
                <Grid item xs={4}>
                  <Button className={classes.cancel} variant="outlined" onClick={() => history.push(routes.root.path)}>
                    {t("registration-page.cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </LoginVerifyLayout>
  );
};

export default InformationFailurePage;
