/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PasswordSignInRequest
 */
export interface PasswordSignInRequest {
  /**
   *
   * @type {string}
   * @memberof PasswordSignInRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PasswordSignInRequest
   */
  password: string;
  /**
   *
   * @type {boolean}
   * @memberof PasswordSignInRequest
   */
  rememberLogin?: boolean;
  /**
   *
   * @type {string}
   * @memberof PasswordSignInRequest
   */
  returnUrl?: string | null;
}

export function PasswordSignInRequestFromJSON(json: any): PasswordSignInRequest {
  return PasswordSignInRequestFromJSONTyped(json, false);
}

export function PasswordSignInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordSignInRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    password: json["password"],
    rememberLogin: !exists(json, "rememberLogin") ? undefined : json["rememberLogin"],
    returnUrl: !exists(json, "returnUrl") ? undefined : json["returnUrl"],
  };
}

export function PasswordSignInRequestToJSON(value?: PasswordSignInRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    rememberLogin: value.rememberLogin,
    returnUrl: value.returnUrl,
  };
}
