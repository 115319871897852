import { CardField, EditableDeletableCard } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  yearsExperience?: string;
  nonLastMile?: string;
  insurance?: string;
  maintenanceProgram?: string;
  hireLease?: string;
  EmpNonEmpVehicles?: string;
  describeCircumstances?: string;
  filingAddress?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 500,
    width: "100%",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    padding: ".5rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
    },
  },
  cardField: {
    padding: ".2rem 0",
  },
  coverageCopy: {
    fontWeight: 700,
  },
  content: {
    marginTop: "1rem",
  },
  filingAddress: {
    paddingTop: ".5rem",
  },
  indent: {
    paddingLeft: ".5rem",
  },
}));

const AboutYourCompanyCard: React.FC<Props> = ({
  yearsExperience,
  nonLastMile,
  insurance,
  maintenanceProgram,
  hireLease,
  EmpNonEmpVehicles,
  describeCircumstances,
  filingAddress,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("about-your-company.card.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("about-your-company.card.edit-button") }}
        onEdit={() => history.push(routes.aboutYourCompany.path)}
      >
        <Grid spacing={1} container direction="row" wrap="nowrap">
          <Grid container item direction="column" wrap="nowrap">
            <Grid className={classes.borderBottom}>
              <Grid container direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  className={classes.cardCopy}
                  id="years-experience"
                  label={t("about-your-company.card.years-experience")}
                  values={[{ value: yearsExperience }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
            </Grid>
            <Grid className={classes.borderBottom}>
              <Grid container direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  className={classes.cardCopy}
                  id="non-last-mile"
                  label={t("about-your-company.card.non-last-mile-deliveries")}
                  values={[{ value: nonLastMile }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
              <Grid container direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  className={classes.cardCopy}
                  id="insurance"
                  label={t("about-your-company.card.insurance")}
                  values={[{ value: insurance }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
            </Grid>
            <Grid className={classes.borderBottom}>
              <Grid container direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  className={classes.cardCopy}
                  id="maintenance-program"
                  label={t("about-your-company.card.maintenance-program")}
                  values={[{ value: maintenanceProgram }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
              <Grid container direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  className={classes.cardCopy}
                  id="hire-lease"
                  label={t("about-your-company.card.hire-lease-vehicles")}
                  values={[{ value: hireLease }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
              <Grid container direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  className={classes.cardCopy}
                  id="emp-non-emp-vehicles"
                  label={t("about-your-company.card.emp-non-emp-vehicles")}
                  values={[{ value: EmpNonEmpVehicles }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
              <Grid container spacing={1} direction="row" className={classes.cardField}>
                <CardField
                  valueClass={classes.indent}
                  labelClass={classes.indent}
                  className={classes.cardCopy}
                  id="describe-circumstances"
                  label={t("about-your-company.card.describe-circumstances")}
                  values={[{ value: describeCircumstances }]}
                  labelGrid={4}
                  valueGrid={8}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.cardField}>
              <CardField
                valueClass={classes.indent}
                className={classNames(classes.cardCopy, classes.filingAddress)}
                id="filing-address"
                label={t("about-your-company.card.filing-address")}
                values={[{ value: filingAddress }]}
                labelGrid={4}
                valueGrid={8}
              />
            </Grid>
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default AboutYourCompanyCard;
