/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  backgroundImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "4rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: "2rem",
      paddingTop: "2rem",
    },
  },
  fullHeight: {
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

type Props = {
  background?: string;
};

const PageWrapper: React.FC<Props> = ({ children, background = false }) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.fullHeight, classes.container, {
        [classes.backgroundImage]: background,
      })}
      style={{
        backgroundImage: background ? `url("${background}")` : "none",
      }}
    >
      <Grid container justify="center">
        <Grid item xs={10} sm={8} md={6} lg={5}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default PageWrapper;
