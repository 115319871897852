import { CardField, EditableDeletableCard } from "@chq/components";
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  excludedIndividuals?: string;
  driverProjectedYearPayroll?: null | number;
  driverCurrentYearPayroll?: null | number;
  driverPriorYearPayroll?: null | number;
  clericalProjectedYearPayroll?: null | number;
  clericalCurrentYearPayroll?: null | number;
  clericalPriorYearPayroll?: null | number;
  otherProjectedYearPayroll?: null | number;
  otherCurrentYearPayroll?: null | number;
  otherPriorYearPayroll?: null | number;
  projectedYearPremium?: null | number;
  currentYearPremium?: null | number;
  priorYearPremium?: null | number;
  medicalGroupProvided?: string;
  isExcludedSalaryIncludedInPayroll?: string;
  percentEmployeesEnrolled?: string;
  formalAccident?: string;
  lightRestrictedWork?: string;
  createOneVanliner?: string;
  utilizeIndContractors?: string;
  maintainCertificatesOfInsuranceForCoverage?: string;
  willProvideCoverageUnderWorkersCompPolicy?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 1000,
    width: "100%",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
      lineHeight: "1.125rem",
    },
  },
  cellHeading: {
    fontSize: ".75rem",
  },
  cellBody: {
    fontSize: ".875rem",
  },
  columnLeft: {
    margin: "0 0 1rem",
    [theme.breakpoints.up("md")]: {
      margin: "0 1rem 0 0",
    },
  },
  columnRight: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "1rem",
    },
  },
  coverageCopy: {
    fontWeight: 700,
  },
  content: {
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    marginTop: ".5rem",
    "& .MuiTableCell-head": {
      paddingBottom: ".5rem",
    },
    "& .MuiTableCell-root": {
      borderBottom: "none",
      padding: ".25rem",
    },
    "& .MuiTable-root": {
      marginTop: "1rem",
    },
  },
  indent: {
    paddingLeft: ".5rem",
  },
}));

const ReviewWorkersCompCard: React.FC<Props> = ({
  excludedIndividuals,
  driverProjectedYearPayroll,
  driverCurrentYearPayroll,
  driverPriorYearPayroll,
  clericalProjectedYearPayroll,
  clericalCurrentYearPayroll,
  clericalPriorYearPayroll,
  otherProjectedYearPayroll,
  otherCurrentYearPayroll,
  otherPriorYearPayroll,
  projectedYearPremium,
  currentYearPremium,
  priorYearPremium,
  medicalGroupProvided,
  isExcludedSalaryIncludedInPayroll,
  percentEmployeesEnrolled,
  formalAccident,
  lightRestrictedWork,
  createOneVanliner,
  utilizeIndContractors,
  maintainCertificatesOfInsuranceForCoverage,
  willProvideCoverageUnderWorkersCompPolicy,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <EditableDeletableCard
      className={classes.background}
      variant="edit"
      title={t("workers-compensation.label")}
      titleVariant="h2"
      titleComponent="h3"
      IconButtonProps={{ "aria-label": t("workers-compensation.card.edit-button") }}
      onEdit={() => history.push(routes.workersCompPage.path)}
    >
      <Grid className={classes.content} container direction="row" wrap="nowrap">
        <Grid
          container
          item
          direction="row"
          wrap="nowrap"
          className={classNames(classes.borderBottom, classes.columnLeft)}
        >
          <CardField
            direction="column"
            wrap="nowrap"
            className={classes.cardCopy}
            id="excluded-individuals"
            label={t("workers-compensation.card.excluded-individuals")}
            values={[{ value: excludedIndividuals }]}
            labelGrid={12}
            valueGrid={12}
          />
        </Grid>
      </Grid>
      <Grid className={classes.content} alignItems="flex-start" container direction="row" wrap="nowrap">
        <Grid container item direction="column" wrap="nowrap" className={classes.columnLeft}>
          <Grid container>
            <Typography variant={"h4"} component="p" color={"textSecondary"}>
              {t("workers-compensation.card.payroll-premium")}
            </Typography>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" component="h3" className={classes.cellHeading}>
                    {t("workers-compensation.card.driver")}
                    <br />
                    {t("workers-compensation.card.payroll")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" component="h3" className={classes.cellHeading}>
                    {t("workers-compensation.card.clerical")}
                    <br />
                    {t("workers-compensation.card.payroll")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" component="h3" className={classes.cellHeading}>
                    {t("workers-compensation.card.other")}
                    <br />
                    {t("workers-compensation.card.payroll")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" component="h3" className={classes.cellHeading}>
                    {t("workers-compensation.card.premiums")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" className={classes.cellBody}>
                    {t("workers-compensation.card.projected-year")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${driverProjectedYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${clericalProjectedYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${otherProjectedYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${projectedYearPremium?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" className={classes.cellBody}>
                    {t("workers-compensation.card.current-year")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${driverCurrentYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${clericalCurrentYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${otherCurrentYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${currentYearPremium?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="h4" className={classes.cellBody}>
                    {t("workers-compensation.card.prior-year")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${driverPriorYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${clericalPriorYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${otherPriorYearPayroll?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" className={classes.cellBody}>
                    ${priorYearPremium?.toLocaleString(undefined)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid container item direction="column" wrap="nowrap" className={classes.columnRight}>
          {excludedIndividuals && (
            <CardField
              className={classes.cardCopy}
              labelClass={classes.indent}
              id="is-salary-included"
              label={t("workers-compensation.card.salary-excluded")}
              values={[{ value: isExcludedSalaryIncludedInPayroll }]}
              labelGrid={10}
              valueGrid={2}
            />
          )}
          <CardField
            className={classes.cardCopy}
            id="group-medical-provided"
            label={t("workers-compensation.card.medical-group-provided")}
            values={[{ value: medicalGroupProvided }]}
            labelGrid={10}
            valueGrid={2}
          />
          {medicalGroupProvided === t("common.yes") && (
            <CardField
              className={classes.cardCopy}
              labelClass={classes.indent}
              id="percent-employees-enrolled"
              label={t("workers-compensation.card.percent-employees-enrolled")}
              values={[{ value: percentEmployeesEnrolled }]}
              labelGrid={10}
              valueGrid={2}
            />
          )}
          <CardField
            className={classes.cardCopy}
            id="formal-accident"
            label={t("workers-compensation.card.formal-accident")}
            values={[{ value: formalAccident }]}
            labelGrid={10}
            valueGrid={2}
          />
          <CardField
            className={classes.cardCopy}
            id="light-restricted-work"
            label={t("workers-compensation.card.light-restricted-work")}
            values={[{ value: lightRestrictedWork }]}
            labelGrid={10}
            valueGrid={2}
          />
          {lightRestrictedWork === t("common.no") && (
            <CardField
              className={classes.cardCopy}
              labelClass={classes.indent}
              id="create-one-vanliner"
              label={t("workers-compensation.card.create-light-restricted-work-program")}
              values={[{ value: createOneVanliner }]}
              labelGrid={10}
              valueGrid={2}
            />
          )}
          <CardField
            className={classes.cardCopy}
            id="utilize-ind-contractors"
            label={t("workers-compensation.card.utilize-ind-contractors")}
            values={[{ value: utilizeIndContractors }]}
            labelGrid={10}
            valueGrid={2}
          />
          <CardField
            className={classes.cardCopy}
            labelClass={classes.indent}
            id="maintain-current-coverage"
            label={
              utilizeIndContractors === t("common.yes")
                ? t("workers-compensation.card.maintain-current-coverage")
                : t("workers-compensation.card.will-provide-owner-coverage")
            }
            values={
              utilizeIndContractors === t("common.yes")
                ? [{ value: maintainCertificatesOfInsuranceForCoverage }]
                : [{ value: willProvideCoverageUnderWorkersCompPolicy }]
            }
            labelGrid={10}
            valueGrid={2}
          />
        </Grid>
      </Grid>
    </EditableDeletableCard>
  );
};

export default ReviewWorkersCompCard;
