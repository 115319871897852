import {
  AlertType,
  ComponentConfirmOverlay,
  PageAlert,
  PlusCircleIcon as PlusCircle,
  StyleLabel,
  PrevNextNavBar,
} from "@chq/components";
import { LogisticsProvider, State as APIState, Terminal } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import LogisticsProviderCard from "../../../components/logistics-provider-card";
import LogisticsProviderForm, {
  Fields as LogisticsProviderFields,
  FormProps as LogisticsProviderFormProps,
  useValidationSchema as useLogisticsProviderValidationSchema,
} from "../../../components/logistics-provider-form";
import TerminalCard from "../../../components/terminal-card";
import TerminalForm, {
  Fields as TerminalFields,
  FormProps as TerminalFormProps,
  useValidationSchema as useTerminalValidationSchema,
} from "../../../components/terminal-form";
import { useChangeApplication, useGetApplication } from "../../../data/enrollment";
import { useDeleteLogisticsProvider, useDeleteTerminal } from "../../../data/enrollment/useDeleteCollection";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: "4rem",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1.5rem",
  },
  addNewText: {
    fontWeight: "bold",
  },
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    marginLeft: ".25rem",
  },
  pageTitle: {
    paddingBottom: "1.5rem",
    textAlign: "left",
  },
  titleContainer: {
    textAlign: "center",
  },
  cardSpacing: {
    marginTop: "1rem",
  },
  cardContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    marginBottom: "1rem",
    width: "inherit",
    margin: "inherit",
  },
  card: {
    position: "relative",
  },
  addIcon: {
    height: "40px",
    width: "auto",
  },
  addButton: {
    justifyContent: "left",
  },
  reduceIndent: {
    "&.MuiGrid-item": {
      paddingLeft: "0px",
    },
  },
  indent: {
    "&.MuiGrid-item": {
      paddingLeft: "0.71rem",
    },
  },
}));

type ProviderExtended = Partial<LogisticsProviderFormProps> & {
  isEditing?: boolean;
  id?: number;
};

type TerminalExtended = Partial<TerminalFormProps> & {
  isEditing?: boolean;
  id?: number;
};

const providerMapper = (provider: ProviderExtended): LogisticsProvider => {
  return {
    id: provider.id,
    providerName: provider[LogisticsProviderFields.providerName],
    shipmentPercent: provider[LogisticsProviderFields.shipmentPercent] as number,
    yearsContracted: provider[LogisticsProviderFields.yearsContracted] as number,
  };
};

const terminalMapper = (terminal: TerminalExtended): Terminal => {
  return {
    id: terminal.id,
    terminalCity: terminal[TerminalFields.terminalCity],
    terminalState: terminal[TerminalFields.terminalState] as APIState,
    terminalZipCode: terminal[TerminalFields.terminalZipCode],
  };
};

const useValidation = () => {
  const logisticsProviderFormValidation = useLogisticsProviderValidationSchema();
  const terminalFormValidation = useTerminalValidationSchema();
  return useCallback(
    (logisticsProviders: ProviderExtended[], terminals: TerminalExtended[]) => {
      return (
        logisticsProviders.length &&
        logisticsProviders.every((logisticsProvider) => {
          return !logisticsProvider.isEditing && logisticsProviderFormValidation.isValidSync(logisticsProvider);
        }) &&
        terminals.length &&
        terminals.every((terminal) => {
          return !terminal.isEditing && terminalFormValidation.isValidSync(terminal);
        })
      );
    },
    [logisticsProviderFormValidation, terminalFormValidation],
  );
};

const OperationsPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const { data: application } = useGetApplication();
  const { mutate: editApplication, ...editApplicationCheck } = useChangeApplication();
  const { mutate: deleteTerminal } = useDeleteTerminal();
  const { mutate: deleteLogisticsProvider } = useDeleteLogisticsProvider();

  const [providers, setProviders] = useState<Array<ProviderExtended>>(
    application?.logisticsProviders && application?.logisticsProviders.length > 0
      ? () => {
          const arr = application?.logisticsProviders!.map((provider: LogisticsProvider) => {
            return { ...provider, isEditing: false };
          });
          return arr;
        }
      : [{ isEditing: true }],
  );
  const [terminals, setTerminals] = useState<Array<TerminalExtended>>(
    application?.terminals && application?.terminals.length > 0
      ? () => {
          const arr = application?.terminals!.map((terminal: Terminal) => {
            return { ...terminal, isEditing: false };
          });
          return arr;
        }
      : [{ isEditing: true }],
  );

  const [showProviderConfirmationOverlay, setShowProviderConfirmationOverlay] = useState<number | null>(null);
  const [showTerminalConfirmationOverlay, setShowTerminalConfirmationOverlay] = useState<number | null>(null);

  const isValid = useValidation()(providers, terminals);

  const deleteProviderConfirmation = (onDelete: () => void) => {
    onDelete();
    setShowProviderConfirmationOverlay(null);
  };

  const deleteTerminalConfirmation = (onDelete: () => void) => {
    onDelete();
    setShowTerminalConfirmationOverlay(null);
  };

  useScrollToTop();

  return (
    <PageWrapper>
      {editApplicationCheck.isError && (
        <PageAlert alertType={AlertType.error} message={t("operations.page-level-error")} />
      )}
      <Grid container direction="column" className={classes.container}>
        <Grid container item direction="column" className={classes.pageTitle}>
          <Grid item>
            <Typography variant="h1" component="h1">
              {t("operations.title")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={2} className={classes.cardContainer}>
          <StyleLabel
            variant={"h3"}
            variantMapping={{ h3: "h2" }}
            containerStyle={classes.reduceIndent}
            labelText={t("operations.terminal-form.title")}
          />
          {terminals.map((terminal, tIndex) => {
            return (
              <Grid item key={`terminal-${tIndex}`} className={classes.indent} xs={12}>
                {!terminal.isEditing ? (
                  <TerminalCard
                    terminalCity={terminal[TerminalFields.terminalCity]!}
                    terminalState={terminal[TerminalFields.terminalState]!}
                    terminalZipCode={terminal[TerminalFields.terminalZipCode]!}
                    onEdit={() => {
                      setTerminals(terminals.map((item, i) => (tIndex === i ? { ...item, isEditing: true } : item)));
                    }}
                  />
                ) : (
                  <div className={classes.card}>
                    <TerminalForm
                      terminalNumber={tIndex}
                      terminalCity={terminal[TerminalFields.terminalCity]}
                      terminalState={terminal[TerminalFields.terminalState]}
                      terminalZipCode={terminal[TerminalFields.terminalZipCode]}
                      IconButtonProps={{ disabled: terminals.length === 1 }}
                      onDelete={() => setShowTerminalConfirmationOverlay(tIndex)}
                      onSubmit={(values) => {
                        const newTerminals = terminals.map((item, i) => (tIndex === i ? { ...item, ...values } : item));
                        editApplication(
                          {
                            ...application,
                            terminals: newTerminals.map((terminal) => terminalMapper(terminal)),
                          },
                          {
                            onSuccess: (response) => {
                              if (response && response.data) {
                                setTerminals(
                                  response.data.terminals!.map((item, i) =>
                                    tIndex === i ? { ...item, ...values, isEditing: false } : item,
                                  ),
                                );
                              }
                            },
                          },
                        );
                      }}
                    />
                    {showTerminalConfirmationOverlay === tIndex && (
                      <ComponentConfirmOverlay
                        confirmText={t("common.delete")}
                        confirmAction={() =>
                          deleteTerminalConfirmation(() => {
                            setTerminals((prevState) => {
                              prevState.splice(tIndex, 1);
                              return [...prevState];
                            });
                            editApplication({
                              ...application,
                              terminals: terminals.map((terminal) => terminalMapper(terminal)),
                            });
                            deleteTerminal({ id: terminal.id });
                          })
                        }
                        cancelText={t("common.cancel-delete")}
                        cancelAction={() => setShowTerminalConfirmationOverlay(null)}
                      />
                    )}
                  </div>
                )}
              </Grid>
            );
          })}
          <Grid
            onClick={() => {
              setTerminals((prevState) => [
                ...prevState,
                {
                  isEditing: true,
                },
              ]);
            }}
            alignItems="center"
            className={classNames(classes.addNew, classes.indent)}
            item
            container
            direction="row"
          >
            <PlusCircle className={classes.addNewIcon} />
            <Grid item>
              <Typography className={classes.addNewCopy} variant="h3">
                <div className={classes.addNewText}>
                  <strong>{t("operations.terminal-form.add-terminal")}</strong>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={2} className={classes.cardContainer}>
          <StyleLabel
            variant={"h3"}
            containerStyle={classes.reduceIndent}
            labelText={t("operations.logistics-provider-form.title")}
          />
          {providers.map((provider, pIndex) => {
            return (
              <Grid item key={`logistics-provider-${pIndex}`} className={classes.indent} xs={12}>
                {!provider.isEditing ? (
                  <LogisticsProviderCard
                    providerName={provider[LogisticsProviderFields.providerName]!}
                    shipmentPercent={provider[LogisticsProviderFields.shipmentPercent]!}
                    yearsContracted={provider[LogisticsProviderFields.yearsContracted]!}
                    onEdit={() => {
                      setProviders(providers.map((item, i) => (pIndex === i ? { ...item, isEditing: true } : item)));
                    }}
                  />
                ) : (
                  <div className={classes.card}>
                    <LogisticsProviderForm
                      providerNumber={pIndex}
                      providerName={provider[LogisticsProviderFields.providerName]}
                      shipmentPercent={provider[LogisticsProviderFields.shipmentPercent]}
                      yearsContracted={provider[LogisticsProviderFields.yearsContracted]}
                      IconButtonProps={{ disabled: providers.length === 1 }}
                      onDelete={() => setShowProviderConfirmationOverlay(pIndex)}
                      onSubmit={(values) => {
                        const newProviders = providers.map((item, i) => (pIndex === i ? { ...item, ...values } : item));
                        editApplication(
                          {
                            ...application,
                            logisticsProviders: newProviders.map((provider) => providerMapper(provider)),
                          },
                          {
                            onSuccess: (response) => {
                              if (response && response.data) {
                                setProviders(
                                  response.data.logisticsProviders!.map((item, i) =>
                                    pIndex === i ? { ...item, ...values, isEditing: false } : item,
                                  ),
                                );
                              }
                            },
                          },
                        );
                      }}
                    />
                    {showProviderConfirmationOverlay === pIndex && (
                      <ComponentConfirmOverlay
                        confirmText={t("common.delete")}
                        confirmAction={() =>
                          deleteProviderConfirmation(() => {
                            setProviders((prevState) => {
                              prevState.splice(pIndex, 1);
                              return [...prevState];
                            });
                            editApplication({
                              ...application,
                              logisticsProviders: providers.map((provider) => providerMapper(provider)),
                            });
                            deleteLogisticsProvider({ id: provider.id });
                          })
                        }
                        cancelText={t("common.cancel-delete")}
                        cancelAction={() => setShowProviderConfirmationOverlay(null)}
                      />
                    )}
                  </div>
                )}
              </Grid>
            );
          })}
          <Grid
            onClick={() => {
              setProviders((prevState) => [
                ...prevState,
                {
                  isEditing: true,
                },
              ]);
            }}
            alignItems="center"
            className={classNames(classes.addNew, classes.indent)}
            container
            item
            direction="row"
          >
            <PlusCircle className={classes.addNewIcon} />
            <Grid item>
              <Typography className={classes.addNewCopy} variant="h3">
                <div className={classes.addNewText}>
                  <strong>{t("operations.logistics-provider-form.add-logistics-provider")}</strong>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <PrevNextNavBar
          prevOnClick={() => {
            history.push(routes.businessInfo.path);
          }}
          prevAriaLabel={t("operations.previous-button-label")}
          nextOnClick={() => {
            editApplication(
              {
                ...application,
                applicationState: RouteNames.aboutYourCompany,
              },
              {
                onSuccess: () => {
                  history.push(routes.aboutYourCompany.path);
                },
                onError: () => {
                  history.push(routes.issue.path);
                },
              },
            );
          }}
          nextDisabled={!isValid || editApplicationCheck.isLoading}
          isLoading={editApplicationCheck.isLoading}
          nextButtonText={t("operations.next-button")}
        />
      </Grid>
    </PageWrapper>
  );
};

export default OperationsPage;
