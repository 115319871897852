import { CurrencyInput, DeleteIcon, NumericInput, TextInput } from "@chq/components";
import { Button, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import classNames from "classnames";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { maxInt } from "../utils/number-validation";

const useStyles = makeStyles((theme: Theme) => ({
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  saveButtonContainer: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
  },
  saveButton: {
    fontSize: "",
  },
  saveButtonText: {
    paddingLeft: ".4rem",
  },
  saveButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.grey[400],
    },
  },
  saveButtonDisabled: {
    backgroundColor: theme.palette.primary.light,
  },
  saveIcon: {
    height: "1.125rem",
  },
  saveButtonLabel: {
    alignItems: "center",
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    fontSize: ".75rem",
    marginLeft: ".25rem",
  },
  addButton: {
    padding: "1.125rem 0rem",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "3rem",
  },
  bodyCopy: {
    fontSize: ".75rem",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  bodyHeading: {
    fontSize: ".875rem",
  },
  button: {
    padding: "1rem 0",
  },
  checkboxContain: {
    marginTop: "2.75rem",
  },
  formHeading: {
    height: "2rem",
    marginBottom: "1rem",
  },
  formInstance: {
    marginTop: "2rem",
  },
  cardStyles: {
    backgroundColor: theme.palette.grey[600],
    padding: "0",
    width: "100%",
    marginTop: "1rem",
  },
  editableDeletableCardStyles: {
    padding: "0",
    backgroundColor: theme.palette.common.white,
  },
}));

export enum Fields {
  name = "name",
  id = "id",
  title = "title",
  relationshipType = "relationshipType",
  ownerPercentage = "ownershipPercent",
  ownershipPercent = "ownershipPercent",
  salary = "salary",
  employeeNumber = "employeeNumber",
}

type Props = {
  id?: number | undefined;
  name?: string | null;
  title?: string | null;
  relationshipType?: string | null;
  ownerPercentage?: number | undefined;
  salary?: number | undefined;
  employeeNumber?: number;
  onDelete?: () => void;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export type FormProps = {
  [Fields.id]: number | undefined;
  [Fields.name]: string | null;
  [Fields.title]: string | null;
  [Fields.relationshipType]: string | null;
  [Fields.ownerPercentage]: number | undefined;
  [Fields.salary]: number | undefined;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.name]: yup.string().required(t("errors.required", { field: t("excluded-individual-form.name") })),
    [Fields.title]: yup.string().required(t("errors.required", { field: t("excluded-individual-form.title") })),
    [Fields.relationshipType]: yup
      .string()
      .required(t("errors.required", { field: t("excluded-individual-form.relationship-type") })),
    [Fields.ownerPercentage]: yup
      .number()
      .min(1, t("errors.min", { field: t("excluded-individual-form.ownership-percentage"), min: 1 }))
      .max(100, t("errors.max", { field: t("excluded-individual-form.ownership-percentage"), max: 100 }))
      .required(t("errors.required", { field: t("excluded-individual-form.ownership-percentage") })),
    [Fields.salary]: yup
      .number()
      .required(t("errors.required", { field: t("excluded-individual-form.salary") }))
      .max(maxInt, t("errors.max", { field: t("excluded-individual-form.salary"), max: maxInt })),
  });
};

const ExcludedIndividualForm: React.FC<Props> = ({
  name = "",
  title = "",
  relationshipType = "",
  ownerPercentage,
  salary,
  employeeNumber,
  id,
  onDelete,
  onSubmit,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const validationSchema = useValidationSchema();
  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.id]: id,
      [Fields.name]: name,
      [Fields.title]: title,
      [Fields.relationshipType]: relationshipType,
      [Fields.ownerPercentage]: ownerPercentage,
      [Fields.salary]: salary,
    },
    enableReinitialize: true,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
    validateOnMount: true,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid className={classes.formInstance} container spacing={2}>
          <Grid item xs={12}>
            <Grid justify="space-between" alignItems="center" className={classes.formHeading} container>
              <Typography variant="h2">
                {t("excluded-individual-form.excluded-individual")} {employeeNumber ? employeeNumber : ""}
              </Typography>
              <Grid item>
                <Grid item xs={2}>
                  <IconButton
                    aria-label={t(`partner-form.delete-icon`)}
                    className={classes.iconButton}
                    onClick={onDelete}
                  >
                    <DeleteIcon color="disabled" className={classes.icon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={9} xs={12}>
            <TextInput
              required
              id={Fields.name}
              name={Fields.name}
              label={t("excluded-individual-form.name")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.name] && Boolean(formik.errors[Fields.name])}
              helperText={formik.touched[Fields.name] && formik.errors[Fields.name]}
              value={formik.values[Fields.name]}
              fullWidth
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextInput
              id={Fields.title}
              name={Fields.title}
              label={t("excluded-individual-form.title")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.title] && Boolean(formik.errors[Fields.title])}
              helperText={formik.touched[Fields.title] && formik.errors[Fields.title]}
              value={formik.values[Fields.title]}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput
              id={Fields.relationshipType}
              name={Fields.relationshipType}
              label={t("excluded-individual-form.relationship-type")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.relationshipType] && Boolean(formik.errors[Fields.relationshipType])}
              helperText={formik.touched[Fields.relationshipType] && formik.errors[Fields.relationshipType]}
              value={formik.values[Fields.relationshipType]}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumericInput
              format="###"
              id={Fields.ownerPercentage}
              name={Fields.ownerPercentage}
              label={t("excluded-individual-form.ownership-percentage")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.ownerPercentage] && Boolean(formik.errors[Fields.ownerPercentage])}
              helperText={formik.touched[Fields.ownerPercentage] && formik.errors[Fields.ownerPercentage]}
              value={formik.values[Fields.ownerPercentage]}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CurrencyInput
              id={Fields.salary}
              name={Fields.salary}
              label={t("excluded-individual-form.salary")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.salary] && Boolean(formik.errors[Fields.salary])}
              helperText={formik.touched[Fields.salary] && formik.errors[Fields.salary]}
              value={formik.values[Fields.salary]?.toString()}
              required
              fullWidth
            />
          </Grid>
          <Grid item className={classes.saveButtonContainer}>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              disabled={!formik.isValid}
              className={formik.isValid ? classNames(classes.saveButtonActive) : classNames(classes.saveButtonDisabled)}
            >
              <Grid container className={classes.saveButtonLabel}>
                <SaveIcon className={classes.saveIcon} />
                <div className={classes.saveButtonText}>
                  {t("excluded-individual-form.add-individual", { number: employeeNumber ? employeeNumber : "" })}
                </div>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ExcludedIndividualForm;
