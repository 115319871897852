/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserStatusResponse
 */
export interface UserStatusResponse {
  /**
   *
   * @type {boolean}
   * @memberof UserStatusResponse
   */
  hasPassword?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserStatusResponse
   */
  isLockedOut?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserStatusResponse
   */
  accessFailedCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserStatusResponse
   */
  hasPhone?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserStatusResponse
   */
  hasEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserStatusResponse
   */
  phoneConfirmed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserStatusResponse
   */
  emailConfirmed?: boolean;
}

export function UserStatusResponseFromJSON(json: any): UserStatusResponse {
  return UserStatusResponseFromJSONTyped(json, false);
}

export function UserStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    hasPassword: !exists(json, "hasPassword") ? undefined : json["hasPassword"],
    isLockedOut: !exists(json, "isLockedOut") ? undefined : json["isLockedOut"],
    accessFailedCount: !exists(json, "accessFailedCount") ? undefined : json["accessFailedCount"],
    hasPhone: !exists(json, "hasPhone") ? undefined : json["hasPhone"],
    hasEmail: !exists(json, "hasEmail") ? undefined : json["hasEmail"],
    phoneConfirmed: !exists(json, "phoneConfirmed") ? undefined : json["phoneConfirmed"],
    emailConfirmed: !exists(json, "emailConfirmed") ? undefined : json["emailConfirmed"],
  };
}

export function UserStatusResponseToJSON(value?: UserStatusResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    hasPassword: value.hasPassword,
    isLockedOut: value.isLockedOut,
    accessFailedCount: value.accessFailedCount,
    hasPhone: value.hasPhone,
    hasEmail: value.hasEmail,
    phoneConfirmed: value.phoneConfirmed,
    emailConfirmed: value.emailConfirmed,
  };
}
