/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TokenConfirmEmailRequest
 */
export interface TokenConfirmEmailRequest {
  /**
   *
   * @type {string}
   * @memberof TokenConfirmEmailRequest
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof TokenConfirmEmailRequest
   */
  token?: string | null;
}

export function TokenConfirmEmailRequestFromJSON(json: any): TokenConfirmEmailRequest {
  return TokenConfirmEmailRequestFromJSONTyped(json, false);
}

export function TokenConfirmEmailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TokenConfirmEmailRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    token: !exists(json, "token") ? undefined : json["token"],
  };
}

export function TokenConfirmEmailRequestToJSON(value?: TokenConfirmEmailRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    token: value.token,
  };
}
