/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenerateTokenChangeEmailResponse
 */
export interface GenerateTokenChangeEmailResponse {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenChangeEmailResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTokenChangeEmailResponse
   */
  token?: string | null;
}

export function GenerateTokenChangeEmailResponseFromJSON(json: any): GenerateTokenChangeEmailResponse {
  return GenerateTokenChangeEmailResponseFromJSONTyped(json, false);
}

export function GenerateTokenChangeEmailResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenChangeEmailResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    token: !exists(json, "token") ? undefined : json["token"],
  };
}

export function GenerateTokenChangeEmailResponseToJSON(value?: GenerateTokenChangeEmailResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    token: value.token,
  };
}
