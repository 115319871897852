import { EnrollmentApi, UploadTemplatesLinksApiResponse } from "@chq/lastmiledelivery-api";
import { useQuery, UseQueryResult } from "react-query";
import { useApi } from "./useApi";

export const useUploadTemplatesLinks = (): UseQueryResult<UploadTemplatesLinksApiResponse, Error | unknown> => {
  const applicationApi = useApi(EnrollmentApi);

  const mutation = useQuery("uploads-templates", async () => {
    const response = await applicationApi.apiV10EnrollmentGetUploadTemplatesGet();

    return response;
  });
  return mutation;
};
