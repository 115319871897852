/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Partner
 */
export interface Partner {
  /**
   *
   * @type {number}
   * @memberof Partner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Partner
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof Partner
   */
  ownershipPercent?: number;
}

export function PartnerFromJSON(json: any): Partner {
  return PartnerFromJSONTyped(json, false);
}

export function PartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    ownershipPercent: !exists(json, "ownershipPercent") ? undefined : json["ownershipPercent"],
  };
}

export function PartnerToJSON(value?: Partner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    ownershipPercent: value.ownershipPercent,
  };
}
