import { PrevNextNavBar } from "@chq/components";
import { Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router";
import DocumentsForm, {
  Fields as DocumentsFormFields,
  FormProps as defaultFormProps,
  useFormikConfig as useDefaultFormikConfig,
} from "../../../components/documents-form";
import RTWDocumentsForm, {
  Fields as RTWDocumentsFormFields,
  FormProps as rtwFormProps,
  useFormikConfig as useRTWFormikConfig,
} from "../../../components/documents-rtw-form";
import {
  useAddDriverList,
  useAddLossRunsList,
  useAddReturnToWorkList,
  useAddVehicleList,
  useChangeApplication,
  useGetApplication,
} from "../../../data/enrollment";
import {
  useDeleteDriverList,
  useDeleteLossRunsList,
  useDeleteReturnToWorkList,
  useDeleteVehicleList,
} from "../../../data/enrollment/useDeleteDocument";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: "4rem",
  },
  title: {
    marginBottom: "3.25rem",
  },
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
}));

const DocumentUploadsPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { data: application } = useGetApplication();

  const {
    mutate: editApplication,
    isLoading: isEditingApplication,
    isSuccess: goToReview,
    isError: goToVerify,
  } = useChangeApplication();
  const { mutate: addVehicleList, isLoading: isAddingVehicleList } = useAddVehicleList();
  const { mutate: addDriverList, isLoading: isAddingDriverList } = useAddDriverList();
  const { mutate: addLossRunsList, isLoading: isAddingLossRunList } = useAddLossRunsList();
  const { mutate: addReturnToWorkList, isLoading: isAddingReturnToWorkList } = useAddReturnToWorkList();
  const isAddingDocument = isAddingVehicleList || isAddingDriverList || isAddingLossRunList || isAddingReturnToWorkList;
  const { mutate: deleteVehicleList, isLoading: isDeletingVehicleList } = useDeleteVehicleList();
  const { mutate: deleteDriversList, isLoading: isDeletingDriversList } = useDeleteDriverList();
  const { mutate: deleteLossRunsList, isLoading: isDeletingLossRunsList } = useDeleteLossRunsList();
  const { mutate: deleteReturnToWorkList, isLoading: isDeletingReturnToWorkList } = useDeleteReturnToWorkList();
  const isDeletingDocument =
    isDeletingVehicleList || isDeletingDriversList || isDeletingLossRunsList || isDeletingReturnToWorkList;

  const loadDefaultForm = application?.coverageFleet || false;
  const loadReturnToWork = application?.doesHaveReturnToWorkProgram || false;
  const loadWorkersComp = application?.coverageWorkersComp || false;

  const defaultFormikProps = useDefaultFormikConfig();
  const rtwFormikProps = useRTWFormikConfig();

  if (goToReview) {
    return <Redirect to={routes.review.path} />;
  }

  if (goToVerify) {
    return <Redirect to={routes.verify.path} />;
  }
  return (
    <PageWrapper>
      <Formik
        initialValues={{
          [DocumentsFormFields.vehicleList]: application?.vehicleListDocument,
          [DocumentsFormFields.driverList]: application?.driverListDocument,
          [DocumentsFormFields.lossRuns]: application?.lossRunsDocument,
          [RTWDocumentsFormFields.rtwList]: application?.returnToWorkDocument,
        }}
        validateOnMount
        validationSchema={defaultFormikProps.validationSchema & rtwFormikProps.validationSchema}
        enableReinitialize
        onSubmit={(values: defaultFormProps & rtwFormProps) => {
          editApplication({
            ...application,
            applicationState: RouteNames.review,
            vehicleListDocument: values[DocumentsFormFields.vehicleList],
            driverListDocument: values[DocumentsFormFields.driverList],
            lossRunsDocument: values[DocumentsFormFields.lossRuns],
            returnToWorkDocument: values[RTWDocumentsFormFields.rtwList],
          });
        }}
      >
        {(formik) => (
          <Grid container direction="column" className={classes.container}>
            <Grid container>
              <Grid item>
                <Typography variant="h1" component="h3" align="left" className={classes.title}>
                  {t("upload-some-documents.title")}
                </Typography>
              </Grid>
              {loadDefaultForm && (
                <Grid container item xs={12}>
                  <DocumentsForm
                    addVehicleList={(file: File) =>
                      addVehicleList({
                        emailAddress: application!.contactEmail!,
                        file: file,
                      })
                    }
                    addDriverList={(file: File) =>
                      addDriverList({
                        emailAddress: application!.contactEmail!,
                        file: file,
                      })
                    }
                    addLossRuns={(file: File) =>
                      addLossRunsList({
                        emailAddress: application!.contactEmail!,
                        file: file,
                      })
                    }
                    vehicleList={formik.values[DocumentsFormFields.vehicleList]}
                    driverList={formik.values[DocumentsFormFields.driverList]}
                    lossRuns={formik.values[DocumentsFormFields.lossRuns]}
                    onDeleteDriverList={() => deleteDriversList({ emailAddress: application!.contactEmail! })}
                    onDeleteVehicleList={() => deleteVehicleList({ emailAddress: application!.contactEmail! })}
                    onDeleteLossRunList={() => deleteLossRunsList({ emailAddress: application!.contactEmail! })}
                  />
                </Grid>
              )}

              {loadWorkersComp && loadReturnToWork && (
                <Grid container item xs={12}>
                  {loadDefaultForm && <Divider className={classes.divider} />}
                  <RTWDocumentsForm
                    addReturnToWork={(file: File) =>
                      addReturnToWorkList({
                        emailAddress: application!.contactEmail!,
                        file: file,
                      })
                    }
                    rtwList={formik.values[RTWDocumentsFormFields.rtwList]}
                    onDelete={() => deleteReturnToWorkList({ emailAddress: application!.contactEmail! })}
                  />
                </Grid>
              )}
            </Grid>
            <PrevNextNavBar
              prevOnClick={() => {
                loadWorkersComp ? history.push(routes.workersCompPage.path) : history.push(routes.install.path);
              }}
              prevAriaLabel={
                loadWorkersComp
                  ? t("upload-some-documents.previous-button-workers-comp")
                  : t("upload-some-documents.previous-button-install")
              }
              nextOnClick={() => formik.handleSubmit()}
              nextDisabled={!formik.isValid || isEditingApplication || isAddingDocument || isDeletingDocument}
              isLoading={isEditingApplication}
              nextButtonText={t("upload-some-documents.next-button")}
            />
          </Grid>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default DocumentUploadsPage;
