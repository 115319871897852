import { CompanyType, Partner, PhoneType, State } from "@chq/lastmiledelivery-api";
import { PrevNextNavBar } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import BusinessInfoForm, {
  Fields as BusinessInfoFields,
  FormProps as BusinessInfoFormProps,
  useFormikConfig as useBusinessInfoFormikConfig,
} from "../../../components/business-info-form";
import BusinessOwnerForm, {
  Fields as BusinessOwnerFields,
  FormProps as BusinessOwnerFormProps,
  useFormikConfig as useBusinessOwnerFormikConfig,
} from "../../../components/business-owner-form";
import MailingAddressCheck, {
  Fields as MailingAddressFields,
  FormProps as MailingAddressFormProps,
  useFormikConfig as useMailingCheckFormikConfig,
} from "../../../components/mailing-address-check";
import PartnerForm, {
  Fields as PartnerFields,
  FormProps as PartnerFormProps,
  Partner as PartnerFormType,
  useFormikConfig as usePartnerFormikConfig,
} from "../../../components/partner-form";
import PhysicalAddressForm, {
  Fields as PhysicalAddressFields,
  FormProps as PhysicalAddressFormProps,
  useFormikConfig as usePhysicalAddressFormikConfig,
} from "../../../components/physical-address-form";
import SubsidiaryForm, {
  Fields as SubsidiaryFields,
  FormProps as SubsidiaryFormProps,
  useFormikConfig as useSubsidiaryFormikConfig,
} from "../../../components/subsidiary-form";
import { useChangeApplication, useGetApplication } from "../../../data/enrollment";
import { useDeletePartner } from "../../../data/enrollment/useDeletePartner";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { RouteNames } from "../../../utils/route-names";
import { trueOrFalseConversion, yesOrNoConversion } from "../../../utils/yes-no-conversion";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: "4rem",
  },
  titleContainer: {
    textAlign: "center",
  },
}));

type ValueProps = BusinessInfoFormProps &
  BusinessOwnerFormProps &
  MailingAddressFormProps &
  PhysicalAddressFormProps &
  SubsidiaryFormProps &
  PartnerFormProps;

const partnerMapper = (partner: Partner): PartnerFormType => {
  return {
    id: partner.id,
    name: partner.name as string,
  };
};

const BusinessInfoPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { data: application } = useGetApplication();

  const { mutate: editApplication, isLoading: isEditingApplication } = useChangeApplication();
  const { mutate: deletePartner, isLoading: isDeletingPartner } = useDeletePartner();
  const formikBusinessInfoProps = useBusinessInfoFormikConfig({
    businessName: application?.businessName || undefined,
    companyType: application?.companyType || undefined,
    dotNumber: application?.dotNumber || undefined,
    mcNumber: application?.mcNumber || undefined,
    ein: application?.einNumber || undefined,
  });
  const formikPhysicalAddressProps = usePhysicalAddressFormikConfig({
    street: application?.physicalAddress || undefined,
    city: application?.physicalCity || undefined,
    state: application?.physicalState || undefined,
    zip: application?.physicalZip || undefined,
  });
  const formikMailingCheckProps = useMailingCheckFormikConfig({
    mailingSameAsPhysicalAddress: application?.mailingSameAsPhysicalAddress ? true : false,
    mailingStreet: application?.mailingAddress || undefined,
    mailingCity: application?.mailingCity || undefined,
    mailingState: application?.mailingState || undefined,
    mailingZip: application?.mailingZip || undefined,
  });
  const formikBusinessOwnerProps = useBusinessOwnerFormikConfig({
    ownerName: application?.ownerName || undefined,
    email: application?.ownerEmail || undefined,
    phone: application?.ownerPhone || undefined,
    phoneType: application?.phoneType || undefined,
  });
  const formikPartnerProps = usePartnerFormikConfig({
    partners: application?.partners ? application?.partners.map((partner) => partnerMapper(partner)) : undefined,
  });
  const formikSubsidiaryProps = useSubsidiaryFormikConfig({
    isSubsidiary: yesOrNoConversion(application?.isOrHaveSubsidiaries),
    subsidiaries: application?.subsidiaryInfo || undefined,
  });
  const validationSchema = formikBusinessInfoProps.validationSchema
    .concat(formikPhysicalAddressProps.validationSchema)
    .concat(formikMailingCheckProps.validationSchema)
    .concat(formikBusinessOwnerProps.validationSchema)
    .concat(formikPartnerProps.validationSchema)
    .concat(formikSubsidiaryProps.validationSchema);

  useScrollToTop();

  return (
    <PageWrapper>
      <Formik
        initialValues={{
          ...formikBusinessInfoProps.initialValues,
          ...formikPhysicalAddressProps.initialValues,
          ...formikMailingCheckProps.initialValues,
          ...formikBusinessOwnerProps.initialValues,
          ...formikSubsidiaryProps.initialValues,
          partners: formikPartnerProps.initialValues.partners,
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values: ValueProps) => {
          editApplication(
            {
              ...application,
              applicationState:
                application?.coverageWorkersComp && !application?.coverageFleet
                  ? RouteNames.workersComp
                  : RouteNames.operations,
              businessName: values[BusinessInfoFields.businessName],
              companyType: values[BusinessInfoFields.companyType] as CompanyType,
              dotNumber: values[BusinessInfoFields.dotNumber],
              mcNumber: values[BusinessInfoFields.mcNumber],
              einNumber: values[BusinessInfoFields.ein],
              physicalAddress: values[PhysicalAddressFields.street],
              physicalCity: values[PhysicalAddressFields.city],
              physicalState: values[PhysicalAddressFields.state] as State,
              physicalZip: values[PhysicalAddressFields.zip],
              mailingSameAsPhysicalAddress: values[MailingAddressFields.mailingSameAsPhysicalAddress],
              mailingAddress: values[MailingAddressFields.mailingSameAsPhysicalAddress]
                ? values[PhysicalAddressFields.street]
                : values[MailingAddressFields.mailingStreet],
              mailingCity: values[MailingAddressFields.mailingSameAsPhysicalAddress]
                ? values[PhysicalAddressFields.city]
                : values[MailingAddressFields.mailingCity],
              mailingState: values[MailingAddressFields.mailingSameAsPhysicalAddress]
                ? (values[PhysicalAddressFields.state] as State)
                : (values[MailingAddressFields.mailingState] as State),
              mailingZip: values[MailingAddressFields.mailingSameAsPhysicalAddress]
                ? values[PhysicalAddressFields.zip]
                : values[MailingAddressFields.mailingZip],
              ownerName: values[BusinessOwnerFields.ownerName],
              ownerEmail: values[BusinessOwnerFields.email],
              ownerPhone: values[BusinessOwnerFields.phone],
              phoneType: values[BusinessOwnerFields.phoneType] as PhoneType,
              isOrHaveSubsidiaries: trueOrFalseConversion(values[SubsidiaryFields.isSubsidiary]),
              subsidiaryInfo: values[SubsidiaryFields.subsidiaries],
              partners: (values.partners || []).map((partner) => ({
                id: partner[PartnerFields.id],
                name: partner[PartnerFields.name],
                ownershipPercent: undefined,
              })),
            },
            {
              onSuccess: (response) => {
                if (response.data && response.data.coverageWorkersComp && !response.data.coverageFleet) {
                  history.push(routes.workersCompPage.path);
                } else {
                  history.push(routes.operations.path);
                }
              },
              onError: () => {
                history.push(routes.issue.path);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Grid className={classes.container} container direction="column" spacing={4}>
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography variant="h1" component="h3" align="left">
                {t("business-info-page.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <BusinessInfoForm />
            </Grid>
            <Grid item xs={12}>
              <PhysicalAddressForm title={t("business-info-page.physical-address")} />
            </Grid>
            <Grid item xs={12}>
              <MailingAddressCheck />
            </Grid>
            <Grid item xs={12}>
              <BusinessOwnerForm />
            </Grid>
            <Grid item xs={12}>
              <PartnerForm isDeleting={isDeletingPartner} onDeletePartner={(id) => deletePartner(id)} />
            </Grid>
            <Grid item xs={12}>
              <SubsidiaryForm />
            </Grid>
            <PrevNextNavBar
              prevOnClick={() => {
                history.push(routes.coverages.path);
              }}
              prevAriaLabel={t("business-info-page.previous-button")}
              nextOnClick={() => formik.handleSubmit()}
              nextDisabled={!formik.isValid || isEditingApplication}
              isLoading={isEditingApplication}
              nextButtonText={
                application?.coverageWorkersComp && !application?.coverageFleet
                  ? t("business-info-page.workers-comp")
                  : t("business-info-page.operations")
              }
            />
          </Grid>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default BusinessInfoPage;
