import { FailIcon } from "@chq/components";
import { Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useScrollToTop } from "../../../data/useScrollToTop";
import CompletedContentLayout from "../components/completed-content-layout";

const useStyles = makeStyles((theme: Theme) => ({
  failIcon: {
    color: theme.palette.error.main,
    display: "block",
    fontSize: "6rem",
    margin: "0 auto",
  },
}));

const InformationFailurePage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  useScrollToTop();

  return (
    <CompletedContentLayout title={t("failure-page.title")} icon={<FailIcon className={classes.failIcon} />}>
      <Grid item>
        <Typography variant="body2" align="center">
          <Trans i18nKey={"failure-page.main"}>
            <Typography component="span">Contact us at</Typography>
            <Link variant="body1" href={`tel:833-791-0858`}>
              833-791-0858
            </Link>
            <Typography component="span">for more information.</Typography>
          </Trans>
        </Typography>
      </Grid>
    </CompletedContentLayout>
  );
};

export default InformationFailurePage;
