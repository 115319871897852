import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type State = {
  id: string;
  name: string;
  abv: string;
};

export const useStates = (): State[] => {
  const [t] = useTranslation("regions");
  return useMemo(() => {
    const regions = t("regions", { returnObjects: true }) as Record<string, Record<string, string>>;
    const usStates = regions["US"] || {};
    return Object.keys(usStates)
      .map((abv: string) => {
        const name = usStates[abv];
        return {
          id: abv,
          name,
          abv,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [t]);
};
