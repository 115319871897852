/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LogisticsProvider
 */
export interface LogisticsProvider {
  /**
   *
   * @type {number}
   * @memberof LogisticsProvider
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof LogisticsProvider
   */
  providerName?: string | null;
  /**
   *
   * @type {number}
   * @memberof LogisticsProvider
   */
  shipmentPercent?: number;
  /**
   *
   * @type {number}
   * @memberof LogisticsProvider
   */
  yearsContracted?: number;
}

export function LogisticsProviderFromJSON(json: any): LogisticsProvider {
  return LogisticsProviderFromJSONTyped(json, false);
}

export function LogisticsProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogisticsProvider {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    providerName: !exists(json, "providerName") ? undefined : json["providerName"],
    shipmentPercent: !exists(json, "shipmentPercent") ? undefined : json["shipmentPercent"],
    yearsContracted: !exists(json, "yearsContracted") ? undefined : json["yearsContracted"],
  };
}

export function LogisticsProviderToJSON(value?: LogisticsProvider | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    providerName: value.providerName,
    shipmentPercent: value.shipmentPercent,
    yearsContracted: value.yearsContracted,
  };
}
