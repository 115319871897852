import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { SuccessIcon } from "./icons";

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    background: "transparent",
    minHeight: "3rem",
    "& .MuiAccordionSummary-expandIcon": {
      padding: "0rem",
      color: theme.palette.grey[400],
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
    width: "100%",
  },
  summary: {
    padding: "0px",
    minHeight: "3rem",
    background: theme.palette.grey[200],
    borderRadius: ".75rem",
    paddingRight: "1.25rem",
    paddingLeft: ".5rem",

    "&.Mui-expanded": {
      minHeight: "3rem",
    },
  },
  titleContainer: {
    padding: "0rem 0.5rem",
  },
  icon: {
    height: "2.5rem",
    width: "auto",
  },
  content: {
    background: "transparent",
    padding: "1rem 1.75rem",
  },
  successIcon: {
    color: theme.palette.success.main,
  },
}));

type Props = {
  titleText: string;
  showSuccessIcon?: boolean;
  titleVariant?: Variant;
};

const QuoteSummaryAccordion: React.FC<Props> = ({ titleText, showSuccessIcon, children, titleVariant = "h2" }) => {
  const classes = useStyles();

  return (
    <Accordion square elevation={0} className={classes.mainContainer}>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon className={classes.icon} />}>
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.titleContainer}>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              {showSuccessIcon && <SuccessIcon className={classes.successIcon} fontSize="large" />}
              <Typography variant={titleVariant}>{titleText}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.content}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default QuoteSummaryAccordion;
