/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Application,
  ApplicationFromJSON,
  ApplicationToJSON,
  ApplicationApiResponse,
  ApplicationApiResponseFromJSON,
  ApplicationApiResponseToJSON,
  ApplicationCheckApiResponse,
  ApplicationCheckApiResponseFromJSON,
  ApplicationCheckApiResponseToJSON,
  BooleanApiResponse,
  BooleanApiResponseFromJSON,
  BooleanApiResponseToJSON,
  CreateApplicationRequest,
  CreateApplicationRequestFromJSON,
  CreateApplicationRequestToJSON,
  TermsAndConditionsLinksApiResponse,
  TermsAndConditionsLinksApiResponseFromJSON,
  TermsAndConditionsLinksApiResponseToJSON,
  UploadTemplatesLinksApiResponse,
  UploadTemplatesLinksApiResponseFromJSON,
  UploadTemplatesLinksApiResponseToJSON,
} from "../models";

export interface ApiV10EnrollmentCreateApplicationPostRequest {
  createApplicationRequest?: CreateApplicationRequest;
}

export interface ApiV10EnrollmentDoesApplicationExistGetRequest {
  emailAddress?: string;
}

export interface ApiV10EnrollmentGetApplicationGetRequest {
  emailAddress?: string;
}

export interface ApiV10EnrollmentSendCompletedEmailPostRequest {
  emailAddress?: string;
}

export interface ApiV10EnrollmentSendHelpEmailPostRequest {
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
}

export interface ApiV10EnrollmentSendResumeApplicationLinkPostRequest {
  emailAddress?: string;
}

export interface ApiV10EnrollmentUpsertApplicationPutRequest {
  application?: Application;
}

/**
 *
 */
export class EnrollmentApi extends runtime.BaseAPI {
  /**
   * Create a new application
   */
  async apiV10EnrollmentCreateApplicationPostRaw(
    requestParameters: ApiV10EnrollmentCreateApplicationPostRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/CreateApplication`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateApplicationRequestToJSON(requestParameters.createApplicationRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   * Create a new application
   */
  async apiV10EnrollmentCreateApplicationPost(
    requestParameters: ApiV10EnrollmentCreateApplicationPostRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10EnrollmentCreateApplicationPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentDoesApplicationExistGetRaw(
    requestParameters: ApiV10EnrollmentDoesApplicationExistGetRequest,
  ): Promise<runtime.ApiResponse<ApplicationCheckApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/DoesApplicationExist`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationCheckApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentDoesApplicationExistGet(
    requestParameters: ApiV10EnrollmentDoesApplicationExistGetRequest,
  ): Promise<ApplicationCheckApiResponse> {
    const response = await this.apiV10EnrollmentDoesApplicationExistGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentGetApplicationGetRaw(
    requestParameters: ApiV10EnrollmentGetApplicationGetRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/GetApplication`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentGetApplicationGet(
    requestParameters: ApiV10EnrollmentGetApplicationGetRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10EnrollmentGetApplicationGetRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentGetTermsOfUseGetRaw(): Promise<runtime.ApiResponse<TermsAndConditionsLinksApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/GetTermsOfUse`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => TermsAndConditionsLinksApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentGetTermsOfUseGet(): Promise<TermsAndConditionsLinksApiResponse> {
    const response = await this.apiV10EnrollmentGetTermsOfUseGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentGetUploadTemplatesGetRaw(): Promise<runtime.ApiResponse<UploadTemplatesLinksApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/GetUploadTemplates`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UploadTemplatesLinksApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentGetUploadTemplatesGet(): Promise<UploadTemplatesLinksApiResponse> {
    const response = await this.apiV10EnrollmentGetUploadTemplatesGetRaw();
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentSendCompletedEmailPostRaw(
    requestParameters: ApiV10EnrollmentSendCompletedEmailPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/SendCompletedEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentSendCompletedEmailPost(
    requestParameters: ApiV10EnrollmentSendCompletedEmailPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10EnrollmentSendCompletedEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentSendHelpEmailPostRaw(
    requestParameters: ApiV10EnrollmentSendHelpEmailPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    if (requestParameters.phoneNumber !== undefined) {
      queryParameters["phoneNumber"] = requestParameters.phoneNumber;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/SendHelpEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentSendHelpEmailPost(
    requestParameters: ApiV10EnrollmentSendHelpEmailPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10EnrollmentSendHelpEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentSendResumeApplicationLinkPostRaw(
    requestParameters: ApiV10EnrollmentSendResumeApplicationLinkPostRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.emailAddress !== undefined) {
      queryParameters["emailAddress"] = requestParameters.emailAddress;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/SendResumeApplicationLink`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentSendResumeApplicationLinkPost(
    requestParameters: ApiV10EnrollmentSendResumeApplicationLinkPostRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10EnrollmentSendResumeApplicationLinkPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10EnrollmentUpsertApplicationPutRaw(
    requestParameters: ApiV10EnrollmentUpsertApplicationPutRequest,
  ): Promise<runtime.ApiResponse<ApplicationApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Enrollment/UpsertApplication`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ApplicationToJSON(requestParameters.application),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10EnrollmentUpsertApplicationPut(
    requestParameters: ApiV10EnrollmentUpsertApplicationPutRequest,
  ): Promise<ApplicationApiResponse> {
    const response = await this.apiV10EnrollmentUpsertApplicationPutRaw(requestParameters);
    return await response.value();
  }
}
