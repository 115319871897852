import { EditableDeletableCard, EditableDeletableCardProps, NumericInput, Select, TextInput } from "@chq/components";
import { State } from "@chq/lastmiledelivery-api";
import { Button, Grid, IconButtonProps, makeStyles, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SaveIcon from "@material-ui/icons/Save";
import classNames from "classnames";
import { FormikConfig, useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  saveButtonContainer: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
  },
  saveButtonText: {
    paddingLeft: ".4rem",
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  saveButtonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.grey[400],
    },
  },
  saveButtonDisabled: {
    backgroundColor: theme.palette.primary.light,
  },
  saveIcon: {
    height: "1.125rem",
  },
  saveButtonLabel: {
    alignItems: "center",
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    fontSize: ".75rem",
    marginLeft: ".25rem",
  },
  addButton: {
    padding: "1.125rem 0rem",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "3rem",
  },
  bodyCopy: {
    fontSize: ".75rem",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0px",
  },
  bodyHeading: {
    fontSize: ".875rem",
  },
  button: {
    padding: "1rem 0",
  },
  checkboxContain: {
    marginTop: "2.75rem",
  },
  formHeading: {
    height: "2rem",
    marginBottom: "1rem",
  },
  formInstance: {
    marginTop: "2rem",
  },
  cardStyles: {
    backgroundColor: theme.palette.grey[600],
    padding: "0",
    width: "100%",
    marginTop: "1rem",
  },
  editableDeletableCardStyles: {
    padding: "0",
    backgroundColor: theme.palette.common.white,
  },
  bold: {
    fontWeight: "bold",
  },
}));

export enum Fields {
  terminalCity = "terminalCity",
  terminalState = "terminalState",
  terminalZipCode = "terminalZipCode",
  delete = "delete-button",
}

type Props = {
  terminalCity?: string | null;
  terminalState?: string | State;
  terminalZipCode?: string | null;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
  onDelete?: EditableDeletableCardProps["onDelete"];
  terminalNumber?: number;
  IconButtonProps?: Omit<IconButtonProps, "onClick">;
};

export type FormProps = {
  [Fields.terminalCity]: string | null;
  [Fields.terminalState]: string | State;
  [Fields.terminalZipCode]: string | null;
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.terminalCity]: yup
      .string()
      .required(t(`errors.required`, { field: t(`operations.terminal-form.${Fields.terminalCity}.label`) })),
    [Fields.terminalState]: yup
      .string()
      .required(t(`errors.required`, { field: t(`operations.terminal-form.${Fields.terminalState}.label`) })),
    [Fields.terminalZipCode]: yup
      .string()
      .required(
        t("errors.required", {
          field: t(`operations.terminal-form.${[Fields.terminalZipCode]}.label`),
        }),
      )
      .test(
        "length",
        t("errors.exact-digits", {
          field: t(`operations.terminal-form.${[Fields.terminalZipCode]}.label`),
          exact: 5,
        }),
        (val) => val !== undefined && val.toString().length === 5,
      ),
  });
};

export const useFormikConfig = ({
  terminalCity: initialTerminalCity = "",
  terminalState: initialTerminalState = "",
  terminalZipCode: initialTerminalZipCode = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.terminalCity]: initialTerminalCity,
      [Fields.terminalState]: initialTerminalState,
      [Fields.terminalZipCode]: initialTerminalZipCode,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const TerminalForm: React.FC<Props> = ({
  terminalCity: initialTerminalCity = "",
  terminalState: initialTerminalState = "",
  terminalZipCode: initialTerminalZipCode = "",
  onSubmit,
  onDelete,
  terminalNumber = 0,
  IconButtonProps,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const states = useStates();
  const validationSchema = useValidationSchema();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const formik = useFormik<FormProps>({
    initialValues: {
      [Fields.terminalCity]: initialTerminalCity,
      [Fields.terminalState]: initialTerminalState,
      [Fields.terminalZipCode]: initialTerminalZipCode,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      onSubmit && onSubmit(values, formikHelpers);
    },
  });

  const checkIfErrorIsShowing = () => {
    const errors = Object.keys(formik.errors);
    const isErrorShowing = Object.keys(formik.touched).map((touched) => {
      return errors.includes(touched);
    });
    return isErrorShowing.includes(true);
  };

  return (
    <EditableDeletableCard
      className={classes.cardStyles}
      containerClass={classes.editableDeletableCardStyles}
      border={false}
      title={t("operations.terminal-form.terminal", { number: terminalNumber + 1 })}
      error={checkIfErrorIsShowing()}
      variant="delete"
      titleVariant="h3"
      titleComponent="h3"
      titleClass={classes.bold}
      onDelete={onDelete}
      IconButtonProps={{
        "aria-label": t("operations.terminal-card.delete-icon"),
        ...IconButtonProps,
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          direction="row"
          key={`terminal-${terminalNumber}`}
          className={classes.formInstance}
          spacing={isSmall ? 0 : 2}
        >
          <Grid item xs={12} sm={6}>
            <TextInput
              required
              id={Fields.terminalCity}
              name={Fields.terminalCity}
              label={t(`operations.terminal-form.${[Fields.terminalCity]}.label`)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.terminalCity] && Boolean(formik.errors[Fields.terminalCity])}
              helperText={formik.touched[Fields.terminalCity] && formik.errors[Fields.terminalCity]}
              value={formik.values[Fields.terminalCity]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              fullWidth
              required
              items={states
                .map((state) => ({
                  name: state.abv,
                  value: state.abv,
                }))
                .sort((a, b) => {
                  return a.value > b.value ? 1 : -1;
                })}
              label={t(`operations.terminal-form.${[Fields.terminalState]}.label`)}
              id={Fields.terminalState}
              name={Fields.terminalState}
              value={formik.values[Fields.terminalState]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.terminalState] && Boolean(formik.errors[Fields.terminalState])}
              helperText={formik.touched[Fields.terminalState] && formik.errors[Fields.terminalState]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <NumericInput
              isNumericString
              format="#####"
              id={Fields.terminalZipCode}
              name={Fields.terminalZipCode}
              label={t(`operations.terminal-form.${[Fields.terminalZipCode]}.label`)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.terminalZipCode] && Boolean(formik.errors[Fields.terminalZipCode])}
              helperText={formik.touched[Fields.terminalZipCode] && formik.errors[Fields.terminalZipCode]}
              value={formik.values[Fields.terminalZipCode]}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item className={classes.saveButtonContainer}>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            disabled={!formik.isValid}
            className={formik.isValid ? classNames(classes.saveButtonActive) : classNames(classes.saveButtonDisabled)}
          >
            <Grid container className={classes.saveButtonLabel}>
              <SaveIcon className={classes.saveIcon} />
              <div className={classes.saveButtonText}>
                {t("operations.terminal-form.save-terminal-city", { number: terminalNumber + 1 })}
              </div>
            </Grid>
          </Button>
        </Grid>
      </form>
    </EditableDeletableCard>
  );
};

export default TerminalForm;
