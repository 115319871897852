import { Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import PageWrapper from "../components/page-wrapper";
import successErrorBackground from "../images/success-error-background.jpg";

const useStyles = makeStyles((theme: Theme) => ({
  backgroundImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${successErrorBackground})`,
  },
  card: {
    borderRadius: ".5rem",
    margin: "20% auto",
    padding: "2.5rem",
    maxWidth: "32.75rem",
  },
  fullHeight: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  title: {
    margin: "3rem 0",
    lineHeight: "2.5rem",
  },
}));

type Props = {
  icon: React.ReactNode;
  title: string;
};

const CompleteContentLayout: React.FC<Props> = ({ icon, title, children }) => {
  const classes = useStyles();
  const imageUrl = { successErrorBackground };

  return (
    <PageWrapper background={imageUrl.successErrorBackground}>
      <Paper className={classes.card} elevation={0}>
        <Grid container direction="column">
          <Grid item xs={12}>
            {icon}
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h1" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid container direction="column" spacing={1}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </PageWrapper>
  );
};

export default CompleteContentLayout;
