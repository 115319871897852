/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./";

/**
 *
 * @export
 * @interface ByteArrayApiResponse
 */
export interface ByteArrayApiResponse {
  /**
   *
   * @type {string}
   * @memberof ByteArrayApiResponse
   */
  data?: string | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ByteArrayApiResponse
   */
  error?: ErrorResponse;
}

export function ByteArrayApiResponseFromJSON(json: any): ByteArrayApiResponse {
  return ByteArrayApiResponseFromJSONTyped(json, false);
}

export function ByteArrayApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ByteArrayApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : json["data"],
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ByteArrayApiResponseToJSON(value?: ByteArrayApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data,
    error: ErrorResponseToJSON(value.error),
  };
}
