import { route } from "static-route-paths";

export const routes = route({
  root: route(),
  missingPage: route("404"),
  unauthorizePage: route("401"),
  emailLinkPage: route("email-link"),
  applicationPage: route("application"),
  coverages: route("coverages"),
  businessInfo: route("business-info"),
  operations: route("operations"),
  hiring: route("hiring"),
  install: route("install"),
  review: route("review"),
  documentsUpload: route("documents-upload"),
  verify: route("verify"),
  register: route("registration"),
  issue: route("issue"),
  success: route("success"),
  aboutYourCompany: route("about-your-company"),
  workersCompPage: route("workers-comp"),
});
