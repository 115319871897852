import { useAuth } from "@chq/authentication";
import qs from "qs";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes";

const fixToken = (token: string) => {
  const fixedToken = token.split(" ").join("+");
  return fixedToken;
};

const EmailLinkPage: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const params = qs.parse(search);
  if (params.token) {
    params.token = fixToken(params.token as string);
  }
  const {
    loginWithMagicLink: [{ loading: isAuthenticating }, loginWithMagicLink],
  } = useAuth();

  useEffect(() => {
    if (!isAuthenticating && search.includes("?action=resumeApp")) {
      loginWithMagicLink(params.user as string, params.token as string, routes.applicationPage.path);
    }
  }, [history, loginWithMagicLink, isAuthenticating, params.token, params.user, search]);
  return <div>Loading....</div>;
};

export default EmailLinkPage;
