import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    marginBottom: 10,
  },
  button: {
    marginTop: "4rem",
    height: 56,
    width: "100%",
  },
  buttonContain: {
    width: "100%",
  },
}));

type Props = {
  onClose?: () => void;
};

const WelcomeBack: React.FC<Props> = ({ onClose }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center">
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.main}>
          <strong>{t(`welcome-back-modal.heading`)}</strong>
        </Typography>
        <Typography variant="body2">{t(`welcome-back-modal.main-one`)}</Typography>
        <Typography variant="body2">
          <strong>{t(`welcome-back-modal.main-two`)}</strong>
        </Typography>
      </Grid>
      <Grid className={classes.buttonContain} item xs={12}>
        <Button className={classes.button} onClick={onClose} variant="contained">
          {t(`welcome-back-modal.continue-button`)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default WelcomeBack;
