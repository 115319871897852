import {
  ApiV10CollectionsDeleteExcludedIndividualDeleteRequest,
  ApiV10CollectionsDeleteLogisticsProviderDeleteRequest,
  ApiV10CollectionsDeleteTerminalDeleteRequest,
  BooleanApiResponse,
  CollectionsApi,
  OperationState,
} from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useDeleteExcludedIndividual = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10CollectionsDeleteExcludedIndividualDeleteRequest
> => {
  const collectionsApi = useApi(CollectionsApi);
  const mutation = useMutation(async (request: ApiV10CollectionsDeleteExcludedIndividualDeleteRequest) => {
    const response = await collectionsApi.apiV10CollectionsDeleteExcludedIndividualDelete(request);

    return response;
  });
  return mutation;
};

export const useDeleteTerminal = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10CollectionsDeleteTerminalDeleteRequest
> => {
  const collectionsApi = useApi(CollectionsApi);
  const mutation = useMutation(async (request: ApiV10CollectionsDeleteTerminalDeleteRequest) => {
    const response = await collectionsApi.apiV10CollectionsDeleteTerminalDelete(request);

    return response;
  });
  return mutation;
};

export const useDeleteLogisticsProvider = (): UseMutationResult<
  BooleanApiResponse,
  Error | unknown,
  ApiV10CollectionsDeleteLogisticsProviderDeleteRequest
> => {
  const collectionsApi = useApi(CollectionsApi);
  const mutation = useMutation(async (request: ApiV10CollectionsDeleteLogisticsProviderDeleteRequest) => {
    const response = await collectionsApi.apiV10CollectionsDeleteLogisticsProviderDelete(request);

    return response;
  });
  return mutation;
};

export const useDeleteOperationState = (): UseMutationResult<void, Error, OperationState[]> => {
  const collectionsApi = useApi(CollectionsApi);
  const mutation = useMutation<void, Error, OperationState[]>(async (states: OperationState[]) => {
    for (const state of states) {
      const response = await collectionsApi.apiV10CollectionsDeleteOperationStateDelete({ id: state.id });
      if (response.error || !response.data) {
        throw new Error(response.error?.message || "Delete State Failed");
      }
    }
    return;
  });
  return mutation;
};
