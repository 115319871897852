import { createContext, useState } from "react";

type Email = {
  email: string;
};

const initialEmail: Email = {
  email: "",
};

export type GlobalContent = {
  email: Email;
  updateEmail: (newEmail: string) => void;
};

export const EmailContext = createContext<GlobalContent>({
  email: initialEmail,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateEmail: () => {},
});

export const EmailProvider: React.FC = ({ children }) => {
  const [email, setEmail] = useState(initialEmail);

  const updateEmail = (newEmail: string) => {
    setEmail({ email: newEmail });
  };

  return <EmailContext.Provider value={{ email, updateEmail }}>{children}</EmailContext.Provider>;
};
