/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum RadiusOfOperation {
  LocalUpTo50Miles = "Local up to 50 miles",
  Intermediate51To200Miles = "Intermediate 51 to 200 miles",
  LongDistanceOver200Miles = "Long distance over 200 miles",
}

export function RadiusOfOperationFromJSON(json: any): RadiusOfOperation {
  return RadiusOfOperationFromJSONTyped(json, false);
}

export function RadiusOfOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RadiusOfOperation {
  return json as RadiusOfOperation;
}

export function RadiusOfOperationToJSON(value?: RadiusOfOperation | null): any {
  return value as any;
}
