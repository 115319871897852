import { FormControlLabel, Grid, makeStyles, Switch, SwitchProps, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  label: {
    justifyContent: "space-between",
    width: "100%",
    "& .MuiFormControlLabel-label": {
      padding: 0,
    },
  },
  heading: {
    fontSize: "1.125rem",
  },
}));

type Props = Omit<SwitchProps, "color"> & {
  value?: boolean;
  label: React.ReactNode;
};

const ToggleSwitch: React.FC<Props> = ({ value, label, ...SwitchProps }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.label}
      control={<Switch checked={value} color="primary" {...SwitchProps} />}
      label={
        <Grid container direction="column">
          <Typography className={classes.heading} variant="h2">
            {label}
          </Typography>
        </Grid>
      }
      labelPlacement="start"
    />
  );
};

export default ToggleSwitch;
