import { PrevNextNavBar } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import InstallationForm, { Fields, useFormikConfig } from "../../../components/installation-form";
import { useChangeApplication, useGetApplication } from "../../../data/enrollment";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { RouteNames } from "../../../utils/route-names";
import { trueOrFalseConversion, yesOrNoConversion } from "../../../utils/yes-no-conversion";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingBottom: "2rem",
    marginBottom: "2rem",
  },
  container: {
    padding: "0 0 4rem",
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem 1rem 2.25rem",
    },
  },
}));

const InstallationPage: React.FC = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const formikProps = useFormikConfig();
  const { mutate: editApplication, isLoading: isEditingApplication } = useChangeApplication();
  const { data: application } = useGetApplication();

  useScrollToTop();

  return (
    <PageWrapper>
      <Formik
        {...formikProps}
        initialValues={{
          [Fields.doesPerformOnSiteAssemblyOfProducts]: yesOrNoConversion(
            application?.doesPerformOnSiteAssemblyOfProducts,
          ),
          [Fields.percentOfProductAssemblyIsDailyDeliveries]:
            application?.percentOfProductAssemblyIsDailyDeliveries ?? undefined,
          [Fields.doesAssembleFurniture]:
            application?.doesAssembleFurniture !== null ? application?.doesAssembleFurniture : undefined,
          [Fields.doesAssembleElectronics]:
            application?.doesAssembleElectronics !== null ? application?.doesAssembleElectronics : undefined,
          [Fields.doesAssembleExibitsAndOrDisplays]:
            application?.doesAssembleExibitsAndOrDisplays === null
              ? undefined
              : application?.doesAssembleExibitsAndOrDisplays,
          [Fields.doesAssembleExerciseEquipment]:
            application?.doesAssembleExerciseEquipment !== null
              ? application?.doesAssembleExerciseEquipment
              : undefined,
          [Fields.doesAssembleOtherProducts]:
            application?.doesAssembleOtherProducts !== null ? application?.doesAssembleOtherProducts : undefined,
          [Fields.otherProductsAssembled]: application?.otherProductsAssembled ?? "",
          [Fields.doesPerformOnsiteAssemblyOfAppliances]: yesOrNoConversion(
            application?.doesPerformOnsiteAssemblyOfAppliances,
          ),
          [Fields.waterIsDailyDelivery]:
            application?.waterIsDailyDelivery !== null ? application?.waterIsDailyDelivery : undefined,
          [Fields.percentOfApplianceAssemblyIsDailyDeliveries]:
            application?.percentOfApplianceAssemblyIsDailyDeliveries ?? undefined,
          [Fields.gasIsDailyDelivery]:
            application?.gasIsDailyDelivery !== null ? application?.gasIsDailyDelivery : undefined,
          [Fields.noDailyDeliveries]:
            application?.noDailyDeliveries !== null ? application?.noDailyDeliveries : undefined,
          [Fields.doesPerformInstallsRequiringContractors]: yesOrNoConversion(
            application?.doesPerformInstallsRequiringContractor,
          ),
          [Fields.isLicensedContractor]: yesOrNoConversion(application?.isLicensedContractor),
        }}
        onSubmit={(values) => {
          editApplication(
            {
              ...application,
              applicationState: application?.coverageWorkersComp ? RouteNames.workersComp : RouteNames.documentsUpload,
              doesPerformOnSiteAssemblyOfProducts: trueOrFalseConversion(
                values[Fields.doesPerformOnSiteAssemblyOfProducts],
              ),
              percentOfProductAssemblyIsDailyDeliveries: values[Fields.percentOfProductAssemblyIsDailyDeliveries],
              doesAssembleExibitsAndOrDisplays: values[Fields.doesAssembleExibitsAndOrDisplays],
              doesAssembleFurniture: values[Fields.doesAssembleFurniture],
              doesAssembleElectronics: values[Fields.doesAssembleElectronics],
              doesAssembleExerciseEquipment: values[Fields.doesAssembleExerciseEquipment],
              doesAssembleOtherProducts: values[Fields.doesAssembleOtherProducts],
              otherProductsAssembled: values[Fields.otherProductsAssembled],
              doesPerformOnsiteAssemblyOfAppliances: trueOrFalseConversion(
                values[Fields.doesPerformOnsiteAssemblyOfAppliances],
              ),
              percentOfApplianceAssemblyIsDailyDeliveries: values[Fields.percentOfApplianceAssemblyIsDailyDeliveries],
              waterIsDailyDelivery: values[Fields.waterIsDailyDelivery],
              gasIsDailyDelivery: values[Fields.gasIsDailyDelivery],
              noDailyDeliveries: values[Fields.noDailyDeliveries],
              doesPerformInstallsRequiringContractor: trueOrFalseConversion(
                values[Fields.doesPerformInstallsRequiringContractors],
              ),
              isLicensedContractor: trueOrFalseConversion(values[Fields.isLicensedContractor]),
            },
            {
              onSuccess: (response) => {
                if (response.data && response.data.coverageWorkersComp) {
                  history.push(routes.workersCompPage.path);
                } else {
                  history.push(routes.documentsUpload.path);
                }
              },
              onError: () => {
                history.push(routes.issue.path);
              },
            },
          );
        }}
      >
        {(formik) => (
          <Grid container direction="column" className={classes.container}>
            <Grid item xs={12} className={classes.title}>
              <Typography variant="h1" component="h3">
                {t("installation-page.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <InstallationForm />
            </Grid>
            <PrevNextNavBar
              prevOnClick={() => {
                history.push(routes.hiring.path);
              }}
              prevAriaLabel={t("about-your-company-page.previous-button")}
              nextOnClick={() => formik.handleSubmit()}
              nextDisabled={!formik.isValid || isEditingApplication}
              isLoading={isEditingApplication}
              nextButtonText={
                application?.coverageWorkersComp
                  ? t("installation-form.workers-comp")
                  : t("installation-form.upload-documents")
              }
            />
          </Grid>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default InstallationPage;
