/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  Application,
  ApplicationFromJSON,
  ApplicationFromJSONTyped,
  ApplicationToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface ApplicationApiResponse
 */
export interface ApplicationApiResponse {
  /**
   *
   * @type {Application}
   * @memberof ApplicationApiResponse
   */
  data?: Application;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ApplicationApiResponse
   */
  error?: ErrorResponse;
}

export function ApplicationApiResponseFromJSON(json: any): ApplicationApiResponse {
  return ApplicationApiResponseFromJSONTyped(json, false);
}

export function ApplicationApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : ApplicationFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ApplicationApiResponseToJSON(value?: ApplicationApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ApplicationToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
