/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
  GenerateTokenSignInResponse,
  GenerateTokenSignInResponseFromJSON,
  GenerateTokenSignInResponseFromJSONTyped,
  GenerateTokenSignInResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface GenerateTokenSignInResponseApiResponse
 */
export interface GenerateTokenSignInResponseApiResponse {
  /**
   *
   * @type {GenerateTokenSignInResponse}
   * @memberof GenerateTokenSignInResponseApiResponse
   */
  data?: GenerateTokenSignInResponse;
  /**
   *
   * @type {ErrorResponse}
   * @memberof GenerateTokenSignInResponseApiResponse
   */
  error?: ErrorResponse;
}

export function GenerateTokenSignInResponseApiResponseFromJSON(json: any): GenerateTokenSignInResponseApiResponse {
  return GenerateTokenSignInResponseApiResponseFromJSONTyped(json, false);
}

export function GenerateTokenSignInResponseApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenerateTokenSignInResponseApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : GenerateTokenSignInResponseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function GenerateTokenSignInResponseApiResponseToJSON(
  value?: GenerateTokenSignInResponseApiResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GenerateTokenSignInResponseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
