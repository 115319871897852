/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { State, StateFromJSON, StateFromJSONTyped, StateToJSON } from "./";

/**
 *
 * @export
 * @interface OperationState
 */
export interface OperationState {
  /**
   *
   * @type {number}
   * @memberof OperationState
   */
  id?: number;
  /**
   *
   * @type {State}
   * @memberof OperationState
   */
  state?: State;
}

export function OperationStateFromJSON(json: any): OperationState {
  return OperationStateFromJSONTyped(json, false);
}

export function OperationStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationState {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    state: !exists(json, "state") ? undefined : StateFromJSON(json["state"]),
  };
}

export function OperationStateToJSON(value?: OperationState | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    state: StateToJSON(value.state),
  };
}
