import { TextInput, YesNoInput, YesNoValues } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
  },
  titleContainer: {
    display: "flex",
  },
  required: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  subsidiaries: {
    paddingLeft: "0.5rem",
    paddingTop: "0.5rem",
    "& .MuiFormControl-root": {
      height: "4rem",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
  },
}));

export enum Fields {
  isSubsidiary = "is-subsidiary",
  subsidiaries = "subsidiaries",
}

export type FormProps = {
  [Fields.isSubsidiary]: string;
  [Fields.subsidiaries]: string;
};

type Props = {
  isSubsidiary?: string;
  subsidiaries?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.isSubsidiary]: yup.string().required(t(`subsidiary-form.is-subsidiary.error-text`)),
    [Fields.subsidiaries]: yup.string().when(Fields.isSubsidiary, {
      is: YesNoValues.yes,
      then: yup.string().required(t(`subsidiary-form.subsidiaries.error-text`)),
    }),
  });
};

export const useFormikConfig = ({
  isSubsidiary: initialIsSubsidiary = "",
  subsidiaries: initialSubsidiaries = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.isSubsidiary]: initialIsSubsidiary,
      [Fields.subsidiaries]: initialSubsidiaries,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const SubsidiaryForm: React.FC<Props> = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h3" className={classes.required}>
            {"\u00a0*"}
          </Typography>
          <Typography variant="h3" className={classes.title}>
            {t("subsidiary-form.title")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <YesNoInput
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.isSubsidiary}
            name={Fields.isSubsidiary}
            value={formik.values[Fields.isSubsidiary]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.isSubsidiary] && Boolean(formik.errors[Fields.isSubsidiary])}
            helperText={formik.touched[Fields.isSubsidiary] && formik.errors[Fields.isSubsidiary]}
          />
        </Grid>

        {formik.values[Fields.isSubsidiary] === YesNoValues.yes && (
          <Grid item xs={12} className={classes.subsidiaries}>
            <TextInput
              required
              fullWidth
              multiline
              label={t("subsidiary-form.subsidiaries.label")}
              id={Fields.subsidiaries}
              name={Fields.subsidiaries}
              value={formik.values[Fields.subsidiaries]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.subsidiaries] && Boolean(formik.errors[Fields.subsidiaries])}
              helperText={formik.touched[Fields.subsidiaries] && formik.errors[Fields.subsidiaries]}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default SubsidiaryForm;
