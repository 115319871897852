import { CardField, EditableDeletableCard } from "@chq/components";
import { LogisticsProvider, Terminal } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  terminals?: Terminal[] | null;
  logisticsProviders?: LogisticsProvider[] | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 500,
    width: "100%",
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  businessDetails: {
    marginBottom: ".5rem",
  },
  cardField: {
    margin: ".25rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
      lineHeight: "1.125rem",
    },
  },
  content: {
    marginTop: ".5rem",
    "& .MuiTypography-root": {
      lineHeight: "1.125rem",
    },
  },
  section: {
    padding: ".5rem 0",
  },
}));

const ReviewWhereYourCompanyOperatesCard: React.FC<Props> = ({ terminals, logisticsProviders }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container item xs={12}>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("where-your-company-operates.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("where-your-company-operates.edit-button") }}
        onEdit={() => history.push(routes.operations.path)}
      >
        <Grid className={classes.content} spacing={1} container direction="row" wrap="nowrap">
          <Grid container item direction="column" wrap="nowrap">
            <Grid container direction="column" className={classNames(classes.borderBottom, classes.section)}>
              <CardField
                className={classes.cardCopy}
                id="terminals"
                label={t("where-your-company-operates.terminals")}
                values={[
                  {
                    value: (terminals || []).map((terminal, index, arr) => (
                      <React.Fragment key={terminal.id}>
                        <span>
                          {terminal.terminalCity}, {terminal.terminalState} {terminal.terminalZipCode}
                        </span>
                        {index < arr.length - 1 ? <br /> : null}
                      </React.Fragment>
                    )),
                  },
                ]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
            <Grid container direction="column" className={classes.section}>
              <CardField
                className={classes.cardCopy}
                id="logistics-providers"
                label={t("where-your-company-operates.logistics-providers")}
                values={[
                  {
                    value: (logisticsProviders || []).map((provider, index, arr) => (
                      <React.Fragment key={provider.id}>
                        <span key={provider.id}>
                          {provider.providerName} - {provider.shipmentPercent}&#37; - {provider.yearsContracted} years
                        </span>
                        {index < arr.length - 1 ? <br /> : null}
                      </React.Fragment>
                    )),
                  },
                ]}
                labelGrid={6}
                valueGrid={6}
              />
            </Grid>
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ReviewWhereYourCompanyOperatesCard;
