import { NumberPicker, NumberPickerChangeEvent, StyleLabel, TextInput, YesNoInput, YesNoValues } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import classnames from "classnames";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { maxInt, preventNegativeNumber } from "../utils/number-validation";

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    width: "12.5rem",
  },
  indentation: {
    paddingLeft: "1rem",
  },
  label: {
    position: "relative",
    top: "1rem",
    fontWeight: 700,
    textTransform: "capitalize",
  },
}));

export enum Fields {
  yearsExperience = "years-experience",
  hasOtherOperations = "has-other-operations",
  otherOperations = "other-operations",
  isMaintainingForTwelveMonths = "is-maintaining-for-twelve-months",
  hasWrittenVehicleMaintenance = "has-written-vehicile-maintenance",
  hasOtherVehicles = "has-other-vehicles",
  hasEmployeesUseTheirAutos = "has-employees-use-their-autos",
  reasonsEmployeesUseTheirAutos = "reasons-employees-use-their-autos",
  hasEmployeeInsuranceOfTheirAutos = "has-employee-insurance-of-their-autos",
  numberOfEmployeesUsingTheirAutos = "number-of-employees-using-their-autos",
}

export type FormProps = {
  [Fields.yearsExperience]: number;
  [Fields.hasOtherOperations]: string;
  [Fields.otherOperations]: string;
  [Fields.isMaintainingForTwelveMonths]: string;
  [Fields.hasWrittenVehicleMaintenance]: string;
  [Fields.hasOtherVehicles]: string;
  [Fields.hasEmployeesUseTheirAutos]: string;
  [Fields.reasonsEmployeesUseTheirAutos]: string;
  [Fields.hasEmployeeInsuranceOfTheirAutos]: string;
  [Fields.numberOfEmployeesUsingTheirAutos]: number;
};

type Props = {
  yearsExperience?: number;
  hasOtherOperations?: string;
  otherOperations?: string;
  isMaintainingForTwelveMonths?: string;
  hasWrittenVehicleMaintenance?: string;
  hasOtherVehicles?: string;
  hasEmployeesUseTheirAutos?: string;
  reasonsEmployeesUseTheirAutos?: string;
  hasEmployeeInsuranceOfTheirAutos?: string;
  numberOfEmployeesUsingTheirAutos?: number;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object().shape({
    [Fields.yearsExperience]: yup
      .number()
      .required(t("errors.required", { field: t("about-your-company-form.years-experience.helper-text") }))
      .max(maxInt, t("errors.max", { field: t("about-your-company-form.years-experience.helper-text"), max: maxInt }))
      .typeError(t("errors.required", { field: t("about-your-company-form.years-experience.helper-text") })),
    [Fields.hasOtherOperations]: yup.string().required(""),
    [Fields.otherOperations]: yup.string().when(Fields.hasOtherOperations, {
      is: YesNoValues.yes,
      then: yup.string().required(""),
    }),
    [Fields.isMaintainingForTwelveMonths]: yup.string().required(),
    [Fields.hasWrittenVehicleMaintenance]: yup.string().required(),
    [Fields.hasOtherVehicles]: yup.string().required(),
    [Fields.hasEmployeesUseTheirAutos]: yup.string().required(),
    [Fields.reasonsEmployeesUseTheirAutos]: yup.string().when(Fields.hasEmployeesUseTheirAutos, {
      is: YesNoValues.yes,
      then: yup.string().required(t("about-your-company-form.reasons-employees-use-their-autos.helper-text")),
    }),
    [Fields.hasEmployeeInsuranceOfTheirAutos]: yup.string().when(Fields.hasEmployeesUseTheirAutos, {
      is: YesNoValues.yes,
      then: yup.string().required(),
    }),
    [Fields.numberOfEmployeesUsingTheirAutos]: yup.number().when(Fields.hasEmployeesUseTheirAutos, {
      is: YesNoValues.yes,
      then: yup
        .number()
        .required(
          t("errors.required", {
            field: t("about-your-company-form.number-of-employees-using-their-autos.label"),
          }),
        )
        .min(
          1,
          t("errors.min", {
            field: t("about-your-company-form.number-of-employees-using-their-autos.helper-text"),
            min: 1,
          }),
        )
        .max(
          maxInt,
          t("errors.max", {
            field: t("about-your-company-form.number-of-employees-using-their-autos.helper-text"),
            max: maxInt,
          }),
        )
        .typeError(
          t("errors.required", {
            field: t("about-your-company-form.number-of-employees-using-their-autos.label"),
          }),
        ),
    }),
  });
};
export const useFormikConfig = ({
  yearsExperience: initialYearsExperience = 0,
  hasOtherOperations: initialHasOtherOperations = "",
  otherOperations: initialOtherOperations = "",
  isMaintainingForTwelveMonths: initialIsMaintainingForTwelveMonths = "",
  hasWrittenVehicleMaintenance: initialHasWrittenVehicleMaintenance = "",
  hasOtherVehicles: initialHasOtherVehicles = "",
  hasEmployeesUseTheirAutos: initialHasEmployeesUseTheirAutos = "",
  reasonsEmployeesUseTheirAutos: initialReasonsEmployeesUseTheirAutos = "",
  hasEmployeeInsuranceOfTheirAutos: initialHasEmployeeInsuranceOfTheirAutos = "",
  numberOfEmployeesUsingTheirAutos: initialNumberOfEmployeesUsingTheirAutos = 0,
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.yearsExperience]: initialYearsExperience,
      [Fields.hasOtherOperations]: initialHasOtherOperations,
      [Fields.otherOperations]: initialOtherOperations,
      [Fields.isMaintainingForTwelveMonths]: initialIsMaintainingForTwelveMonths,
      [Fields.hasWrittenVehicleMaintenance]: initialHasWrittenVehicleMaintenance,
      [Fields.hasOtherVehicles]: initialHasOtherVehicles,
      [Fields.hasEmployeesUseTheirAutos]: initialHasEmployeesUseTheirAutos,
      [Fields.reasonsEmployeesUseTheirAutos]: initialReasonsEmployeesUseTheirAutos,
      [Fields.hasEmployeeInsuranceOfTheirAutos]: initialHasEmployeeInsuranceOfTheirAutos,
      [Fields.numberOfEmployeesUsingTheirAutos]: initialNumberOfEmployeesUsingTheirAutos,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const AboutYourCompanyForm: React.FC = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.label}>
          <StyleLabel variant="h4" labelText={t("about-your-company-form.years-experience.label")} />
        </Grid>
        <Grid item xs={12}>
          <NumberPicker
            id={Fields.yearsExperience}
            name={Fields.yearsExperience}
            required
            fullWidth
            value={formik.values[Fields.yearsExperience]}
            onChange={(e: NumberPickerChangeEvent) => {
              e.target.value = preventNegativeNumber(e.target.value);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched[Fields.yearsExperience] && Boolean(formik.errors[Fields.yearsExperience])}
            helperText={formik.touched[Fields.yearsExperience] && formik.errors[Fields.yearsExperience]}
            inputProps={{ "aria-label": `${t("about-your-company-form.years-experience.label")}` }}
            data-testid={"years-experience"}
            min={0}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.label}>
            <StyleLabel variant="h4" labelText={t("about-your-company-form.has-other-operations.label")} />
          </Grid>
          <Grid item xs={12}>
            <YesNoInput
              id={Fields.hasOtherOperations}
              name={Fields.hasOtherOperations}
              required
              fullWidth
              yesText={t("common.yes")}
              noText={t("common.no")}
              value={formik.values[Fields.hasOtherOperations]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.hasOtherOperations] && Boolean(formik.errors[Fields.hasOtherOperations])}
              helperText={formik.touched[Fields.hasOtherOperations] && formik.errors[Fields.hasOtherOperations]}
              radioClass={classes.radio}
            />
          </Grid>
        </Grid>
        {formik.values[Fields.hasOtherOperations] === YesNoValues.yes && (
          <Grid item container xs={12}>
            <Grid item xs={12} className={classes.label}>
              <StyleLabel variant="h4" labelText={t("about-your-company-form.other-operations.label")} />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id={Fields.otherOperations}
                name={Fields.otherOperations}
                multiline
                fullWidth
                value={formik.values[Fields.otherOperations]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.otherOperations] && Boolean(formik.errors[Fields.otherOperations])}
                helperText={formik.touched[Fields.otherOperations] && formik.errors[Fields.otherOperations]}
                className={classes.indentation}
                data-testid={"other-operations"}
                inputProps={{ "aria-label": `${t("about-your-company-form.years-experience.label")}` }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.label}>
            <StyleLabel variant="h4" labelText={t("about-your-company-form.is-maintaining-for-twelve-months.label")} />
          </Grid>
          <Grid item xs={12}>
            <YesNoInput
              id={Fields.isMaintainingForTwelveMonths}
              name={Fields.isMaintainingForTwelveMonths}
              required
              fullWidth
              yesText={t("common.yes")}
              noText={t("common.no")}
              value={formik.values[Fields.isMaintainingForTwelveMonths]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[Fields.isMaintainingForTwelveMonths] &&
                Boolean(formik.errors[Fields.isMaintainingForTwelveMonths])
              }
              helperText={
                formik.touched[Fields.isMaintainingForTwelveMonths] &&
                formik.errors[Fields.isMaintainingForTwelveMonths]
              }
              radioClass={classes.radio}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.label}>
            <StyleLabel variant="h4" labelText={t("about-your-company-form.has-written-vehicle-maintenance.label")} />
          </Grid>
          <Grid item xs={12}>
            <YesNoInput
              id={Fields.hasWrittenVehicleMaintenance}
              name={Fields.hasWrittenVehicleMaintenance}
              required
              fullWidth
              yesText={t("common.yes")}
              noText={t("common.no")}
              value={formik.values[Fields.hasWrittenVehicleMaintenance]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[Fields.hasWrittenVehicleMaintenance] &&
                Boolean(formik.errors[Fields.hasWrittenVehicleMaintenance])
              }
              helperText={
                formik.touched[Fields.hasWrittenVehicleMaintenance] &&
                formik.errors[Fields.hasWrittenVehicleMaintenance]
              }
              radioClass={classes.radio}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.label}>
            <StyleLabel variant="h4" labelText={t("about-your-company-form.has-other-vehicles.label")} />
          </Grid>
          <Grid item xs={12}>
            <YesNoInput
              id={Fields.hasOtherVehicles}
              name={Fields.hasOtherVehicles}
              required
              fullWidth
              yesText={t("common.yes")}
              noText={t("common.no")}
              value={formik.values[Fields.hasOtherVehicles]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.hasOtherVehicles] && Boolean(formik.errors[Fields.hasOtherVehicles])}
              helperText={formik.touched[Fields.hasOtherVehicles] && formik.errors[Fields.hasOtherVehicles]}
              radioClass={classes.radio}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} className={classes.label}>
            <StyleLabel variant="h4" labelText={t("about-your-company-form.has-employees-use-their-autos.label")} />
          </Grid>
          <Grid item xs={12}>
            <YesNoInput
              id={Fields.hasEmployeesUseTheirAutos}
              name={Fields.hasEmployeesUseTheirAutos}
              required
              fullWidth
              yesText={t("common.yes")}
              noText={t("common.no")}
              value={formik.values[Fields.hasEmployeesUseTheirAutos]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[Fields.hasEmployeesUseTheirAutos] &&
                Boolean(formik.errors[Fields.hasEmployeesUseTheirAutos])
              }
              helperText={
                formik.touched[Fields.hasEmployeesUseTheirAutos] && formik.errors[Fields.hasEmployeesUseTheirAutos]
              }
              radioClass={classes.radio}
            />
          </Grid>
        </Grid>
        {formik.values[Fields.hasEmployeesUseTheirAutos] === YesNoValues.yes && (
          <>
            <Grid item container xs={12}>
              <Grid item xs={12} className={classes.label}>
                <StyleLabel
                  variant="h4"
                  labelText={t("about-your-company-form.reasons-employees-use-their-autos.label")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id={Fields.reasonsEmployeesUseTheirAutos}
                  name={Fields.reasonsEmployeesUseTheirAutos}
                  multiline
                  fullWidth
                  value={formik.values[Fields.reasonsEmployeesUseTheirAutos]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[Fields.reasonsEmployeesUseTheirAutos] &&
                    Boolean(formik.errors[Fields.reasonsEmployeesUseTheirAutos])
                  }
                  helperText={
                    formik.touched[Fields.reasonsEmployeesUseTheirAutos] &&
                    formik.errors[Fields.reasonsEmployeesUseTheirAutos]
                  }
                  className={classes.indentation}
                  data-testid={"reasons-employees-use-their-autos"}
                  inputProps={{ "aria-label": `${t("about-your-company-form.years-experience.label")}` }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={12} className={classes.label}>
                <StyleLabel
                  variant="h4"
                  labelText={t("about-your-company-form.has-employee-insurance-of-their-autos.label")}
                />
              </Grid>
              <Grid item xs={12}>
                <YesNoInput
                  id={Fields.hasEmployeeInsuranceOfTheirAutos}
                  name={Fields.hasEmployeeInsuranceOfTheirAutos}
                  required
                  fullWidth
                  yesText={t("common.yes")}
                  noText={t("common.no")}
                  value={formik.values[Fields.hasEmployeeInsuranceOfTheirAutos]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[Fields.hasEmployeeInsuranceOfTheirAutos] &&
                    Boolean(formik.errors[Fields.hasEmployeeInsuranceOfTheirAutos])
                  }
                  helperText={
                    formik.touched[Fields.hasEmployeeInsuranceOfTheirAutos] &&
                    formik.errors[Fields.hasEmployeeInsuranceOfTheirAutos]
                  }
                  radioClass={classes.radio}
                  data-testid={"has-employee-insurance-of-their-autos"}
                  className={classnames(classes.indentation)}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} className={classes.label}>
                <StyleLabel
                  variant="h4"
                  labelText={t("about-your-company-form.number-of-employees-using-their-autos.label")}
                />
              </Grid>
              <Grid item xs={12}>
                <NumberPicker
                  id={Fields.numberOfEmployeesUsingTheirAutos}
                  name={Fields.numberOfEmployeesUsingTheirAutos}
                  required
                  min={0}
                  value={formik.values[Fields.numberOfEmployeesUsingTheirAutos]}
                  onChange={(e: NumberPickerChangeEvent) => {
                    e.target.value = preventNegativeNumber(e.target.value);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[Fields.numberOfEmployeesUsingTheirAutos] &&
                    Boolean(formik.errors[Fields.numberOfEmployeesUsingTheirAutos])
                  }
                  helperText={
                    formik.touched[Fields.numberOfEmployeesUsingTheirAutos] &&
                    formik.errors[Fields.numberOfEmployeesUsingTheirAutos]
                  }
                  data-testid={"number-of-employees-using-their-autos"}
                  inputProps={{ "aria-label": `${t("about-your-company-form.years-experience.label")}` }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default AboutYourCompanyForm;
