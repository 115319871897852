import { EditableDeletableCard, EditableDeletableCardProps } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { State } from "../data/useStates";

type Props = {
  terminalCity?: string;
  terminalState?: string | State;
  terminalZipCode?: string;
  onEdit: EditableDeletableCardProps["onEdit"];
};

const useStyles = makeStyles((theme: Theme) => ({
  cardStyles: {
    backgroundColor: theme.palette.grey[600],
    paddingTop: "0.2rem",
    width: "100%",
  },
  editableDeletableCardStyles: {
    paddingBottom: "0.2rem",
  },
  titleClass: {
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
}));

const TerminalCard: React.FC<Props> = ({ terminalCity, terminalState, terminalZipCode, onEdit }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Grid container>
      <EditableDeletableCard
        className={classes.cardStyles}
        containerClass={classes.editableDeletableCardStyles}
        variant="edit"
        title={`${terminalCity}, ${terminalState} ${terminalZipCode}`}
        titleVariant="h3"
        titleClass={classes.titleClass}
        titleComponent="h3"
        onEdit={onEdit}
        IconButtonProps={{ "aria-label": t("operations.terminal-card.edit-button") }}
      />
    </Grid>
  );
};

export default TerminalCard;
