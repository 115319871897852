import { Footer, Header, NotFoundPage } from "@chq/components";
import { makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useSendHelpEmailRequest } from "../../data/useSendHelpEmailRequest";
import { useTermsAndConditionLinks } from "../../data/useTermsAndConditionsLinks";
import MobileForestImage from "../last-mile/images/forest-image-mobile.png";
import ForestImage from "../last-mile/images/forest-image.png";
import { routes } from "../routes";
import { useFormikConfig } from "../shared/validation-schema/validationSchema";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
  },
}));

enum Fields {
  name = "name",
  email = "email",
  phone = "phone",
  problem = "problem",
  description = "description",
}

type FormProps = {
  [Fields.name]: string;
  [Fields.email]: string;
  [Fields.phone]: string;
  [Fields.problem]: string;
  [Fields.description]: string;
};

const MissingPage: React.FC = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();
  const formikProps = useFormikConfig();
  const { mutate: sendEmail } = useSendHelpEmailRequest();
  const terms = useTermsAndConditionLinks();
  const termsLink = terms.data?.data?.termsOfUseLink;
  const privacyLink = terms.data?.data?.privacyPolicyLink;

  //Get Array of Problems for footer email
  const listOfProblems = t("footer.problem-options", {
    returnObjects: true,
  });
  const problemOptionsArray: string[] = Object.values(listOfProblems);

  return (
    <>
      <Header
        aonTitleAccess={t("common.aon-logo")}
        aonAriaLabel={t("common.aon-logo")}
        chqTitleAccess={t("common.chq-logo")}
        chqAriaLabel={t("common.chq-logo")}
        headerBarTitleAccess={t("common.middle-bar")}
        headerBarAriaLabel={t("common.middle-bar")}
        lastMile
      />
      <NotFoundPage
        desktopBackgroundImage={ForestImage}
        mobileBackgroundImage={MobileForestImage}
        numbersText={t("missing-page.404")}
        headerText={t("missing-page.header-text")}
        mainInfoText={t("missing-page.main-info")}
        buttonText={t("missing-page.home-button")}
        nextButtonOnClick={() => history.push(routes.path)}
      />

      <div className={classes.footer}>
        <Formik
          {...formikProps}
          onSubmit={(values: FormProps) => {
            sendEmail({
              name: values[Fields.name],
              emailAddress: values[Fields.email],
              phoneNumber: values[Fields.phone],
            });
          }}
        >
          {() => (
            <Footer
              termsLink={termsLink}
              privacyLink={privacyLink}
              nameLabel={t("footer.name")}
              phoneLabel={t("footer.phone")}
              emailLabel={t("footer.email")}
              problemLabel={t("footer.problem")}
              problemList={problemOptionsArray}
              problemListCondition={problemOptionsArray[6]}
              descriptionLabel={t("footer.description")}
              needHelpText={t("footer.need-help")}
              reachOutText={t("footer.reach-out")}
              privacyText={t("footer.privacy")}
              termsText={t("footer.terms")}
              legalText={t("footer.legal")}
            />
          )}
        </Formik>
      </div>
    </>
  );
};

export default MissingPage;
