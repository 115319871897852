import React from "react";
import { Redirect } from "react-router-dom";
import { useGetApplication } from "../../../data/enrollment";

const ApplicationPage: React.FC = () => {
  const { data } = useGetApplication();

  if (data?.applicationState) {
    return <Redirect to={data.applicationState} />;
  }

  return <div>Loading...</div>;
};

export default ApplicationPage;
