/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ELDProvider {
  AdvantageAssetTracking = "Advantage Asset Tracking",
  ActSoft = "ActSoft",
  Agilis = "Agilis",
  AssuredTelematics = "Assured Telematics",
  Azuga = "Azuga",
  EagleWireless = "Eagle Wireless",
  EROAD = "EROAD",
  EZFleet = "EZ Fleet",
  Geotab = "Geotab",
  GoFleet = "GoFleet",
  GPSInsight = "GPS Insight",
  Gridline = "Gridline",
  InTouchGPS = "InTouch GPS",
  OnTrakSolutions = "OnTrak Solutions",
  Omnitrac = "Omnitrac",
  RushEnterprises = "Rush Enterprises",
  SafetyComplianceSolutions = "Safety & Compliance Solutions",
  Samsara = "Samsara",
  SimpleTruckELD = "Simple Truck ELD",
  TeletracNavman = "Teletrac-Navman",
  TraxxisGPS = "Traxxis GPS",
  Trimble = "Trimble",
  Zonar = "Zonar",
  Other = "Other",
  KeepTruckin = "KeepTruckin",
}

export function ELDProviderFromJSON(json: any): ELDProvider {
  return ELDProviderFromJSONTyped(json, false);
}

export function ELDProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELDProvider {
  return json as ELDProvider;
}

export function ELDProviderToJSON(value?: ELDProvider | null): any {
  return value as any;
}
