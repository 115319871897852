import { TextInput } from "@chq/components";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useCheckApplication } from "../../../../data/enrollment";
import { useSendResumeApplicationEmail } from "../../../../data/useSendResumeApplicationLink";
import StartNew from "./start-new";
import WelcomeBack from "./welcome-back";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    marginBottom: 10,
  },
  button: {
    marginTop: 36,
    height: 56,
    width: "100%",
  },
  buttonContain: {
    width: "100%",
  },
  email: {
    marginTop: "1rem",
  },
}));

export enum Fields {
  email = "email",
}

type Props = {
  email?: string;
  onClose?: () => void;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export type FormProps = {
  [Fields.email]: string;
};

const useValidationSchema = () => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.email]: yup
      .string()
      .email(t("errors.invalid-email"))
      .required(t(`errors.required`, { field: t(`already-started-modal.email`) })),
  });
};

export const useFormikConfig = ({ email: initialEmail = "" }: Props = {}): Omit<
  FormikConfig<FormProps>,
  "onSubmit"
> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      email: initialEmail,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const AlreadyStarted: React.FC<Props> = ({ onClose }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { mutate: checkApplication } = useCheckApplication();
  const [isEmailTrue, setIsEmailTrue] = useState<boolean>(false);
  const [emailChecked, setEmailChecked] = useState<string | null>();
  const { mutate: sendResumeApplicationLink } = useSendResumeApplicationEmail();

  const [hasEmailBeenChecked, setHasEmailBeenChecked] = useState<boolean>(false);

  return (
    <>
      <Formik
        initialValues={{
          ...useFormikConfig().initialValues,
        }}
        validationSchema={useFormikConfig().validationSchema}
        enableReinitialize
        validateOnMount
        onSubmit={(values: FormProps) => {
          setIsEmailTrue(false);
          setHasEmailBeenChecked(false);
          checkApplication(
            {
              emailAddress: values[Fields.email],
            },
            {
              onSuccess: (response) => {
                setHasEmailBeenChecked(true);
                if (response.data?.doesApplicationExist) {
                  setIsEmailTrue(true);
                  sendResumeApplicationLink({
                    emailAddress: values[Fields.email],
                  });
                } else {
                  setEmailChecked(response.data?.email);
                }
              },
            },
          );
        }}
      >
        {(formik) => (
          <>
            <form onSubmit={formik.handleSubmit}>
              {!hasEmailBeenChecked ? (
                <Grid container direction="column" justify="center">
                  <Grid item xs={12}>
                    <Typography variant="h2" className={classes.main}>
                      <strong>{t("already-started-modal.heading")}</strong>
                    </Typography>
                    <Typography variant="body2">{t(`already-started-modal.main`)}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.email}>
                    <TextInput
                      label={t("business-user-form.email.label")}
                      required
                      fullWidth
                      id={Fields.email}
                      name={Fields.email}
                      value={formik.values[Fields.email]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched[Fields.email] && Boolean(formik.errors[Fields.email])}
                      helperText={formik.touched[Fields.email] && formik.errors[Fields.email]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button className={classes.button} onClick={() => formik.handleSubmit()} variant="contained">
                      {t(`already-started-modal.continue-button`)}
                    </Button>
                  </Grid>
                </Grid>
              ) : isEmailTrue ? (
                <WelcomeBack onClose={onClose} />
              ) : (
                <StartNew email={emailChecked} onClose={onClose} />
              )}
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AlreadyStarted;
