import { CardField, EditableDeletableCard, SuccessIcon } from "@chq/components";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  theme?: Theme;
  statesDelivered?: React.ReactNode;
  effectiveDate?: string;
  vehicles?: string;
  years?: string;
  miles?: string;
  fleetCoverage?: boolean;
  workersComp?: boolean;
  logisticsProvider?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 500,
    width: "100%",
  },
  coverageCopy: {
    fontWeight: 700,
  },
  coveragesContainer: {
    padding: ".75rem 0",
    margin: "1rem 0",
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    borderTop: `1px solid ${theme.palette.grey[400]}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    padding: ".5rem 0",
  },
  cardPadding: {
    paddingTop: ".5rem",
  },
  cardField: {
    padding: ".2rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
    },
  },
  heading: {
    marginTop: "1rem",
  },
  successIcon: {
    color: theme.palette.success.main,
    marginRight: ".5rem",
  },
  workersComp: {
    marginBottom: ".5rem",
  },
  fleetCoverages: {
    flexWrap: "nowrap",
  },
  effectiveDatePadding: {
    paddingLeft: ".9rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0rem",
      marginLeft: ".1rem",
      whiteSpace: "nowrap",
    },
  },
  vehiclePadding: {
    paddingLeft: ".7rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
  },
  section: {
    padding: ".5rem 0",
  },
}));

const ReviewCoveragesCard: React.FC<Props> = ({
  fleetCoverage,
  workersComp,
  logisticsProvider,
  statesDelivered,
  effectiveDate,
  vehicles,
  years,
  miles,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("review-coverages.card.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("review-coverages.card.edit-button") }}
        onEdit={() => history.push(routes.coverages.path)}
      >
        <Grid container direction="row" className={classes.borderBottom}>
          <Grid container direction="column" className={(classes.borderBottom, classes.section)}>
            <CardField
              className={classes.cardCopy}
              id="states-delivered"
              label={t("where-your-company-operates.states-delivered")}
              values={[{ value: statesDelivered }]}
              labelGrid={6}
              valueGrid={6}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.borderBottom}>
          <Grid item xs={12} className={classes.cardField}>
            <CardField
              valueClass={classes.effectiveDatePadding}
              className={classes.cardCopy}
              id="effective-date"
              label={t("review-coverages.card.effective-date")}
              values={[{ value: effectiveDate }]}
              labelGrid={3}
              valueGrid={9}
            />
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.borderBottom}>
          {fleetCoverage && (
            <Grid item xs={12} className={(workersComp ? classes.workersComp : "", classes.cardField)}>
              <Grid container alignItems="center" className={classes.fleetCoverages}>
                <SuccessIcon className={classes.successIcon} />
                <Typography className={classes.coverageCopy} variant="body2">
                  {t("review-coverages.card.fleet-coverage")}
                </Typography>
              </Grid>
            </Grid>
          )}

          {workersComp && (
            <Grid item xs={12} className={classes.cardField}>
              <Grid container alignItems="center">
                <SuccessIcon className={classes.successIcon} />
                <Typography className={classes.coverageCopy} variant="body2">
                  {t("review-coverages.card.workers-comp")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.borderBottom}>
          <Grid item xs={12} sm={3} className={classes.cardField}>
            <CardField
              className={classes.cardCopy}
              valueClass={classes.vehiclePadding}
              id="vehicles"
              label={t("review-coverages.card.vehicles")}
              values={[{ value: vehicles }]}
              labelGrid={6}
              valueGrid={6}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.cardField}>
            <CardField
              className={classes.cardCopy}
              id="years"
              label={t("review-coverages.card.years-in-business")}
              values={[{ value: years }]}
              labelGrid={8}
              valueGrid={4}
            />
          </Grid>
          <Grid item xs={12} sm={5} className={classes.cardField}>
            <CardField
              className={classes.cardCopy}
              id="miles"
              label={t("review-coverages.card.annual-fleet-miles")}
              values={[{ value: miles }]}
              labelGrid={7}
              valueGrid={5}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.cardPadding}>
          <Grid item xs={12} className={classes.cardField}>
            <CardField
              className={classes.cardCopy}
              id="logistics-provider"
              label={t("review-coverages.card.logistics-providers")}
              values={[{ value: logisticsProvider }]}
              labelGrid={4}
              valueGrid={8}
            />
          </Grid>
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ReviewCoveragesCard;
