/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { BooleanApiResponse, BooleanApiResponseFromJSON, BooleanApiResponseToJSON } from "../models";

export interface ApiV10CollectionsDeleteEmployeeDeleteRequest {
  id?: number;
}

export interface ApiV10CollectionsDeleteExcludedIndividualDeleteRequest {
  id?: number;
}

export interface ApiV10CollectionsDeleteLogisticsProviderDeleteRequest {
  id?: number;
}

export interface ApiV10CollectionsDeleteOperationStateDeleteRequest {
  id?: number;
}

export interface ApiV10CollectionsDeletePartnerDeleteRequest {
  id?: number;
}

export interface ApiV10CollectionsDeleteTerminalDeleteRequest {
  id?: number;
}

/**
 *
 */
export class CollectionsApi extends runtime.BaseAPI {
  /**
   */
  async apiV10CollectionsDeleteEmployeeDeleteRaw(
    requestParameters: ApiV10CollectionsDeleteEmployeeDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Collections/DeleteEmployee`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10CollectionsDeleteEmployeeDelete(
    requestParameters: ApiV10CollectionsDeleteEmployeeDeleteRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10CollectionsDeleteEmployeeDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10CollectionsDeleteExcludedIndividualDeleteRaw(
    requestParameters: ApiV10CollectionsDeleteExcludedIndividualDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Collections/DeleteExcludedIndividual`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10CollectionsDeleteExcludedIndividualDelete(
    requestParameters: ApiV10CollectionsDeleteExcludedIndividualDeleteRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10CollectionsDeleteExcludedIndividualDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10CollectionsDeleteLogisticsProviderDeleteRaw(
    requestParameters: ApiV10CollectionsDeleteLogisticsProviderDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Collections/DeleteLogisticsProvider`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10CollectionsDeleteLogisticsProviderDelete(
    requestParameters: ApiV10CollectionsDeleteLogisticsProviderDeleteRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10CollectionsDeleteLogisticsProviderDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10CollectionsDeleteOperationStateDeleteRaw(
    requestParameters: ApiV10CollectionsDeleteOperationStateDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Collections/DeleteOperationState`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10CollectionsDeleteOperationStateDelete(
    requestParameters: ApiV10CollectionsDeleteOperationStateDeleteRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10CollectionsDeleteOperationStateDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10CollectionsDeletePartnerDeleteRaw(
    requestParameters: ApiV10CollectionsDeletePartnerDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Collections/DeletePartner`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10CollectionsDeletePartnerDelete(
    requestParameters: ApiV10CollectionsDeletePartnerDeleteRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10CollectionsDeletePartnerDeleteRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10CollectionsDeleteTerminalDeleteRaw(
    requestParameters: ApiV10CollectionsDeleteTerminalDeleteRequest,
  ): Promise<runtime.ApiResponse<BooleanApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Collections/DeleteTerminal`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10CollectionsDeleteTerminalDelete(
    requestParameters: ApiV10CollectionsDeleteTerminalDeleteRequest,
  ): Promise<BooleanApiResponse> {
    const response = await this.apiV10CollectionsDeleteTerminalDeleteRaw(requestParameters);
    return await response.value();
  }
}
