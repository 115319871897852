/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExcludedIndividual
 */
export interface ExcludedIndividual {
  /**
   *
   * @type {number}
   * @memberof ExcludedIndividual
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ExcludedIndividual
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExcludedIndividual
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ExcludedIndividual
   */
  relationshipType?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExcludedIndividual
   */
  ownershipPercent?: number;
  /**
   *
   * @type {number}
   * @memberof ExcludedIndividual
   */
  salary?: number;
}

export function ExcludedIndividualFromJSON(json: any): ExcludedIndividual {
  return ExcludedIndividualFromJSONTyped(json, false);
}

export function ExcludedIndividualFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcludedIndividual {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    title: !exists(json, "title") ? undefined : json["title"],
    relationshipType: !exists(json, "relationshipType") ? undefined : json["relationshipType"],
    ownershipPercent: !exists(json, "ownershipPercent") ? undefined : json["ownershipPercent"],
    salary: !exists(json, "salary") ? undefined : json["salary"],
  };
}

export function ExcludedIndividualToJSON(value?: ExcludedIndividual | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    title: value.title,
    relationshipType: value.relationshipType,
    ownershipPercent: value.ownershipPercent,
    salary: value.salary,
  };
}
