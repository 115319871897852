import { EnrollmentApi, TermsAndConditionsLinksApiResponse } from "@chq/lastmiledelivery-api";
import { useQuery, UseQueryResult } from "react-query";
import { useApi } from "./useApi";

export const useTermsAndConditionLinks = (): UseQueryResult<TermsAndConditionsLinksApiResponse, Error | unknown> => {
  const applicationApi = useApi(EnrollmentApi);

  const mutation = useQuery("terms", async () => {
    const response = await applicationApi.apiV10EnrollmentGetTermsOfUseGet();

    return response;
  });
  return mutation;
};
