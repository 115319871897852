import {
  AlertType,
  ComponentConfirmOverlay,
  PageAlert,
  PlusCircleIcon as AddCircleRoundedIcon,
  PrevNextNavBar,
} from "@chq/components";
import { ExcludedIndividual } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";
import ExcludedIndividualCard from "../../../components/excluded-individual-card";
import ExcludedIndividualForm, {
  Fields as ExcludedIndividualFields,
  FormProps as ExcludedInvdividualFormProps,
  useValidationSchema as useExcludedIndividualValidationSchema,
} from "../../../components/excluded-individual-form";
import {
  default as PayrollForm,
  Fields as PayrollFields,
  useFormikConfig as usePayrollFormikConfig,
} from "../../../components/payroll-form";
import {
  default as PayrollYesNoForm,
  Fields as PayrollYesNoFields,
  useFormikConfig as usePayrollYesNoFormikConfig,
} from "../../../components/payroll-yes-no-form";
import { useChangeApplication, useGetApplication } from "../../../data/enrollment";
import { useDeleteExcludedIndividual } from "../../../data/enrollment/useDeleteCollection";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { excludedIndividualMapper } from "../../../utils/excluded-individual-mapper";
import { RouteNames } from "../../../utils/route-names";
import { trueOrFalseConversion, yesOrNoConversion } from "../../../utils/yes-no-conversion";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: "4rem",
  },
  card: {
    position: "relative",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1.5rem",
  },
  addNewText: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    fontSize: ".75rem",
    marginLeft: ".25rem",
  },
  bodyCopy: {
    fontSize: ".85rem",
  },
  basicInfoContainer: {
    display: "flex",
  },
  cardContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    marginBottom: "1rem",
  },
  excluded: {
    marginTop: "2rem",
  },
  inCalifornia: {
    fontSize: ".85rem",
    fontWeight: "bold",
  },
  title: {
    marginBottom: "3.25rem",
  },
  bodyHeading: {
    fontSize: "1.1rem",
    fontWeight: 700,
  },
}));

export type ExcludedIndividualUnit = Partial<ExcludedInvdividualFormProps> & {
  isEditing?: boolean;
  employeeNumber?: number;
  id?: number;
};

export enum Fields {
  excludedIndividuals = "excludedIndividuals",
}

const WorkersCompPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { data: application } = useGetApplication();
  const { mutate: editApplication, ...editApplicationCheck } = useChangeApplication();
  const { mutate: deleteExcludedIndividual } = useDeleteExcludedIndividual();

  const payrollProps = usePayrollFormikConfig({
    driverPayrollProjectedYear: application?.driverPayrollProjectedYear?.toString() || undefined,
    driverPayrollCurrentYear: application?.driverPayrollCurrentYear?.toString() || undefined,
    driverPayrollPriorYear: application?.driverPayrollPriorYear?.toString() || undefined,
    clericalPayrollProjectedYear: application?.clericalPayrollProjectedYear?.toString() || undefined,
    clericalPayrollCurrentYear: application?.clericalPayrollCurrentYear?.toString() || undefined,
    clericalPayrollPriorYear: application?.clericalPayrollPriorYear?.toString() || undefined,
    otherPayrollProjectedYear: application?.otherPayrollProjectedYear?.toString() || undefined,
    otherPayrollCurrentYear: application?.otherPayrollCurrentYear?.toString() || undefined,
    otherPayrollPriorYear: application?.otherPayrollPriorYear?.toString() || undefined,
    premiumsProjectedYear: application?.premiumsProjectedYear?.toString() || undefined,
    premiumsCurrentYear: application?.premiumsCurrentYear?.toString() || undefined,
    premiumsPriorYear: application?.premiumsPriorYear?.toString() || undefined,
  });

  const payrollYesNoProps = usePayrollYesNoFormikConfig({
    percentOfEmployeesEnrolledInMedicalPlan: application?.percentOfEmployeesEnrolledInMedicalPlan || undefined,
    isExcludedSalaryIncludedInPayroll: yesOrNoConversion(application?.isExcludedSalaryIncludedInPayroll),
    isGroupMedicalProvided: yesOrNoConversion(application?.isGroupMedicalProvided),
    isFormalAccidentReportingInPlace: yesOrNoConversion(application?.isFormalAccidentReportingInPlace),
    doesHaveReturnToWorkProgram: yesOrNoConversion(application?.doesHaveReturnToWorkProgram),
    isWillingToCreateVanlinerAssistance: yesOrNoConversion(application?.isWillingToCreateVanlinerAssistance),
    doesUtilizeIndependentContractors: yesOrNoConversion(application?.doesUtilizeIndependentContractors),
    maintainCertificatesOfInsuranceForCoverage: yesOrNoConversion(
      application?.maintainCertificatesOfInsuranceForCoverage,
    ),
    willProvideCoverageUnderWorkersCompPolicy: yesOrNoConversion(
      application?.willProvideCoverageUnderWorkersCompPolicy,
    ),
  });

  const excludedIndividualFormValidation = useExcludedIndividualValidationSchema();
  const validationSchema = payrollProps.validationSchema.concat(payrollYesNoProps.validationSchema).concat(
    Yup.object({
      [Fields.excludedIndividuals]: Yup.array().of(excludedIndividualFormValidation),
      [PayrollYesNoFields.isExcludedSalaryIncludedInPayroll]: Yup.string().when(Fields.excludedIndividuals, {
        is: (value: ExcludedIndividualUnit[]) => value.length > 0,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    }),
  );

  /* ************** delete overlays ************** */
  const [showExcludedIndividualConfirmationOverlay, setShowExcludedIndividualConfirmationOverlay] = useState<
    number | null
  >(null);
  const deleteExcludedIndividualConfirmation = (onDelete: () => void) => {
    onDelete();
    setShowExcludedIndividualConfirmationOverlay(null);
  };

  useScrollToTop();

  return (
    <PageWrapper>
      {editApplicationCheck.isError && (
        <PageAlert alertType={AlertType.error} message={t("operations.page-level-error")} />
      )}
      <Grid container direction="column" className={classes.container}>
        <Grid container>
          <Grid item className={classes.basicInfoContainer}>
            <Typography variant="h1" align="left" component="h3" className={classes.title}>
              {t("workers-comp-page.title")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item direction="column" className={classes.cardContainer}>
          <Formik
            initialValues={{
              ...payrollProps.initialValues,
              ...payrollYesNoProps.initialValues,
              [Fields.excludedIndividuals]: (application?.excludedIndividuals || []).map(
                (excludedIndividual: ExcludedIndividual, index: number) => {
                  return {
                    ...excludedIndividual,
                    isEditing: false,
                    employeeNumber: index,
                  } as ExcludedIndividualUnit;
                },
              ),
            }}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
            onSubmit={(values) => {
              editApplication(
                {
                  ...application,
                  applicationState:
                    application?.coverageFleet ||
                    trueOrFalseConversion(values[PayrollYesNoFields.doesHaveReturnToWorkProgram])
                      ? RouteNames.documentsUpload
                      : RouteNames.review,
                  driverPayrollProjectedYear: parseInt(values[PayrollFields.driverPayrollProjectedYear]),
                  driverPayrollCurrentYear: parseInt(values[PayrollFields.driverPayrollCurrentYear]),
                  driverPayrollPriorYear: parseInt(values[PayrollFields.driverPayrollPriorYear]),
                  clericalPayrollProjectedYear: parseInt(values[PayrollFields.clericalPayrollProjectedYear]),
                  clericalPayrollCurrentYear: parseInt(values[PayrollFields.clericalPayrollCurrentYear]),
                  clericalPayrollPriorYear: parseInt(values[PayrollFields.clericalPayrollPriorYear]),
                  otherPayrollProjectedYear: parseInt(values[PayrollFields.otherPayrollProjectedYear]),
                  otherPayrollCurrentYear: parseInt(values[PayrollFields.otherPayrollCurrentYear]),
                  otherPayrollPriorYear: parseInt(values[PayrollFields.otherPayrollPriorYear]),
                  premiumsProjectedYear: parseInt(values[PayrollFields.premiumsProjectedYear]),
                  premiumsCurrentYear: parseInt(values[PayrollFields.premiumsCurrentYear]),
                  premiumsPriorYear: parseInt(values[PayrollFields.premiumsPriorYear]),
                  percentOfEmployeesEnrolledInMedicalPlan:
                    values[PayrollYesNoFields.percentOfEmployeesEnrolledInMedicalPlan],
                  isGroupMedicalProvided: trueOrFalseConversion(values[PayrollYesNoFields.isGroupMedicalProvided]),
                  isExcludedSalaryIncludedInPayroll: trueOrFalseConversion(
                    values[PayrollYesNoFields.isExcludedSalaryIncludedInPayroll],
                  ),

                  isFormalAccidentReportingInPlace: trueOrFalseConversion(
                    values[PayrollYesNoFields.isFormalAccidentReportingInPlace],
                  ),
                  doesHaveReturnToWorkProgram: trueOrFalseConversion(
                    values[PayrollYesNoFields.doesHaveReturnToWorkProgram],
                  ),
                  isWillingToCreateVanlinerAssistance: trueOrFalseConversion(
                    values[PayrollYesNoFields.isWillingToCreateVanlinerAssistance],
                  ),
                  doesUtilizeIndependentContractors: trueOrFalseConversion(
                    values[PayrollYesNoFields.doesUtilizeIndependentContractors],
                  ),
                  maintainCertificatesOfInsuranceForCoverage: trueOrFalseConversion(
                    values[PayrollYesNoFields.maintainCertificatesOfInsuranceForCoverage],
                  ),
                  willProvideCoverageUnderWorkersCompPolicy: trueOrFalseConversion(
                    values[PayrollYesNoFields.willProvideCoverageUnderWorkersCompPolicy],
                  ),
                  excludedIndividuals: (values[Fields.excludedIndividuals] || []).map((unit) =>
                    excludedIndividualMapper(unit),
                  ),
                },
                {
                  onSuccess: () => {
                    application?.coverageFleet ||
                    trueOrFalseConversion(values[PayrollYesNoFields.doesHaveReturnToWorkProgram])
                      ? history.push(routes.documentsUpload.path)
                      : history.push(routes.review.path);
                  },
                  onError: () => {
                    history.push(routes.issue.path);
                  },
                },
              );
            }}
          >
            {(formik) => (
              <>
                <Grid item xs={12}>
                  <PayrollForm />
                </Grid>
                <Grid item xs={12} className={classes.excluded}>
                  <Typography variant="h3" className={classes.bodyHeading}>
                    {t("workers-comp-page.excluded-individual-verbiage-label")}
                  </Typography>
                  <Typography variant="body1" className={classes.bodyCopy} component="span">
                    {t("workers-comp-page.verbiage-part-one")}
                  </Typography>
                  <Typography variant="body1" className={classes.inCalifornia} component="span">
                    {t("workers-comp-page.verbiage-part-two")}
                  </Typography>
                  <Typography variant="body1" className={classes.bodyCopy} component="span">
                    {t("workers-comp-page.verbiage-part-three")}
                  </Typography>
                </Grid>
                <FieldArray name={Fields.excludedIndividuals}>
                  {({ push, remove }) => {
                    return (
                      <Grid container item>
                        {formik.values[Fields.excludedIndividuals].map((unit, index) => {
                          return (
                            <Grid item xs={12} key={`excluded individual - ${unit.employeeNumber}`}>
                              {!unit.isEditing ? (
                                <ExcludedIndividualCard
                                  title={unit[ExcludedIndividualFields.title]}
                                  name={unit[ExcludedIndividualFields.name]}
                                  salary={unit[ExcludedIndividualFields.salary]}
                                  relationshipType={unit[ExcludedIndividualFields.relationshipType]}
                                  ownershipPercentage={unit[ExcludedIndividualFields.ownerPercentage]}
                                  onEdit={() => {
                                    formik.setFieldValue(
                                      Fields.excludedIndividuals,
                                      formik.values[Fields.excludedIndividuals].map((item, i) =>
                                        index === i ? { ...item, isEditing: true, id: item.id } : item,
                                      ),
                                    );
                                  }}
                                />
                              ) : (
                                <div className={classes.card}>
                                  <ExcludedIndividualForm
                                    title={unit[ExcludedIndividualFields.title]}
                                    name={unit[ExcludedIndividualFields.name]}
                                    relationshipType={unit[ExcludedIndividualFields.relationshipType]}
                                    ownerPercentage={unit[ExcludedIndividualFields.ownerPercentage]}
                                    employeeNumber={unit[ExcludedIndividualFields.employeeNumber]}
                                    salary={unit[ExcludedIndividualFields.salary]}
                                    onDelete={() => setShowExcludedIndividualConfirmationOverlay(index)}
                                    onSubmit={(values) => {
                                      const newExcludedIndividuals = formik.values[
                                        Fields.excludedIndividuals
                                      ].map((item, i) =>
                                        index === i ? { ...item, ...values, isEditing: false, id: item.id } : item,
                                      );
                                      formik.setFieldValue(Fields.excludedIndividuals, newExcludedIndividuals);
                                    }}
                                  />
                                  {showExcludedIndividualConfirmationOverlay === index && (
                                    <ComponentConfirmOverlay
                                      confirmText={t("common.delete")}
                                      confirmAction={() =>
                                        deleteExcludedIndividualConfirmation(() => {
                                          remove(index);
                                          deleteExcludedIndividual({ id: unit.id });
                                        })
                                      }
                                      cancelText={t("common.cancel-delete")}
                                      cancelAction={() => setShowExcludedIndividualConfirmationOverlay(null)}
                                    />
                                  )}
                                </div>
                              )}
                            </Grid>
                          );
                        })}
                        <Grid
                          onClick={() => {
                            const prevState = formik.values[Fields.excludedIndividuals];
                            push({
                              employeeNumber: prevState[prevState.length - 1]
                                ? prevState[prevState.length - 1].employeeNumber! + 1
                                : 1,
                              isEditing: true,
                            });
                          }}
                          alignItems="center"
                          className={classes.addNew}
                          container
                          direction="row"
                        >
                          <AddCircleRoundedIcon className={classes.addNewIcon} />
                          <Grid item>
                            <Typography className={classes.addNewCopy} variant="h3">
                              {t("excluded-individual-form.add-new-individual")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }}
                </FieldArray>

                <Grid item xs={12}>
                  <PayrollYesNoForm
                    excludedIndividual={formik.values[Fields.excludedIndividuals].length ? true : false}
                  />
                </Grid>
                <PrevNextNavBar
                  prevOnClick={() => {
                    application?.coverageFleet
                      ? history.push(routes.install.path)
                      : history.push(routes.businessInfo.path);
                  }}
                  prevAriaLabel={t("operations.previous-button-label")}
                  nextOnClick={() => formik.handleSubmit()}
                  nextDisabled={!formik.isValid || editApplicationCheck.isLoading}
                  isLoading={editApplicationCheck.isLoading}
                  nextButtonText={
                    application?.coverageFleet ||
                    trueOrFalseConversion(formik.values[PayrollYesNoFields.doesHaveReturnToWorkProgram])
                      ? t("workers-comp-page.next-button")
                      : t("workers-comp-page.next-button-review")
                  }
                />
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default WorkersCompPage;
