import { useAuth } from "@chq/authentication";
import { NextButton } from "@chq/components";
import { Button, CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import qs from "qs";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import BusinessUserForm, { Fields, FormProps, useFormikConfig } from "../../../components/business-user-form";
import RegistrationModal from "../../../components/registration-modal";
import { useCheckApplication, useCreateApplication, useGetApplication } from "../../../data/enrollment";
import { useSendResumeApplicationEmail } from "../../../data/useSendResumeApplicationLink";
import AlreadyStarted from "../../../ui/last-mile/components/modal-views/already-started";
import WelcomeBack from "../../../ui/last-mile/components/modal-views/welcome-back";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    color: theme.palette.error.main,
    marginBottom: 10,
  },
  button: {
    marginTop: 36,
    height: 56,
    width: "100%",
  },
  buttonContain: {
    width: "100%",
  },
  container: {
    paddingTop: "4rem",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "2rem",
    },
    margin: "auto",
    maxWidth: 500,
  },
  continueApplication: {
    background: theme.palette.grey[200],
    borderRadius: ".5rem",
    marginBottom: "2.5rem",
    padding: ".5rem 1rem",
  },
  continueApplicationButton: {
    background: theme.palette.common.white,
    padding: ".5rem 1.5rem",
  },
  pageButtons: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  title: {
    paddingBottom: "3.25rem",
  },
}));

const BusinessUserPage: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { pathname, search } = useLocation();
  const {
    mutate: checkApplication,
    isLoading: isCheckingForExistingApplication,
    ...applicationCheck
  } = useCheckApplication();

  const history = useHistory();
  const {
    isAuthenticated,
    loginWithEmail: [{ loading: isAuthenticating }, loginWithEmail],
  } = useAuth();
  const { data: application } = useGetApplication({ enabled: isAuthenticated });
  const { mutate: sendResumeApplicationEmail } = useSendResumeApplicationEmail();
  const { mutate: createApplicationRequest, isLoading: isCreatingApplication } = useCreateApplication();
  const params = useMemo(() => qs.parse(search.slice(1)), [search]);

  const [openModal, setOpenModal] = useState<boolean>(Boolean(applicationCheck.data?.data?.doesApplicationExist));
  const [openContinueApp, setOpenContinueApp] = useState<boolean>(false);
  const [openWelcomeBack, setOpenWelcomeBack] = useState<boolean>(false);

  const beginContinueApp = () => {
    setOpenModal(true);
    setOpenContinueApp(true);
  };

  const beginWelcomeBack = () => {
    setOpenModal(true);
    setOpenWelcomeBack(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setOpenContinueApp(false);
    setOpenWelcomeBack(false);
  };

  const formikProps = useFormikConfig({
    email: application?.contactEmail || (params.contactEmail as string) || undefined,
    firstName: application?.contactFirstName || (params.contactFirstName as string) || undefined,
    lastName: application?.contactLastName || (params.contactLastName as string) || undefined,
    phone: application?.contactPhone || (params.contactPhone as string) || undefined,
  });

  useEffect(() => {
    const hasFormValues = Object.keys(params).length > 0;
    if (isAuthenticated && hasFormValues && !isCreatingApplication) {
      createApplicationRequest(
        {
          contactEmail: params.contactEmail as string,
          contactFirstName: params.contactFirstName as string,
          contactLastName: params.contactLastName as string,
          contactPhone: params.contactPhone as string,
          applicationState: RouteNames.coverages,
        },
        {
          onSuccess: () => history.push(routes.coverages.path),
          onError: () => history.push(routes.issue.path),
        },
      );
    }
  }, [createApplicationRequest, history, isAuthenticated, isCreatingApplication, params, search]);

  return (
    <PageWrapper>
      {openContinueApp && (
        <RegistrationModal open={openModal} onClose={() => closeModal()}>
          <AlreadyStarted onClose={() => closeModal()} />
        </RegistrationModal>
      )}
      {openWelcomeBack && (
        <RegistrationModal open={openModal} onClose={() => closeModal()}>
          <WelcomeBack onClose={() => closeModal()} />
        </RegistrationModal>
      )}
      <Formik
        {...formikProps}
        onSubmit={(values: FormProps) => {
          checkApplication(
            {
              emailAddress: values[Fields.email],
            },
            {
              onSuccess: (response) => {
                if (response.data?.doesApplicationExist) {
                  beginWelcomeBack();
                  sendResumeApplicationEmail(
                    { emailAddress: values[Fields.email] },
                    {
                      onSuccess: () => {
                        setOpenModal(true);
                      },
                    },
                  );
                } else {
                  const returnUrl =
                    pathname +
                    qs.stringify(
                      {
                        contactEmail: values[Fields.email],
                        contactFirstName: values[Fields.firstName],
                        contactLastName: values[Fields.lastName],
                        contactPhone: values[Fields.phone],
                      },
                      { addQueryPrefix: true },
                    );
                  loginWithEmail(values[Fields.email], returnUrl);
                }
              },
            },
          );
          if (window.LO && window.LO.visitor) {
            window.LO?.visitor.identify(values[Fields.email] || "", {
              email: values[Fields.email],
              phone: values[Fields.phone],
            });
          }
        }}
      >
        {(formik) => (
          <Grid container>
            <Grid container item direction="column">
              <Grid item>
                <Grid container alignItems="center" justify="space-between" className={classes.continueApplication}>
                  <Grid item>
                    <Typography variant="body2">{t("business-user-page.continue-application.main")}</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => beginContinueApp()}
                      variant="outlined"
                      color="primary"
                      className={classes.continueApplicationButton}
                    >
                      {t("business-user-page.continue-application.button")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h1" align="left" className={classes.title}>
                  {t("business-user-page.title.label")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="row">
              <Grid item xs={12}>
                <BusinessUserForm />
              </Grid>
              <Grid container item direction="row" className={classes.pageButtons} spacing={2} xs={12}>
                <Grid item xs={10}>
                  <NextButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={
                      !formik.isValid || isCheckingForExistingApplication || isAuthenticating || isCreatingApplication
                    }
                    onClick={() => formik.handleSubmit()}
                  >
                    {isAuthenticating || isCheckingForExistingApplication || isCreatingApplication ? (
                      <CircularProgress color="inherit" size="2rem" />
                    ) : (
                      t("business-user-page.next-button.label")
                    )}
                  </NextButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default BusinessUserPage;
