import { Accordion, Checkbox, DownloadIcon, TextInput } from "@chq/components";
import { CircularProgress, Divider, Grid, IconButton, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import dateFnsFormat from "date-fns/format";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    marginLeft: "2rem",
    marginTop: "-0.5rem",
    marginRight: "0.5rem",
    "& .MuiTypography-body1": {
      margin: ".5rem 0",
    },
    "& .MuiAccordionSummary-root": {
      backgroundColor: theme.palette.divider,
      marginTop: "1rem",
      minHeight: "2.5rem",
    },
    "& .MuiGrid-item": {
      marginTop: "0",
    },
    "& .MuiAccordionDetails-root": {
      padding: "0.5rem",
    },
    "& .MuiSvgIcon-root": {
      height: "2rem",
    },
  },
  checkbox: {
    marginBottom: ".5rem",
    "& .MuiFormControlLabel-root": {
      alignItems: "self-start",
    },
    "& .MuiFormControlLabel-label": {
      paddingTop: "0.5rem",
    },
    "& a.MuiIconButton-root": {
      margin: "-0.5rem",
      marginRight: "0",
    },
  },
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
  icon: {
    height: "40px",
    width: "auto",
  },
  iconButton: {
    padding: "0",
  },
  heading: {
    marginBottom: "1.5rem",
  },
  nameDateContain: {
    margin: "1.5rem auto 0",
    maxWidth: 500,
  },
  subheading: {
    fontWeight: 700,
    marginBottom: "1rem",
  },
  signatureBlock: {
    fontStyle: "italic",
  },
}));

export enum Fields {
  eConsent = "e-consent",
  legalAuthority = "legal-authority",
  lossRuns = "loss-runs",
  routineInquiry = "routineInquiry",
  name = "name",
  date = "date",
}

type Props = ConditionalProps & {
  eConsent?: boolean;
  legalAuthority?: boolean;
  lossRuns?: boolean;
  routineInquiry?: boolean;
  name?: string;
  date?: Date;
  signEDeliveryConsent?: () => void;
  eConsentSignedDownload?: () => void;
  deleteDocument?: () => void;
};

type ConditionalProps = {
  voluntaryEConsentState?: boolean;
  signingIsLoading?: boolean;
};

export type FormProps = {
  [Fields.eConsent]: boolean;
  [Fields.legalAuthority]: boolean;
  [Fields.lossRuns]: boolean;
  [Fields.routineInquiry]: boolean;
  [Fields.name]: string;
  [Fields.date]: Date | undefined;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useValidationSchema = (voluntaryEConsentState: boolean) => {
  const [t] = useTranslation();

  return yup.object({
    [Fields.eConsent]: voluntaryEConsentState
      ? yup.boolean()
      : yup
          .boolean()
          .oneOf(
            [true],
            t("errors.must-be-checked", {
              field: t("review-application-page.e-consent"),
            }),
          )
          .required(t("errors.required", { field: t("review-application-page.e-consent") })),
    [Fields.lossRuns]: yup
      .boolean()
      .oneOf(
        [true],
        t("errors.must-be-checked", {
          field: t("review-application-page.loss-runs"),
        }),
      )
      .required(t("errors.required", { field: t("review-application-page.loss-runs") })),
    [Fields.routineInquiry]: yup
      .boolean()
      .oneOf(
        [true],
        t("errors.must-be-checked", {
          field: t("review-application-page.routine-inquiry"),
        }),
      )
      .required(t("errors.required", { field: t("review-application-page.routine-inquiry") })),
    [Fields.name]: yup
      .string()
      .matches(/^[^0-9]+$/, t("review-application-page.name-letters-only"))
      .required(t("errors.required", { field: t("review-application-page.name") })),
    [Fields.legalAuthority]: yup
      .boolean()
      .oneOf(
        [true],
        t("errors.must-be-checked", {
          field: t("review-application-page.legal-authority"),
        }),
      )
      .required(t("errors.required", { field: t("review-application-page.legal-authority") })),
  });
};

export const useFormikConfig = ({
  eConsent: initialEConsent = false,
  lossRuns: initialLossRuns = false,
  routineInquiry: initialRoutineInquiry = false,
  name: initialName = "",
  date: initialDate,
  legalAuthority: initialLegalAuthority = false,
  voluntaryEConsentState = false,
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema(voluntaryEConsentState);
  return {
    initialValues: {
      [Fields.eConsent]: initialEConsent,
      [Fields.lossRuns]: initialLossRuns,
      [Fields.routineInquiry]: initialRoutineInquiry,
      [Fields.name]: initialName,
      [Fields.date]: initialDate,
      [Fields.legalAuthority]: initialLegalAuthority,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const ReviewAgreementsForm: React.FC<Props> = ({
  voluntaryEConsentState,
  signEDeliveryConsent,
  eConsentSignedDownload,
  signingIsLoading,
  deleteDocument,
}) => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  const eConsentUnsignedURL = "/templates/LMDElectronicDisclosureandConsentForm.pdf";

  const stateReqs = () => {
    const sr = [];
    const numReqs = 9;

    for (let i = 0; i < numReqs; i++) {
      sr.push({
        header: t(`review-application-page.state-specific.accordion.${i}.header`),
        content: t(`review-application-page.state-specific.accordion.${i}.content`),
      });
    }
    return sr;
  };
  const formattedToday = dateFnsFormat(new Date(), "MM/dd/yyyy");

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography color="primary" variant="h2" align="center" className={classes.heading}>
          {t("review-application-page.complete-form")}
        </Typography>
        <Typography className={classes.subheading} align="center">
          {t("review-application-page.submit-app")}
        </Typography>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.eConsent}
            name={Fields.eConsent}
            label={
              <Grid>
                {signingIsLoading ? (
                  <Grid>
                    <CircularProgress color="inherit" size="2rem" />
                  </Grid>
                ) : (
                  <Grid>
                    {formik.values[Fields.eConsent] ? (
                      <label>
                        <IconButton
                          aria-label={t(`review-application-page.e-consent-download-icon`)}
                          className={classes.iconButton}
                          onClick={eConsentSignedDownload}
                        >
                          <DownloadIcon color="disabled" className={classes.icon} />
                        </IconButton>
                        {t("review-application-page.e-consent-checked-1")}
                        <Link onClick={eConsentSignedDownload}>
                          <u>{t("review-application-page.e-consent-link")}</u>
                        </Link>
                        {t("review-application-page.e-consent-checked-2")}
                      </label>
                    ) : (
                      <label>
                        {voluntaryEConsentState
                          ? t("review-application-page.non-e-consent-state-unchecked-1")
                          : t("review-application-page.e-consent-unchecked-1")}
                        <Link download={true} href={eConsentUnsignedURL}>
                          <u>{t("review-application-page.e-consent-link")}</u>
                        </Link>
                        {voluntaryEConsentState
                          ? t("review-application-page.non-e-consent-state-unchecked-2")
                          : t("review-application-page.e-consent-unchecked-2")}
                      </label>
                    )}
                  </Grid>
                )}
              </Grid>
            }
            onChange={(changeEvent) => {
              if (changeEvent.target.checked) {
                signEDeliveryConsent && signEDeliveryConsent();
              } else {
                deleteDocument && deleteDocument();
              }
              formik.handleChange(changeEvent);
            }}
            onBlur={formik.handleBlur}
            value="on"
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.lossRuns}
            name={Fields.lossRuns}
            label={t("review-application-page.loss-runs")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.lossRuns]}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.routineInquiry}
            name={Fields.routineInquiry}
            label={t("review-application-page.routine-inquiry")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.routineInquiry]}
          />
        </Grid>
        <Grid item className={classes.checkbox}>
          <Checkbox
            id={Fields.legalAuthority}
            name={Fields.legalAuthority}
            label={t("review-application-page.legal-authority")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[Fields.legalAuthority]}
          />
          <Grid item className={classes.accordion} xs={12}>
            <Accordion titleText={t("review-application-page.state-specific.title")} titleVariant="body1">
              <Grid container>
                {stateReqs().map((item, index) => (
                  <Grid key={index}>
                    <Typography variant="body1">
                      <strong>
                        <u>{item.header}</u>
                      </strong>
                      <br />
                      {item.content}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Accordion>
          </Grid>
        </Grid>
      </form>
      <Divider className={classes.divider} />
      <Grid container direction="column">
        <Typography variant="body1" className={classes.signatureBlock}>
          {t("review-application-page.signature-block")}
        </Typography>
        <Grid container item spacing={1} className={classes.nameDateContain}>
          <Grid item xs={7}>
            <TextInput
              required
              fullWidth
              label={t("review-application-page.name")}
              id={Fields.name}
              name={Fields.name}
              value={formik.values[Fields.name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.name] && Boolean(formik.errors[Fields.name])}
              helperText={formik.touched[Fields.name] && formik.errors[Fields.name]}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              required
              fullWidth
              label={t("review-application-page.date")}
              id={Fields.date}
              name={Fields.date}
              value={formattedToday}
              onBlur={formik.handleBlur}
              error={formik.touched[Fields.date] && Boolean(formik.errors[Fields.date])}
              helperText={formik.touched[Fields.date] && formik.errors[Fields.date]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewAgreementsForm;
