/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyType,
  CompanyTypeFromJSON,
  CompanyTypeFromJSONTyped,
  CompanyTypeToJSON,
  Document,
  DocumentFromJSON,
  DocumentFromJSONTyped,
  DocumentToJSON,
  ExcludedIndividual,
  ExcludedIndividualFromJSON,
  ExcludedIndividualFromJSONTyped,
  ExcludedIndividualToJSON,
  LogisticsProvider,
  LogisticsProviderFromJSON,
  LogisticsProviderFromJSONTyped,
  LogisticsProviderToJSON,
  OperationState,
  OperationStateFromJSON,
  OperationStateFromJSONTyped,
  OperationStateToJSON,
  Partner,
  PartnerFromJSON,
  PartnerFromJSONTyped,
  PartnerToJSON,
  PhoneType,
  PhoneTypeFromJSON,
  PhoneTypeFromJSONTyped,
  PhoneTypeToJSON,
  State,
  StateFromJSON,
  StateFromJSONTyped,
  StateToJSON,
  Terminal,
  TerminalFromJSON,
  TerminalFromJSONTyped,
  TerminalToJSON,
} from "./";

/**
 *
 * @export
 * @interface Application
 */
export interface Application {
  /**
   *
   * @type {number}
   * @memberof Application
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  applicationState?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  contactFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  contactLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  contactEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  contactPhone?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Application
   */
  effectiveDate?: Date | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  numberOfVehicles?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  yearsInBusiness?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  fleetAnnualMiles?: number | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  logisticsProvider?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  coverageFleet?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  coverageWorkersComp?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  businessName?: string | null;
  /**
   *
   * @type {CompanyType}
   * @memberof Application
   */
  companyType?: CompanyType;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  dotNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  mcNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  einNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  physicalAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  physicalCity?: string | null;
  /**
   *
   * @type {State}
   * @memberof Application
   */
  physicalState?: State;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  physicalZip?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  mailingSameAsPhysicalAddress?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  mailingAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  mailingCity?: string | null;
  /**
   *
   * @type {State}
   * @memberof Application
   */
  mailingState?: State;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  mailingZip?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  ownerName?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  ownerEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  ownerPhone?: string | null;
  /**
   *
   * @type {PhoneType}
   * @memberof Application
   */
  phoneType?: PhoneType;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isOrHaveSubsidiaries?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  subsidiaryInfo?: string | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  yearsContinousExperience?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  hasNonLMDOperations?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  otherOperationsDescription?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isMaintainingForTwelveMonths?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  hasWrittenVehicleMaintenance?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  hasOtherVehicles?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  hasEmployeesUseTheirAutos?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  employeesUsingTheirAutosDescription?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  hasEmployeeInsuranceOfTheirAutos?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  numberOfEmployeesUsingTheirAutos?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  filingSameAsPhysicalAddress?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  filingStreet?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  filingCity?: string | null;
  /**
   *
   * @type {State}
   * @memberof Application
   */
  filingState?: State;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  filingZip?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  newDriverMvr?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  reviewMvrAnnually?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  preHireDrugScreening?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  randomDrugScreening?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  postAccidentDrugScreening?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  applicationScreeningProcess?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  employeeLeasing?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  twoYearsDrivingExperienceRequired?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  utilizeIndependentContractors?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  hireDayCasualLabor?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesPerformOnSiteAssemblyOfProducts?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  percentOfProductAssemblyIsDailyDeliveries?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesAssembleExibitsAndOrDisplays?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesAssembleFurniture?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesAssembleElectronics?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesAssembleExerciseEquipment?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesAssembleOtherProducts?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  otherProductsAssembled?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesPerformOnsiteAssemblyOfAppliances?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  percentOfApplianceAssemblyIsDailyDeliveries?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  waterIsDailyDelivery?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  gasIsDailyDelivery?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  noDailyDeliveries?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesPerformInstallsRequiringContractor?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isLicensedContractor?: boolean | null;
  /**
   *
   * @type {Document}
   * @memberof Application
   */
  vehicleListDocument?: Document;
  /**
   *
   * @type {Document}
   * @memberof Application
   */
  driverListDocument?: Document;
  /**
   *
   * @type {Document}
   * @memberof Application
   */
  lossRunsDocument?: Document;
  /**
   *
   * @type {Document}
   * @memberof Application
   */
  returnToWorkDocument?: Document;
  /**
   *
   * @type {Document}
   * @memberof Application
   */
  electronicConsentRequirementsDocument?: Document;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  uploadList?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  driverPayrollProjectedYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  driverPayrollCurrentYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  driverPayrollPriorYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  clericalPayrollProjectedYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  clericalPayrollCurrentYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  clericalPayrollPriorYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  otherPayrollProjectedYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  otherPayrollCurrentYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  otherPayrollPriorYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  premiumsProjectedYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  premiumsCurrentYear?: number | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  premiumsPriorYear?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isExcludedSalaryIncludedInPayroll?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isGroupMedicalProvided?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Application
   */
  percentOfEmployeesEnrolledInMedicalPlan?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isFormalAccidentReportingInPlace?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesHaveReturnToWorkProgram?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  isWillingToCreateVanlinerAssistance?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  doesUtilizeIndependentContractors?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  willProvideCoverageUnderWorkersCompPolicy?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  maintainCertificatesOfInsuranceForCoverage?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  agreeLossRun?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  agreeFairCreditReporting?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  stateRequirements?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  electronicConsentRequirements?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  submissionName?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Application
   */
  submissionDate?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  agreeTerms?: boolean | null;
  /**
   *
   * @type {Array<Partner>}
   * @memberof Application
   */
  partners?: Array<Partner> | null;
  /**
   *
   * @type {Array<ExcludedIndividual>}
   * @memberof Application
   */
  excludedIndividuals?: Array<ExcludedIndividual> | null;
  /**
   *
   * @type {Array<LogisticsProvider>}
   * @memberof Application
   */
  logisticsProviders?: Array<LogisticsProvider> | null;
  /**
   *
   * @type {Array<Terminal>}
   * @memberof Application
   */
  terminals?: Array<Terminal> | null;
  /**
   *
   * @type {Array<OperationState>}
   * @memberof Application
   */
  operationStates?: Array<OperationState> | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly effectiveDateFormatted?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly fleetCoverageDisplay?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly workersCompDisplay?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly itemsAssembled?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly appliancesAssembled?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly partnerValues?: string | null;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  readonly excludedIndividualValues?: string | null;
}

export function ApplicationFromJSON(json: any): Application {
  return ApplicationFromJSONTyped(json, false);
}

export function ApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Application {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    applicationState: !exists(json, "applicationState") ? undefined : json["applicationState"],
    contactFirstName: !exists(json, "contactFirstName") ? undefined : json["contactFirstName"],
    contactLastName: !exists(json, "contactLastName") ? undefined : json["contactLastName"],
    contactEmail: !exists(json, "contactEmail") ? undefined : json["contactEmail"],
    contactPhone: !exists(json, "contactPhone") ? undefined : json["contactPhone"],
    effectiveDate: !exists(json, "effectiveDate")
      ? undefined
      : json["effectiveDate"] === null
      ? null
      : new Date(json["effectiveDate"]),
    numberOfVehicles: !exists(json, "numberOfVehicles") ? undefined : json["numberOfVehicles"],
    yearsInBusiness: !exists(json, "yearsInBusiness") ? undefined : json["yearsInBusiness"],
    fleetAnnualMiles: !exists(json, "fleetAnnualMiles") ? undefined : json["fleetAnnualMiles"],
    logisticsProvider: !exists(json, "logisticsProvider") ? undefined : json["logisticsProvider"],
    coverageFleet: !exists(json, "coverageFleet") ? undefined : json["coverageFleet"],
    coverageWorkersComp: !exists(json, "coverageWorkersComp") ? undefined : json["coverageWorkersComp"],
    businessName: !exists(json, "businessName") ? undefined : json["businessName"],
    companyType: !exists(json, "companyType") ? undefined : CompanyTypeFromJSON(json["companyType"]),
    dotNumber: !exists(json, "dotNumber") ? undefined : json["dotNumber"],
    mcNumber: !exists(json, "mcNumber") ? undefined : json["mcNumber"],
    einNumber: !exists(json, "einNumber") ? undefined : json["einNumber"],
    physicalAddress: !exists(json, "physicalAddress") ? undefined : json["physicalAddress"],
    physicalCity: !exists(json, "physicalCity") ? undefined : json["physicalCity"],
    physicalState: !exists(json, "physicalState") ? undefined : StateFromJSON(json["physicalState"]),
    physicalZip: !exists(json, "physicalZip") ? undefined : json["physicalZip"],
    mailingSameAsPhysicalAddress: !exists(json, "mailingSameAsPhysicalAddress")
      ? undefined
      : json["mailingSameAsPhysicalAddress"],
    mailingAddress: !exists(json, "mailingAddress") ? undefined : json["mailingAddress"],
    mailingCity: !exists(json, "mailingCity") ? undefined : json["mailingCity"],
    mailingState: !exists(json, "mailingState") ? undefined : StateFromJSON(json["mailingState"]),
    mailingZip: !exists(json, "mailingZip") ? undefined : json["mailingZip"],
    ownerName: !exists(json, "ownerName") ? undefined : json["ownerName"],
    ownerEmail: !exists(json, "ownerEmail") ? undefined : json["ownerEmail"],
    ownerPhone: !exists(json, "ownerPhone") ? undefined : json["ownerPhone"],
    phoneType: !exists(json, "phoneType") ? undefined : PhoneTypeFromJSON(json["phoneType"]),
    isOrHaveSubsidiaries: !exists(json, "isOrHaveSubsidiaries") ? undefined : json["isOrHaveSubsidiaries"],
    subsidiaryInfo: !exists(json, "subsidiaryInfo") ? undefined : json["subsidiaryInfo"],
    yearsContinousExperience: !exists(json, "yearsContinousExperience") ? undefined : json["yearsContinousExperience"],
    hasNonLMDOperations: !exists(json, "hasNonLMDOperations") ? undefined : json["hasNonLMDOperations"],
    otherOperationsDescription: !exists(json, "otherOperationsDescription")
      ? undefined
      : json["otherOperationsDescription"],
    isMaintainingForTwelveMonths: !exists(json, "isMaintainingForTwelveMonths")
      ? undefined
      : json["isMaintainingForTwelveMonths"],
    hasWrittenVehicleMaintenance: !exists(json, "hasWrittenVehicleMaintenance")
      ? undefined
      : json["hasWrittenVehicleMaintenance"],
    hasOtherVehicles: !exists(json, "hasOtherVehicles") ? undefined : json["hasOtherVehicles"],
    hasEmployeesUseTheirAutos: !exists(json, "hasEmployeesUseTheirAutos")
      ? undefined
      : json["hasEmployeesUseTheirAutos"],
    employeesUsingTheirAutosDescription: !exists(json, "employeesUsingTheirAutosDescription")
      ? undefined
      : json["employeesUsingTheirAutosDescription"],
    hasEmployeeInsuranceOfTheirAutos: !exists(json, "hasEmployeeInsuranceOfTheirAutos")
      ? undefined
      : json["hasEmployeeInsuranceOfTheirAutos"],
    numberOfEmployeesUsingTheirAutos: !exists(json, "numberOfEmployeesUsingTheirAutos")
      ? undefined
      : json["numberOfEmployeesUsingTheirAutos"],
    filingSameAsPhysicalAddress: !exists(json, "filingSameAsPhysicalAddress")
      ? undefined
      : json["filingSameAsPhysicalAddress"],
    filingStreet: !exists(json, "filingStreet") ? undefined : json["filingStreet"],
    filingCity: !exists(json, "filingCity") ? undefined : json["filingCity"],
    filingState: !exists(json, "filingState") ? undefined : StateFromJSON(json["filingState"]),
    filingZip: !exists(json, "filingZip") ? undefined : json["filingZip"],
    newDriverMvr: !exists(json, "newDriverMvr") ? undefined : json["newDriverMvr"],
    reviewMvrAnnually: !exists(json, "reviewMvrAnnually") ? undefined : json["reviewMvrAnnually"],
    preHireDrugScreening: !exists(json, "preHireDrugScreening") ? undefined : json["preHireDrugScreening"],
    randomDrugScreening: !exists(json, "randomDrugScreening") ? undefined : json["randomDrugScreening"],
    postAccidentDrugScreening: !exists(json, "postAccidentDrugScreening")
      ? undefined
      : json["postAccidentDrugScreening"],
    applicationScreeningProcess: !exists(json, "applicationScreeningProcess")
      ? undefined
      : json["applicationScreeningProcess"],
    employeeLeasing: !exists(json, "employeeLeasing") ? undefined : json["employeeLeasing"],
    twoYearsDrivingExperienceRequired: !exists(json, "twoYearsDrivingExperienceRequired")
      ? undefined
      : json["twoYearsDrivingExperienceRequired"],
    utilizeIndependentContractors: !exists(json, "utilizeIndependentContractors")
      ? undefined
      : json["utilizeIndependentContractors"],
    hireDayCasualLabor: !exists(json, "hireDayCasualLabor") ? undefined : json["hireDayCasualLabor"],
    doesPerformOnSiteAssemblyOfProducts: !exists(json, "doesPerformOnSiteAssemblyOfProducts")
      ? undefined
      : json["doesPerformOnSiteAssemblyOfProducts"],
    percentOfProductAssemblyIsDailyDeliveries: !exists(json, "percentOfProductAssemblyIsDailyDeliveries")
      ? undefined
      : json["percentOfProductAssemblyIsDailyDeliveries"],
    doesAssembleExibitsAndOrDisplays: !exists(json, "doesAssembleExibitsAndOrDisplays")
      ? undefined
      : json["doesAssembleExibitsAndOrDisplays"],
    doesAssembleFurniture: !exists(json, "doesAssembleFurniture") ? undefined : json["doesAssembleFurniture"],
    doesAssembleElectronics: !exists(json, "doesAssembleElectronics") ? undefined : json["doesAssembleElectronics"],
    doesAssembleExerciseEquipment: !exists(json, "doesAssembleExerciseEquipment")
      ? undefined
      : json["doesAssembleExerciseEquipment"],
    doesAssembleOtherProducts: !exists(json, "doesAssembleOtherProducts")
      ? undefined
      : json["doesAssembleOtherProducts"],
    otherProductsAssembled: !exists(json, "otherProductsAssembled") ? undefined : json["otherProductsAssembled"],
    doesPerformOnsiteAssemblyOfAppliances: !exists(json, "doesPerformOnsiteAssemblyOfAppliances")
      ? undefined
      : json["doesPerformOnsiteAssemblyOfAppliances"],
    percentOfApplianceAssemblyIsDailyDeliveries: !exists(json, "percentOfApplianceAssemblyIsDailyDeliveries")
      ? undefined
      : json["percentOfApplianceAssemblyIsDailyDeliveries"],
    waterIsDailyDelivery: !exists(json, "waterIsDailyDelivery") ? undefined : json["waterIsDailyDelivery"],
    gasIsDailyDelivery: !exists(json, "gasIsDailyDelivery") ? undefined : json["gasIsDailyDelivery"],
    noDailyDeliveries: !exists(json, "noDailyDeliveries") ? undefined : json["noDailyDeliveries"],
    doesPerformInstallsRequiringContractor: !exists(json, "doesPerformInstallsRequiringContractor")
      ? undefined
      : json["doesPerformInstallsRequiringContractor"],
    isLicensedContractor: !exists(json, "isLicensedContractor") ? undefined : json["isLicensedContractor"],
    vehicleListDocument: !exists(json, "vehicleListDocument")
      ? undefined
      : DocumentFromJSON(json["vehicleListDocument"]),
    driverListDocument: !exists(json, "driverListDocument") ? undefined : DocumentFromJSON(json["driverListDocument"]),
    lossRunsDocument: !exists(json, "lossRunsDocument") ? undefined : DocumentFromJSON(json["lossRunsDocument"]),
    returnToWorkDocument: !exists(json, "returnToWorkDocument")
      ? undefined
      : DocumentFromJSON(json["returnToWorkDocument"]),
    electronicConsentRequirementsDocument: !exists(json, "electronicConsentRequirementsDocument")
      ? undefined
      : DocumentFromJSON(json["electronicConsentRequirementsDocument"]),
    uploadList: !exists(json, "uploadList") ? undefined : json["uploadList"],
    driverPayrollProjectedYear: !exists(json, "driverPayrollProjectedYear")
      ? undefined
      : json["driverPayrollProjectedYear"],
    driverPayrollCurrentYear: !exists(json, "driverPayrollCurrentYear") ? undefined : json["driverPayrollCurrentYear"],
    driverPayrollPriorYear: !exists(json, "driverPayrollPriorYear") ? undefined : json["driverPayrollPriorYear"],
    clericalPayrollProjectedYear: !exists(json, "clericalPayrollProjectedYear")
      ? undefined
      : json["clericalPayrollProjectedYear"],
    clericalPayrollCurrentYear: !exists(json, "clericalPayrollCurrentYear")
      ? undefined
      : json["clericalPayrollCurrentYear"],
    clericalPayrollPriorYear: !exists(json, "clericalPayrollPriorYear") ? undefined : json["clericalPayrollPriorYear"],
    otherPayrollProjectedYear: !exists(json, "otherPayrollProjectedYear")
      ? undefined
      : json["otherPayrollProjectedYear"],
    otherPayrollCurrentYear: !exists(json, "otherPayrollCurrentYear") ? undefined : json["otherPayrollCurrentYear"],
    otherPayrollPriorYear: !exists(json, "otherPayrollPriorYear") ? undefined : json["otherPayrollPriorYear"],
    premiumsProjectedYear: !exists(json, "premiumsProjectedYear") ? undefined : json["premiumsProjectedYear"],
    premiumsCurrentYear: !exists(json, "premiumsCurrentYear") ? undefined : json["premiumsCurrentYear"],
    premiumsPriorYear: !exists(json, "premiumsPriorYear") ? undefined : json["premiumsPriorYear"],
    isExcludedSalaryIncludedInPayroll: !exists(json, "isExcludedSalaryIncludedInPayroll")
      ? undefined
      : json["isExcludedSalaryIncludedInPayroll"],
    isGroupMedicalProvided: !exists(json, "isGroupMedicalProvided") ? undefined : json["isGroupMedicalProvided"],
    percentOfEmployeesEnrolledInMedicalPlan: !exists(json, "percentOfEmployeesEnrolledInMedicalPlan")
      ? undefined
      : json["percentOfEmployeesEnrolledInMedicalPlan"],
    isFormalAccidentReportingInPlace: !exists(json, "isFormalAccidentReportingInPlace")
      ? undefined
      : json["isFormalAccidentReportingInPlace"],
    doesHaveReturnToWorkProgram: !exists(json, "doesHaveReturnToWorkProgram")
      ? undefined
      : json["doesHaveReturnToWorkProgram"],
    isWillingToCreateVanlinerAssistance: !exists(json, "isWillingToCreateVanlinerAssistance")
      ? undefined
      : json["isWillingToCreateVanlinerAssistance"],
    doesUtilizeIndependentContractors: !exists(json, "doesUtilizeIndependentContractors")
      ? undefined
      : json["doesUtilizeIndependentContractors"],
    willProvideCoverageUnderWorkersCompPolicy: !exists(json, "willProvideCoverageUnderWorkersCompPolicy")
      ? undefined
      : json["willProvideCoverageUnderWorkersCompPolicy"],
    maintainCertificatesOfInsuranceForCoverage: !exists(json, "maintainCertificatesOfInsuranceForCoverage")
      ? undefined
      : json["maintainCertificatesOfInsuranceForCoverage"],
    agreeLossRun: !exists(json, "agreeLossRun") ? undefined : json["agreeLossRun"],
    agreeFairCreditReporting: !exists(json, "agreeFairCreditReporting") ? undefined : json["agreeFairCreditReporting"],
    stateRequirements: !exists(json, "stateRequirements") ? undefined : json["stateRequirements"],
    electronicConsentRequirements: !exists(json, "electronicConsentRequirements")
      ? undefined
      : json["electronicConsentRequirements"],
    submissionName: !exists(json, "submissionName") ? undefined : json["submissionName"],
    submissionDate: !exists(json, "submissionDate")
      ? undefined
      : json["submissionDate"] === null
      ? null
      : new Date(json["submissionDate"]),
    agreeTerms: !exists(json, "agreeTerms") ? undefined : json["agreeTerms"],
    partners: !exists(json, "partners")
      ? undefined
      : json["partners"] === null
      ? null
      : (json["partners"] as Array<any>).map(PartnerFromJSON),
    excludedIndividuals: !exists(json, "excludedIndividuals")
      ? undefined
      : json["excludedIndividuals"] === null
      ? null
      : (json["excludedIndividuals"] as Array<any>).map(ExcludedIndividualFromJSON),
    logisticsProviders: !exists(json, "logisticsProviders")
      ? undefined
      : json["logisticsProviders"] === null
      ? null
      : (json["logisticsProviders"] as Array<any>).map(LogisticsProviderFromJSON),
    terminals: !exists(json, "terminals")
      ? undefined
      : json["terminals"] === null
      ? null
      : (json["terminals"] as Array<any>).map(TerminalFromJSON),
    operationStates: !exists(json, "operationStates")
      ? undefined
      : json["operationStates"] === null
      ? null
      : (json["operationStates"] as Array<any>).map(OperationStateFromJSON),
    effectiveDateFormatted: !exists(json, "effectiveDateFormatted") ? undefined : json["effectiveDateFormatted"],
    fleetCoverageDisplay: !exists(json, "fleetCoverageDisplay") ? undefined : json["fleetCoverageDisplay"],
    workersCompDisplay: !exists(json, "workersCompDisplay") ? undefined : json["workersCompDisplay"],
    itemsAssembled: !exists(json, "itemsAssembled") ? undefined : json["itemsAssembled"],
    appliancesAssembled: !exists(json, "appliancesAssembled") ? undefined : json["appliancesAssembled"],
    partnerValues: !exists(json, "partnerValues") ? undefined : json["partnerValues"],
    excludedIndividualValues: !exists(json, "excludedIndividualValues") ? undefined : json["excludedIndividualValues"],
  };
}

export function ApplicationToJSON(value?: Application | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    applicationState: value.applicationState,
    contactFirstName: value.contactFirstName,
    contactLastName: value.contactLastName,
    contactEmail: value.contactEmail,
    contactPhone: value.contactPhone,
    effectiveDate:
      value.effectiveDate === undefined
        ? undefined
        : value.effectiveDate === null
        ? null
        : value.effectiveDate.toISOString(),
    numberOfVehicles: value.numberOfVehicles,
    yearsInBusiness: value.yearsInBusiness,
    fleetAnnualMiles: value.fleetAnnualMiles,
    logisticsProvider: value.logisticsProvider,
    coverageFleet: value.coverageFleet,
    coverageWorkersComp: value.coverageWorkersComp,
    businessName: value.businessName,
    companyType: CompanyTypeToJSON(value.companyType),
    dotNumber: value.dotNumber,
    mcNumber: value.mcNumber,
    einNumber: value.einNumber,
    physicalAddress: value.physicalAddress,
    physicalCity: value.physicalCity,
    physicalState: StateToJSON(value.physicalState),
    physicalZip: value.physicalZip,
    mailingSameAsPhysicalAddress: value.mailingSameAsPhysicalAddress,
    mailingAddress: value.mailingAddress,
    mailingCity: value.mailingCity,
    mailingState: StateToJSON(value.mailingState),
    mailingZip: value.mailingZip,
    ownerName: value.ownerName,
    ownerEmail: value.ownerEmail,
    ownerPhone: value.ownerPhone,
    phoneType: PhoneTypeToJSON(value.phoneType),
    isOrHaveSubsidiaries: value.isOrHaveSubsidiaries,
    subsidiaryInfo: value.subsidiaryInfo,
    yearsContinousExperience: value.yearsContinousExperience,
    hasNonLMDOperations: value.hasNonLMDOperations,
    otherOperationsDescription: value.otherOperationsDescription,
    isMaintainingForTwelveMonths: value.isMaintainingForTwelveMonths,
    hasWrittenVehicleMaintenance: value.hasWrittenVehicleMaintenance,
    hasOtherVehicles: value.hasOtherVehicles,
    hasEmployeesUseTheirAutos: value.hasEmployeesUseTheirAutos,
    employeesUsingTheirAutosDescription: value.employeesUsingTheirAutosDescription,
    hasEmployeeInsuranceOfTheirAutos: value.hasEmployeeInsuranceOfTheirAutos,
    numberOfEmployeesUsingTheirAutos: value.numberOfEmployeesUsingTheirAutos,
    filingSameAsPhysicalAddress: value.filingSameAsPhysicalAddress,
    filingStreet: value.filingStreet,
    filingCity: value.filingCity,
    filingState: StateToJSON(value.filingState),
    filingZip: value.filingZip,
    newDriverMvr: value.newDriverMvr,
    reviewMvrAnnually: value.reviewMvrAnnually,
    preHireDrugScreening: value.preHireDrugScreening,
    randomDrugScreening: value.randomDrugScreening,
    postAccidentDrugScreening: value.postAccidentDrugScreening,
    applicationScreeningProcess: value.applicationScreeningProcess,
    employeeLeasing: value.employeeLeasing,
    twoYearsDrivingExperienceRequired: value.twoYearsDrivingExperienceRequired,
    utilizeIndependentContractors: value.utilizeIndependentContractors,
    hireDayCasualLabor: value.hireDayCasualLabor,
    doesPerformOnSiteAssemblyOfProducts: value.doesPerformOnSiteAssemblyOfProducts,
    percentOfProductAssemblyIsDailyDeliveries: value.percentOfProductAssemblyIsDailyDeliveries,
    doesAssembleExibitsAndOrDisplays: value.doesAssembleExibitsAndOrDisplays,
    doesAssembleFurniture: value.doesAssembleFurniture,
    doesAssembleElectronics: value.doesAssembleElectronics,
    doesAssembleExerciseEquipment: value.doesAssembleExerciseEquipment,
    doesAssembleOtherProducts: value.doesAssembleOtherProducts,
    otherProductsAssembled: value.otherProductsAssembled,
    doesPerformOnsiteAssemblyOfAppliances: value.doesPerformOnsiteAssemblyOfAppliances,
    percentOfApplianceAssemblyIsDailyDeliveries: value.percentOfApplianceAssemblyIsDailyDeliveries,
    waterIsDailyDelivery: value.waterIsDailyDelivery,
    gasIsDailyDelivery: value.gasIsDailyDelivery,
    noDailyDeliveries: value.noDailyDeliveries,
    doesPerformInstallsRequiringContractor: value.doesPerformInstallsRequiringContractor,
    isLicensedContractor: value.isLicensedContractor,
    vehicleListDocument: DocumentToJSON(value.vehicleListDocument),
    driverListDocument: DocumentToJSON(value.driverListDocument),
    lossRunsDocument: DocumentToJSON(value.lossRunsDocument),
    returnToWorkDocument: DocumentToJSON(value.returnToWorkDocument),
    electronicConsentRequirementsDocument: DocumentToJSON(value.electronicConsentRequirementsDocument),
    uploadList: value.uploadList,
    driverPayrollProjectedYear: value.driverPayrollProjectedYear,
    driverPayrollCurrentYear: value.driverPayrollCurrentYear,
    driverPayrollPriorYear: value.driverPayrollPriorYear,
    clericalPayrollProjectedYear: value.clericalPayrollProjectedYear,
    clericalPayrollCurrentYear: value.clericalPayrollCurrentYear,
    clericalPayrollPriorYear: value.clericalPayrollPriorYear,
    otherPayrollProjectedYear: value.otherPayrollProjectedYear,
    otherPayrollCurrentYear: value.otherPayrollCurrentYear,
    otherPayrollPriorYear: value.otherPayrollPriorYear,
    premiumsProjectedYear: value.premiumsProjectedYear,
    premiumsCurrentYear: value.premiumsCurrentYear,
    premiumsPriorYear: value.premiumsPriorYear,
    isExcludedSalaryIncludedInPayroll: value.isExcludedSalaryIncludedInPayroll,
    isGroupMedicalProvided: value.isGroupMedicalProvided,
    percentOfEmployeesEnrolledInMedicalPlan: value.percentOfEmployeesEnrolledInMedicalPlan,
    isFormalAccidentReportingInPlace: value.isFormalAccidentReportingInPlace,
    doesHaveReturnToWorkProgram: value.doesHaveReturnToWorkProgram,
    isWillingToCreateVanlinerAssistance: value.isWillingToCreateVanlinerAssistance,
    doesUtilizeIndependentContractors: value.doesUtilizeIndependentContractors,
    willProvideCoverageUnderWorkersCompPolicy: value.willProvideCoverageUnderWorkersCompPolicy,
    maintainCertificatesOfInsuranceForCoverage: value.maintainCertificatesOfInsuranceForCoverage,
    agreeLossRun: value.agreeLossRun,
    agreeFairCreditReporting: value.agreeFairCreditReporting,
    stateRequirements: value.stateRequirements,
    electronicConsentRequirements: value.electronicConsentRequirements,
    submissionName: value.submissionName,
    submissionDate:
      value.submissionDate === undefined
        ? undefined
        : value.submissionDate === null
        ? null
        : value.submissionDate.toISOString(),
    agreeTerms: value.agreeTerms,
    partners:
      value.partners === undefined
        ? undefined
        : value.partners === null
        ? null
        : (value.partners as Array<any>).map(PartnerToJSON),
    excludedIndividuals:
      value.excludedIndividuals === undefined
        ? undefined
        : value.excludedIndividuals === null
        ? null
        : (value.excludedIndividuals as Array<any>).map(ExcludedIndividualToJSON),
    logisticsProviders:
      value.logisticsProviders === undefined
        ? undefined
        : value.logisticsProviders === null
        ? null
        : (value.logisticsProviders as Array<any>).map(LogisticsProviderToJSON),
    terminals:
      value.terminals === undefined
        ? undefined
        : value.terminals === null
        ? null
        : (value.terminals as Array<any>).map(TerminalToJSON),
    operationStates:
      value.operationStates === undefined
        ? undefined
        : value.operationStates === null
        ? null
        : (value.operationStates as Array<any>).map(OperationStateToJSON),
  };
}
