import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useGetApplication } from "../../../data/enrollment";

const useStyles = makeStyles((theme: Theme) => ({
  fullPage: {
    minHeight: "100vh",
  },
}));

const withApplicationData = <P extends Record<string, unknown>>(Component: React.ComponentType): React.FC<P> => (
  props: P,
): JSX.Element => {
  const { isLoading } = useGetApplication();
  const classes = useStyles();

  if (isLoading) {
    return <Grid container className={classes.fullPage}></Grid>;
  }

  return <Component {...props} />;
};

export default withApplicationData;
