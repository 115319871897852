import { useAuth } from "@chq/authentication";
import { Application, AuthenticationApi, EnrollmentApi } from "@chq/lastmiledelivery-api";
import { useCallback, useContext } from "react";
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import { EmailContext } from "../authentication/EmailContext";
import { useApi } from "../useApi";

const getCurrentApplicationCacheKey = () => "current-application";

export const useGetApplication = (
  options?: UseQueryOptions<Application, Error>,
): UseQueryResult<Application, Error> => {
  const applicationApi = useApi(EnrollmentApi);
  const authenticationApi = useApi(AuthenticationApi);
  const { user } = useAuth();
  const { email, updateEmail } = useContext(EmailContext);
  const query = useQuery<Application, Error>(
    getCurrentApplicationCacheKey(),
    async () => {
      let emailAddress: string;
      if (email.email) {
        emailAddress = email.email;
      } else {
        const response = await authenticationApi.apiV10AuthenticationUserEmailPost({
          userEmailRequest: { userId: user?.sub },
        });
        if (response.error) {
          throw new Error(response.error.message || "Unable to find application");
        }
        emailAddress = response.data!.email!;
      }

      const response = await applicationApi.apiV10EnrollmentGetApplicationGet({ emailAddress });
      if (response.data) {
        updateEmail(emailAddress);
      }
      return response.data!;
    },
    options,
  );
  return query;
};

export const useGetCachedApplication = () => {
  const cache = useQueryClient();
  return cache.getQueryData<Application>(getCurrentApplicationCacheKey());
};

export const useUpdateCachedApplication = () => {
  const cache = useQueryClient();
  return useCallback(
    (application: Application) => {
      cache.setQueryData(getCurrentApplicationCacheKey(), application);
    },
    [cache],
  );
};

export const useCachedApplication = () => {
  const application = useGetCachedApplication();
  const updateApplication = useUpdateCachedApplication();
  return { application, updateApplication };
};
