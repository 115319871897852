import React from "react";
import { Grid, makeStyles, Theme, CircularProgress, GridProps } from "@material-ui/core";
import PreviousButton from "./buttons/previous-button";
import NextButton from "./buttons/next-button";
import classNames from "classnames";

type PrevNextNavbarProps = {
  prevOnClick: () => void;
  prevAriaLabel?: string;
  nextOnClick: () => void;
  nextDisabled?: boolean;
  nextButtonText: string;
  isLoading?: boolean;
  GridProps?: GridProps;
  prevButtonGridProps?: GridProps;
  nextButtonGridProps?: GridProps;
  className?: string;
};
const useStyles = makeStyles((theme: Theme) => ({
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  pageButtons: {
    paddingTop: "1rem",
    justifyContent: "flex-start",
  },
  limitSize: {
    maxWidth: "500px",
  },
}));

/**Previous/next navigation bar for page navigation*/
const PrevNextNavbar: React.FC<PrevNextNavbarProps> = ({
  prevOnClick,
  prevAriaLabel,
  prevButtonGridProps,
  nextOnClick,
  nextDisabled,
  nextButtonText,
  nextButtonGridProps,
  isLoading,
  GridProps,
  className,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      direction="row"
      className={classNames(classes.pageButtons, className)}
      spacing={2}
      xs={12}
      {...GridProps}
    >
      <Grid item xs={4} sm={2} {...prevButtonGridProps}>
        <PreviousButton
          variant="outlined"
          color="primary"
          fullWidth
          className={classes.previousButton}
          onClick={prevOnClick}
          aria-label={prevAriaLabel}
        ></PreviousButton>
      </Grid>
      <Grid item xs={8} className={classes.limitSize} {...nextButtonGridProps}>
        <NextButton fullWidth color="primary" variant="contained" onClick={nextOnClick} disabled={nextDisabled}>
          {isLoading ? <CircularProgress color="inherit" size="2rem" /> : nextButtonText}
        </NextButton>
      </Grid>
    </Grid>
  );
};

export default PrevNextNavbar;
