/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ApplicationCheck,
  ApplicationCheckFromJSON,
  ApplicationCheckFromJSONTyped,
  ApplicationCheckToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseFromJSONTyped,
  ErrorResponseToJSON,
} from "./";

/**
 *
 * @export
 * @interface ApplicationCheckApiResponse
 */
export interface ApplicationCheckApiResponse {
  /**
   *
   * @type {ApplicationCheck}
   * @memberof ApplicationCheckApiResponse
   */
  data?: ApplicationCheck;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ApplicationCheckApiResponse
   */
  error?: ErrorResponse;
}

export function ApplicationCheckApiResponseFromJSON(json: any): ApplicationCheckApiResponse {
  return ApplicationCheckApiResponseFromJSONTyped(json, false);
}

export function ApplicationCheckApiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ApplicationCheckApiResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : ApplicationCheckFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ApplicationCheckApiResponseToJSON(value?: ApplicationCheckApiResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ApplicationCheckToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
