import { Application, ApplicationApiResponse, EnrollmentApi } from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";
import { useCachedApplication } from "./useGetApplication";

export const useChangeApplication = (): UseMutationResult<ApplicationApiResponse, Error | unknown, Application> => {
  const applicationApi = useApi(EnrollmentApi);
  const { updateApplication } = useCachedApplication();
  const mutation = useMutation(async (request: Application) => {
    const response = await applicationApi.apiV10EnrollmentUpsertApplicationPut({ application: request });
    if (response.data) {
      updateApplication(response.data);
    }

    return response;
  });
  return mutation;
};
