import { NumericInput, StyleLabel, YesNoInput, YesNoValues } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((theme: Theme) => ({
  addNewIcon: {
    color: theme.palette.primary.main,
  },
  addNewCopy: {
    color: theme.palette.primary.main,
    fontSize: ".75rem",
    marginLeft: ".25rem",
  },
  addNew: {
    cursor: "pointer",
    marginTop: "3rem",
  },
  title: {
    fontWeight: 700,
    marginBottom: "1rem",
  },
  inputContainer: {
    marginLeft: "1rem",
  },
  labelPlacementStart: {
    justifyContent: "space-between",
  },
  hiddenQuestion: {
    paddingLeft: "0.813rem",
  },
  yesNoInputSection: {
    marginTop: "1.5rem",
    marginLeft: "0",
  },
  label: {
    fontSize: "1rem",
  },
  labelOneLineDesktop: {
    height: "4rem",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      height: "auto",
    },
  },
  coverageQuestions: {
    height: "7rem",
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
      height: "auto",
    },
  },
}));

export enum Fields {
  isGroupMedicalProvided = "is-group-medical-provided",
  isExcludedSalaryIncludedInPayroll = "is-salary-included-in-payroll",
  percentOfEmployeesEnrolledInMedicalPlan = "percent-of-employees-enrolled-in-medical-plan",
  isFormalAccidentReportingInPlace = "is-formal-accident-reporting-in-place",
  doesHaveReturnToWorkProgram = "do-have-return-to-work-progam",
  isWillingToCreateVanlinerAssistance = "is-willing-to-create-vanliner-assistance",
  doesUtilizeIndependentContractors = "does-utilize-independentContractors",
  maintainCertificatesOfInsuranceForCoverage = "maintain-certificates-of-insurance-for-coverage",
  willProvideCoverageUnderWorkersCompPolicy = "will-provide-coverage-under-workers-comp-policy",
}

export type FormProps = {
  [Fields.isGroupMedicalProvided]: string;
  [Fields.isExcludedSalaryIncludedInPayroll]: string;
  [Fields.percentOfEmployeesEnrolledInMedicalPlan]: number | undefined;
  [Fields.isFormalAccidentReportingInPlace]: string;
  [Fields.doesHaveReturnToWorkProgram]: string;
  [Fields.doesUtilizeIndependentContractors]: string;
  [Fields.isWillingToCreateVanlinerAssistance]: string;
  [Fields.maintainCertificatesOfInsuranceForCoverage]: string;
  [Fields.willProvideCoverageUnderWorkersCompPolicy]: string;
};

type Props = ConditionalProps & {
  isGroupMedicalProvided?: string;
  isExcludedSalaryIncludedInPayroll?: string;
  percentOfEmployeesEnrolledInMedicalPlan?: number | undefined;
  isFormalAccidentReportingInPlace?: string;
  doesHaveReturnToWorkProgram?: string;
  doesUtilizeIndependentContractors?: string;
  isWillingToCreateVanlinerAssistance?: string;
  maintainCertificatesOfInsuranceForCoverage?: string;
  willProvideCoverageUnderWorkersCompPolicy?: string;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

type ConditionalProps = {
  excludedIndividual?: boolean;
};

const useValidationSchema = (isExcludedSalaryIncludedInPayrollRequired: boolean) => {
  const [t] = useTranslation();
  return yup.object({
    [Fields.isGroupMedicalProvided]: yup.string().required(),
    [Fields.isExcludedSalaryIncludedInPayroll]: isExcludedSalaryIncludedInPayrollRequired
      ? yup.string().required()
      : yup.string(),
    [Fields.percentOfEmployeesEnrolledInMedicalPlan]: yup.number().when(Fields.isGroupMedicalProvided, {
      is: YesNoValues.yes,
      then: yup
        .number()
        .min(
          1,
          t("errors.min", { field: t("workers-compensation.payroll.enrolled-in-medical-plan-percentage"), min: 1 }),
        )
        .max(
          100,
          t("errors.max", { field: t("workers-compensation.payroll.enrolled-in-medical-plan-percentage"), max: 100 }),
        )
        .required(t("workers-compensation.payroll.percent-of-employees-enrolled-in-medical-plan-error")),
    }),
    [Fields.isFormalAccidentReportingInPlace]: yup.string().required(),
    [Fields.doesHaveReturnToWorkProgram]: yup.string().required(),
    [Fields.doesUtilizeIndependentContractors]: yup.string().required(),
    [Fields.isWillingToCreateVanlinerAssistance]: yup.string().when(Fields.doesHaveReturnToWorkProgram, {
      is: YesNoValues.no,
      then: yup.string().required(),
    }),
    [Fields.maintainCertificatesOfInsuranceForCoverage]: yup.string().when(Fields.doesUtilizeIndependentContractors, {
      is: YesNoValues.yes,
      then: yup.string().required(),
    }),
    [Fields.willProvideCoverageUnderWorkersCompPolicy]: yup.string().when(Fields.doesUtilizeIndependentContractors, {
      is: YesNoValues.no,
      then: yup.string().required(),
    }),
  });
};

export const useFormikConfig = ({
  isGroupMedicalProvided: initialIsGroupMedicalProvided = "",
  isExcludedSalaryIncludedInPayroll: initialIsExcludedSalaryIncludedInPayroll = "",
  percentOfEmployeesEnrolledInMedicalPlan: initialPercentOfEmployeesEnrolledInMedicalPlan,
  isFormalAccidentReportingInPlace: initialIsFormalAccidentReportingInPlace = "",
  doesHaveReturnToWorkProgram: initialDoesHaveReturnToWorkProgram = "",
  isWillingToCreateVanlinerAssistance: initialIsWillingToCreateVanLinerAssistance = "",
  doesUtilizeIndependentContractors: initialDoesUtilizeIndependentContractors = "",
  maintainCertificatesOfInsuranceForCoverage: initialMaintainCertificatesOfInsuranceForCoverage = "",
  willProvideCoverageUnderWorkersCompPolicy: initialWillProvideCoverageUnderWorkersCompPolicy = "",
  excludedIndividual = false,
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema(excludedIndividual);
  return {
    initialValues: {
      [Fields.isGroupMedicalProvided]: initialIsGroupMedicalProvided,
      [Fields.isExcludedSalaryIncludedInPayroll]: initialIsExcludedSalaryIncludedInPayroll,
      [Fields.percentOfEmployeesEnrolledInMedicalPlan]: initialPercentOfEmployeesEnrolledInMedicalPlan,
      [Fields.isFormalAccidentReportingInPlace]: initialIsFormalAccidentReportingInPlace,
      [Fields.doesHaveReturnToWorkProgram]: initialDoesHaveReturnToWorkProgram,
      [Fields.isWillingToCreateVanlinerAssistance]: initialIsWillingToCreateVanLinerAssistance,
      [Fields.doesUtilizeIndependentContractors]: initialDoesUtilizeIndependentContractors,
      [Fields.maintainCertificatesOfInsuranceForCoverage]: initialMaintainCertificatesOfInsuranceForCoverage,
      [Fields.willProvideCoverageUnderWorkersCompPolicy]: initialWillProvideCoverageUnderWorkersCompPolicy,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};

const PayrollYesNoForm: React.FC<ConditionalProps> = ({ excludedIndividual }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const formik = useFormikContext<FormProps>();
  return (
    <form onSubmit={formik.handleSubmit}>
      {/* ************************** Yes / No Section ************************** */}
      <Grid container spacing={4} className={classes.yesNoInputSection}>
        {excludedIndividual && (
          <Grid item xs={12}>
            <StyleLabel variant="h4" labelText={t("workers-compensation.payroll.salary-excluded")} />

            <YesNoInput
              className={classes.labelOneLineDesktop}
              required
              yesText={t("common.yes")}
              noText={t("common.no")}
              aria-label={t("workers-compensation.payroll.salary-excluded")}
              id={Fields.isExcludedSalaryIncludedInPayroll}
              name={Fields.isExcludedSalaryIncludedInPayroll}
              value={formik.values[Fields.isExcludedSalaryIncludedInPayroll]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[Fields.isExcludedSalaryIncludedInPayroll] &&
                Boolean(formik.errors[Fields.isExcludedSalaryIncludedInPayroll])
              }
              helperText={
                formik.touched[Fields.isExcludedSalaryIncludedInPayroll] &&
                formik.errors[Fields.isExcludedSalaryIncludedInPayroll]
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <YesNoInput
            className={classes.labelOneLineDesktop}
            label={t("workers-compensation.payroll.group-medical-provided")}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.isGroupMedicalProvided}
            name={Fields.isGroupMedicalProvided}
            value={formik.values[Fields.isGroupMedicalProvided]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.isGroupMedicalProvided] && Boolean(formik.errors[Fields.isGroupMedicalProvided])
            }
            helperText={formik.touched[Fields.isGroupMedicalProvided] && formik.errors[Fields.isGroupMedicalProvided]}
          />
        </Grid>
        {formik.values[Fields.isGroupMedicalProvided] === YesNoValues.yes && (
          <Grid item xs={12}>
            <Grid item className={classes.hiddenQuestion}>
              <StyleLabel
                variant={"h2"}
                labelText={t("workers-compensation.payroll.percent-of-employees-enrolled-in-medical-plan")}
              />
              <NumericInput
                required
                format="###"
                inputProps={{ "aria-label": `${Fields.percentOfEmployeesEnrolledInMedicalPlan}` }}
                id={Fields.percentOfEmployeesEnrolledInMedicalPlan}
                name={Fields.percentOfEmployeesEnrolledInMedicalPlan}
                value={formik.values[Fields.percentOfEmployeesEnrolledInMedicalPlan]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.percentOfEmployeesEnrolledInMedicalPlan] &&
                  Boolean(formik.errors[Fields.percentOfEmployeesEnrolledInMedicalPlan])
                }
                helperText={
                  formik.touched[Fields.percentOfEmployeesEnrolledInMedicalPlan] &&
                  formik.errors[Fields.percentOfEmployeesEnrolledInMedicalPlan]
                }
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <YesNoInput
            className={classes.labelOneLineDesktop}
            label={t("workers-compensation.payroll.formal-accident-reporting-procedure")}
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.isFormalAccidentReportingInPlace}
            name={Fields.isFormalAccidentReportingInPlace}
            value={formik.values[Fields.isFormalAccidentReportingInPlace]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.isFormalAccidentReportingInPlace] &&
              Boolean(formik.errors[Fields.isFormalAccidentReportingInPlace])
            }
            helperText={
              formik.touched[Fields.isFormalAccidentReportingInPlace] &&
              formik.errors[Fields.isFormalAccidentReportingInPlace]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <YesNoInput
            required
            className={classes.labelOneLineDesktop}
            label={t("workers-compensation.payroll.return-to-work")}
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesHaveReturnToWorkProgram}
            name={Fields.doesHaveReturnToWorkProgram}
            value={formik.values[Fields.doesHaveReturnToWorkProgram]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesHaveReturnToWorkProgram] &&
              Boolean(formik.errors[Fields.doesHaveReturnToWorkProgram])
            }
            helperText={
              formik.touched[Fields.doesHaveReturnToWorkProgram] && formik.errors[Fields.doesHaveReturnToWorkProgram]
            }
          />
        </Grid>
        {formik.values[Fields.doesHaveReturnToWorkProgram] === YesNoValues.no && (
          <Grid item xs={12}>
            <Grid item className={classes.hiddenQuestion}>
              <YesNoInput
                className={classes.labelOneLineDesktop}
                required
                label={t("workers-compensation.payroll.create-light-restricted-work-program")}
                yesText={t("common.yes")}
                noText={t("common.no")}
                id={Fields.isWillingToCreateVanlinerAssistance}
                name={Fields.isWillingToCreateVanlinerAssistance}
                value={formik.values[Fields.isWillingToCreateVanlinerAssistance]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.isWillingToCreateVanlinerAssistance] &&
                  Boolean(formik.errors[Fields.isWillingToCreateVanlinerAssistance])
                }
                helperText={
                  formik.touched[Fields.isWillingToCreateVanlinerAssistance] &&
                  formik.errors[Fields.isWillingToCreateVanlinerAssistance]
                }
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <YesNoInput
            required
            className={classes.labelOneLineDesktop}
            label={t("workers-compensation.payroll.utilize-independent-contractors")}
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesUtilizeIndependentContractors}
            name={Fields.doesUtilizeIndependentContractors}
            value={formik.values[Fields.doesUtilizeIndependentContractors]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesUtilizeIndependentContractors] &&
              Boolean(formik.errors[Fields.doesUtilizeIndependentContractors])
            }
            helperText={
              formik.touched[Fields.doesUtilizeIndependentContractors] &&
              formik.errors[Fields.doesUtilizeIndependentContractors]
            }
          />
        </Grid>
        {formik.values[Fields.doesUtilizeIndependentContractors] === YesNoValues.yes && (
          <Grid item xs={12}>
            <Grid item className={classes.hiddenQuestion}>
              <YesNoInput
                className={classes.coverageQuestions}
                required
                label={t("workers-compensation.payroll.maintain-current-coverage")}
                yesText={t("common.yes")}
                noText={t("common.no")}
                id={Fields.maintainCertificatesOfInsuranceForCoverage}
                name={Fields.maintainCertificatesOfInsuranceForCoverage}
                value={formik.values[Fields.maintainCertificatesOfInsuranceForCoverage]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.maintainCertificatesOfInsuranceForCoverage] &&
                  Boolean(formik.errors[Fields.maintainCertificatesOfInsuranceForCoverage])
                }
                helperText={
                  formik.touched[Fields.maintainCertificatesOfInsuranceForCoverage] &&
                  formik.errors[Fields.maintainCertificatesOfInsuranceForCoverage]
                }
              />
            </Grid>
          </Grid>
        )}
        {formik.values[Fields.doesUtilizeIndependentContractors] === YesNoValues.no && (
          <Grid item xs={12}>
            <Grid item className={classes.hiddenQuestion}>
              <YesNoInput
                className={classes.coverageQuestions}
                required
                label={t("workers-compensation.payroll.will-provide-owner-coverage")}
                yesText={t("common.yes")}
                noText={t("common.no")}
                id={Fields.willProvideCoverageUnderWorkersCompPolicy}
                name={Fields.willProvideCoverageUnderWorkersCompPolicy}
                value={formik.values[Fields.willProvideCoverageUnderWorkersCompPolicy]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched[Fields.willProvideCoverageUnderWorkersCompPolicy] &&
                  Boolean(formik.errors[Fields.willProvideCoverageUnderWorkersCompPolicy])
                }
                helperText={
                  formik.touched[Fields.willProvideCoverageUnderWorkersCompPolicy] &&
                  formik.errors[Fields.willProvideCoverageUnderWorkersCompPolicy]
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default PayrollYesNoForm;
