import { Checkbox, NumericInput, Select, TextInput } from "@chq/components";
import { State } from "@chq/lastmiledelivery-api";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useStates } from "../data/useStates";

const useStyles = makeStyles((theme: Theme) => ({
  mailingAddress: {
    fontWeight: 700,
  },
  mailingAddressContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelOneLine: {
    whiteSpace: "nowrap",
  },
}));

export enum Fields {
  mailingSameAsPhysicalAddress = "mailing-same-as-physical-address",
  mailingStreet = "mailing-street",
  mailingCity = "mailing-city",
  mailingState = "mailing-state",
  mailingZip = "mailing-zip",
}

export type FormProps = {
  [Fields.mailingSameAsPhysicalAddress]: boolean | undefined;
  [Fields.mailingStreet]: string;
  [Fields.mailingCity]: string;
  [Fields.mailingState]: State | "";
  [Fields.mailingZip]: string;
};

type Props = {
  title?: string;
  mailingSameAsPhysicalAddress?: boolean;
  mailingStreet?: string;
  mailingCity?: string;
  mailingState?: State | "";
  mailingZip?: string;
};

const useValidationSchema = () => {
  const [t] = useTranslation();
  return yup.object({
    [Fields.mailingSameAsPhysicalAddress]: yup.boolean(),
    [Fields.mailingStreet]: yup.string().when(Fields.mailingSameAsPhysicalAddress, {
      is: false,
      then: yup.string().required(t(`errors.required`, { field: t(`mailing-address-form.street.label`) })),
    }),
    [Fields.mailingCity]: yup.string().when(Fields.mailingSameAsPhysicalAddress, {
      is: false,
      then: yup.string().required(t(`errors.required`, { field: t(`mailing-address-form.city.label`) })),
    }),
    [Fields.mailingState]: yup.string().when(Fields.mailingSameAsPhysicalAddress, {
      is: false,
      then: yup.string().required(t(`errors.required`, { field: t(`mailing-address-form.state.label`) })),
    }),
    [Fields.mailingZip]: yup.string().when(Fields.mailingSameAsPhysicalAddress, {
      is: false,
      then: yup
        .string()
        .required(
          t("errors.required", {
            field: t(`mailing-address-form.zip.label`),
          }),
        )
        .test(
          "length",
          t("errors.exact-digits", {
            field: t(`mailing-address-form.zip.label`),
            exact: 5,
          }),
          (val) => val !== undefined && val.toString().length === 5,
        ),
    }),
  });
};

export const useFormikConfig = ({
  mailingSameAsPhysicalAddress: initialSameAsPhysicalAddress,
  mailingStreet: initialMailingStreet = "",
  mailingCity: initialMailingCity = "",
  mailingState: initialMailingState = "",
  mailingZip: initialMailingZip = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.mailingSameAsPhysicalAddress]: initialSameAsPhysicalAddress,
      [Fields.mailingStreet]: initialMailingStreet,
      [Fields.mailingCity]: initialMailingCity,
      [Fields.mailingState]: initialMailingState,
      [Fields.mailingZip]: initialMailingZip,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
  };
};

const MailingAddressCheck: React.FC<Props> = ({ title }) => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  const states = useStates();

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6} className={classes.mailingAddressContainer}>
          <Typography variant="h3" className={classes.mailingAddress}>
            {title ? title : t("mailing-address-form.mailing-address")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            label={t("mailing-address-form.checkbox")}
            id={Fields.mailingSameAsPhysicalAddress}
            name={Fields.mailingSameAsPhysicalAddress}
            value={formik.values[Fields.mailingSameAsPhysicalAddress]}
            onChange={formik.handleChange}
            checked={formik.values[Fields.mailingSameAsPhysicalAddress]}
          />
        </Grid>
        {formik.values[Fields.mailingSameAsPhysicalAddress] !== true && (
          <>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                required
                label={t("mailing-address-form.street.label")}
                id={Fields.mailingStreet}
                name={Fields.mailingStreet}
                value={formik.values[Fields.mailingStreet]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.mailingStreet] && Boolean(formik.errors[Fields.mailingStreet])}
                helperText={formik.touched[Fields.mailingStreet] && formik.errors[Fields.mailingStreet]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                fullWidth
                required
                label={t("mailing-address-form.city.label")}
                id={Fields.mailingCity}
                name={Fields.mailingCity}
                value={formik.values[Fields.mailingCity]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.mailingCity] && Boolean(formik.errors[Fields.mailingCity])}
                helperText={formik.touched[Fields.mailingCity] && formik.errors[Fields.mailingCity]}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                fullWidth
                required
                items={states
                  .map((state) => ({
                    name: state.abv,
                    value: state.abv,
                  }))
                  .sort((a, b) => {
                    return a.value > b.value ? 1 : -1;
                  })}
                label={t("mailing-address-form.state.label")}
                id={Fields.mailingState}
                name={Fields.mailingState}
                value={formik.values[Fields.mailingState]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.mailingState] && Boolean(formik.errors[Fields.mailingState])}
                helperText={formik.touched[Fields.mailingState] && formik.errors[Fields.mailingState]}
              />
            </Grid>
            <Grid item xs={3}>
              <NumericInput
                fullWidth
                format="#####"
                isNumericString
                required
                label={t("mailing-address-form.zip.label")}
                id={Fields.mailingZip}
                name={Fields.mailingZip}
                value={formik.values[Fields.mailingZip]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[Fields.mailingZip] && Boolean(formik.errors[Fields.mailingZip])}
                helperText={formik.touched[Fields.mailingZip] && formik.errors[Fields.mailingZip]}
                className={classes.labelOneLine}
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default MailingAddressCheck;
