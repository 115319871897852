import {
  ApiV10EnrollmentDoesApplicationExistGetRequest,
  ApplicationCheckApiResponse,
  EnrollmentApi,
} from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useCheckApplication = (): UseMutationResult<
  ApplicationCheckApiResponse,
  Error | unknown,
  ApiV10EnrollmentDoesApplicationExistGetRequest
> => {
  const applicationApi = useApi(EnrollmentApi);
  const mutation = useMutation(async (request: ApiV10EnrollmentDoesApplicationExistGetRequest) => {
    const response = await applicationApi.apiV10EnrollmentDoesApplicationExistGet(request);

    return response;
  });
  return mutation;
};
