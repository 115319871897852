/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum LegalEntity {
  LLC = "LLC",
  Corporation = "Corporation",
  SoleProprietor = "Sole Proprietor",
  DontKnow = "Don't Know",
  Partnership = "Partnership",
}

export function LegalEntityFromJSON(json: any): LegalEntity {
  return LegalEntityFromJSONTyped(json, false);
}

export function LegalEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalEntity {
  return json as LegalEntity;
}

export function LegalEntityToJSON(value?: LegalEntity | null): any {
  return value as any;
}
