import { Fade, Grid, makeStyles, Modal, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    color: theme.palette.error.main,
    marginBottom: 10,
  },
  button: {
    marginTop: 36,
    height: 56,
    width: "100%",
  },
  buttonContain: {
    width: "100%",
  },
  modal: {
    background: theme.palette.common.white,
    borderRadius: 4,
    maxWidth: 576,
    padding: "18px 15px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  },
  modalContain: {
    position: "relative",
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
};

const RegistrationModal: React.FC<Props> = ({ open, onClose, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const classes = useStyles();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal onClose={onClose} open={isOpen} className={classes.modalContain}>
      <Fade in={isOpen}>
        <Grid className={classes.modal}>{children}</Grid>
      </Fade>
    </Modal>
  );
};

export default RegistrationModal;
