import { AuthenticationApi, EnrollmentApi, UserAddPasswordRequest } from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { RouteNames } from "../../utils/route-names";
import { useCachedApplication } from "../enrollment/useGetApplication";
import { useApi } from "../useApi";

export enum VerifiedStatus {
  verfiyEmailSentPasswordCreated = 0,
  accountIsVerifiedPasswordCreated,
}

export const useAddPassword = (): UseMutationResult<VerifiedStatus, Error | unknown, UserAddPasswordRequest> => {
  const authenticationApi = useApi(AuthenticationApi);
  const enrollmentApi = useApi(EnrollmentApi);
  const { application } = useCachedApplication();

  const mutation = useMutation(async (request: UserAddPasswordRequest) => {
    await authenticationApi.apiV10AuthenticationUserAddPasswordPost({
      userAddPasswordRequest: request,
    });
    const { data } = await authenticationApi.apiV10AuthenticationUserStatusPost({
      userStatusRequest: { userId: request.userId },
    });
    if (data?.emailConfirmed) {
      await enrollmentApi.apiV10EnrollmentUpsertApplicationPut({
        application: { applicationState: RouteNames.verify },
      });
      return VerifiedStatus.accountIsVerifiedPasswordCreated;
    } else {
      await enrollmentApi.apiV10EnrollmentSendResumeApplicationLinkPost({ emailAddress: application!.contactEmail! });
      await enrollmentApi.apiV10EnrollmentUpsertApplicationPut({
        application: { ...application, applicationState: RouteNames.success },
      });
      return VerifiedStatus.verfiyEmailSentPasswordCreated;
    }
  });
  return mutation;
};
