import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginBottom: 10,
  },
  button: {
    marginTop: "4rem",
    height: 56,
    width: "100%",
  },
  buttonContain: {
    width: "100%",
  },
}));

type Props = {
  email?: string | null;
  onClose?: () => void;
};

const StartNew: React.FC<Props> = ({ onClose, email }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center">
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.alert}>
          <strong>{t(`start-new-modal.heading`)}</strong>
        </Typography>
        <Typography component="span">{email}</Typography>

        <Typography component="span"> {t(`start-new-modal.main`)}</Typography>
      </Grid>
      <Grid className={classes.buttonContain} item xs={12}>
        <Button className={classes.button} onClick={onClose} variant="contained">
          {t(`common.ok`)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default StartNew;
