import { ExcludedIndividual } from "@chq/lastmiledelivery-api";
import { ExcludedIndividualUnit } from "../ui/last-mile/pages/workers-comp-page";

export const excludedIndividualMapper = (excludedIndividual: ExcludedIndividualUnit): ExcludedIndividual => {
  return {
    name: excludedIndividual.name,
    title: excludedIndividual.title,
    relationshipType: excludedIndividual.relationshipType,
    ownershipPercent: excludedIndividual.ownershipPercent,
    id: excludedIndividual.id,
    salary: excludedIndividual.salary,
  };
};
