import { Checkbox, NumericInput, StyleLabel, TextInput, YesNoInput, YesNoValues } from "@chq/components";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import { FormikConfig, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const useStyles = makeStyles((them: Theme) => ({
  onsiteProductAssembly: {
    marginLeft: "1.5rem",
  },
  productAssemblyItem: {
    alignContent: "center",
    alignItems: "center",
    minWidth: "12.5rem",
  },
  productTypeSection: {
    marginTop: "1rem",
  },
  installRequiringContractorLicense: {
    marginLeft: "1rem",
    paddingBottom: "1rem",
  },
  labelAndElement: {
    position: "relative",
    top: "1rem",
    fontWeight: 700,
  },
  textInput: {
    "&.MuiFormControl-marginNormal": {
      marginTop: "0rem",
    },
  },
}));

export enum Fields {
  doesPerformOnSiteAssemblyOfProducts = "does-perform-onsite-assembly-of-products",
  percentOfProductAssemblyIsDailyDeliveries = "percent-of-onsite-production-assembly-is-daily-total-deliverables",
  doesAssembleFurniture = "does-assemble-furniture",
  doesAssembleElectronics = "does-assemble-electronics",
  doesAssembleExibitsAndOrDisplays = "does-assemble-exibits-and-or-displays",
  doesAssembleExerciseEquipment = "does-assemble-exercise-equipment",
  doesAssembleOtherProducts = "does-assemble-other-products",
  otherProductsAssembled = "other-products-assembled",
  doesPerformOnsiteAssemblyOfAppliances = "does-perform-onsite-assembly-of-appliances",
  percentOfApplianceAssemblyIsDailyDeliveries = "percent-of-product-assembly-is-daily-deliveries",
  waterIsDailyDelivery = "water-is-daily-delivery",
  gasIsDailyDelivery = "gas-is-daily-delivery",
  noDailyDeliveries = "no-daily-deliveries",
  doesPerformInstallsRequiringContractors = "does-perform-installs-requiring-contractors",
  isLicensedContractor = "is-licensed-contractor",
}

type Props = {
  doesPerformOnSiteAssemblyOfProducts?: string | undefined;
  percentOfProductAssemblyIsDailyDeliveries?: number;
  doesAssembleExibitsAndOrDisplays?: boolean | undefined;
  doesAssembleFurniture?: boolean | undefined;
  doesAssembleElectronics?: boolean | undefined;
  doesAssembleExerciseEquipment?: boolean | undefined;
  doesAssembleOtherProducts?: boolean | undefined;
  otherProductsAssembled?: string;
  doesPerformOnsiteAssemblyOfAppliances?: string | undefined;
  percentOfApplianceAssemblyIsDailyDeliveries?: number;
  waterIsDailyDelivery?: boolean | undefined;
  gasIsDailyDelivery?: boolean | undefined;
  noDailyDeliveries?: boolean | undefined;
  doesPerformInstallsRequiringContractors?: string | undefined;
  isLicensedContractor?: string | undefined;
};

export type FormProps = {
  [Fields.doesPerformOnSiteAssemblyOfProducts]: string | undefined;
  [Fields.percentOfProductAssemblyIsDailyDeliveries]: number | undefined;
  [Fields.doesAssembleExibitsAndOrDisplays]: boolean | undefined;
  [Fields.doesAssembleFurniture]: boolean | undefined;
  [Fields.doesAssembleElectronics]: boolean | undefined;
  [Fields.doesAssembleExerciseEquipment]: boolean | undefined;
  [Fields.doesAssembleOtherProducts]: boolean | undefined;
  [Fields.otherProductsAssembled]?: string;
  [Fields.doesPerformOnsiteAssemblyOfAppliances]: string | undefined;
  [Fields.percentOfApplianceAssemblyIsDailyDeliveries]: number | undefined;
  [Fields.waterIsDailyDelivery]: boolean | undefined;
  [Fields.gasIsDailyDelivery]: boolean | undefined;
  [Fields.noDailyDeliveries]: boolean | undefined;
  [Fields.doesPerformInstallsRequiringContractors]: string | undefined;
  [Fields.isLicensedContractor]: string | undefined;
  onSubmit?: FormikConfig<FormProps>["onSubmit"];
};

export const useFormikConfig = ({
  doesPerformOnSiteAssemblyOfProducts: initialDoesPerformOnSiteAssemblyOfProducts = "",
  percentOfProductAssemblyIsDailyDeliveries: initialPercentOfProductAssemblyIsDailyDeliveries,
  doesAssembleExibitsAndOrDisplays: initialDoesAssembleExibitsAndOrDisplays = false,
  doesAssembleFurniture: initialDoesAssembleFurniture = false,
  doesAssembleElectronics: initialDoesAssembleElectronics = false,
  doesAssembleExerciseEquipment: initialDoesAssembleExerciseEquipment = false,
  doesAssembleOtherProducts: intialDoesAssembleOtherProducts = false,
  otherProductsAssembled: initialOtherProductAssembled = "",
  doesPerformOnsiteAssemblyOfAppliances: initialDoesPerformOnsiteAssemblyOfAppliances = "",
  waterIsDailyDelivery: initialWaterIsDailyDelivery = false,
  percentOfApplianceAssemblyIsDailyDeliveries: initialPercentOfApplianceAssemblyIsDailyDeliveries,
  gasIsDailyDelivery: initialGasIsDailyDelivery = false,
  noDailyDeliveries: initialNoDailyDeliveries = false,
  doesPerformInstallsRequiringContractors: intialDoesPerformInstallsRequiringContractors = "",
  isLicensedContractor: initialIsLicensedContractor = "",
}: Props = {}): Omit<FormikConfig<FormProps>, "onSubmit"> => {
  const validationSchema = useValidationSchema();
  return {
    initialValues: {
      [Fields.doesPerformOnSiteAssemblyOfProducts]: initialDoesPerformOnSiteAssemblyOfProducts,
      [Fields.percentOfProductAssemblyIsDailyDeliveries]: initialPercentOfProductAssemblyIsDailyDeliveries,
      [Fields.doesAssembleFurniture]: initialDoesAssembleFurniture,
      [Fields.doesAssembleElectronics]: initialDoesAssembleElectronics,
      [Fields.doesAssembleExibitsAndOrDisplays]: initialDoesAssembleExibitsAndOrDisplays,
      [Fields.doesAssembleExerciseEquipment]: initialDoesAssembleExerciseEquipment,
      [Fields.doesAssembleOtherProducts]: intialDoesAssembleOtherProducts,
      [Fields.otherProductsAssembled]: initialOtherProductAssembled,
      [Fields.doesPerformOnsiteAssemblyOfAppliances]: initialDoesPerformOnsiteAssemblyOfAppliances,
      [Fields.waterIsDailyDelivery]: initialWaterIsDailyDelivery,
      [Fields.percentOfApplianceAssemblyIsDailyDeliveries]: initialPercentOfApplianceAssemblyIsDailyDeliveries,
      [Fields.gasIsDailyDelivery]: initialGasIsDailyDelivery,
      [Fields.noDailyDeliveries]: initialNoDailyDeliveries,
      [Fields.doesPerformInstallsRequiringContractors]: intialDoesPerformInstallsRequiringContractors,
      [Fields.isLicensedContractor]: initialIsLicensedContractor,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  };
};
export const useValidationSchema = () => {
  const [t] = useTranslation();
  return yup.object().shape({
    [Fields.doesPerformOnSiteAssemblyOfProducts]: yup.string().required(),
    [Fields.percentOfProductAssemblyIsDailyDeliveries]: yup.number().when(Fields.doesPerformOnSiteAssemblyOfProducts, {
      is: YesNoValues.yes,
      then: yup
        .number()
        .min(1, t("installation-form.percent-daily-error-min"))
        .max(100, t("installation-form.percent-daily-error-max"))
        .required(t("installation-form.percent-daily-error")),
    }),
    [Fields.doesAssembleExibitsAndOrDisplays]: yup.boolean().when(Fields.doesPerformOnSiteAssemblyOfProducts, {
      is: YesNoValues.yes,
      then: yup
        .boolean()
        .when(
          [
            Fields.doesAssembleOtherProducts,
            Fields.doesAssembleFurniture,
            Fields.doesAssembleElectronics,
            Fields.doesAssembleExerciseEquipment,
          ],
          {
            is: false,
            then: yup.boolean().required().oneOf([true]),
          },
        ),
    }),
    [Fields.doesAssembleFurniture]: yup.boolean(),
    [Fields.doesAssembleElectronics]: yup.boolean(),
    [Fields.doesAssembleExerciseEquipment]: yup.boolean(),
    [Fields.doesAssembleOtherProducts]: yup.boolean(),
    [Fields.otherProductsAssembled]: yup.string().when(Fields.doesAssembleOtherProducts, {
      is: true,
      then: yup.string().required(t("installation-form.other-reason-error")),
    }),
    [Fields.doesPerformOnsiteAssemblyOfAppliances]: yup.string().required(t("")),
    [Fields.percentOfApplianceAssemblyIsDailyDeliveries]: yup
      .number()
      .when(Fields.doesPerformOnsiteAssemblyOfAppliances, {
        is: YesNoValues.yes,
        then: yup
          .number()
          .min(1, t("installation-form.percent-daily-error-min"))
          .max(100, t("installation-form.percent-daily-error-max"))
          .required(t("installation-form.percent-daily-error")),
      }),
    [Fields.waterIsDailyDelivery]: yup.boolean().when(Fields.doesPerformOnsiteAssemblyOfAppliances, {
      is: YesNoValues.yes,
      then: yup.boolean().when([Fields.gasIsDailyDelivery, Fields.noDailyDeliveries], {
        is: false,
        then: yup.boolean().required().oneOf([true]),
      }),
    }),
    [Fields.gasIsDailyDelivery]: yup.boolean(),
    [Fields.noDailyDeliveries]: yup.boolean(),
    [Fields.doesPerformInstallsRequiringContractors]: yup
      .string()
      .when([Fields.doesPerformOnsiteAssemblyOfAppliances], {
        is: YesNoValues.yes,
        then: yup.string().required(),
      }),
    [Fields.isLicensedContractor]: yup.string().when(Fields.doesPerformInstallsRequiringContractors, {
      is: YesNoValues.yes,
      then: yup.string().required(),
    }),
  });
};

const InstallationForm = () => {
  const [t] = useTranslation();
  const formik = useFormikContext<FormProps>();
  const classes = useStyles();
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item className={classes.labelAndElement}>
          <StyleLabel variant="h2" labelText={t("installation-form.products-onsite-assembly-yes-no")} />
        </Grid>
        <Grid item xs={12}>
          <YesNoInput
            required
            yesText={t("common.yes")}
            noText={t("common.no")}
            id={Fields.doesPerformOnSiteAssemblyOfProducts}
            name={Fields.doesPerformOnSiteAssemblyOfProducts}
            value={formik.values[Fields.doesPerformOnSiteAssemblyOfProducts]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched[Fields.doesPerformOnSiteAssemblyOfProducts] &&
              Boolean(formik.errors[Fields.doesPerformOnSiteAssemblyOfProducts])
            }
            helperText={
              formik.touched[Fields.doesPerformOnSiteAssemblyOfProducts] &&
              formik.errors[Fields.doesPerformOnSiteAssemblyOfProducts]
            }
            data-testid="doesPerformOnSiteAssemblyOfProducts"
          />
        </Grid>
        <Grid item xs={12} className={classes.onsiteProductAssembly}>
          {formik.values[Fields.doesPerformOnSiteAssemblyOfProducts] === YesNoValues.yes && (
            <Grid item container>
              <Grid item className={classes.labelAndElement}>
                <StyleLabel
                  variant="h2"
                  labelText={t("installation-form.percent-of-total-onsite-production-assembly-input")}
                />
              </Grid>
              <Grid item xs={12} className={classes.productAssemblyItem}>
                <NumericInput
                  inputProps={{ "aria-label": `${Fields.percentOfProductAssemblyIsDailyDeliveries}`, maxLength: 4 }}
                  id={Fields.percentOfProductAssemblyIsDailyDeliveries}
                  name={Fields.percentOfProductAssemblyIsDailyDeliveries}
                  value={formik.values[Fields.percentOfProductAssemblyIsDailyDeliveries]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  suffix="%"
                  min={0}
                  max={100}
                  error={
                    formik.touched[Fields.percentOfProductAssemblyIsDailyDeliveries] &&
                    Boolean(formik.errors[Fields.percentOfProductAssemblyIsDailyDeliveries])
                  }
                  helperText={
                    formik.touched[Fields.percentOfProductAssemblyIsDailyDeliveries] &&
                    formik.errors[Fields.percentOfProductAssemblyIsDailyDeliveries]
                  }
                  data-testid="percentOfProductAssemblyIsDailyDeliveries"
                />
              </Grid>
              <Grid item className={classes.productTypeSection}>
                <StyleLabel variant="h2" labelText={t("installation-form.item-types-assembled-checkbox-section")} />
                <Grid container item direction="row">
                  <Grid item xs={12} sm={4}>
                    <Checkbox
                      id={Fields.doesAssembleFurniture}
                      name={Fields.doesAssembleFurniture}
                      label={t("installation-form.furniture-checkbox")}
                      value={formik.values[Fields.doesAssembleFurniture]}
                      onChange={formik.handleChange}
                      checked={formik.values[Fields.doesAssembleFurniture]}
                      data-testid="doesAssembleFurniture"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Checkbox
                      id={Fields.doesAssembleElectronics}
                      name={Fields.doesAssembleElectronics}
                      label={t("installation-form.electronics-checkbox")}
                      value={formik.values[Fields.doesAssembleElectronics]}
                      onChange={formik.handleChange}
                      checked={formik.values[Fields.doesAssembleElectronics]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Checkbox
                      id={Fields.doesAssembleExibitsAndOrDisplays}
                      name={Fields.doesAssembleExibitsAndOrDisplays}
                      label={t("installation-form.exibits-and-or-displays-checkbox")}
                      value={formik.values[Fields.doesAssembleExibitsAndOrDisplays]}
                      onChange={formik.handleChange}
                      checked={formik.values[Fields.doesAssembleExibitsAndOrDisplays]}
                    />
                  </Grid>
                </Grid>
                <Grid container item direction="row">
                  <Grid item xs={12} sm={4}>
                    <Checkbox
                      id={Fields.doesAssembleExerciseEquipment}
                      name={Fields.doesAssembleExerciseEquipment}
                      label={t("installation-form.exercise-equipment-checkbox")}
                      value={formik.values[Fields.doesAssembleExerciseEquipment]}
                      onChange={formik.handleChange}
                      checked={formik.values[Fields.doesAssembleExerciseEquipment]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Checkbox
                      id={Fields.doesAssembleOtherProducts}
                      name={Fields.doesAssembleOtherProducts}
                      label={t("installation-form.other-products-checkbox")}
                      value={formik.values[Fields.doesAssembleOtherProducts]}
                      onChange={formik.handleChange}
                      checked={formik.values[Fields.doesAssembleOtherProducts]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextInput
                      fullWidth
                      required
                      placeholder={t("installation-form.other-options-input-placeholder")}
                      id={Fields.otherProductsAssembled}
                      name={Fields.otherProductsAssembled}
                      value={formik.values[Fields.otherProductsAssembled]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched[Fields.otherProductsAssembled] &&
                        Boolean(formik.errors[Fields.otherProductsAssembled])
                      }
                      helperText={
                        formik.touched[Fields.otherProductsAssembled] && formik.errors[Fields.otherProductsAssembled]
                      }
                      className={classes.textInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid item className={classes.labelAndElement}>
            <StyleLabel variant="h2" labelText={t("installation-form.appliances-onsite-assembly-yes-no")} />
          </Grid>
          <Grid item>
            <YesNoInput
              required
              yesText={t("common.yes")}
              noText={t("common.no")}
              id={Fields.doesPerformOnsiteAssemblyOfAppliances}
              name={Fields.doesPerformOnsiteAssemblyOfAppliances}
              value={formik.values[Fields.doesPerformOnsiteAssemblyOfAppliances]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[Fields.doesPerformOnsiteAssemblyOfAppliances] &&
                Boolean(formik.errors[Fields.doesPerformOnsiteAssemblyOfAppliances])
              }
              helperText={
                formik.touched[Fields.doesPerformOnsiteAssemblyOfAppliances] &&
                formik.errors[Fields.doesPerformOnsiteAssemblyOfAppliances]
              }
              data-testid="doesPerformOnsiteAssemblyOfAppliances"
            />
          </Grid>
        </Grid>
        {formik.values[Fields.doesPerformOnsiteAssemblyOfAppliances] === YesNoValues.yes && (
          <Grid item xs={12} className={classes.onsiteProductAssembly}>
            <Grid item xs={12} className={classes.labelAndElement}>
              <StyleLabel
                variant="h2"
                labelText={t("installation-form.percent-of-product-assembly-is-daily-deliveries")}
              />
            </Grid>
            <Grid item xs={12} className={classes.productAssemblyItem}>
              <NumericInput
                inputProps={{ "aria-label": `${Fields.percentOfApplianceAssemblyIsDailyDeliveries}`, maxLength: 4 }}
                id={Fields.percentOfApplianceAssemblyIsDailyDeliveries}
                name={Fields.percentOfApplianceAssemblyIsDailyDeliveries}
                value={formik.values[Fields.percentOfApplianceAssemblyIsDailyDeliveries]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                suffix="%"
                min={0}
                max={100}
                error={
                  formik.touched[Fields.percentOfApplianceAssemblyIsDailyDeliveries] &&
                  Boolean(formik.errors[Fields.percentOfApplianceAssemblyIsDailyDeliveries])
                }
                helperText={
                  formik.touched[Fields.percentOfApplianceAssemblyIsDailyDeliveries] &&
                  formik.errors[Fields.percentOfApplianceAssemblyIsDailyDeliveries]
                }
                data-testid="percentOfApplianceAssemblyIsDailyDeliveries"
              />
            </Grid>
            <Grid item className={classes.productTypeSection}>
              <StyleLabel variant="h2" labelText={t("installation-form.item-types-assembled-checkbox-section")} />
              <Grid container direction={"row"}>
                <Grid item>
                  <Grid container direction={"row"} className={classes.productAssemblyItem}>
                    <Grid item>
                      <Checkbox
                        id={Fields.waterIsDailyDelivery}
                        name={Fields.waterIsDailyDelivery}
                        label={t("installation-form.water-is-daily-delivery")}
                        onChange={formik.handleChange}
                        value={formik.values[Fields.waterIsDailyDelivery]}
                        checked={formik.values[Fields.waterIsDailyDelivery]}
                        data-testid="waterIsDailyDelivery"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container direction={"row"} className={classes.productAssemblyItem}>
                    <Grid item>
                      <Checkbox
                        id={Fields.gasIsDailyDelivery}
                        name={Fields.gasIsDailyDelivery}
                        label={t("installation-form.gas-is-daily-delivery")}
                        value={formik.values[Fields.gasIsDailyDelivery]}
                        onChange={formik.handleChange}
                        checked={formik.values[Fields.gasIsDailyDelivery]}
                      />
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction={"row"} className={classes.productAssemblyItem}>
                    <Grid item>
                      <Checkbox
                        id={Fields.noDailyDeliveries}
                        name={Fields.noDailyDeliveries}
                        label={t("installation-form.no-daily-deliveries")}
                        value={formik.values[Fields.noDailyDeliveries]}
                        onChange={formik.handleChange}
                        checked={formik.values[Fields.noDailyDeliveries]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item className={classes.labelAndElement}>
                <StyleLabel
                  variant="h2"
                  labelText={t("installation-form.does-perform-installs-requiring-contractors")}
                />
              </Grid>
              <Grid item xs={12}>
                <YesNoInput
                  required
                  yesText={t("common.yes")}
                  noText={t("common.no")}
                  id={Fields.doesPerformInstallsRequiringContractors}
                  name={Fields.doesPerformInstallsRequiringContractors}
                  value={formik.values[Fields.doesPerformInstallsRequiringContractors]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched[Fields.doesPerformInstallsRequiringContractors] &&
                    Boolean(formik.errors[Fields.doesPerformInstallsRequiringContractors])
                  }
                  helperText={
                    formik.touched[Fields.doesPerformInstallsRequiringContractors] &&
                    formik.errors[Fields.doesPerformInstallsRequiringContractors]
                  }
                  data-testid="doesPerformInstallsRequiringContractors"
                />
              </Grid>
              {formik.values[Fields.doesPerformInstallsRequiringContractors] === YesNoValues.yes && (
                <Grid className={classes.installRequiringContractorLicense}>
                  <Grid item>
                    <Grid item className={classes.labelAndElement}>
                      <StyleLabel variant="h2" labelText={t("installation-form.is-licensed-contractor")} />
                    </Grid>
                    <YesNoInput
                      required
                      yesText={t("common.yes")}
                      noText={t("common.no")}
                      id={Fields.isLicensedContractor}
                      name={Fields.isLicensedContractor}
                      value={formik.values[Fields.isLicensedContractor]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched[Fields.isLicensedContractor] &&
                        Boolean(formik.errors[Fields.isLicensedContractor])
                      }
                      helperText={
                        formik.touched[Fields.isLicensedContractor] && formik.errors[Fields.isLicensedContractor]
                      }
                      data-testid="isLicensedContractor"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default InstallationForm;
