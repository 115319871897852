import { AuthenticationProvider } from "@chq/authentication";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { EnrollmentProvider } from "../data/enrollment";
import LastMileRoutes from "./last-mile";
import ApplicationPage from "./last-mile/pages/application-page";
import EmailLinkPage from "./last-mile/pages/email-link-page";
import { routes } from "./routes";
import MissingPage from "./shared/missing-page";
import UnauthorizedPage from "./shared/unauthorized-page";

const UnhandledError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const errorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={UnhandledError} onError={errorHandler}>
      <AuthenticationProvider>
        <EnrollmentProvider>
          <Router>
            <Switch>
              <Route exact path={routes.missingPage.path} component={MissingPage} />
              <Route exact path={routes.unauthorizePage.path} component={UnauthorizedPage} />
              <Route exact path={routes.emailLinkPage.path} component={EmailLinkPage} />
              <Route exact path={routes.applicationPage.path} component={ApplicationPage} />
              <Route path={routes.path}>
                <LastMileRoutes />
              </Route>
              <Redirect to={routes.missingPage.path} />
            </Switch>
          </Router>
        </EnrollmentProvider>
      </AuthenticationProvider>
    </ErrorBoundary>
  );
};

export default App;
