import { MultiSelect, NextButton } from "@chq/components";
import { MultiSelectItem } from "@chq/components/dist/multiselect";
import { OperationState, State as APIState } from "@chq/lastmiledelivery-api";
import { CircularProgress, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import * as yup from "yup";
import CoveragesDatesForm, {
  Fields,
  useFormikConfig,
  useValidationSchema as useCoveragesDatesValidation,
} from "../../../components/coverage-dates-form";
import { useChangeApplication, useDeleteOperationState, useGetApplication } from "../../../data/enrollment";
import { useScrollToTop } from "../../../data/useScrollToTop";
import { useStates } from "../../../data/useStates";
import { RouteNames } from "../../../utils/route-names";
import { routes } from "../../routes";
import PageWrapper from "../components/page-wrapper";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: "4rem",
  },
  basicInfoContainer: {
    display: "flex",
  },
  previousButton: {
    padding: "0",
    minWidth: "0",
  },
  pageButtons: {
    paddingTop: "1rem",
  },
  title: {
    marginBottom: "3.25rem",
  },
}));

const stateMapper = (state: MultiSelectItem): OperationState => {
  return {
    id: state.id,
    state: state.value as APIState,
  };
};

const useStatesValidationSchema = () => {
  const [t] = useTranslation();

  return yup
    .array()
    .min(1)
    .required(t(`errors.required`, { field: t(`coverage-dates-form.states.label`) }));
};

const CoverageDatesPage: React.FC = () => {
  const [t] = useTranslation();
  const [r] = useTranslation("regions");
  const classes = useStyles();

  const { data: application } = useGetApplication();
  const { mutate: editApplication, isLoading: isEditingApplication, isSuccess, isError } = useChangeApplication();

  const statesFormValidation = useStatesValidationSchema();
  const statesList = useStates();
  const { mutate: deleteStates, isLoading: isDeletingStates } = useDeleteOperationState();
  const formikProps = useFormikConfig({
    date: application?.effectiveDate || undefined,
    vehicles: application?.numberOfVehicles || undefined,
    years: application?.yearsInBusiness || undefined,
    providers: application?.logisticsProvider || undefined,
    liability: application?.coverageFleet || undefined,
    workersComp: application?.coverageWorkersComp || undefined,
    miles: application?.fleetAnnualMiles || undefined,
  });
  const validationSchema = useCoveragesDatesValidation({
    operationStates: statesFormValidation,
  });

  useScrollToTop();

  if (isSuccess) {
    return <Redirect to={routes.businessInfo.path} />;
  }

  if (isError) {
    return <Redirect to={routes.issue.path} />;
  }

  return (
    <PageWrapper>
      <Formik
        initialValues={{ ...formikProps.initialValues, operationStates: application?.operationStates || [] }}
        validateOnMount
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          if (window.LO && window.LO.visitor) {
            window.LO.visitor.identify(application?.id?.toString() || "", {
              email: application?.contactEmail,
              phone: application?.contactPhone,
            });
          }
          const removals = (application?.operationStates || []).filter(
            (existing) =>
              !values.operationStates.find((v) => {
                return existing.id === v.id;
              }),
          );
          deleteStates(removals, {
            onSettled: () =>
              editApplication({
                ...application,
                applicationState: RouteNames.businessInfo,
                operationStates: values.operationStates,
                effectiveDate: values[Fields.date],
                numberOfVehicles: values[Fields.vehicles],
                fleetAnnualMiles: values[Fields.miles],
                coverageFleet: values[Fields.liability],
                coverageWorkersComp: values[Fields.workersComp],
                logisticsProvider: values[Fields.providers],
                yearsInBusiness: values[Fields.years],
              }),
          });
        }}
      >
        {(formik) => (
          <Grid container direction="column" className={classes.container}>
            <Grid container item>
              <Grid item className={classes.basicInfoContainer}>
                <Typography variant="h1" align="left" component="h3" className={classes.title}>
                  {t("coverage-dates-page.title")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="column">
              <Grid item>
                <MultiSelect
                  fullWidth={true}
                  label={t(`coverage-dates-form.states.label`)}
                  required={true}
                  items={statesList
                    .map((state) => ({
                      name: state.name,
                      value: state.abv,
                    }))
                    .sort((a, b) => {
                      return a.name > b.name ? 1 : -1;
                    })}
                  values={formik.values.operationStates.map((state: OperationState) => {
                    return { id: state.id, name: r(`regions.US.${state.state}`), value: state.state as string };
                  })}
                  id="states"
                  limitTags={50}
                  setValue={(updatedValues: Array<MultiSelectItem>) => {
                    formik.setFieldValue(
                      "operationStates",
                      updatedValues.map((value) => stateMapper(value)),
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item direction="column" className={classes.basicInfoContainer}>
              <Grid item xs={12}>
                <CoveragesDatesForm />
              </Grid>
              <Grid container item direction="row" className={classes.pageButtons} spacing={2} xs={12}>
                <Grid item xs={10}>
                  <NextButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid || isDeletingStates || isEditingApplication}
                  >
                    {isDeletingStates || isEditingApplication ? (
                      <CircularProgress color="inherit" size="2rem" />
                    ) : (
                      t("coverage-dates-page.next")
                    )}
                  </NextButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default CoverageDatesPage;
