import { AuthenticationApi, UserIdRequest, UserIdResponseApiResponse } from "@chq/lastmiledelivery-api";
import { useMutation, UseMutationResult } from "react-query";
import { useApi } from "../useApi";

export const useCheckEmail = (): UseMutationResult<UserIdResponseApiResponse, Error | unknown, UserIdRequest> => {
  const authenticationApi = useApi(AuthenticationApi);
  const mutation = useMutation(async (request: UserIdRequest) => {
    const response = await authenticationApi.apiV10AuthenticationUserIdPost({ userIdRequest: request });

    return response;
  });
  return mutation;
};
