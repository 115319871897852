/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserRegisterResponse
 */
export interface UserRegisterResponse {
  /**
   *
   * @type {string}
   * @memberof UserRegisterResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegisterResponse
   */
  verifyEmailToken?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserRegisterResponse
   */
  verifyPhoneNumberToken?: string | null;
}

export function UserRegisterResponseFromJSON(json: any): UserRegisterResponse {
  return UserRegisterResponseFromJSONTyped(json, false);
}

export function UserRegisterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegisterResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    verifyEmailToken: !exists(json, "verifyEmailToken") ? undefined : json["verifyEmailToken"],
    verifyPhoneNumberToken: !exists(json, "verifyPhoneNumberToken") ? undefined : json["verifyPhoneNumberToken"],
  };
}

export function UserRegisterResponseToJSON(value?: UserRegisterResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    verifyEmailToken: value.verifyEmailToken,
    verifyPhoneNumberToken: value.verifyPhoneNumberToken,
  };
}
