import { CardField, EditableDeletableCard } from "@chq/components";
import { Divider, Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { routes } from "../ui/routes";

type Props = {
  attachedVehicleList?: string;
  attachedDriverList?: string;
  attachedLossRuns?: string;
  returnToWorkDocument?: string;
  loadDefaultForm: boolean;
  loadReturnToWork: boolean;
  loadWorkersComp: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundColor: theme.palette.grey[600],
    maxWidth: 1000,
    width: "100%",
  },
  cardField: {
    margin: ".2rem 0",
  },
  cardCopy: {
    "& .MuiTypography-root": {
      fontSize: ".875rem",
    },
  },
  coverageCopy: {
    fontWeight: 700,
  },
  content: {
    marginTop: ".5rem",
  },
  divider: {
    width: "100%",
    margin: "1rem 0",
  },
}));

const ReviewUploadDocumentsCard: React.FC<Props> = ({
  attachedVehicleList,
  attachedDriverList,
  attachedLossRuns,
  returnToWorkDocument,
  loadDefaultForm,
  loadReturnToWork,
  loadWorkersComp,
}) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Grid container item xs={12}>
      <EditableDeletableCard
        className={classes.background}
        variant="edit"
        title={t("upload-some-documents.title")}
        titleVariant="h2"
        titleComponent="h3"
        IconButtonProps={{ "aria-label": t("upload-some-documents.card.edit-button") }}
        onEdit={() => history.push(routes.documentsUpload.path)}
      >
        <Grid className={classes.content} container direction="column" wrap="nowrap">
          {loadDefaultForm && (
            <Grid container spacing={2} direction="row" wrap="nowrap">
              <Grid item xs={4} className={classes.cardField}>
                <CardField
                  direction="column"
                  wrap="nowrap"
                  className={classes.cardCopy}
                  id="attached-vehicle-list"
                  label={t("upload-some-documents.attached-vehicle-list")}
                  values={[{ value: attachedVehicleList }]}
                  labelGrid={12}
                  valueGrid={12}
                />
              </Grid>
              <Grid item xs={4} className={classes.cardField}>
                <CardField
                  direction="column"
                  wrap="nowrap"
                  className={classes.cardCopy}
                  id="attached-driver-list"
                  label={t("upload-some-documents.attached-driver-list")}
                  values={[{ value: attachedDriverList }]}
                  labelGrid={12}
                  valueGrid={12}
                />
              </Grid>
              <Grid item xs={4} className={classes.cardField}>
                <CardField
                  direction="column"
                  wrap="nowrap"
                  className={classes.cardCopy}
                  id="attached-loss-runs"
                  label={t("upload-some-documents.attached-loss-runs")}
                  values={[{ value: attachedLossRuns }]}
                  labelGrid={12}
                  valueGrid={12}
                />
              </Grid>
            </Grid>
          )}
          {loadWorkersComp && loadReturnToWork && (
            <Grid container direction="column" wrap="nowrap">
              {loadDefaultForm && <Divider className={classes.divider} />}
              <Grid item xs={12} className={classes.cardField}>
                <CardField
                  direction="column"
                  wrap="nowrap"
                  className={classes.cardCopy}
                  id="wc-return-to-work"
                  label={t("upload-some-documents.return-to-work")}
                  values={[{ value: returnToWorkDocument }]}
                  labelGrid={12}
                  valueGrid={12}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </EditableDeletableCard>
    </Grid>
  );
};

export default ReviewUploadDocumentsCard;
