/* tslint:disable */
/* eslint-disable */
/**
 * CHQ Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PdfDocumentType {
  EDeliveryConsentForm = "EDeliveryConsentForm",
  InsuranceApplication = "InsuranceApplication",
  UmuimCoverage = "UmuimCoverage",
  FraudStatement = "FraudStatement",
  CertificateOfInsurance = "CertificateOfInsurance",
  RrgMembershipForm = "RrgMembershipForm",
  SpeedgaugeConsentForm = "SpeedgaugeConsentForm",
}

export function PdfDocumentTypeFromJSON(json: any): PdfDocumentType {
  return PdfDocumentTypeFromJSONTyped(json, false);
}

export function PdfDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfDocumentType {
  return json as PdfDocumentType;
}

export function PdfDocumentTypeToJSON(value?: PdfDocumentType | null): any {
  return value as any;
}
