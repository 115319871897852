/* tslint:disable */
/* eslint-disable */
/**
 * CHQ LMD Enrollment API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  GenerateTokenChangeEmailRequest,
  GenerateTokenChangeEmailRequestFromJSON,
  GenerateTokenChangeEmailRequestToJSON,
  GenerateTokenChangeEmailResponseApiResponse,
  GenerateTokenChangeEmailResponseApiResponseFromJSON,
  GenerateTokenChangeEmailResponseApiResponseToJSON,
  GenerateTokenChangePhoneRequest,
  GenerateTokenChangePhoneRequestFromJSON,
  GenerateTokenChangePhoneRequestToJSON,
  GenerateTokenChangePhoneResponseApiResponse,
  GenerateTokenChangePhoneResponseApiResponseFromJSON,
  GenerateTokenChangePhoneResponseApiResponseToJSON,
  GenerateTokenConfirmEmailRequest,
  GenerateTokenConfirmEmailRequestFromJSON,
  GenerateTokenConfirmEmailRequestToJSON,
  GenerateTokenConfirmEmailResponseApiResponse,
  GenerateTokenConfirmEmailResponseApiResponseFromJSON,
  GenerateTokenConfirmEmailResponseApiResponseToJSON,
  GenerateTokenPasswordResetRequest,
  GenerateTokenPasswordResetRequestFromJSON,
  GenerateTokenPasswordResetRequestToJSON,
  GenerateTokenPasswordResetResponseApiResponse,
  GenerateTokenPasswordResetResponseApiResponseFromJSON,
  GenerateTokenPasswordResetResponseApiResponseToJSON,
  GenerateTokenSignInRequest,
  GenerateTokenSignInRequestFromJSON,
  GenerateTokenSignInRequestToJSON,
  GenerateTokenSignInResponseApiResponse,
  GenerateTokenSignInResponseApiResponseFromJSON,
  GenerateTokenSignInResponseApiResponseToJSON,
  PasswordSignInRequest,
  PasswordSignInRequestFromJSON,
  PasswordSignInRequestToJSON,
  StringApiResponse,
  StringApiResponseFromJSON,
  StringApiResponseToJSON,
  TokenChangeEmailRequest,
  TokenChangeEmailRequestFromJSON,
  TokenChangeEmailRequestToJSON,
  TokenChangePhoneRequest,
  TokenChangePhoneRequestFromJSON,
  TokenChangePhoneRequestToJSON,
  TokenConfirmEmailRequest,
  TokenConfirmEmailRequestFromJSON,
  TokenConfirmEmailRequestToJSON,
  TokenPasswordResetRequest,
  TokenPasswordResetRequestFromJSON,
  TokenPasswordResetRequestToJSON,
  TokenSignInRequest,
  TokenSignInRequestFromJSON,
  TokenSignInRequestToJSON,
  UserAddPasswordRequest,
  UserAddPasswordRequestFromJSON,
  UserAddPasswordRequestToJSON,
  UserCreateRequest,
  UserCreateRequestFromJSON,
  UserCreateRequestToJSON,
  UserCreateResponseApiResponse,
  UserCreateResponseApiResponseFromJSON,
  UserCreateResponseApiResponseToJSON,
  UserEmailRequest,
  UserEmailRequestFromJSON,
  UserEmailRequestToJSON,
  UserEmailResponseApiResponse,
  UserEmailResponseApiResponseFromJSON,
  UserEmailResponseApiResponseToJSON,
  UserIdRequest,
  UserIdRequestFromJSON,
  UserIdRequestToJSON,
  UserIdResponseApiResponse,
  UserIdResponseApiResponseFromJSON,
  UserIdResponseApiResponseToJSON,
  UserRegisterRequest,
  UserRegisterRequestFromJSON,
  UserRegisterRequestToJSON,
  UserRegisterResponseApiResponse,
  UserRegisterResponseApiResponseFromJSON,
  UserRegisterResponseApiResponseToJSON,
  UserStatusRequest,
  UserStatusRequestFromJSON,
  UserStatusRequestToJSON,
  UserStatusResponseApiResponse,
  UserStatusResponseApiResponseFromJSON,
  UserStatusResponseApiResponseToJSON,
} from "../models";

export interface ApiV10AuthenticationGenerateTokenChangeEmailPostRequest {
  generateTokenChangeEmailRequest?: GenerateTokenChangeEmailRequest;
}

export interface ApiV10AuthenticationGenerateTokenChangePhonePostRequest {
  generateTokenChangePhoneRequest?: GenerateTokenChangePhoneRequest;
}

export interface ApiV10AuthenticationGenerateTokenConfirmEmailPostRequest {
  generateTokenConfirmEmailRequest?: GenerateTokenConfirmEmailRequest;
}

export interface ApiV10AuthenticationGenerateTokenPasswordResetPostRequest {
  generateTokenPasswordResetRequest?: GenerateTokenPasswordResetRequest;
}

export interface ApiV10AuthenticationGenerateTokenSignInPostRequest {
  generateTokenSignInRequest?: GenerateTokenSignInRequest;
}

export interface ApiV10AuthenticationPasswordSignInPostRequest {
  passwordSignInRequest?: PasswordSignInRequest;
}

export interface ApiV10AuthenticationTokenChangeEmailPostRequest {
  tokenChangeEmailRequest?: TokenChangeEmailRequest;
}

export interface ApiV10AuthenticationTokenChangePhonePostRequest {
  tokenChangePhoneRequest?: TokenChangePhoneRequest;
}

export interface ApiV10AuthenticationTokenConfirmEmailPostRequest {
  tokenConfirmEmailRequest?: TokenConfirmEmailRequest;
}

export interface ApiV10AuthenticationTokenPasswordResetPostRequest {
  tokenPasswordResetRequest?: TokenPasswordResetRequest;
}

export interface ApiV10AuthenticationTokenSignInPostRequest {
  tokenSignInRequest?: TokenSignInRequest;
}

export interface ApiV10AuthenticationUserAddPasswordPostRequest {
  userAddPasswordRequest?: UserAddPasswordRequest;
}

export interface ApiV10AuthenticationUserCreateEmailPostRequest {
  userCreateRequest?: UserCreateRequest;
}

export interface ApiV10AuthenticationUserEmailPostRequest {
  userEmailRequest?: UserEmailRequest;
}

export interface ApiV10AuthenticationUserIdPostRequest {
  userIdRequest?: UserIdRequest;
}

export interface ApiV10AuthenticationUserRegisterPostRequest {
  userRegisterRequest?: UserRegisterRequest;
}

export interface ApiV10AuthenticationUserStatusPostRequest {
  userStatusRequest?: UserStatusRequest;
}

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   */
  async apiV10AuthenticationGenerateTokenChangeEmailPostRaw(
    requestParameters: ApiV10AuthenticationGenerateTokenChangeEmailPostRequest,
  ): Promise<runtime.ApiResponse<GenerateTokenChangeEmailResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/generate-token-change-email`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenerateTokenChangeEmailRequestToJSON(requestParameters.generateTokenChangeEmailRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GenerateTokenChangeEmailResponseApiResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenChangeEmailPost(
    requestParameters: ApiV10AuthenticationGenerateTokenChangeEmailPostRequest,
  ): Promise<GenerateTokenChangeEmailResponseApiResponse> {
    const response = await this.apiV10AuthenticationGenerateTokenChangeEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenChangePhonePostRaw(
    requestParameters: ApiV10AuthenticationGenerateTokenChangePhonePostRequest,
  ): Promise<runtime.ApiResponse<GenerateTokenChangePhoneResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/generate-token-change-phone`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenerateTokenChangePhoneRequestToJSON(requestParameters.generateTokenChangePhoneRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GenerateTokenChangePhoneResponseApiResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenChangePhonePost(
    requestParameters: ApiV10AuthenticationGenerateTokenChangePhonePostRequest,
  ): Promise<GenerateTokenChangePhoneResponseApiResponse> {
    const response = await this.apiV10AuthenticationGenerateTokenChangePhonePostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenConfirmEmailPostRaw(
    requestParameters: ApiV10AuthenticationGenerateTokenConfirmEmailPostRequest,
  ): Promise<runtime.ApiResponse<GenerateTokenConfirmEmailResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/generate-token-confirm-email`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenerateTokenConfirmEmailRequestToJSON(requestParameters.generateTokenConfirmEmailRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GenerateTokenConfirmEmailResponseApiResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenConfirmEmailPost(
    requestParameters: ApiV10AuthenticationGenerateTokenConfirmEmailPostRequest,
  ): Promise<GenerateTokenConfirmEmailResponseApiResponse> {
    const response = await this.apiV10AuthenticationGenerateTokenConfirmEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenPasswordResetPostRaw(
    requestParameters: ApiV10AuthenticationGenerateTokenPasswordResetPostRequest,
  ): Promise<runtime.ApiResponse<GenerateTokenPasswordResetResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/generate-token-password-reset`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenerateTokenPasswordResetRequestToJSON(requestParameters.generateTokenPasswordResetRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GenerateTokenPasswordResetResponseApiResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenPasswordResetPost(
    requestParameters: ApiV10AuthenticationGenerateTokenPasswordResetPostRequest,
  ): Promise<GenerateTokenPasswordResetResponseApiResponse> {
    const response = await this.apiV10AuthenticationGenerateTokenPasswordResetPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenSignInPostRaw(
    requestParameters: ApiV10AuthenticationGenerateTokenSignInPostRequest,
  ): Promise<runtime.ApiResponse<GenerateTokenSignInResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/generate-token-sign-in`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenerateTokenSignInRequestToJSON(requestParameters.generateTokenSignInRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GenerateTokenSignInResponseApiResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async apiV10AuthenticationGenerateTokenSignInPost(
    requestParameters: ApiV10AuthenticationGenerateTokenSignInPostRequest,
  ): Promise<GenerateTokenSignInResponseApiResponse> {
    const response = await this.apiV10AuthenticationGenerateTokenSignInPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationPasswordSignInPostRaw(
    requestParameters: ApiV10AuthenticationPasswordSignInPostRequest,
  ): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/password-sign-in`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PasswordSignInRequestToJSON(requestParameters.passwordSignInRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationPasswordSignInPost(
    requestParameters: ApiV10AuthenticationPasswordSignInPostRequest,
  ): Promise<StringApiResponse> {
    const response = await this.apiV10AuthenticationPasswordSignInPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationTokenChangeEmailPostRaw(
    requestParameters: ApiV10AuthenticationTokenChangeEmailPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/token-change-email`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenChangeEmailRequestToJSON(requestParameters.tokenChangeEmailRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10AuthenticationTokenChangeEmailPost(
    requestParameters: ApiV10AuthenticationTokenChangeEmailPostRequest,
  ): Promise<void> {
    await this.apiV10AuthenticationTokenChangeEmailPostRaw(requestParameters);
  }

  /**
   */
  async apiV10AuthenticationTokenChangePhonePostRaw(
    requestParameters: ApiV10AuthenticationTokenChangePhonePostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/token-change-phone`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenChangePhoneRequestToJSON(requestParameters.tokenChangePhoneRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10AuthenticationTokenChangePhonePost(
    requestParameters: ApiV10AuthenticationTokenChangePhonePostRequest,
  ): Promise<void> {
    await this.apiV10AuthenticationTokenChangePhonePostRaw(requestParameters);
  }

  /**
   */
  async apiV10AuthenticationTokenConfirmEmailPostRaw(
    requestParameters: ApiV10AuthenticationTokenConfirmEmailPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/token-confirm-email`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenConfirmEmailRequestToJSON(requestParameters.tokenConfirmEmailRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10AuthenticationTokenConfirmEmailPost(
    requestParameters: ApiV10AuthenticationTokenConfirmEmailPostRequest,
  ): Promise<void> {
    await this.apiV10AuthenticationTokenConfirmEmailPostRaw(requestParameters);
  }

  /**
   */
  async apiV10AuthenticationTokenPasswordResetPostRaw(
    requestParameters: ApiV10AuthenticationTokenPasswordResetPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/token-password-reset`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenPasswordResetRequestToJSON(requestParameters.tokenPasswordResetRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10AuthenticationTokenPasswordResetPost(
    requestParameters: ApiV10AuthenticationTokenPasswordResetPostRequest,
  ): Promise<void> {
    await this.apiV10AuthenticationTokenPasswordResetPostRaw(requestParameters);
  }

  /**
   */
  async apiV10AuthenticationTokenSignInPostRaw(
    requestParameters: ApiV10AuthenticationTokenSignInPostRequest,
  ): Promise<runtime.ApiResponse<StringApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/token-sign-in`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TokenSignInRequestToJSON(requestParameters.tokenSignInRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationTokenSignInPost(
    requestParameters: ApiV10AuthenticationTokenSignInPostRequest,
  ): Promise<StringApiResponse> {
    const response = await this.apiV10AuthenticationTokenSignInPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationUserAddPasswordPostRaw(
    requestParameters: ApiV10AuthenticationUserAddPasswordPostRequest,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-add-password`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserAddPasswordRequestToJSON(requestParameters.userAddPasswordRequest),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async apiV10AuthenticationUserAddPasswordPost(
    requestParameters: ApiV10AuthenticationUserAddPasswordPostRequest,
  ): Promise<void> {
    await this.apiV10AuthenticationUserAddPasswordPostRaw(requestParameters);
  }

  /**
   */
  async apiV10AuthenticationUserCreateEmailPostRaw(
    requestParameters: ApiV10AuthenticationUserCreateEmailPostRequest,
  ): Promise<runtime.ApiResponse<UserCreateResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-create-email`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserCreateRequestToJSON(requestParameters.userCreateRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserCreateResponseApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationUserCreateEmailPost(
    requestParameters: ApiV10AuthenticationUserCreateEmailPostRequest,
  ): Promise<UserCreateResponseApiResponse> {
    const response = await this.apiV10AuthenticationUserCreateEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationUserCreatePostRaw(): Promise<runtime.ApiResponse<UserCreateResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-create`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserCreateResponseApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationUserCreatePost(): Promise<UserCreateResponseApiResponse> {
    const response = await this.apiV10AuthenticationUserCreatePostRaw();
    return await response.value();
  }

  /**
   * Gets the users email from their identity Id guid.
   */
  async apiV10AuthenticationUserEmailPostRaw(
    requestParameters: ApiV10AuthenticationUserEmailPostRequest,
  ): Promise<runtime.ApiResponse<UserEmailResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-email`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserEmailRequestToJSON(requestParameters.userEmailRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserEmailResponseApiResponseFromJSON(jsonValue));
  }

  /**
   * Gets the users email from their identity Id guid.
   */
  async apiV10AuthenticationUserEmailPost(
    requestParameters: ApiV10AuthenticationUserEmailPostRequest,
  ): Promise<UserEmailResponseApiResponse> {
    const response = await this.apiV10AuthenticationUserEmailPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationUserIdPostRaw(
    requestParameters: ApiV10AuthenticationUserIdPostRequest,
  ): Promise<runtime.ApiResponse<UserIdResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-id`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserIdRequestToJSON(requestParameters.userIdRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserIdResponseApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationUserIdPost(
    requestParameters: ApiV10AuthenticationUserIdPostRequest,
  ): Promise<UserIdResponseApiResponse> {
    const response = await this.apiV10AuthenticationUserIdPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationUserRegisterPostRaw(
    requestParameters: ApiV10AuthenticationUserRegisterPostRequest,
  ): Promise<runtime.ApiResponse<UserRegisterResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-register`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserRegisterRequestToJSON(requestParameters.userRegisterRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserRegisterResponseApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationUserRegisterPost(
    requestParameters: ApiV10AuthenticationUserRegisterPostRequest,
  ): Promise<UserRegisterResponseApiResponse> {
    const response = await this.apiV10AuthenticationUserRegisterPostRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async apiV10AuthenticationUserStatusPostRaw(
    requestParameters: ApiV10AuthenticationUserStatusPostRequest,
  ): Promise<runtime.ApiResponse<UserStatusResponseApiResponse>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      if (typeof this.configuration.accessToken === "function") {
        headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
      } else {
        headerParameters["Authorization"] = this.configuration.accessToken;
      }
    }

    const response = await this.request({
      path: `/api/v1.0/Authentication/user-status`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserStatusRequestToJSON(requestParameters.userStatusRequest),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => UserStatusResponseApiResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV10AuthenticationUserStatusPost(
    requestParameters: ApiV10AuthenticationUserStatusPostRequest,
  ): Promise<UserStatusResponseApiResponse> {
    const response = await this.apiV10AuthenticationUserStatusPostRaw(requestParameters);
    return await response.value();
  }
}
