import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import React from "react";
import PageWrapper from "../components/page-wrapper";
import loginRegistrationBackground from "../images/login-registration-bg.jpg";

const useStyles = makeStyles((theme: Theme) => ({
  backgroundImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${loginRegistrationBackground})`,
  },
  card: {
    borderRadius: ".5rem",
    margin: "10% auto",
    padding: "2.5rem",
    maxWidth: "32.75rem",
  },
  fullHeight: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  title: {
    margin: "3rem 0",
    lineHeight: "2.5rem",
  },
}));

type Props = {
  title?: string;
};

const LoginVerifyLayout: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();
  const imageUrl = { loginRegistrationBackground };

  return (
    <PageWrapper background={imageUrl.loginRegistrationBackground}>
      <Paper className={classes.card} elevation={0}>
        <Grid container direction="column">
          <Grid container direction="column">
            {children}
          </Grid>
        </Grid>
      </Paper>
    </PageWrapper>
  );
};

export default LoginVerifyLayout;
